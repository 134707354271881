import React, { createRef } from "react";
import { Api, Constants, Session } from "fsy.common-library";
import { Loading } from "../general/form/Loading";
import Helper from "../../services/Helper";
import HtmlStructure from "../general/HtmlStructure";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import { InputField, RequiredText, TextareaField } from "../general/form/Input";
import { SelectField } from "../general/form/Select";
import ReactSwitch from "react-switch";
import _ from "lodash";
import moment from "moment/moment";
import { Nav, Navs } from "../general/Navs/Navs";
import CriterionNumericRow from "./AidCriterion/CriterionNumericRow";
import {
  AID_STATUS_DRAFT,
  AID_STATUS_REFUSED,
  AID_STATUS_VALIDATED,
  AID_STATUS_VALIDATING,
  ASCENDING_SORT_DIRECTION,
  HTTP_CREATED,
  HTTP_REFERENCE_DATA_ALREADY_EXIST,
} from "fsy.common-library/lib/env/Constants";
import CriterionOBGRow from "./AidCriterion/CriterionOBGRow";
import CriterionTXTRow from "./AidCriterion/CriterionTXTRow";
import CriterionLOCRow from "./AidCriterion/CriterionLOCRow";
import { Divider } from "@mui/material";
import { AidActivities } from "./AidActivities";
import fsyTheme from "../general/form/FsyTheme";
import { ThemeProvider } from "@mui/material";
import SliderRange from "../general/form/SliderRange";
import AidHelper from "../../services/AidHelper";

import "./aidForm.css";
import { Add, OpenInNew, Save, Delete } from "@mui/icons-material";
import { RIGHTS_NOACCESS, RIGHTS_WRITE } from "../../services/Constants";
import Modal from "../general/form/Modal";
import AidFundingWithCriterions from "./AidFundingWithCriterions/AidFundingWithCriterions";
import AidFundingTable from "./AidFundingWithCriterions/AidFundingTable/AidFundingTable";

export default function AidEditPage() {
  document.title = "Aides - " + Constants.DOCUMENT_TITLE_BACKOFFICE;

  return (
    <HtmlStructure menuName="aids" sectionClassName="aids">
      <AidForm />
    </HtmlStructure>
  );
}

export class AidForm extends React.Component {
  static defaultProps = { type: "create", aid: null };

  REQUIRED_FIELDS_DRAFT = ["name", "organization", "nature"];
  REQUIRED_FIELDS = [
    "name",
    "description",
    "organization",
    "nature",
    "form",
    "funding",
    "taxCreditPercent",
    "contactName",
    "documentUrl",
    "requestUrl",
    "status",
  ];
  REQUIRED_FIELDS_NO_SIMULABLE_AID = [
    "name",
    "description",
    "organization",
    "nature",
    "taxCreditPercent",
    "contactName",
    "documentUrl",
    "requestUrl",
    "status",
  ];

  constructor(props) {
    super(props);

    this.typeOrganism = [
      Constants.ORGANISM_TYPE_FSY,
      Constants.ORGANISM_TYPE_ORGANISM,
      Constants.ORGANISM_TYPE_PARTNER,
      Constants.ORGANISM_TYPE_OTHER,
    ];

    this.aidCommentFormRef = createRef();
    this.aidCommentTableTitleRef = createRef();
    this.user = Session.getSessionUser();
    this.ADDITIONAL_REMARK_MAX_CHARACTER = 255;
    this.DETAILED_REMARK_MAX_CHARACTER = 1600;

    this.statusOptions = [
      {
        label: "statut",
        icon: "fa-list-check",
        options: [
          { value: AID_STATUS_DRAFT, label: "Brouillon" },
          {
            value: AID_STATUS_VALIDATING,
            label: "À valider",
          },
          { value: AID_STATUS_REFUSED, label: "Refusée" },
          { value: AID_STATUS_VALIDATED, label: "Validée" },
        ],
      },
    ];

    this.textAidFundingCriterionShortNames = [
      "travaux_09",
      "descrip_13",
      "descrip_14",
      "general_02",
    ];

    this.aidFixedFundingData = {
      [Constants.AID_FUNDING_FIXED_AMOUNT]: [
        {
          label: "Montant à l'hectare",
          shortName: "amount",
          unit: "€",
        },
      ],
      [Constants.AID_FUNDING_SCALE]: [
        {
          label: "Montant de base",
          shortName: "baseAmount",
          unit: "€/Ha",
        },
        {
          label: "Taux de l'aide",
          shortName: "rate",
          unit: "%",
        },
      ],
      [Constants.AID_FUNDING_PLAN]: [
        {
          label: "Montant au plant",
          shortName: "amount",
          unit: "€",
        },
        {
          label: "Densité",
          shortName: "density",
        },
      ],
    };

    this.state = {
      loading: true,
      aid: getEmptyAidObject(),
      organismList: [],
      natureList: [],
      complexityList: [],
      schemesList: [],
      environmentsList: [],
      criterionList: [],
      formList: [],
      fundingList: [],
      aidFundingPlans: [],
      aidFundingScales: [],
      aidFundingFixedAmounts: [],
      speciesGroup: [],
      organismListFiltered: [{ label: "Organisme", options: [] }],
      aidHasData: false,
      aidId: null,
      aidStatus: null,
      aidActive: null,
      aidEdition: true,
      aidInitialStatus: null,
      aidCriterions: {},
      selectVersionId: null,
      lastVersion: null,
      lastActiveVersion: null,
      aidParent: null,
      forceLocation: false,
      startDate: new Date(),
      activeLabel: "",
      listVersionOptions: [],
      requiredFields: [],
      requiredNumericFields: false,
      checkField: false,
      currentThemeMenu: "Global",
      zoneList: [],
      regionList: [],
      departmentlist: [],
      themeList: [],
      activeThemeContainerId: "",
      disabledFields: [],
      canValidate: this.props.canValidate,
      canEdit: false,
      folders: [],
      subfolders: [],
      comments: [], // Les commentaires déja enregistrés en BDD au cas de modification d'une aide
      newAidComments: [], // Les commentaires qui seront ajoutés
      hasPublishedAid: false,
      isAidSchemeDialogShowing: false,
      isAidSchemeFormUpdated: false,
      isRegionDialogShowing: false,
      isRegionFormUpdated: false,
      regionError: false,
      newRegion: getEmptyNewRegionDataObject(),
      currentRegion: getEmptyNewRegionDataObject(),
      newAddedRegion: null,
      aidSchemeData: getEmptyAidSchemeObject(),
      aidSchemeErrorData: getEmptyAidSchemeErrorObject(),

      aidFundingFormMode: "creation",
      isAidFundingFormShowing: false,
      isAidFundingFormUpdated: false,
      aidFundingCriterionValues: [],
      currentAidFundingData: null,
      aidFundingCriterions: getEmptyAidFundingCriterions(),
    };

    this.criterionThemeIds = [];

    this.initFunctions();
    this.initRef();
  }

  componentDidMount() {
    (async function () {
      if (this.props.type === "edit") {
        await this.editAid(this.props.aid.id);
      } else {
        this.setState({ aid: getEmptyAidObject(), aidHasData: false });
        this.setActiveLabel(false);
        this.setStatus(AID_STATUS_DRAFT); // brouillon par défaut
      }

      Promise.all([
        Api.criterionTheme.getCriterionThemes(),
        Api.organization.getOrganizations(),
        Api.aid.getAidNatures(),
        Api.aid.getAidComplexities(),
        Api.aid.getAidSchemes(),
        Api.aid.getAidEnvironments(),
        Api.aid.getAidForms(),
        Api.aid.getAidFundings(),
        Api.speciesGroup.getSpeciesGroups(),
        Api.zone.getActiveZones(),
        Api.region.getRegions(1, { active: true, "order[position]": "ASC" }),
        Api.department.getDepartments(),
        Api.folder.getFolders(1, {
          folderType: 1,
          pagination: false,
          [`order[name]`]: ASCENDING_SORT_DIRECTION,
        }),
        Api.aidComment.getAidComments(this.props.aid?.id || null),
        Api.criteria.getActiveCriterionByFunding(),
      ]).then(
        ([
          CriterionThemeResponse,
          orgResponse,
          natureResponse,
          complexityResponse,
          schemeResponse,
          envResponse,
          formResponse,
          fundResponse,
          speciesGroupResponse,
          zonesResponse,
          regionsResponse,
          departmentResponse,
          folderResponse,
          aidComments,
          aidFundingCriterions,
        ]) => {
          const themes = Helper.isValidResponse(CriterionThemeResponse);
          const organizations = Helper.isValidResponse(orgResponse);
          const natures = Helper.isValidResponse(natureResponse);
          const complexities = Helper.isValidResponse(complexityResponse);
          const schemes = Helper.isValidResponse(schemeResponse);
          const environments = Helper.isValidResponse(envResponse);
          const forms = Helper.isValidResponse(formResponse);
          const fundings = Helper.isValidResponse(fundResponse);
          const speciesGroup = Helper.isValidResponse(speciesGroupResponse);
          const zones = Helper.isValidResponse(zonesResponse);
          const regions = Helper.isValidResponse(regionsResponse);
          const departments = Helper.isValidResponse(departmentResponse);
          const folders = Helper.isValidResponse(folderResponse);
          const comments = Helper.isValidResponse(aidComments);
          const criterions = Helper.isValidResponse(aidFundingCriterions);

          let themeList = !themes ? [] : formatThemeList(themes);
          const organismList = !organizations
            ? []
            : organizations.filter((o) => {
                const userOrganization = Session.getSessionUser().organization;
                if (userOrganization.organism) {
                  return o.id === userOrganization.id;
                }

                return true;
              });
          const natureList = !natures ? [] : formatSelectorOptions(natures);
          const complexityList = !complexities
            ? []
            : formatSelectorOptions(complexities);
          const schemesList = !schemes ? [] : formatSelectorOptions(schemes);
          const environmentsList = !environments
            ? []
            : formatSelectorOptions(environments);
          const formList = !forms ? [] : formatSelectorOptions(forms);
          const zoneList = !zones ? [] : zones;
          const regionList = !regions ? [] : regions;
          const departmentlist = !departments ? [] : departments;
          const fundingList = !fundings
            ? []
            : _.map(fundings, (element) => {
                return {
                  value: element.id,
                  label: element.label,
                  shortName: element.shortName,
                  object: element,
                };
              });
          const folderList = !folders
            ? []
            : _.map(folders, (element) => {
                let subfolders = [];

                if (element.subfolders) {
                  element.subfolders = Object.values(element.subfolders);

                  element.subfolders.map((subfolder) => {
                    subfolders.push({
                      value: subfolder.id,
                      label: subfolder.name,
                    });

                    return null;
                  });

                  subfolders.sort((a, b) => {
                    let label1 = a.label;
                    let label2 = b.label;

                    return label1.localeCompare(label2, "fr", {
                      sensitivity: "base",
                    });
                  });
                }

                return {
                  value: element.id,
                  label: element.name,
                  subfolders: subfolders,
                };
              });

          _.each(themes, (t) => {
            this.criterionThemeIds.push(t.id);
          });
          const aidFundingCriterionsObject = criterions?.data
            ? criterions.data
            : getEmptyAidFundingCriterions();
          const obgTexts = criterions?.obgTexts ? criterions?.obgTexts : [];

          Object.entries(aidFundingCriterionsObject).forEach(
            ([key, values]) => {
              values.map((value) => {
                let criterionShortName = value.shortName;

                if (
                  [
                    Constants.CRITERION_TYPE_TXT,
                    Constants.CRITERION_TYPE_OBG,
                  ].includes(value.type.shortName)
                ) {
                  value.criterionValues = Object.values(value.criterionValues);
                }

                if (value.type.shortName === Constants.CRITERION_TYPE_TXT) {
                  value.criterionValues =
                    value.criterionValues?.filter(
                      (element) => element.active
                    ) || [];

                  value.criterionValues.sort((a, b) => a.position - b.position);
                }

                if (value.type.shortName === Constants.CRITERION_TYPE_OBG) {
                  let obgData =
                    obgTexts.find(
                      (element) => element.shortName === criterionShortName
                    ) || {};

                  value.criterionValues = [
                    {
                      id: "yes",
                      value: obgData["obgText1"] ?? "Oui",
                      active: true,
                    },
                    {
                      id: "no",
                      value: obgData["obgText2"] ?? "Non",
                      active: true,
                    },
                  ];
                }

                return null;
              });
            }
          );

          if (speciesGroup) {
            speciesGroup.map((group) => {
              group.species.sort((specie1, specie2) => {
                let label1 = specie1.label;
                let label2 = specie2.label;

                return label1.localeCompare(label2, "fr", {
                  sensitivity: "base",
                });
              });

              return null;
            });
          }

          if (comments) {
            this.setState({ comments });
          }

          Api.criteria
            .getCriterion(1, {
              "order[position]": "ASC",
              "groupBy[theme]": "true",
              active: true,
              simulatorOnly: false,
            })
            .then((response) => {
              const criterion = Helper.isValidResponse(response);
              themeList = _countCriterionTheme(criterion, themeList);
              this.setState(
                {
                  natureList: natureList,
                  organismList: organismList,
                  complexityList: complexityList,
                  schemesList: schemesList,
                  environmentsList: environmentsList,
                  formList: formList,
                  fundingList: fundingList,
                  criterionList: !criterion ? [] : criterion,
                  speciesGroup: speciesGroup,
                  themeList: themeList,
                  zoneList: zoneList,
                  regionList: regionList,
                  departmentlist: departmentlist,
                  folders: folderList,
                  aidFundingCriterions: aidFundingCriterionsObject,
                  loading: false,
                },
                () => {
                  this._updateOrganismList();

                  const container =
                    document.getElementsByClassName("bo-navs-content")[0];
                  if (container) {
                    container.addEventListener("scroll", this.scrollSpy);
                  }
                }
              );
            });
        }
      );
    }).bind(this)();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.aid.status !== prevState.aid.status) {
      if (
        this.state.aid.status &&
        this.state.aid.status.value === AID_STATUS_DRAFT
      ) {
        this.setState({
          requiredFields: this.REQUIRED_FIELDS_DRAFT,
          requiredNumericFields: false,
        });
      } else {
        this.setState({
          requiredFields: this.REQUIRED_FIELDS,
          requiredNumericFields: true,
        });
      }
    }

    if (this.state.aid.id !== prevState.aid.id && this.state.lastVersion) {
      const userOrganization = Session.getSessionUser().organization;
      const organizationCreator = this.props.aid.organizationCreator;
      const aidOrganization = this.props.aid.organization;
      const isValided = userOrganization.organism
        ? userOrganization.id === aidOrganization?.id
        : true;

      const can_edit =
        (this.state.canValidate && isValided) ||
        (userOrganization.organism &&
          userOrganization.id === aidOrganization.id &&
          this.props.aid?.status !== AID_STATUS_VALIDATING) || //case: user can validate so have full access
        (!this.state.canValidate &&
          !this.props.readOnly &&
          this.state.aid.status === AID_STATUS_DRAFT &&
          ((!userOrganization.organism && !userOrganization.partner) || // Les organismes qui ne sont ni partenaire ni organisme peut tout voir et editer
            (userOrganization.partner &&
              userOrganization.id === organizationCreator.id) ||
            (userOrganization.organism &&
              userOrganization.id === aidOrganization.id))) || // case: user can only edit draft and send drafts to validation
        (userOrganization.partner &&
          this.props.aid?.status !== AID_STATUS_VALIDATING &&
          this.props.aid?.creator?.organization?.partner &&
          Session.getSessionUser().id === this.props.aid?.creator?.id); // case: partner can update aids created by him/her

      this.setState({
        canEdit: can_edit,
        aidEdition:
          can_edit &&
          this.state.aid.status !== AID_STATUS_VALIDATED &&
          (this.state.aid.id === this.state.lastVersion.id ||
            !this.state.aid.id),
      });
    }

    if (
      this.state.aid.simulable !== prevState.aid.simulable ||
      this.state.aid.status !== prevState.aid.status
    ) {
      if (!this.state.aid.simulable) {
        this.setState({
          requiredFields:
            this.state.aid.status?.value === AID_STATUS_DRAFT
              ? this.REQUIRED_FIELDS_DRAFT
              : this.REQUIRED_FIELDS_NO_SIMULABLE_AID,
          requiredNumericFields: false,
        });
      }
    }

    if (
      prevState.isRegionDialogShowing !== this.state.isRegionDialogShowing &&
      this.state.isRegionDialogShowing
    ) {
      setTimeout(() => {
        const regionForm = document.getElementById("new-region-form");

        if (regionForm) {
          regionForm.focus();
        }
      }, 500);
    }
  }

  initFunctions() {
    this.editAid = this.editAid.bind(this);
    this.initStateObjects = this.initStateObjects.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleSimulableChange = this.handleSimulableChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleVersionSelect = this.handleVersionSelect.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.handleOrganismChange = this.handleOrganismChange.bind(this);
    this.handleNatureChange = this.handleNatureChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFundingChange = this.handleFundingChange.bind(this);
    this.handleComplexityChange = this.handleComplexityChange.bind(this);
    this.handleSchemeChange = this.handleSchemeChange.bind(this);
    this.handleEnvironmentChange = this.handleEnvironmentChange.bind(this);
    this.handleNumericRowChange = this.handleNumericRowChange.bind(this);
    this.handleOBGRowChange = this.handleOBGRowChange.bind(this);
    this.handleTXTRowChange = this.handleTXTRowChange.bind(this);
    this.handleLOCRowChange = this.handleLOCRowChange.bind(this);
    this.handleChangeAmountRate = this.handleChangeAmountRate.bind(this);
    this.handleChangeAmountPerPlant =
      this.handleChangeAmountPerPlant.bind(this);
    this.handleChangeAmountPerHectare =
      this.handleChangeAmountPerHectare.bind(this);
    this.checkValidAidFundingSpeciesGroup =
      this.checkValidAidFundingSpeciesGroup.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.createVersion = this.createVersion.bind(this);
    this.canChangeStatus = this.canChangeStatus.bind(this);
    this.scrollSpy = this.scrollSpy.bind(this);
    this._getCriterionFormByType = this._getCriterionFormByType.bind(this);
    this._navigateToTheme = this._navigateToTheme.bind(this);
    this._showActivities = this._showActivities.bind(this);
    this._checkDisabledFields = this._checkDisabledFields.bind(this);
    this._formatVersionOptions = this._formatVersionOptions.bind(this);
    this.handleFolderChange = this.handleFolderChange.bind(this);
    this._handleAddNewComment = this._handleAddNewComment.bind(this);
    this._handleSaveAidScheme = this._handleSaveAidScheme.bind(this);
    this._handleSaveRegion = this._handleSaveRegion.bind(this);
    this._handleCreateNewRegion = this._handleCreateNewRegion.bind(this);
    this._handleAidSchemeModalDisplay =
      this._handleAidSchemeModalDisplay.bind(this);
    this._handleRegionModalDisplay = this._handleRegionModalDisplay.bind(this);
    this._handleChooseRegion = this._handleChooseRegion.bind(this);
    this._handleChangeNewRegion = this._handleChangeNewRegion.bind(this);
    this._handleDepartementChange = this._handleDepartementChange.bind(this);
    this._handleDeleteDepartment = this._handleDeleteDepartment.bind(this);
    this._handleChangeNewAidScheme = this._handleChangeNewAidScheme.bind(this);
    this._handleCreateAidScheme = this._handleCreateAidScheme.bind(this);
    this._handleAidFundingFormDisplay =
      this._handleAidFundingFormDisplay.bind(this);
    this._handleAddAidFundingLine = this._handleAddAidFundingLine.bind(this);
    this._handleAidFundingRowsCumulChange =
      this._handleAidFundingRowsCumulChange.bind(this);
    this._getAidFundingTableRowData =
      this._getAidFundingTableRowData.bind(this);
    this._handleDeleteAidFundingRow =
      this._handleDeleteAidFundingRow.bind(this);
    this._handleAidFundingRowClick = this._handleAidFundingRowClick.bind(this);
    this._handleSetConfirm = this._handleSetConfirm.bind(this);
    this._getCriterionCVLabelById = this._getCriterionCVLabelById.bind(this);
    this._getZoneTypeById = this._getZoneTypeById.bind(this);
    this._getLocationById = this._getLocationById.bind(this);
    this._getSpecieLabelById = this._getSpecieLabelById.bind(this);
    this._getDensityLabelById = this._getDensityLabelById.bind(this);
    this._getFundingLinesDataFromAidObject =
      this._getFundingLinesDataFromAidObject.bind(this);
  }

  initRef() {
    this.nameField = React.createRef();
    this.descriptionField = React.createRef();
    this.organizationField = React.createRef();
    this.natureField = React.createRef();
    this.openDateField = React.createRef();
    this.formField = React.createRef();
    this.fundingField = React.createRef();
    this.taxCreditPercentField = React.createRef();
    this.complexityField = React.createRef();
    this.contactNameField = React.createRef();
    this.contactEmailField = React.createRef();
    this.documentUrlField = React.createRef();
    this.requestUrlField = React.createRef();
    this.statusField = React.createRef();
    this.environmentField = React.createRef();
  }

  scrollSpy = () => {
    const container = document.getElementsByClassName("bo-navs-content")[0];
    if (!container) {
      return;
    }

    const sections = this.criterionThemeIds
      .map((id) => document.getElementById("criterionTheme" + id))
      ?.filter((element) => element !== null);

    const getCurrentSection = () => {
      const scrollTop = container.scrollTop;
      const containerHeight = container.offsetHeight;
      const scrollHeight = container.scrollHeight;

      if (scrollTop + containerHeight >= scrollHeight - 1) {
        return sections[sections.length - 1].id;
      }

      const sectionsOffsets = sections.map((section) => ({
        id: section.id,
        offsetTop: section.offsetTop - container.offsetTop,
      }));

      for (let i = sectionsOffsets.length - 1; i >= 0; i--) {
        if (scrollTop >= sectionsOffsets[i].offsetTop - 1) {
          return sectionsOffsets[i].id;
        }
      }
      return "";
    };

    const activeId = getCurrentSection();
    this.setState({
      activeThemeContainerId: activeId,
    });
  };

  async initStateObjects(resultObject) {
    let folder = null;
    let subfolder = null;

    switch (resultObject.folder?.type?.id) {
      case 1:
        folder = {
          value: resultObject.folder.id,
          label: resultObject.folder.name,
        };
        break;
      case 2:
        subfolder = {
          value: resultObject.folder.id,
          label: resultObject.folder.name,
        };

        folder = {
          value: resultObject.folder.folder.id,
          label: resultObject.folder.folder.name,
        };
        break;
      default:
        break;
    }

    const aid = {
      ...resultObject,
      isWithAidFundingRowsCumul: resultObject.withAidFundingRowsCumul || false,
      leadtime: resultObject.leadtime ?? "",
      contractDuration: resultObject.contractDuration ?? "",
      taxCreditPercent: resultObject.taxCreditPercent ?? "",
      openDate: resultObject.openDate
        ? moment(resultObject.openDate).format("YYYY-MM-DD")
        : "",
      depositDate: resultObject.depositDate
        ? moment(resultObject.depositDate).format("YYYY-MM-DD")
        : "",
      organization:
        resultObject.organization === null
          ? ""
          : {
              label: resultObject.organization.name,
              value: resultObject.organization.id,
            },
      organizationCreator:
        resultObject.organizationCreator === null
          ? ""
          : {
              label: resultObject.organizationCreator.name,
              value: resultObject.organizationCreator.id,
            },
      nature: {
        label: resultObject.nature.name,
        value: resultObject.nature.id,
      },
      form: resultObject.form
        ? { label: resultObject.form.name, value: resultObject.form.id }
        : "",
      funding: resultObject.funding
        ? {
            label: resultObject.funding.label,
            value: resultObject.funding.id,
            shortName: resultObject.funding.shortName,
          }
        : "",
      aidFundingScales: [], //resultObject.aidFundingScales,
      aidFundingPlans: [], //resultObject.aidFundingPlans,
      aidFundingFixedAmounts: [], //resultObject.aidFundingFixedAmounts,
      fundingLines: this._getFundingLinesDataFromAidObject(resultObject),
      complexity: resultObject.complexity
        ? {
            label: resultObject.complexity.name,
            value: resultObject.complexity.id,
          }
        : "",
      scheme: resultObject.scheme
        ? {
            label: resultObject.scheme.name,
            value: resultObject.scheme.id,
          }
        : "",
      environment: resultObject.environment
        ? {
            label: resultObject.environment.name,
            value: resultObject.environment.id,
          }
        : "",
      version: {
        value: resultObject.id,
        label: `Version ${resultObject.version}`,
      },
      contactName: resultObject.contactName ?? "",
      contactEmail: resultObject.contactEmail ?? "",
      documentUrl: resultObject.documentUrl ?? "",
      requestUrl: resultObject.requestUrl ?? "",
      originUrl: resultObject.originUrl ?? "",
      parent: resultObject.parent ? resultObject.parent : "",
      folder: folder,
      subfolder: subfolder,
    };

    const aidCriterions = {};
    aid.aidCriterions.forEach((aidCriterion) => {
      aidCriterions[aidCriterion.criterion.id] = aidCriterion;
      aidCriterions[aidCriterion.criterion.id].remove = false;
      if (aidCriterion.criterion.shortName === "criPlan_01") {
        const minimaDiversification = aidCriterions[aidCriterion.criterion.id];
        if (
          minimaDiversification &&
          minimaDiversification.value &&
          minimaDiversification.value.answer
        ) {
          aidCriterions[aidCriterion.criterion.id].remove =
            minimaDiversification.value.answer.length === 0;
        } else {
          aidCriterions[aidCriterion.criterion.id].remove = true;
        }
      }
    });

    this.setState(
      {
        aid: aid,
        aidCriterions: aidCriterions,
        aidHasData: true,
        aidId: aid.id,
        aidStatus: aid.status,
        aidActive: aid.active,
      },
      function () {
        this.setActiveLabel(aid.active);
        this.setStatus(aid.status);
        this._checkDisabledFields(aidCriterions);
      }
    );
  }

  async editAid(id) {
    const versionsResponse = await Api.aid.getAidVersions(id);
    let subfoldersResponse = [];

    const resultObject = Helper.isValidResponse(versionsResponse);
    if (resultObject) {
      const aid = resultObject.filter((item) => {
        return item.id === +id;
      });

      const listVersionOptions = resultObject.map((item) => {
        return {
          value: item.id,
          label: `Version ${item.version}`,
          active: item.active,
        };
      });

      const activeVersions = resultObject.filter((item) => {
        return item.active;
      });

      const lastVersion = resultObject.length
        ? resultObject.reduce((prev, curr) =>
            +prev.version > +curr.version ? prev : curr
          )
        : null;
      const lastActiveVersion = activeVersions.length
        ? activeVersions.reduce((prev, curr) =>
            +prev.version > +curr.version ? prev : curr
          )
        : null;
      const aidParent = resultObject.reduce((prev, curr) =>
        curr.parent == null ? curr : prev
      );

      if (aid.length) {
        const folderType = aid[0].folder?.type?.id;

        if (folderType) {
          subfoldersResponse = await Api.folder.getFolders(1, {
            folderType: 2,
            folder:
              folderType === 1 ? aid[0].folder.id : aid[0].folder.folder.id,
            [`order[name]`]: ASCENDING_SORT_DIRECTION,
          });
          subfoldersResponse = Helper.isValidResponse(subfoldersResponse);
        }

        this.initStateObjects(aid[0]);
        this.setState({
          lastVersion: lastVersion,
          lastActiveVersion: lastActiveVersion,
          aidParent: aidParent,
          listVersionOptions: listVersionOptions,
          aidInitialStatus: aid[0].status,
          subfolders: subfoldersResponse.map((element) => {
            return {
              value: element.id,
              label: element.name,
            };
          }),
        });
      }
    }
  }

  handleChange(e) {
    const field = e.target.name;
    let value = e.target.value;

    if (
      ["leadtime", "contractDuration", "taxCreditPercent"].includes(field) &&
      value < 0
    ) {
      value = 0;
    }

    if (field === "name") {
      this.props.onNameChange(value);
    }

    this.setState((prevState) => {
      let aid = prevState.aid;
      aid[field] = value;
      return { aid: aid };
    });

    if (field === "comment" && this.props.type !== "create") {
      this.props.commentModify(true);
    } else {
      this.props.modalModify(true);
    }
  }

  _updateOrganismList = () => {
    this.setState({
      organismListFiltered: Helper.FormatOrganismListFiltered(
        this.typeOrganism,
        this.state.organismList
      ),
    });
  };

  handleDelete() {
    (async function () {
      this.setLoading();
      let response;
      let message = "Aide supprimée";

      response = await Api.aid.deleteAid(this.state.aidId);

      if (response?.status !== 204) {
        message = "Une erreur est survenue. Merci de rééssayer ultérieurement";
        toast.error(message, Helper.getToastOptions());
      } else {
        toast.success(message, Helper.getToastOptions());
        setTimeout(() => {
          this.setState({ forceLocation: true });
        }, 1500);
      }
    }).bind(this)();
  }

  setLoading(status = true) {
    this.setState({ loading: status });
  }

  handleOrganismChange = (selectedOptions) => {
    this.setState({
      aid: { ...this.state.aid, organization: selectedOptions },
    });
    this.props.modalModify(true);
  };

  handleFolderChange(selectedOptions, type) {
    const newState = {
      aid: { ...this.state.aid, [type]: selectedOptions },
    };

    if (type === "folder") {
      newState.aid.subfolder = null;
      newState.subfolders = selectedOptions?.subfolders || [];
    }

    this.setState(newState);
    this.props.modalModify(true);
  }

  handleNatureChange = (selectedOptions) => {
    this.setState({
      aid: { ...this.state.aid, nature: selectedOptions },
    });
    this.props.modalModify(true);
  };

  handleFormChange = (selectedOptions) => {
    this.setState({
      aid: { ...this.state.aid, form: selectedOptions },
    });
    this.props.modalModify(true);
  };

  handleFundingChange = (selectedOptions) => {
    this.setState({
      aid: {
        ...this.state.aid,
        funding: selectedOptions,
        isWithAidFundingRowsCumul: false,
        fundingLines: [],
      },
    });
    this.props.modalModify(true);
  };

  handleComplexityChange = (selectedOptions) => {
    this.setState({
      aid: {
        ...this.state.aid,
        complexity: selectedOptions,
      },
    });
    this.props.modalModify(true);
  };

  handleSchemeChange = (selectedOptions) => {
    if (selectedOptions?.value !== "new") {
      this.setState({
        aid: {
          ...this.state.aid,
          scheme: selectedOptions,
        },
      });
      this.props.modalModify(true);
    } else {
      this.setState({
        isAidSchemeDialogShowing: true,
      });
    }
  };

  handleEnvironmentChange = (selectedOptions) => {
    this.setState({
      aid: {
        ...this.state.aid,
        environment: selectedOptions,
      },
    });
    this.props.modalModify(true);
  };

  handleNumericRowChange = (criterion, aidCriterions) => {
    this._aidCriterionsFormatRow(criterion, aidCriterions);
  };

  handleOBGRowChange = (criterion, aidCriterions) => {
    this._aidCriterionsFormatRow(criterion, aidCriterions);
    this.setState({
      checkField: false,
    });
  };

  handleTXTRowChange = (criterion, aidCriterions) => {
    this._aidCriterionsFormatRow(criterion, aidCriterions);
    this.setState({
      checkField: false,
    });

    this._checkDisabledFields(aidCriterions);
  };

  handleLOCRowChange = (criterion, aidCriterions) => {
    this._aidCriterionsFormatRow(criterion, aidCriterions);
    this.setState({
      checkField: false,
    });
  };

  handleChangeAmountRate = (value) => {
    this.setState({
      aid: {
        ...this.state.aid,
        minimumRate: value[0],
        maximumRate: value[1],
      },
    });
    this.props.modalModify(true);
  };

  handleChangeAmountPerPlant = (value) => {
    this.setState({
      aid: {
        ...this.state.aid,
        minimumAmountPerPlant: value[0],
        maximumAmountPerPlant: value[1],
      },
    });
    this.props.modalModify(true);
  };

  handleChangeAmountPerHectare = (value) => {
    this.setState({
      aid: {
        ...this.state.aid,
        minimumAmountPerHectare: value[0],
        maximumAmountPerHectare: value[1],
      },
    });
    this.props.modalModify(true);
  };

  handleMinOrMaxChange = (value, minOrMaxValue, type) => {
    if (value?.includes(".")) {
      const digitsBetweenComma = value?.split(".");

      if (digitsBetweenComma?.[1]) {
        value = parseFloat(value);
      }
    } else {
      value = parseFloat(value);
    }

    this.setState(
      {
        aid: {
          ...this.state.aid,
          [`${minOrMaxValue}${type}`]: value,
        },
      },
      () => {
        this.props.modalModify(true);
      }
    );
  };

  _aidCriterionsFormatRow(criterion, aidCriterions) {
    const aidCriterionsArray = [];
    Object.keys(aidCriterions).forEach((key) => {
      aidCriterionsArray.push({
        ...aidCriterions[key],
      });
    });

    this.setState(
      {
        aid: { ...this.state.aid, aidCriterions: aidCriterionsArray },
      },
      () => {
        this.props.modalModify(true);
      }
    );
  }

  handleStatusChange(selectedOptions) {
    let changeStatus = false;
    const cannotChangeStatusToDraft =
      this.props.formType !== "create"
        ? this.props.aid?.status === AID_STATUS_VALIDATING &&
          selectedOptions.value === AID_STATUS_DRAFT
        : false;

    if (!this.state.canValidate) {
      switch (selectedOptions.value) {
        case AID_STATUS_DRAFT:
          changeStatus = true;
          break;
        case AID_STATUS_VALIDATING:
          toast.warn(
            "Attention! Une fois passée en validation, vous ne pouvez plus modifier cette aide",
            Helper.getToastOptions()
          );
          changeStatus = true;
          break;
        case AID_STATUS_REFUSED:
        case AID_STATUS_VALIDATED:
          toast.error(
            "Vous n'avez pas le droit de valider une aide",
            Helper.getToastOptions()
          );
          break;
        default:
          break;
      }
    } else {
      changeStatus = true;
    }

    if (cannotChangeStatusToDraft) {
      toast.error(
        "Vous ne pouvez pas changer le statut de cette aide en Brouillon.",
        Helper.getToastOptions()
      );
    }

    if (changeStatus && !cannotChangeStatusToDraft) {
      this.setState({
        aid: {
          ...this.state.aid,
          status: selectedOptions,
        },
      });
      this.props.modalModify(true);
    }
  }

  async handleVersionSelect(version) {
    this.setState(
      { selectVersionId: version.value, hasPublishedAid: false },
      () => {
        this.props.onVersionSwitched(async () => {
          await this.handleVersionChange();
        });
      }
    );
  }

  async handleVersionChange() {
    this.setLoading();
    await this.editAid(this.state.selectVersionId);
    this.setLoading(false);
  }

  handleActiveChange(activeState) {
    const statusOption = this.state.aid.status || { value: 0 };
    if (activeState) {
      if (
        statusOption.value !== AID_STATUS_VALIDATED ||
        (this.state.aid.id && this.state.aid.id !== this.state.lastVersion.id)
      ) {
        toast.error(
          `Vous ne pouvez pas publier une aide sans l'avoir validée !`,
          Helper.getToastOptions()
        );
      } else {
        this.setActiveLabel(activeState);
        this.props.modalModify(true);
        this.setState({ hasPublishedAid: true });
      }
    } else {
      if (
        this.state.aid.id &&
        this.state.aid.id !== this.state.lastVersion.id
      ) {
        toast.error(
          `Vous ne pouvez pas dé-publier cette version car une autre plus récente existe`,
          Helper.getToastOptions()
        );
      } else {
        this.setActiveLabel(activeState);
        this.props.modalModify(true);
        this.setState({ hasPublishedAid: false });
      }
    }
  }

  handleSimulableChange(newSimulableValue) {
    let newAidValue = {
      ...this.state.aid,
      simulable: newSimulableValue,
    };

    this.setState({
      aid: newAidValue,
    });

    this.props.modalModify(true);
  }

  setStatus = (status) => {
    let statusLabel;
    this.statusOptions[0].options.forEach((s) => {
      if (s.value === status) {
        statusLabel = s.label;
      }
    });

    this.setState({
      aid: { ...this.state.aid, status: { label: statusLabel, value: status } },
    });
  };

  setActiveLabel = (activeState) => {
    this.setState({
      aid: { ...this.state.aid, active: activeState },
      activeLabel: activeState ? "Publiée" : "Non publiée",
    });
  };

  createVersion = () => {
    this.props.onVersionCreate("create", () => {
      const lastVersionNumber = +this.state.lastActiveVersion.version + 1;
      const lastVersionOption = {
        value: 0,
        label: `Version ${lastVersionNumber}`,
      };

      const aidCriterions = this.state.aid.aidCriterions;
      aidCriterions.forEach((aidCriterion) => {
        delete aidCriterion["aid"];
        delete aidCriterion["id"];
      });

      const aidFundingScales = this.state.aid.aidFundingScales.map((value) => {
        delete value.id;
        value.criteria = value.criteria.map((c) => {
          delete c.id;
          return c;
        });
        return value;
      });

      const aidFundingPlans = this.state.aid.aidFundingPlans.map((value) => {
        delete value.id;
        return value;
      });

      const aidFundingFixedAmounts = this.state.aid.aidFundingFixedAmounts.map(
        (value) => {
          delete value.id;
          value.criteria = value.criteria.map((c) => {
            delete c.id;
            return c;
          });
          return value;
        }
      );

      const aidFundingLines = this.state.aid.fundingLines.map(
        (fundingLine, index) => {
          fundingLine.id = `newFunding-${index + 1}`;
          fundingLine.isNew = true;

          return fundingLine;
        }
      );

      this.setState(
        {
          aidId: null,
          aidStatus: this.statusOptions[0].options[0],
          aidActive: false,
          loading: true,
          aidInitialStatus: AID_STATUS_DRAFT,
          aid: {
            ...this.state.aid,
            id: null,
            active: false,
            parent: this.state.aidParent,
            version: lastVersionOption,
            activateAt: null,
            aidCriterions: aidCriterions,
            aidFundingScales: aidFundingScales,
            aidFundingPlans: aidFundingPlans,
            aidFundingFixedAmount: aidFundingFixedAmounts,
            fundingLines: aidFundingLines,
          },
        },
        () => {
          this.state.listVersionOptions.push(lastVersionOption);

          this.setState({
            listVersionOptions: this.state.listVersionOptions,
          });
          this.setStatus(AID_STATUS_DRAFT);
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        }
      );
    });
  };

  checkForm() {
    let valid = true;

    const requiredFields = this.state.requiredFields;
    for (const id in requiredFields) {
      if (_.trim(this.state.aid[requiredFields[id]]) === "") {
        this[requiredFields[id] + "Field"].current.classList.add("is-invalid");
        this[requiredFields[id] + "Field"].current.classList.remove("is-valid");
        valid &&= false;
      } else {
        this[requiredFields[id] + "Field"].current.classList.add("is-valid");
        this[requiredFields[id] + "Field"].current.classList.remove(
          "is-invalid"
        );
        valid &&= true;
      }
    }

    // Specific test for email field
    this.contactEmailField.current.className = "form-block is-valid";
    if (this.state.aid.contactEmail) {
      if (
        !this.state.aid.contactEmail.match(
          /[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,}/i
        )
      ) {
        this.contactEmailField.current.className = "form-block is-invalid";
        valid &&= false;
      }
    }

    let requiredCriterionIds = [];
    let themeList = this.state.themeList;

    if (!this.state.aid.simulable) {
      themeList = themeList.filter((element) =>
        ["global", "loca"].includes(element?.keyword)
      );
    }

    _.each(themeList, (theme) => {
      if (theme !== undefined) {
        requiredCriterionIds = [
          ...requiredCriterionIds,
          ...this.state.criterionList[theme.id]
            .filter((criterion) => criterion.mandatory)
            .map((criterion) => criterion.id),
        ];
      }
    });

    let allRequiredCriterionsExist =
      this.state.aid.status.value === AID_STATUS_DRAFT;

    if (this.state.aid.status.value !== AID_STATUS_DRAFT) {
      const requiredCriterionHavingValues = this.state.aid.aidCriterions.filter(
        (aidCriterion) => {
          if (
            aidCriterion.criterion.mandatory &&
            aidCriterion.criterion.active
          ) {
            if (this.state.aid.simulable) {
              if (aidCriterion.type === Constants.CRITERION_TYPE_NUM) {
                if (aidCriterion.criterion.shortName === "criPlan_01") {
                  return (
                    aidCriterion.value &&
                    aidCriterion.value.answer &&
                    aidCriterion.value.answer.length
                  );
                }
                return (
                  aidCriterion.value.min !== "" &&
                  aidCriterion.value.max !== "" &&
                  (aidCriterion.value.min >= 0 || aidCriterion.value.max >= 0)
                );
              }

              if (aidCriterion.type === Constants.CRITERION_TYPE_OBG) {
                return aidCriterion.value && aidCriterion.value.answer;
              }

              if (aidCriterion.type === Constants.CRITERION_TYPE_TXT) {
                return (
                  aidCriterion.value &&
                  aidCriterion.value.answers &&
                  aidCriterion.value.answers.length
                );
              }
            }

            if (
              !this.state.aid.simulable &&
              aidCriterion.criterion.shortName === "global_02"
            ) {
              return aidCriterion.value?.answers?.length;
            }

            if (aidCriterion.type === Constants.CRITERION_TYPE_LOC) {
              return aidCriterion.value && aidCriterion.value.type;
            }
          }

          return false;
        }
      );

      allRequiredCriterionsExist =
        requiredCriterionHavingValues.length === requiredCriterionIds.length;
    }

    this.setState({
      checkField: !allRequiredCriterionsExist,
    });

    valid &&= allRequiredCriterionsExist;

    if (this.state.aid.simulable) {
      valid &&= this.checkValidAidFundingSpeciesGroup();
      valid &&= this.checkRequiredAidFundingValue();
      valid &&= this.checkUniqueAidFundingValue();

      if (this.state.aid.funding) {
        if (
          this.state.aid.funding.shortName === Constants.AID_FUNDING_SCALE &&
          this.state.aid.aidFundingScales.length
        ) {
          valid &&= this.checkValidAidFundingScaleRateValue();
        }

        if (
          this.state.aid.funding.shortName ===
            Constants.AID_FUNDING_FIXED_AMOUNT &&
          this.state.aid.aidFundingFixedAmounts.length
        ) {
          valid &&= this.checkValidAidFundingFixedAmountValue();
        }
      }
    }

    return valid;
  }

  checkUniqueAidFundingValue() {
    let hasUniqueAidFundingValue = true;

    if (this.state.aid.funding) {
      if (this.state.aid.funding.shortName === Constants.AID_FUNDING_SCALE) {
        hasUniqueAidFundingValue = structuredClone(
          this.state.aid.aidFundingScales
        )
          .map((value) => {
            delete value.id;
            value.criteria = value.criteria.map((c) => {
              delete c.id;
              return Helper.orderObjectByKeys(c);
            });

            value.rate = +value.rate;
            value.baseAmount = +value.baseAmount;
            value = Helper.orderObjectByKeys(value);

            return JSON.stringify(value);
          })
          .every((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }

      if (this.state.aid.funding.shortName === Constants.AID_FUNDING_PLAN) {
        hasUniqueAidFundingValue = structuredClone(
          this.state.aid.aidFundingPlans
        )
          .map(
            (value) =>
              (value.specieGroup && value.specieGroup.id
                ? value.specieGroup.id
                : "-") +
              "-" +
              (value.specie && value.specie.id ? value.specie.id : "-")
          )
          .every((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }

      if (
        this.state.aid.funding.shortName === Constants.AID_FUNDING_FIXED_AMOUNT
      ) {
        hasUniqueAidFundingValue = structuredClone(
          this.state.aid.aidFundingFixedAmounts
        )
          .map((value) => {
            delete value.id;
            value.criteria = value.criteria.map((c) => {
              delete c.id;
              return Helper.orderObjectByKeys(c);
            });

            value.amount = +value.amount;
            value = Helper.orderObjectByKeys(value);

            return JSON.stringify(value);
          })
          .every((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
    }

    return hasUniqueAidFundingValue;
  }

  checkValidAidFundingSpeciesGroup() {
    let hasValidSpecieGroup = true;

    if (
      this.state.aid.funding &&
      this.state.aid.funding.shortName === Constants.AID_FUNDING_PLAN &&
      this.state.aid.aidFundingPlans.length
    ) {
      hasValidSpecieGroup = this.state.aid.aidFundingPlans.every((value) => {
        return value.specieGroup && value.specieGroup.id;
      });
    }

    return hasValidSpecieGroup;
  }

  checkValidAidFundingScaleRateValue() {
    let hasValidRate = true;

    if (this.state.aid.aidFundingScales.length) {
      hasValidRate = this.state.aid.aidFundingScales.every((value) => {
        return value.rate;
      });
    }

    return hasValidRate;
  }

  checkValidAidFundingFixedAmountValue() {
    let hasValidAmount = true;

    if (this.state.aid.aidFundingFixedAmounts.length) {
      hasValidAmount = this.state.aid.aidFundingFixedAmounts.every((value) => {
        return value.amount;
      });
    }

    return hasValidAmount;
  }

  checkRequiredAidFundingValue() {
    let hasRequiredValue = true;

    if (
      this.state.aid.funding &&
      this.state.aid.funding.shortName === Constants.AID_FUNDING_FIXED_AMOUNT
    ) {
      hasRequiredValue = this.state.aid.aidFundingFixedAmounts.every(
        (value) =>
          value.criteria.filter((c) =>
            _.isArray(c.value.answer) ? c.value.answer.length : c.value.answer
          ).length > 0
      );
    }

    return hasRequiredValue;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setLoading(true);
    if (this.checkForm()) {
      let aid = { ...this.state.aid };
      aid.organization = { id: aid.organization.value };
      aid.nature = { id: aid.nature.value };
      aid.leadtime = +aid.leadtime;
      aid.contractDuration = +aid.contractDuration;
      aid.taxCreditPercent = +aid.taxCreditPercent;
      aid.status = aid.status ? aid.status.value : AID_STATUS_DRAFT;
      aid.aidCriterions = this.state.aid.aidCriterions.filter(
        (aidCriterion) => {
          if (!this.state.aid.simulable) {
            let condition1 = aidCriterion.type === Constants.CRITERION_TYPE_LOC;
            let condition2 =
              aidCriterion.type === Constants.CRITERION_TYPE_TXT &&
              aidCriterion.criterion.shortName === "global_02";

            return condition1 || condition2;
          } else {
            return !aidCriterion.remove;
          }
        }
      );

      if (!this.state.aid.simulable) {
        aid = {
          ...aid,
          form: null,
          funding: null,
          minimumRate: null,
          maximumRate: null,
          minimumAmountPerPlant: null,
          maximumAmountPerPlant: null,
          minimumAmountPerHectare: null,
          maximumAmountPerHectare: null,
        };
      }

      aid.aidFundingPlans = [];
      aid.aidFundingFixedAmounts = [];
      aid.aidFundingScales = [];

      if (aid.funding && this.state.aid.simulable) {
        const elements = this.state.aid.fundingLines.map((element) => {
          return {
            ...(element.data || {}),
            id: element.id,
          };
        });

        switch (aid.funding.shortName) {
          case Constants.AID_FUNDING_PLAN:
            aid.aidFundingPlans = AidHelper.formatAidFundingValue(elements);
            break;

          case Constants.AID_FUNDING_SCALE:
            aid.aidFundingScales = AidHelper.formatAidFundingValue(elements);
            break;

          case Constants.AID_FUNDING_FIXED_AMOUNT:
            aid.aidFundingFixedAmounts =
              AidHelper.formatAidFundingValue(elements);
            break;

          default:
            break;
        }
      }

      // S'il y a de sous-dossier, c'est l'id du sous-dossier qu'on envoie, sinon celui du dossier
      if (aid.subfolder) {
        aid.folder = { id: aid.subfolder.value };
      } else {
        if (aid.folder) {
          aid.folder = { id: aid.folder.value };
        }
      }

      delete aid.version;
      delete aid.activateAt;
      delete aid.updatedAt;

      if (!aid.form) {
        delete aid.form;
      } else {
        aid.form = { id: aid.form.value };
      }
      if (!aid.complexity) {
        delete aid.complexity;
      } else {
        aid.complexity = { id: aid.complexity.value };
      }
      if (!aid.scheme) {
        delete aid.scheme;
      } else {
        aid.scheme = { id: aid.scheme.value };
      }

      if (!aid.environment) {
        delete aid.environment;
      } else {
        aid.environment = { id: aid.environment.value };
      }

      if (!aid.funding) {
        delete aid.funding;
      } else {
        aid.funding = { id: aid.funding.value };
      }
      if (!aid.openDate) {
        aid.openDate = null;
      }
      if (!aid.depositDate) {
        aid.depositDate = null;
      }
      if (!aid.parent) {
        delete aid.parent;
      }

      // Traitement pour les commentaires
      const withComment = ![null, undefined, ""].includes(
        this.state.aid.comment
      );
      const comments = [];

      // Cas de MODIFICATION uniquement
      if (this.props.type !== "create") {
        this.state.comments?.map((comment) => {
          comments.push({
            id: comment.id,
            description: comment.description,
            userId: comment.userId,
          });

          return null;
        });
      }

      // Cas de CREATION uniquement => Les nouveaux commentaires ajoutés dans la liste
      if (this.state.aidId === null || this.props.type === "create") {
        const newAidComments = [...this.state.newAidComments].reverse();
        newAidComments.map((comment) => {
          comments.push({
            description: comment.description,
            userId: comment.userId,
            date: comment.date,
          });

          return null;
        });
      }

      // S'il y a un commentaire non ajouté dans le formulaire => on ajoute dans la liste
      if (withComment) {
        // Les NOUVEAUX commentaires de l'Aide
        comments.push({
          description: this.state.aid.comment,
          userId: Session.getSessionUser().id,
          date: moment(),
        });
      }

      if (comments.length > 0) {
        aid.comments = comments;
      }

      if (this.state.aidId === null || this.props.type === "create") {
        Api.aid.createAid(aid).then((r) => {
          if (r?.status !== 201) {
            this.props.onSubmit(
              false,
              `Oops ! Une erreur est survenue lors de la création de l'aide : ${r.message}`
            );
            return;
          }

          const result = Helper.isValidResponse(r);

          if (result) {
            this.props.onSubmit(true, `L'aide a été créée`);
          }

          this.setLoading(false);
        });
      } else {
        Api.aid.updateAid(this.state.aidId, aid).then((r) => {
          if (r?.status === 401) {
            this.props.onSubmit(false, r["hydra:description"]);
            this.setLoading(false);
            return;
          }

          if (r?.status !== 200) {
            this.props.onSubmit(
              false,
              `Oops ! Une erreur est survenue lors de la modification de l'aide : ${r.message}`
            );
            this.setLoading(false);
            return;
          }

          const result = Helper.isValidResponse(r);

          if (result) {
            const condition1 =
              this.state.aid.status?.value !== this.props.aid?.status;
            const condition2 = this.state.aid.active !== this.props.aid?.active;
            const condition = condition1 || condition2;

            if (condition) {
              Api.aid
                .notifyInAidStatus(result.id)
                .then((resp) => {
                  const aidNotificationResult = Helper.isValidResponse(resp);

                  if (aidNotificationResult) {
                    this.props.onSubmit(true, `L'aide a été mise à jour`);
                  }

                  this.setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                  this.setLoading(false);
                });
            } else {
              this.props.onSubmit(true, `L'aide a été mise à jour`);
              this.setLoading(false);
            }
          }
        });
      }
    } else {
      this.setLoading(false);

      if (!this.checkValidAidFundingSpeciesGroup()) {
        this.props.onSubmit(
          false,
          `Veuillez renseigner tous les champs vide du groupe d'essence`
        );
        return;
      }

      if (!this.checkRequiredAidFundingValue()) {
        this.props.onSubmit(
          false,
          `Financement sur montant fixe: Veuillez renseigner au moins une opération et/ou si travaux en autonomie ou pas`
        );
        return;
      }

      if (this.state.aid.funding) {
        if (!this.checkUniqueAidFundingValue()) {
          if (
            this.state.aid.funding.shortName === Constants.AID_FUNDING_SCALE
          ) {
            this.props.onSubmit(
              false,
              `Financement au barème: deux lignes ne peuvent pas avoir la même combinaison de données.`
            );
          }

          if (this.state.aid.funding.shortName === Constants.AID_FUNDING_PLAN) {
            this.props.onSubmit(
              false,
              `Les valeurs du groupe d'essence et de l'essence doivent être uniques pour chaque ligne.`
            );
          }

          if (
            this.state.aid.funding.shortName ===
            Constants.AID_FUNDING_FIXED_AMOUNT
          ) {
            this.props.onSubmit(
              false,
              `Financement sur montant fixe: deux lignes ne peuvent pas avoir la même combinaison de données.`
            );
          }
          return;
        }

        if (
          this.state.aid.funding.shortName === Constants.AID_FUNDING_SCALE &&
          !this.checkValidAidFundingScaleRateValue()
        ) {
          this.props.onSubmit(
            false,
            `Financement au barème: le taux d'aide en % doit être renseigner`
          );
          return;
        }

        if (
          this.state.aid.funding.shortName ===
            Constants.AID_FUNDING_FIXED_AMOUNT &&
          !this.checkValidAidFundingFixedAmountValue()
        ) {
          this.props.onSubmit(
            false,
            `Financement sur montant fixe: le montant à l'hectare doit être renseigner`
          );
          return;
        }
      }

      this.props.onSubmit(
        false,
        `Veuillez renseigner tous les champs obligatoires avant d'enregistrer l'aide`
      );
    }
  }

  canChangeStatus() {
    const user = Session.getSessionUser();
    const userOrganization = user.organization;

    if (this.props.readOnly) {
      return;
    }
    if (
      this.state.aidInitialStatus &&
      this.state.aidInitialStatus === AID_STATUS_VALIDATED
    ) {
      return false;
    }

    let canChange = true;

    if (
      this.state.aid &&
      this.state.aid.organizationCreator &&
      (userOrganization.organism || userOrganization.partner)
    ) {
      canChange =
        canChange &&
        ((userOrganization.partner &&
          user.organization.id === this.state.aid.organizationCreator.value) ||
          (userOrganization.organism &&
            user.organization.id === this.state.aid.organization.value));
    }

    if (
      canChange &&
      (!this.state.aid ||
        (this.state.aid && !this.state.aid.id) ||
        !this.state.lastActiveVersion)
    ) {
      return true;
    }

    return (
      canChange &&
      this.state.aid.id === this.state.lastVersion.id &&
      !this.state.aidActive
    );
  }

  /** Return jsx form element corresponding to the criterion type passed in parameter */
  _getCriterionFormByType(criterion) {
    let isFormRequired = false;

    if (this.state.aid.simulable) {
      isFormRequired =
        this.state.aid.status?.value !== AID_STATUS_DRAFT &&
        criterion.mandatory;
    } else {
      isFormRequired =
        this.state.aid.status?.value !== AID_STATUS_DRAFT &&
        criterion.shortName === "global_02";
    }

    /* NUM criterion type */
    if (criterion.type.shortName === Constants.CRITERION_TYPE_NUM) {
      return (
        <CriterionNumericRow
          onInputChange={this.handleNumericRowChange}
          requiredNumericFields={isFormRequired}
          checkField={this.state.checkField}
          key={criterion.id}
          criterion={criterion}
          readOnly={!this.state.aidEdition || !this.state.aid.simulable}
          speciesGroup={this.state.speciesGroup}
          aid={this.state.aid}
          simulable={this.state.aid.simulable}
          aidCriterions={this.state.aidCriterions}
        />
      );
    }

    /* TXT criterion type */
    if (criterion.type.shortName === Constants.CRITERION_TYPE_TXT) {
      return (
        <CriterionTXTRow
          key={criterion.id}
          checkField={this.state.checkField}
          requiredTextFields={isFormRequired}
          criterion={criterion}
          aidCriterions={this.state.aidCriterions}
          speciesGroup={this.state.speciesGroup}
          readOnly={
            !this.state.aidEdition ||
            this.state.disabledFields.includes(criterion.shortName) ||
            (!this.state.aid.simulable && criterion.shortName !== "global_02")
          }
          aid={this.state.aid}
          simulable={this.state.aid.simulable}
          onChange={this.handleTXTRowChange}
        />
      );
    }

    /* OBG criterion type */
    if (criterion.type.shortName === Constants.CRITERION_TYPE_OBG) {
      return (
        <CriterionOBGRow
          key={criterion.id}
          checkField={this.state.checkField}
          requiredObgFields={isFormRequired}
          criterion={criterion}
          aidCriterions={this.state.aidCriterions}
          readOnly={!this.state.aidEdition || !this.state.aid.simulable}
          aid={this.state.aid}
          simulable={this.state.aid.simulable}
          onChange={this.handleOBGRowChange}
        />
      );
    }

    /* LOC criterion type */
    if (criterion.type.shortName === Constants.CRITERION_TYPE_LOC) {
      return (
        <CriterionLOCRow
          key={criterion.id}
          checkField={this.state.checkField}
          criterion={criterion}
          aidCriterions={this.state.aidCriterions}
          readOnly={!this.state.aidEdition}
          aid={this.state.aid}
          zones={this.state.zoneList}
          newRegion={this.state.newAddedRegion}
          regions={this.state.regionList}
          departments={this.state.departmentlist}
          onChange={this.handleLOCRowChange}
          onRequestCreateRegion={this._handleCreateNewRegion}
        />
      );
    }
  }

  /** scroll to a form part on menu item click (criterion form)*/
  _navigateToTheme(event) {
    // get menu key cliked and scroll to the corresponding theme in criterion form
    this.setState({ currentThemeMenu: event.target.dataset.key });
    const elt = document.querySelector(
      `div.aidCriteria-theme-section[data-theme="${event.target.dataset.key}"]`
    );
    if (elt !== null) {
      elt.scrollIntoView({ behavior: "smooth" });
    }
  }

  _showActivities() {
    this.setState((prevState) => {
      return { showActivities: !prevState.showActivities };
    });
  }

  _checkDisabledFields(aidCriterions) {
    const criterionValues = { criPlan_04: [], criPlan_05: [] };
    _.map(aidCriterions, (aidCriterion) => {
      const shortName = aidCriterion.criterion?.shortName;
      if (
        ["criPlan_04", "criPlan_05"].includes(shortName) &&
        !aidCriterion.remove
      ) {
        criterionValues[shortName] = aidCriterion.value.answers;
      }
    });

    let disabledFields = [];
    if (
      criterionValues["criPlan_04"].length &&
      !criterionValues["criPlan_05"].length
    ) {
      disabledFields = ["criPlan_05"];
    } else if (
      criterionValues["criPlan_05"].length &&
      !criterionValues["criPlan_04"].length
    ) {
      disabledFields = ["criPlan_04"];
    }

    this.setState({ disabledFields: disabledFields });
  }

  _formatVersionOptions(data) {
    return data.active ? data.label + "🟢" : data.label;
  }

  _handleAddNewComment() {
    if (this.state.aid.comment) {
      if (this.props.type === "create") {
        const userData = Session.getSessionUser();
        const currentAidNewComments = [...this.state.newAidComments];
        const currentAidNewCommentsLength = currentAidNewComments.length || 0;
        let lastId = 0;

        if (currentAidNewCommentsLength > 0) {
          lastId = currentAidNewComments[currentAidNewCommentsLength - 1].id;
        }

        currentAidNewComments.unshift({
          type: "new",
          id: lastId + 1,
          description: this.state.aid.comment,
          date: moment(),
          userId: userData.id,
          name: userData.name,
          surname: userData.surname,
        });

        this.setState({
          newAidComments: currentAidNewComments,
          aid: {
            ...this.state.aid,
            comment: "",
          },
        });
      } else {
        const data = {
          aidId: this.state.aid?.id,
          userId: this.user.id,
          description: this.state.aid.comment,
        };

        this.setLoading(true);
        Api.aidComment
          .createAidComment(data)
          .then((response) => {
            const result = Helper.isValidResponse(response);
            const stateObject = {
              loading: false,
            };

            if (result) {
              Api.aidComment
                .getAidComments(this.state.aidId)
                .then((resp) => {
                  const res = Helper.isValidResponse(resp);

                  if (res) {
                    stateObject.comments = res;
                    stateObject.aid = {
                      ...this.state.aid,
                      comment: "",
                    };

                    this.props.commentModify(false);
                  }

                  this.setState(stateObject);
                })
                .catch((err) => {
                  console.error(err);
                  this.setLoading(false);
                });
            }
          })
          .catch((error) => {
            this.setLoading(false);
            console.error(error);
          });
      }
    } else {
      toast.error("Veuillez saisir le commentaire", Helper.getToastOptions());
    }
  }

  _handleSaveAidScheme() {
    const name = this.state.aidSchemeData.label;

    if (name) {
      const data = { name };

      this.setState({
        loading: true,
        aidSchemeErrorData: getEmptyAidSchemeErrorObject(),
      });
      Api.aid
        .createReferenceData(data, "aid-scheme")
        .then((response) => {
          const stateObject = { loading: false };

          if (response?.status === HTTP_CREATED) {
            const result = response?.data;
            stateObject.aid = {
              ...this.state.aid,
              scheme: {
                value: result.id,
                label: result.name,
              },
            };

            Api.aid
              .getAidSchemes()
              .then((resp) => {
                const aidSchemes = Helper.isValidResponse(resp);

                if (aidSchemes) {
                  const elements = aidSchemes.map((element) => {
                    return {
                      value: element.id,
                      label: element.name,
                    };
                  });

                  stateObject.isAidSchemeDialogShowing = false;
                  stateObject.isAidSchemeFormUpdated = false;
                  stateObject.aidSchemeData = getEmptyAidSchemeObject();
                  stateObject.aidSchemeErrorData =
                    getEmptyAidSchemeErrorObject();
                  stateObject.schemesList = elements;
                  this.props.modalModify(true);
                  toast.success(
                    "Le dispositif a été enregistré avec succès",
                    Helper.getToastOptions()
                  );
                }

                this.setState(stateObject);
              })
              .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
              });
          } else {
            if (response?.status === HTTP_REFERENCE_DATA_ALREADY_EXIST) {
              this.setState({ loading: false });
              toast.error(
                "Un dispositif d'aide avec ce libellé existe déjà",
                Helper.getToastOptions()
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else {
      this.setState({
        aidSchemeErrorData: {
          ...this.state.aidSchemeErrorData,
          label: true,
        },
      });

      toast.error(
        "Veuillez renseigner le nom du dispositif avant d'enregistrer",
        Helper.getToastOptions()
      );
    }
  }

  // AID SCHEME
  _handleAidSchemeModalDisplay() {
    const stateObject = {
      isAidSchemeDialogShowing: !this.state.isAidSchemeDialogShowing,
    };

    if (this.state.isAidSchemeDialogShowing) {
      stateObject.isAidSchemeFormUpdated = false;
      stateObject.aidSchemeData = getEmptyAidSchemeObject();
      stateObject.aidSchemeErrorData = getEmptyAidSchemeErrorObject();
    }

    this.setState(stateObject);
  }

  _handleCreateAidScheme(label) {
    const stateObject = { isAidSchemeDialogShowing: true };

    if (label) {
      stateObject.isAidSchemeFormUpdated = true;
      stateObject.aidSchemeData = {
        ...this.state.aidSchemeData,
        label,
      };
    }

    this.setState(stateObject);
  }

  // REGION
  _handleRegionModalDisplay() {
    const stateObject = {
      isRegionDialogShowing: !this.state.isRegionDialogShowing,
    };

    if (this.state.isRegionDialogShowing) {
      stateObject.isRegionFormUpdated = false;
      stateObject.currentRegion = getEmptyNewRegionDataObject();
      stateObject.newRegion = getEmptyNewRegionDataObject();
      stateObject.regionError = false;
    }

    this.setState(stateObject);
  }

  _handleChangeNewAidScheme(event) {
    const { value, name } = event.target;

    this.setState({
      isAidSchemeFormUpdated: true,
      aidSchemeData: {
        ...this.state.aidSchemeData,
        [name]: value,
      },
    });
  }

  _handleCreateNewRegion(name) {
    const stateObject = {
      isRegionDialogShowing: true,
    };

    if (name) {
      stateObject.isRegionFormUpdated = true;
      stateObject.newRegion = {
        ...this.state.newRegion,
        label: name,
      };
    }

    this.setState(stateObject);
  }

  _handleChangeNewRegion(event) {
    const { value } = event.target;

    this.setState({
      newRegion: {
        ...this.state.newRegion,
        label: value,
      },
      isRegionFormUpdated: true,
    });
  }

  _handleChooseRegion(region) {
    this.setState({ currentRegion: region });
  }

  _handleDepartementChange(val) {
    const departments = [...this.state.newRegion.departments];
    const isExistDepartment = departments.find(
      (element) => element.id === val.id
    );

    if (!isExistDepartment) {
      departments.push({
        id: val.id,
        label: val.label,
        number: val.number,
        active: val.active,
      });

      this.setState({
        newRegion: {
          ...this.state.newRegion,
          departments,
        },
        currentRegion: {
          ...this.state.currentRegion,
          departments,
        },
      });
    } else {
      toast.error("Département déjà existant", Helper.getToastOptions());
    }
  }

  _handleDeleteDepartment(department) {
    let departments = [...this.state.newRegion.departments];
    departments = departments.filter((element) => element.id !== department.id);

    this.setState({
      newRegion: {
        ...this.state.newRegion,
        departments,
      },
      currentRegion: {
        ...this.state.currentRegion,
        departments,
      },
    });
  }

  _handleSaveRegion() {
    const name = this.state.newRegion.label;

    if (name) {
      const departments = [...this.state.newRegion.departments];
      let canSave = true;

      if (departments.length > 0) {
        let values = [];

        this.state.regionList?.map((region) => {
          values.push(region.departments.map((element) => element.id));

          return null;
        });

        values = values.map((value) => {
          value.sort((a, b) => a - b);

          return JSON.stringify(value);
        });

        let newDepartments = departments.map((element) => {
          return element.id;
        });
        newDepartments.sort((a, b) => a - b);
        newDepartments = JSON.stringify(newDepartments);

        const isListExist = values.find((value) => value === newDepartments);

        canSave = !isListExist;
      }

      if (canSave) {
        this.setState({ loading: true });
        Api.region
          .createRegion(
            name,
            null,
            true,
            true,
            this.state.newRegion.departments
          )
          .then((response) => {
            const result = Helper.isValidResponse(response);
            const stateObject = { loading: false };

            if (result) {
              Promise.all([
                Api.region.getRegions(1, {
                  active: true,
                  "order[position]": "ASC",
                }),
                Api.department.getDepartments(),
              ])
                .then(([regionResponse, departmentResponse]) => {
                  const regions = Helper.isValidResponse(regionResponse);
                  const departments =
                    Helper.isValidResponse(departmentResponse);

                  if (regions) {
                    stateObject.regionList = regions;
                  }

                  if (departments) {
                    stateObject.departmentlist = departments;
                  }

                  stateObject.isRegionDialogShowing = false;
                  stateObject.isRegionFormUpdated = false;
                  stateObject.newRegion = getEmptyNewRegionDataObject();
                  stateObject.currentRegion = getEmptyNewRegionDataObject();
                  stateObject.newAddedRegion = result;

                  if (regions && departments) {
                    toast.success(
                      "La région a été enregistrée avec succès",
                      Helper.getToastOptions()
                    );
                  }

                  this.setState(stateObject);
                })
                .catch((err) => {
                  console.error(err);
                  this.setState({ loading: false });
                });
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ loading: false });
          });
      } else {
        toast.error(
          "Une autre région possède la même liste de département que celle que vous souhaitez enregistrer",
          Helper.getToastOptions()
        );
      }
    } else {
      toast.error(
        "Veuillez saisir le nom de la région avant d'enregistrer",
        Helper.getToastOptions()
      );
    }
  }

  _handleAidFundingFormDisplay() {
    const stateObject = {
      isAidFundingFormShowing: !this.state.isAidFundingFormShowing,
    };

    if (this.state.isAidFundingFormShowing) {
      stateObject.aidFundingFormMode = "creation";
      stateObject.isAidFundingFormUpdated = false;
      stateObject.currentAidFundingData = null;
    }

    this.setState(stateObject);
  }

  _getAidFundingTableRowData(data, isNew = true, dataId = null) {
    const elements = [...this.state.aid.fundingLines];
    const lastElementId = elements
      .filter((element) => element.isNew)
      ?.slice(-1)?.[0]?.id;
    let lastElementPosition = 0;

    if (lastElementId) {
      const ids = lastElementId.split("-");

      if (ids[1] > 0) {
        lastElementPosition = +ids[1];
      }
    }

    const id = dataId ? dataId : `newFunding-${lastElementPosition + 1}`;

    if (data && id) {
      data.id = id;
    }

    return {
      id,
      isNew,
      data,
    };
  }

  _handleAddAidFundingLine(data, id) {
    const lines = [...this.state.aid.fundingLines];

    if (!id) {
      lines.push(this._getAidFundingTableRowData(data));
    } else {
      let index = lines.findIndex((line) => line.id === id);

      if (index !== -1) {
        lines[index] = this._getAidFundingTableRowData(data, isNaN(id), id);
      }
    }

    this.setState({
      aid: {
        ...this.state.aid,
        fundingLines: lines,
      },
      isAidFundingFormUpdated: false,
      isAidFundingFormShowing: false,
      currentAidFundingData: null,
    });

    this.props.modalModify(true);
  }

  _handleAidFundingRowsCumulChange(status) {
    this.setState({
      aid: {
        ...this.state.aid,
        isWithAidFundingRowsCumul: status,
      },
    });
    this.props.modalModify(true);
  }

  _handleAidFundingRowClick(row) {
    this.setState({
      currentAidFundingData: row,
      isAidFundingFormShowing: true,
      aidFundingFormMode: "update",
    });
  }

  _handleDeleteAidFundingRow(row) {
    let lines = [...this.state.aid.fundingLines];
    lines = lines.filter((element) => element.id !== row.id);

    this.setState({
      aid: {
        ...this.state.aid,
        fundingLines: lines,
      },
    });

    this.props.modalModify(true);
  }

  _handleSetConfirm(status = true) {
    this.setState({ isAidFundingFormUpdated: status });
  }

  _getCriterionCVLabelById(id) {
    const criterionValues = [];

    this.state.aidFundingCriterions[this.state.aid.funding?.shortName].map(
      (criterion) => {
        criterionValues.push(...(criterion?.criterionValues || []));

        return null;
      }
    );

    return criterionValues?.find((element) => element.id === id)?.value || "-";
  }

  _getZoneTypeById(value) {
    return (
      this.state.zoneList.find((element) => element.value === value)?.label ||
      "-"
    );
  }

  _getLocationById(id, type) {
    const elements =
      type === Constants.LOCATION_TYPE_REGIONAL
        ? this.state.regionList
        : this.state.departmentlist;
    return elements.find((element) => element.id === id)?.label || "-";
  }

  _getSpecieLabelById(id, isSpecie = false) {
    if (isSpecie) {
      const elements = [];

      this.state.speciesGroup.map((element) => {
        elements.push(...(element.species || []));

        return null;
      });

      return elements.find((element) => element.id === id)?.label || "-";
    } else {
      return (
        this.state.speciesGroup.find((element) => element.id === id)?.label ||
        "-"
      );
    }
  }

  _getDensityLabelById(value) {
    if (value) {
      return value === 1 ? "Actuelle" : "Projet";
    }

    return "-";
  }

  _getFundingLinesDataFromAidObject(data) {
    const results = [];
    const type = data?.funding?.shortName || null;

    if (type) {
      let key = "";

      switch (type) {
        case Constants.AID_FUNDING_FIXED_AMOUNT:
          key = "aidFundingFixedAmounts";
          break;
        case Constants.AID_FUNDING_SCALE:
          key = "aidFundingScales";
          break;
        case Constants.AID_FUNDING_PLAN:
          key = "aidFundingPlans";
          break;
        default:
          break;
      }

      if (key) {
        const elements = data?.[key] || [];

        elements.map((element) => {
          let criterion = {};

          // Les critères
          element?.criteria?.map((criteria) => {
            let criterionType = criteria?.criterion?.type?.shortName || null;
            let shortName = criteria?.criterion?.shortName || null;

            if (criterionType && shortName) {
              let answer = criteria?.value?.answer;
              let condition = false;

              if (criterionType === Constants.CRITERION_TYPE_TXT) {
                if (Array.isArray(answer)) {
                  condition = answer.length > 0;
                } else {
                  condition = Boolean(answer);
                }
              }

              if (criterionType === Constants.CRITERION_TYPE_OBG && answer) {
                condition = Boolean(answer);
              }

              if (criterionType === Constants.CRITERION_TYPE_LOC) {
                condition = Boolean(answer?.type);

                if (condition) {
                  answer = {
                    type: answer.type,
                    location: answer.answer || null,
                  };
                }
              }

              if (criterionType === Constants.CRITERION_TYPE_NUM) {
                if (shortName !== "criPlan_01") {
                  if (
                    typeof answer === "object" &&
                    answer?.hasOwnProperty("min") &&
                    answer?.hasOwnProperty("max")
                  ) {
                    if (
                      Helper.isValidNumber(answer?.["min"]) &&
                      Helper.isValidNumber(answer?.["max"])
                    ) {
                      answer = [answer["min"], answer["max"]];
                      condition = true;
                    }
                  } else {
                    condition = Helper.isValidNumber(answer);

                    if (condition) {
                      answer = [0, Number(answer)];
                    }
                  }
                } else {
                  condition = Boolean(answer?.specieGroup);
                }
              }

              if (condition) {
                criterion[shortName] = {
                  type: criterionType,
                  shortName,
                  value: answer,
                  criterionId: criteria?.criterion?.id,
                };
              }
            }

            return null;
          });

          // Les valeurs fixes
          if (
            [
              Constants.AID_FUNDING_FIXED_AMOUNT,
              Constants.AID_FUNDING_PLAN,
            ].includes(type)
          ) {
            if (Helper.isValidNumber(element["amount"])) {
              criterion["amount"] = element["amount"];
            }
          }

          if (type === Constants.AID_FUNDING_PLAN) {
            if (Helper.isValidNumber(element["density"])) {
              criterion["density"] = element["density"];
            }
          }

          if (type === Constants.AID_FUNDING_SCALE) {
            if (Helper.isValidNumber(element["baseAmount"])) {
              criterion["baseAmount"] = element["baseAmount"];
            }

            if (Helper.isValidNumber(element["rate"])) {
              criterion["rate"] = element["rate"];
            }
          }

          if (!_.isEqual(criterion, {})) {
            results.push({
              id: element.id,
              data: criterion,
            });
          }

          return null;
        });
      }
    }

    return results;
  }

  render() {
    const userOrganization = Session.getSessionUser().organization;
    const aidOrganization = this.props.aid?.organization;
    const aidOrganizationCreator = this.props.aid?.organizationCreator;

    const { activeThemeContainerId } = this.state;

    // Used to redirect to aid list after a delete request
    if (this.state.forceLocation) {
      return <Navigate to={Routing.bo_simulator} replace />;
    }

    // Cas de modification => Activation ou désactivation des formulaires sur les DOSSIERS
    let activedFolderForm = true;

    if (
      this.state.type !== "create" &&
      this.state.aid?.id &&
      this.state.lastVersion?.id
    ) {
      const authEntry = Session.getAuth(Constants.PROFILE_AIDENTRY);
      const authValidation = Session.getAuth(Constants.PROFILE_AIDVALIDATION);
      const hasRightsAccess =
        authEntry === RIGHTS_WRITE || authValidation === RIGHTS_WRITE;

      activedFolderForm =
        hasRightsAccess && this.state.aid.id === this.state.lastVersion.id;

      // Condition supplémentaire pour un organisme de type ORGANISME
      if (userOrganization.organism) {
        activedFolderForm =
          activedFolderForm && userOrganization.id === aidOrganization?.id;
      }

      // Condition supplémentaire pour un organisme de type PARTENAIRE
      if (userOrganization.partner) {
        activedFolderForm =
          activedFolderForm &&
          userOrganization.id === aidOrganizationCreator?.id;
      }

      activedFolderForm =
        activedFolderForm &&
        !(
          this.state.aid?.status?.value === AID_STATUS_VALIDATED &&
          this.state.aid?.active
        );
    }

    let withSaveButtonAdditionalCondition =
      this.state.aid?.id === this.state.lastVersion?.id;

    if (userOrganization.organism) {
      withSaveButtonAdditionalCondition =
        withSaveButtonAdditionalCondition &&
        userOrganization.id === aidOrganization?.id;
    }

    if (userOrganization.partner) {
      withSaveButtonAdditionalCondition =
        withSaveButtonAdditionalCondition &&
        userOrganization.id === aidOrganizationCreator?.id;
    }

    // Condition d'apparition du bouton d'enregistrement
    const withSaveButton =
      this.state.aidEdition ||
      withSaveButtonAdditionalCondition ||
      !this.state.aid.id;
    let withComment = withSaveButton;

    if (this.props.type !== "create") {
      const canPartnerEditComment =
        userOrganization.partner &&
        userOrganization.id === aidOrganizationCreator?.id;
      const canOrganismEditComment =
        userOrganization.organism &&
        userOrganization.id === this.state.aid?.organization?.value;
      withComment =
        withComment || canPartnerEditComment || canOrganismEditComment;
    }

    const isValidedAndActiveAid =
      this.props.type !== "create" &&
      this.state.aid?.status?.value === AID_STATUS_VALIDATED &&
      this.state.aid?.active;

    // Liste des options dans le formulaire de "Dispositif" => Ajout de l'option d'ajout
    const schemesList = this.state.schemesList;

    if (
      Session.getAuth(Constants.PROFILE_AID_SCHEME_ADDING_FROM_AID) >
      RIGHTS_NOACCESS
    ) {
      if (!schemesList.find((element) => element.value === "new")) {
        schemesList.push({
          value: "new",
          label: "Créer un nouveau dispositif",
        });
      }
    }

    return (
      <article className="aid-form">
        <ThemeProvider theme={fsyTheme}>
          {/** Modal pour la création de nouveau dispositif */}
          <Modal
            title="Création de nouveau dispositif"
            isShowing={this.state.isAidSchemeDialogShowing}
            confirm={this.state.isAidSchemeFormUpdated}
            hide={this._handleAidSchemeModalDisplay}
            saveBeforeClose={this._handleSaveAidScheme}
          >
            {this.state.loading && <Loading />}
            <div>
              <div className="form-block" ref={this.nameField}>
                <InputField
                  className="input-tiny"
                  name="label"
                  onChange={this._handleChangeNewAidScheme}
                  value={this.state.aidSchemeData.label}
                  context={this}
                  isFocused={this.state.aidSchemeData.label !== ""}
                  required={true}
                >
                  Libellé
                </InputField>
                {this.state.aidSchemeErrorData.label && (
                  <div className="error">Champ obligatoire</div>
                )}
              </div>
              <button
                className={`btn default btn-lg`}
                type="button"
                title="Enregistrer la donnée saisie"
                onClick={this._handleSaveAidScheme}
              >
                <Save />
                Enregistrer
              </button>
            </div>
          </Modal>

          {/** Modal pour la création de nouvelle Région */}
          <Modal
            title="Création de nouvelle région"
            isShowing={this.state.isRegionDialogShowing}
            confirm={this.state.isRegionFormUpdated}
            hide={this._handleRegionModalDisplay}
            saveBeforeClose={this._handleSaveRegion}
          >
            {this.state.loading && <Loading />}
            <div className="region-form-from-aid">
              {/** Partie des REGIONS */}
              <div className="region-form-content">
                <h3>Régions</h3>
                <div className="region-forms">
                  {this.state.regionList?.map((region, index) => {
                    return (
                      <InputField
                        key={`RegionNonModifiable${index + 1}`}
                        title={`${region.label} (${
                          region.departments?.length
                        } département${
                          region.departments?.length > 1 ? "s" : ""
                        })`}
                        className={`region-input TXT-input input-tiny flex-grow formField--nopadding disable-region ${
                          this.state.currentRegion?.id === region.id
                            ? "active-region"
                            : ""
                        }`}
                        name={`${region.label}`}
                        value={`${region.label}`}
                        context={this}
                        onClick={() => this._handleChooseRegion(region)}
                      />
                    );
                  })}
                  <InputField
                    name="new-region-form"
                    title={
                      this.state.newRegion?.label
                        ? `${this.state.newRegion.label} (${
                            this.state.newRegion.departments?.length
                          } département${
                            this.state.newRegion.departments?.length > 1
                              ? "s"
                              : ""
                          })`
                        : ""
                    }
                    className={`region-input TXT-input input-tiny flex-grow formField--nopadding ${
                      this.state.currentRegion?.id === this.state.newRegion?.id
                        ? "active-region"
                        : ""
                    }`}
                    value={this.state.newRegion.label}
                    context={this}
                    onChange={this._handleChangeNewRegion}
                    onClick={() =>
                      this._handleChooseRegion(this.state.newRegion)
                    }
                  />
                </div>
              </div>

              <Divider
                orientation="vertical"
                className="region-department-vertical-separation"
              />

              {/** Partie des DEPARTEMENTS */}
              <div className="department-form-content">
                <h3>Départements</h3>
                <div className="department-forms">
                  {this.state.currentRegion?.id ===
                    this.state.newRegion?.id && (
                    <SelectField
                      className="department-selector"
                      options={this.state.departmentlist?.map((element) => {
                        return {
                          ...element,
                          value: element.id,
                          label: `${element.label} (${element.number})`,
                        };
                      })}
                      value={null}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      optionsContentHeight="20vh"
                      placeholder="Ajouter un département"
                      onChange={this._handleDepartementChange}
                    />
                  )}
                  {this.state.currentRegion?.departments?.map(
                    (department, index) => {
                      return (
                        <div
                          key={`DepartementNonModifiable${index + 1}`}
                          className={`${
                            this.state.currentRegion?.id ===
                            this.state.newRegion?.id
                              ? "flex-start"
                              : ""
                          }`}
                        >
                          <InputField
                            title={`${department.number} - ${department.label}`}
                            className={`region-input TXT-input input-tiny flex-grow formField--nopadding disable-department`}
                            name={`${department.label}`}
                            value={`${department.number} - ${department.label}`}
                            context={this}
                            onClick={() => {}}
                          />
                          {this.state.currentRegion?.id ===
                            this.state.newRegion?.id && (
                            <button
                              type="button"
                              className="btn btn-tiny alert LOC-button"
                              title="Supprimer cette valeur"
                              onClick={() => {
                                this._handleDeleteDepartment(department);
                              }}
                            >
                              <Delete />
                            </button>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <button
              className={`btn default btn-lg`}
              type="button"
              title="Enregistrer les données saisies"
              onClick={this._handleSaveRegion}
            >
              <Save />
              Enregistrer
            </button>
          </Modal>
          <form
            onSubmit={this.handleFormSubmit}
            noValidate
            className="flex-sb flex-column"
          >
            {this.state.loading && <Loading />}
            {/* ----------------------------------------- Right panel ----------------------------------------- */}
            <div className="aid-right-panel">
              <div className="aid-info">
                {this.state.loading && <Loading />}
                {this.state.canEdit &&
                  this.state.lastVersion &&
                  this.state.lastVersion.id === this.state.aid.id &&
                  this.state.aidActive && (
                    <>
                      <div className="flex-end">
                        <button
                          className="btn btn-tiny"
                          onClick={this.createVersion}
                          style={{ marginLeft: 0, marginRight: 0 }}
                          type="button"
                          title="Créer une nouvelle version de l'aide"
                          ref={this.props.createVersionForwardRef}
                        >
                          <Add />
                          Nouvelle version
                        </button>
                      </div>
                      <Divider className="my-1" />
                    </>
                  )}
                <div className="" ref={this.statusField}>
                  <SelectField
                    options={this.statusOptions}
                    value={this.state.aid.status}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    required={true}
                    placeholder="Statut"
                    isDisabled={!this.canChangeStatus()}
                    onChange={this.handleStatusChange}
                    context={this}
                  />
                  <div className="error">Champ obligatoire</div>
                </div>
                <div className="form-block mx-0">
                  <div className="flex-start">
                    <span className="bold">Etat : </span>
                    <ReactSwitch
                      className="flex-input"
                      checked={this.state.aid.active ?? false}
                      onColor="#a6d290"
                      offColor="#fc9999"
                      onChange={this.handleActiveChange}
                      disabled={
                        this.props.readOnly ||
                        ((userOrganization.organism ||
                          userOrganization.partner) &&
                          ((userOrganization.partner &&
                            userOrganization.id !==
                              this.state.aid.organizationCreator?.id) ||
                            (userOrganization.organism &&
                              userOrganization.id !==
                                this.state.aid.organization?.id)))
                      }
                    />
                    <span>&emsp;{this.state.activeLabel}</span>
                  </div>
                </div>
                {this.state.listVersionOptions.length > 0 && (
                  <div className="form-block mx-0">
                    <span className="bold">Version actuelle</span>
                    <SelectField
                      options={this.state.listVersionOptions}
                      formatOptionLabel={this._formatVersionOptions}
                      value={this.state.aid.version}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      onChange={this.handleVersionSelect}
                      placeholder=""
                    />
                    <button
                      className="btn"
                      onClick={this.handleVersionChange}
                      style={{ marginLeft: 0, marginRight: 0, display: "none" }}
                      type="button"
                      ref={this.props.switchVersionForwardRef}
                    />
                  </div>
                )}

                {this.state.lastActiveVersion && (
                  <p>
                    <span className="bold">Version publiée: </span>
                    {this.state.lastActiveVersion
                      ? this.state.lastActiveVersion.version
                      : "-"}
                  </p>
                )}

                <div className="w-100">
                  <div className="flex-sb">
                    <div className="bold">Date de publication :</div>
                    <div>
                      {this.state.aid.activateAt
                        ? moment(this.state.aid.activateAt).format("DD/MM/YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="bold">Date de Modification :</div>
                    <div>
                      {this.state.aid.updatedAt
                        ? moment(this.state.aid.updatedAt).format("DD/MM/YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="bold">Créée par :</div>
                    <div
                      className="aid-info_organization"
                      title={
                        this.state.aid.organizationCreator
                          ? this.state.aid.organizationCreator.name
                          : "-"
                      }
                    >
                      {this.state.aid.organizationCreator
                        ? this.state.aid.organizationCreator.name
                        : "-"}
                    </div>
                  </div>
                </div>

                <Divider className="my-1" />

                {this.state.aid.id && (
                  <button
                    className="btn btn-tiny white w-100 mx-0"
                    type="button"
                    onClick={this._showActivities}
                    title="Consulter l'historique des changements"
                  >
                    Activités
                  </button>
                )}
              </div>

              {/* ----------------------------------------- Activities div ----------------------------------------- */}

              {this.state.showActivities && (
                <div className="aid-activities">
                  <AidActivities
                    aidId={
                      !this.state.aid.parent
                        ? this.state.aid.id
                        : this.state.aid.parent.id
                    }
                  />
                </div>
              )}
            </div>
            {/* ------------------------------------------- Main form ------------------------------------------- */}

            <Navs
              className="aid-form-navs"
              disabled={this.state.loading}
              handleClickMenu={(id) => {
                if (id === "criterion") {
                  setTimeout(() => {
                    this.scrollSpy();
                  }, 500);
                }
              }}
            >
              {/* ================================== INFORMATIONS GENERALES ================================== */}
              <Nav
                label="Général"
                id="general"
                title="Informations générales de l'aide"
              >
                <div className="aid-form-content">
                  <div className="aid-form-left-part">
                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ NAME ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.nameField}>
                      <InputField
                        className="input-tiny"
                        name="name"
                        onChange={this.handleChange}
                        value={this.state.aid.name}
                        context={this}
                        isFocused={this.state.aid.name !== ""}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes("name")}
                      >
                        Nom de l'aide
                      </InputField>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ FOLDER ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <SelectField
                        options={this.state.folders}
                        value={this.state.aid.folder}
                        isDisabled={
                          !activedFolderForm && !this.state.hasPublishedAid
                        }
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Dossier"
                        onChange={(selectedOptions) =>
                          this.handleFolderChange(selectedOptions, "folder")
                        }
                        noUpperCasePlaceholder={true}
                        context={this}
                      />
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ SUBFOLDER ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <SelectField
                        options={this.state.subfolders}
                        value={this.state.aid.subfolder}
                        isDisabled={
                          !activedFolderForm && !this.state.hasPublishedAid
                        }
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Sous-dossier"
                        onChange={(selectedOptions) =>
                          this.handleFolderChange(selectedOptions, "subfolder")
                        }
                        noUpperCasePlaceholder={true}
                        context={this}
                      />
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ LABEL ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <InputField
                        className="input-tiny"
                        name="label"
                        onChange={this.handleChange}
                        value={this.state.aid.label}
                        context={this}
                        isFocused={this.state.aid.label !== ""}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes("label")}
                      >
                        Libellé
                      </InputField>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~ DESCRIPTION ~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.descriptionField}>
                      <TextareaField
                        className="bo-box-container"
                        name="description"
                        value={this.state.aid.description}
                        context={this}
                        isFocused={this.state.aid.description !== ""}
                        disabled={!this.state.aidEdition}
                        onChange={this.handleChange}
                        rows={3}
                        title="Description de l'aide"
                        required={this.state.requiredFields.includes(
                          "description"
                        )}
                      >
                        Description
                      </TextareaField>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ ORIGIN URL ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <InputField
                        className="input-tiny input-url"
                        name="originUrl"
                        onChange={this.handleChange}
                        value={this.state.aid.originUrl}
                        context={this}
                        isFocused={this.state.aid.originUrl !== ""}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes(
                          "originUrl"
                        )}
                      >
                        URL d'origine
                      </InputField>
                      <a
                        className="aid-form-link"
                        title="Tester le lien dans un nouvel onglet"
                        type="button"
                        target="_blank"
                        rel="noreferrer"
                        href={this.state.aid.originUrl}
                      >
                        <OpenInNew />
                      </a>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ URL DOC ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.documentUrlField}>
                      <InputField
                        className="input-tiny input-url"
                        name="documentUrl"
                        onChange={this.handleChange}
                        value={this.state.aid.documentUrl}
                        context={this}
                        isFocused={this.state.aid.documentUrl !== ""}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes(
                          "documentUrl"
                        )}
                      >
                        URL du document descriptif
                      </InputField>
                      <a
                        className="aid-form-link"
                        title="Tester le lien dans un nouvel onglet"
                        type="button"
                        target="_blank"
                        rel="noreferrer"
                        href={this.state.aid.documentUrl}
                      >
                        <OpenInNew />
                      </a>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~ URL REQUEST ~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.requestUrlField}>
                      <InputField
                        className="input-tiny input-url"
                        name="requestUrl"
                        onChange={this.handleChange}
                        value={this.state.aid.requestUrl}
                        context={this}
                        isFocused={this.state.aid.requestUrl !== ""}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes(
                          "requestUrl"
                        )}
                      >
                        URL pour demander l'aide
                      </InputField>
                      <a
                        className="aid-form-link"
                        title="Tester le lien dans un nouvel onglet"
                        type="button"
                        target="_blank"
                        rel="noreferrer"
                        href={this.state.aid.requestUrl}
                      >
                        <OpenInNew />
                      </a>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ CONTACT ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="flex-sb align-items-start">
                      <div className="flex-grow">
                        <div className="form-block" ref={this.contactNameField}>
                          <InputField
                            className="input-tiny"
                            name="contactName"
                            onChange={this.handleChange}
                            value={this.state.aid.contactName}
                            context={this}
                            isFocused={this.state.aid.contactName !== ""}
                            disabled={!this.state.aidEdition}
                            required={this.state.requiredFields.includes(
                              "contactName"
                            )}
                          >
                            Nom du contact
                          </InputField>
                          <div className="error">Champ obligatoire</div>
                        </div>
                      </div>
                      <div className="flex-grow">
                        <div
                          className="form-block"
                          ref={this.contactEmailField}
                        >
                          <InputField
                            className="input-tiny"
                            type="email"
                            name="contactEmail"
                            onChange={this.handleChange}
                            value={this.state.aid.contactEmail}
                            context={this}
                            isFocused={this.state.aid.contactEmail !== ""}
                            disabled={!this.state.aidEdition}
                          >
                            Adresse email du contact
                          </InputField>
                          <div className="error">Email invalide</div>
                        </div>
                      </div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ ADDITIONAL REMARK ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <TextareaField
                        className="bo-box-container"
                        name="additionalRemark"
                        onChange={this.handleChange}
                        value={this.state.aid.additionalRemark}
                        context={this}
                        isFocused={this.state.aid.additionalRemark !== ""}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes(
                          "additionalRemark"
                        )}
                        maxCharacter={this.ADDITIONAL_REMARK_MAX_CHARACTER}
                      >
                        Remarques complémentaires
                      </TextareaField>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~ DETAILED REMARK ~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <TextareaField
                        className="bo-box-container"
                        name="detailedRemark"
                        value={this.state.aid.detailedRemark}
                        context={this}
                        isFocused={this.state.aid.detailedRemark !== ""}
                        disabled={!this.state.aidEdition}
                        onChange={this.handleChange}
                        rows={3}
                        title="Remarques détaillées"
                        required={this.state.requiredFields.includes(
                          "detailedRemark"
                        )}
                        maxCharacter={this.DETAILED_REMARK_MAX_CHARACTER}
                      >
                        Remarques détaillées
                      </TextareaField>
                    </div>
                  </div>

                  <div className="aid-form-right-part">
                    {/* ~~~~~~~~~~~~~~~~~~~~~~~ ORGANIZATION ~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.organizationField}>
                      <SelectField
                        options={this.state.organismListFiltered}
                        value={this.state.aid.organization}
                        isDisabled={!this.state.aidEdition}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Organisme porteur"
                        onChange={this.handleOrganismChange}
                        context={this}
                        required={this.state.requiredFields.includes(
                          "organization"
                        )}
                      />
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ NATURE ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.natureField}>
                      <SelectField
                        options={this.state.natureList}
                        value={this.state.aid.nature}
                        isDisabled={!this.state.aidEdition}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Nature"
                        onChange={this.handleNatureChange}
                        context={this}
                        required={this.state.requiredFields.includes("nature")}
                      />
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ COMPLEXITY ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.complexityField}>
                      <SelectField
                        options={this.state.complexityList}
                        value={this.state.aid.complexity}
                        isDisabled={!this.state.aidEdition}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Complexité de montage"
                        onChange={this.handleComplexityChange}
                        context={this}
                        required={this.state.requiredFields.includes(
                          "complexity"
                        )}
                      />
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ SCHEME ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <SelectField
                        options={schemesList}
                        value={this.state.aid.scheme}
                        isDisabled={!this.state.aidEdition}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Dispositif"
                        onChange={this.handleSchemeChange}
                        context={this}
                        required={this.state.requiredFields.includes("scheme")}
                        creatable={
                          Session.getAuth(
                            Constants.PROFILE_AID_SCHEME_ADDING_FROM_AID
                          ) > RIGHTS_NOACCESS
                        }
                        formatCreateLabel="Ajouter le dispositif"
                        createOptionPosition="top"
                        onCreateOption={(value) =>
                          this._handleCreateAidScheme(value)
                        }
                      />
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ ENVIRONMENT ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block" ref={this.environmentField}>
                      <SelectField
                        options={this.state.environmentsList}
                        value={this.state.aid.environment}
                        isDisabled={!this.state.aidEdition}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Milieu concerné"
                        onChange={this.handleEnvironmentChange}
                        context={this}
                        required={this.state.requiredFields.includes(
                          "environment"
                        )}
                      />
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ DATES ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div ref={this.openDateField} className="form-block">
                      <InputField
                        className="input-tiny w-100"
                        type="date"
                        name="openDate"
                        isFocused={true}
                        onChange={this.handleChange}
                        center={true}
                        title="Planifier une date d'ouverture"
                        value={this.state.aid.openDate}
                        context={this}
                        disabled={!this.state.aidEdition}
                        required={this.state.requiredFields.includes(
                          "openDate"
                        )}
                      >
                        Date d'ouverture
                      </InputField>
                      <div className="error">Champ obligatoire</div>
                    </div>
                    <div className="form-block">
                      <InputField
                        className="input-tiny w-100"
                        type="date"
                        name="depositDate"
                        center={true}
                        disabled={!this.state.aidEdition}
                        isFocused={true}
                        onChange={this.handleChange}
                        title="Date maximale de dépôt"
                        value={this.state.aid.depositDate}
                        context={this}
                      >
                        Date maximale de dépôt
                      </InputField>
                    </div>

                    {/*<div className="separator thick"/>*/}

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ LEADTIME ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <InputField
                        className="input-tiny"
                        type="number"
                        name="leadtime"
                        center={true}
                        isFocused={this.state.aid.leadtime !== ""}
                        disabled={!this.state.aidEdition}
                        onChange={this.handleChange}
                        title="Délai d'obtention en mois"
                        value={this.state.aid.leadtime}
                        context={this}
                      >
                        Délai d'obtention en mois
                      </InputField>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ CONTRACT ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div className="form-block">
                      <InputField
                        className="input-tiny"
                        type="number"
                        name="contractDuration"
                        isFocused={this.state.aid.contractDuration !== ""}
                        disabled={!this.state.aidEdition}
                        center={true}
                        onChange={this.handleChange}
                        title="Durée du contrat en années"
                        value={this.state.aid.contractDuration}
                        context={this}
                      >
                        Durée du contrat en années
                      </InputField>
                    </div>

                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ CREDIT ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    <div
                      className="form-block"
                      ref={this.taxCreditPercentField}
                    >
                      <InputField
                        className="input-tiny"
                        type="number"
                        name="taxCreditPercent"
                        isFocused={this.state.aid.taxCreditPercent !== ""}
                        disabled={!this.state.aidEdition}
                        center={true}
                        onChange={this.handleChange}
                        title="% de crédit d'impôt"
                        value={this.state.aid.taxCreditPercent}
                        context={this}
                        required={this.state.requiredFields.includes(
                          "taxCreditPercent"
                        )}
                      >
                        Pourcentage de crédit d'impôt
                      </InputField>
                      <div className="error">Champ obligatoire</div>
                    </div>

                    {/** Simulable */}
                    <div className="simulable-switch-block">
                      <div className="flex-start">
                        <span>Simulable : </span>
                        <ReactSwitch
                          className="flex-input"
                          checked={this.state.aid.simulable ?? false}
                          onColor="#A6D290"
                          offColor="#FC9999"
                          onChange={this.handleSimulableChange}
                          disabled={!this.state.aidEdition}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </Nav>

              {/* ================================== CRITERES D'ELIGIBILITÉ ================================== */}
              <Nav
                label="Critères d'éligibilité"
                id="criterion"
                title="Saisie des critères d'éligibilité de l'aide"
              >
                <div className="aid-form-content">
                  <div className="aidCriteria-menu">
                    {_.map(this.state.themeList, (theme, i) => {
                      if (theme === undefined) {
                        return <React.Fragment key={i}></React.Fragment>;
                      }
                      return (
                        <div
                          className={`aidCriteria-menu-item flex ${
                            activeThemeContainerId ===
                              "criterionTheme" + theme.id && "selected"
                          }`}
                          onClick={this._navigateToTheme}
                          key={theme.name}
                          data-key={theme.name}
                        >
                          <span>
                            {theme.name} ({theme.count})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="aidCriteria-list" id="criterionTheme">
                    {_.map(this.state.criterionList, (theme, i) => {
                      if (theme.length === 0) {
                        return <React.Fragment key={i}></React.Fragment>;
                      }
                      return (
                        <div
                          key={theme[0].theme.name}
                          className="aidCriteria-theme-section"
                          data-theme={theme[0].theme.name}
                          id={"criterionTheme" + theme[0].theme.id}
                        >
                          <h3>{theme[0].theme.name}</h3>
                          <div className="aidCriteria-theme-content">
                            {_.map(theme, (criterion) =>
                              this._getCriterionFormByType(criterion)
                            )}
                          </div>
                          <div className="separator-wide" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Nav>

              {/* ================================ INFORMATIONS DE FINANCEMENT =============================== */}
              <Nav
                label="Financement"
                id="funding"
                title="Financements et autres informations financières de l'aide"
              >
                <div className="aid-form-content">
                  <div className="aid-form-part">
                    <div className="form-block" ref={this.formField}>
                      <SelectField
                        options={this.state.formList}
                        value={this.state.aid.form}
                        isDisabled={
                          !this.state.aidEdition || !this.state.aid.simulable
                        }
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Forme de l'aide"
                        onChange={this.handleFormChange}
                        context={this}
                        required={this.state.requiredFields.includes("form")}
                      />
                      {this.state.aid.simulable && (
                        <div className="error">Champ obligatoire</div>
                      )}
                    </div>
                  </div>
                  <div className="aid-form-part">
                    <div className="form-block" ref={this.fundingField}>
                      <SelectField
                        options={this.state.fundingList}
                        value={this.state.aid.funding}
                        isDisabled={
                          !this.state.aidEdition || !this.state.aid.simulable
                        }
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder="Mode de financement"
                        onChange={this.handleFundingChange}
                        context={this}
                        required={this.state.requiredFields.includes("funding")}
                      />
                      {this.state.aid.simulable && (
                        <div className="error">Champ obligatoire</div>
                      )}
                    </div>
                  </div>

                  <div className="aid-form-part w-100 pt-0">
                    <div className="flex-sb align-items-center">
                      <div className="flex-grow w-100">
                        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ AMOUNT RATE ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                        <div className="form-block">
                          <SliderRange
                            min={0}
                            max={100}
                            step={0.5}
                            unit="%"
                            value={[
                              this.state.aid.minimumRate,
                              this.state.aid.maximumRate,
                            ]}
                            disabled={
                              !this.state.aidEdition ||
                              !this.state.aid.simulable
                            }
                            checked={this.state.aid.minimumRate !== null}
                            onChange={this.handleChangeAmountRate}
                            onRequestMinOrMaxChange={(value, type) =>
                              this.handleMinOrMaxChange(value, type, "Rate")
                            }
                            onReset={() => {
                              this.setState(
                                {
                                  aid: {
                                    ...this.state.aid,
                                    minimumRate: null,
                                    maximumRate: null,
                                  },
                                },
                                () => {
                                  this.props.modalModify(true);
                                }
                              );
                            }}
                            label="Taux d'aide"
                          />
                        </div>
                      </div>
                      <div className="flex-grow w-100">
                        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ AMOUNT PER PLANT ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                        <div className="form-block">
                          <SliderRange
                            min={0}
                            max={1000}
                            step={0.5}
                            unit="€"
                            value={[
                              this.state.aid.minimumAmountPerPlant,
                              this.state.aid.maximumAmountPerPlant,
                            ]}
                            disabled={
                              !this.state.aidEdition ||
                              !this.state.aid.simulable
                            }
                            checked={
                              this.state.aid.minimumAmountPerPlant !== null
                            }
                            onChange={this.handleChangeAmountPerPlant}
                            onRequestMinOrMaxChange={(value, type) =>
                              this.handleMinOrMaxChange(
                                value,
                                type,
                                "AmountPerPlant"
                              )
                            }
                            onReset={() => {
                              this.setState({
                                aid: {
                                  ...this.state.aid,
                                  minimumAmountPerPlant: null,
                                  maximumAmountPerPlant: null,
                                },
                              });
                            }}
                            label="Montant par plant"
                          />
                        </div>
                      </div>
                      <div className="flex-grow w-100">
                        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ AMOUNT PER HECATARE ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                        <div className="form-block">
                          <SliderRange
                            min={0}
                            max={20000}
                            step={1}
                            unit="€"
                            value={[
                              this.state.aid.minimumAmountPerHectare,
                              this.state.aid.maximumAmountPerHectare,
                            ]}
                            disabled={
                              !this.state.aidEdition ||
                              !this.state.aid.simulable
                            }
                            checked={
                              this.state.aid.minimumAmountPerHectare !== null
                            }
                            onChange={this.handleChangeAmountPerHectare}
                            onRequestMinOrMaxChange={(value, type) =>
                              this.handleMinOrMaxChange(
                                value,
                                type,
                                "AmountPerHectare"
                              )
                            }
                            onReset={() => {
                              this.setState({
                                aid: {
                                  ...this.state.aid,
                                  minimumAmountPerHectare: null,
                                  maximumAmountPerHectare: null,
                                },
                              });
                            }}
                            label="Montant par hectare"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.aid.funding &&
                  [
                    Constants.AID_FUNDING_FIXED_AMOUNT,
                    Constants.AID_FUNDING_SCALE,
                    Constants.AID_FUNDING_PLAN,
                  ].includes(this.state.aid.funding?.shortName) && (
                    <div className="aid-funding-values-content">
                      <div className="flex-start aid-funding-cumul">
                        <ReactSwitch
                          className="flex-input"
                          checked={this.state.aid.isWithAidFundingRowsCumul}
                          onColor="#a6d290"
                          offColor="#fc9999"
                          onChange={this._handleAidFundingRowsCumulChange}
                          disabled={
                            !this.state.aidEdition || !this.state.aid.simulable
                          }
                        />
                        <span className="cumum-title">
                          Cumuler les lignes de financement
                        </span>
                      </div>
                      <AidFundingTable
                        type={this.state.aid.funding.shortName}
                        disabled={
                          !this.state.aidEdition || !this.state.aid.simulable
                        }
                        criterions={
                          this.state.aidFundingCriterions[
                            this.state.aid.funding.shortName
                          ]
                        }
                        aidFixedFundingElements={
                          this.aidFixedFundingData[
                            this.state.aid.funding.shortName
                          ]
                        }
                        onRequestGetCriterionCVLabelById={
                          this._getCriterionCVLabelById
                        }
                        onRequestGetZoneByTypeId={this._getZoneTypeById}
                        onRequestGetLocationById={this._getLocationById}
                        onRequestGetSpecieLabelById={this._getSpecieLabelById}
                        onRequestGetDensityLabelById={this._getDensityLabelById}
                        lines={this.state.aid.fundingLines}
                        onRequestRowClick={this._handleAidFundingRowClick}
                        onRequestDeleteRow={this._handleDeleteAidFundingRow}
                      />
                      {!(
                        !this.state.aidEdition || !this.state.aid.simulable
                      ) && (
                        <button
                          onClick={this._handleAidFundingFormDisplay}
                          className="btn btn-tiny white"
                          type="button"
                          title="Ajouter une nouvelle ligne"
                        >
                          <Add />
                          Nouveau
                        </button>
                      )}
                      <AidFundingWithCriterions
                        type={this.state.aid.funding.shortName}
                        disabled={
                          !this.state.aidEdition || !this.state.aid.simulable
                        }
                        mode={this.state.aidFundingFormMode}
                        confirm={this.state.isAidFundingFormUpdated}
                        isShowing={this.state.isAidFundingFormShowing}
                        editingAidFunding={this.state.currentAidFundingData}
                        speciesGroup={this.state.speciesGroup}
                        zones={this.state.zoneList}
                        regions={this.state.regionList}
                        departments={this.state.departmentlist}
                        criterions={
                          this.state.aidFundingCriterions[
                            this.state.aid.funding.shortName
                          ]
                        }
                        fundingLines={this.state.aid.fundingLines}
                        onRequestSetConfirm={this._handleSetConfirm}
                        onRequestHide={this._handleAidFundingFormDisplay}
                        onRequestAddAidFundingLine={
                          this._handleAddAidFundingLine
                        }
                        onRequestGetCriterionCVLabelById={
                          this._getCriterionCVLabelById
                        }
                        onRequestGetZoneByTypeId={this._getZoneTypeById}
                        onRequestGetLocationById={this._getLocationById}
                        onRequestGetSpecieLabelById={this._getSpecieLabelById}
                      />
                    </div>
                  )}

                {/*<div className="aid-form-content">
                  {this.state.aid.funding &&
                    this.state.aid.funding.shortName ===
                      Constants.AID_FUNDING_PLAN && (
                      <div className="aid-form-part w-100">
                        <AidFundingPlan
                          funding={this.state.aid.funding}
                          aidFundingPlans={this.state.aid.aidFundingPlans}
                          isDisabled={
                            !this.state.aidEdition || !this.state.aid.simulable
                          }
                          onChange={(aidFundingPlans) => {
                            this.props.modalModify(true);
                            this.setState({
                              aid: {
                                ...this.state.aid,
                                aidFundingPlans: aidFundingPlans,
                              },
                            });
                          }}
                        ></AidFundingPlan>
                      </div>
                    )}

                  {this.state.aid.funding &&
                    this.state.aid.funding.shortName ===
                      Constants.AID_FUNDING_SCALE && (
                      <div className="aid-form-part w-100">
                        <AidFundingScale
                          aidId={this.state.aid.id ?? null}
                          isDisabled={
                            !this.state.aidEdition || !this.state.aid.simulable
                          }
                          aidFundingScales={this.state.aid.aidFundingScales}
                          zones={this.state.zoneList}
                          regions={this.state.regionList}
                          departments={this.state.departmentlist}
                          onChange={(aidFundingScales) => {
                            this.props.modalModify(true);
                            this.setState({
                              aid: {
                                ...this.state.aid,
                                aidFundingScales: aidFundingScales,
                              },
                            });
                          }}
                        ></AidFundingScale>
                      </div>
                    )}

                  {this.state.aid.funding &&
                    this.state.aid.funding.shortName ===
                      Constants.AID_FUNDING_FIXED_AMOUNT && (
                      <div className="aid-form-part w-100">
                        <AidFundingFixedAmount
                          aidId={this.state.aid.id ?? null}
                          isDisabled={
                            !this.state.aidEdition || !this.state.aid.simulable
                          }
                          aidFundingFixedAmounts={
                            this.state.aid.aidFundingFixedAmounts
                          }
                          onChange={(aidFundingFixedAmounts) => {
                            this.props.modalModify(true);
                            this.setState({
                              aid: {
                                ...this.state.aid,
                                aidFundingFixedAmounts: aidFundingFixedAmounts,
                              },
                            });
                          }}
                        ></AidFundingFixedAmount>
                      </div>
                    )}
                </div>*/}
              </Nav>

              {/********************* COMMENTAIRE *********************/}
              <Nav
                label={`Commentaires (${
                  (this.state.comments?.length || 0) +
                  (this.state.newAidComments?.length || 0)
                })`}
                id="comment"
                title="Commentaire"
              >
                <div className="aid-comment-content">
                  <div ref={this.aidCommentFormRef} className="form-block">
                    <TextareaField
                      className="bo-box-container"
                      name="comment"
                      value={this.state.aid.comment}
                      context={this}
                      isFocused={this.state.aid.comment !== ""}
                      onChange={this.handleChange}
                      rows={4}
                      title="Commentaire"
                      disabled={!withComment}
                    >
                      Commentaire
                    </TextareaField>

                    <button
                      type="button"
                      className={`btn btn-tiny default addButton ${
                        [null, undefined, ""].includes(this.state.aid.comment)
                          ? "disabled"
                          : ""
                      }`}
                      title={`Ajouter${
                        this.props.type === "create"
                          ? " le commentaire dans la liste"
                          : ""
                      }`}
                      onClick={this._handleAddNewComment}
                    >
                      <Add />
                      {`Ajouter${
                        this.props.type === "create"
                          ? " le commentaire dans la liste"
                          : ""
                      }`}
                    </button>
                  </div>
                  <div className="form-block">
                    <div>
                      {/** Contenu */}
                      <div
                        style={
                          {
                            /*height: aidCommentTableContentHeight,
                          overflowY: "auto",*/
                          }
                        }
                      >
                        {[
                          ...(this.state.newAidComments || []),
                          ...(this.state.comments || []),
                        ].map((comment) => {
                          return (
                            <div
                              key={`AidComment${comment.id}`}
                              className="comment-row"
                            >
                              <div className="comment-description">
                                {comment.description}
                              </div>
                              <div className="comment-additional-info">
                                <div>{`${comment.name} ${comment.surname}${
                                  this.user.id === comment.userId
                                    ? ` (Moi)`
                                    : ""
                                }`}</div>
                                <div>
                                  {moment(comment.date).format(
                                    "DD/MM/YY HH:mm"
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Nav>
            </Navs>
            <div className="aid-form-footer">
              {withSaveButton && (
                <button
                  className={`btn default btn-lg ${
                    isValidedAndActiveAid && !this.state.hasPublishedAid
                      ? "disabled"
                      : ""
                  }`}
                  type="submit"
                  title="Enregistrer les données saisies"
                  ref={this.props.forwardRef}
                >
                  <Save />
                  Enregistrer
                </button>
              )}
              <RequiredText />
            </div>
          </form>
        </ThemeProvider>
      </article>
    );
  }
}

/* ================================== GLOBAL FUNCTIONS ================================== */

function _countCriterionTheme(criterion, themes) {
  _.each(criterion, (c, key) => {
    themes[key].count = c.length;
  });
  return themes;
}

function formatSelectorOptions(list, withObject = false) {
  return list.map((element) => {
    let obj = { value: element.id, label: element.name };
    if (withObject) {
      obj.object = element;
    }
    return obj;
  });
}

function formatThemeList(themeList) {
  let themes = [];
  _.each(themeList, (t) => (themes[t.position] = { ...t, count: 0 }));
  return themes;
}

function getEmptyAidObject() {
  return {
    name: "",
    label: "",
    additionalRemark: "",
    detailedRemark: "",
    openDate: "",
    depositDate: "",
    leadtime: "",
    minimumRate: null,
    maximumRate: null,
    minimumAmountPerPlant: null,
    maximumAmountPerPlant: null,
    description: "",
    contactName: "",
    contactEmail: "",
    documentUrl: "",
    originUrl: "",
    requestUrl: "",
    status: null,
    active: false,
    origin: "",
    organization: null,
    nature: null,
    form: null,
    complexity: null,
    contractDuration: "",
    taxCreditPercent: "",
    activateAt: "",
    updatedAt: "",
    aidCriterions: [],
    funding: null,
    aidFundingScales: [],
    aidFundingPlans: [],
    aidFundingFixedAmounts: [],
    simulable: true,
    folder: null,
    subfolder: null,
    comment: "",
    isWithAidFundingRowsCumul: false,
    fundingLines: [],
  };
}

function getEmptyNewRegionDataObject() {
  return {
    id: "new-region",
    label: "",
    number: null,
    active: true,
    custom: true,
    departments: [],
  };
}

function getEmptyAidSchemeObject() {
  return {
    label: "",
  };
}

function getEmptyAidSchemeErrorObject() {
  return {
    label: false,
  };
}

function getEmptyAidFundingCriterions() {
  return {
    [Constants.AID_FUNDING_FIXED_AMOUNT]: [],
    [Constants.AID_FUNDING_SCALE]: [],
    [Constants.AID_FUNDING_PLAN]: [],
  };
}
