import { ContentCopy, Delete, DragIndicator } from "@mui/icons-material";
import React, { useState } from "react";
import YesOrNo from "../../../general/YesOrNo/YesOrNo";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";
import MenuButton from "../../../general/MenuButton/MenuButton";
import { Api } from "fsy.common-library";
import Helper from "../../../../services/Helper";
import { toast } from "react-toastify";
import {
  CRITERION_TYPE_OBG,
  HTTP_NO_CONTENT,
} from "fsy.common-library/lib/env/Constants";
import DeleteConfirmationDialog from "./../../../general/DeleteConfirmationDialog/DeleteConfirmationDialog";

const Question = ({
  currentStepId,
  steps,
  question,
  withWriteAuth,
  getQuestionDependencies,
  onRequestSetLoading,
  onRequestGetSteps,
  onRequestGetQuestions,
  onRequestQuestionClick,
  currentStep,
}) => {
  const items = [
    {
      action: "clone",
      icon: <ContentCopy className="question-clone-icon" />,
      label: "Dupliquer",
      disabled: !withWriteAuth,
    },
    {
      action: "changeStep",
      icon: <OpenWithIcon className="question-change-step-icon" />,
      label: "Changer d'étape",
      disabled: !withWriteAuth,
      itemsTitle: "Étapes",
      itemKey: "name",
      items: steps,
    },
    {
      action: "delete",
      icon: <Delete className="question-delete-icon" />,
      label: "Supprimer",
      disabled: !withWriteAuth,
    },
  ];

  const [state, setState] = useState({
    isDeleteConfirmationOpened: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleDeleteConfirmationDialogDisplay = () => {
    _setState({
      isDeleteConfirmationOpened: !state.isDeleteConfirmationOpened,
    });
  };

  const _handleClone = () => {
    const isObgQuestion =
      question.criterion.type.shortName === CRITERION_TYPE_OBG;

    onRequestSetLoading(true);
    Api.question
      .createQuestion(
        `${question.title} - copie`,
        question.questionText,
        question.position + 1,
        question.mandatory,
        currentStepId,
        question.criterion.id,
        isObgQuestion ? question.obgAnswerFirst : null,
        isObgQuestion ? question.obgText1 : null,
        isObgQuestion ? question.obgText2 : null,
        question.questionDependencies
      )
      .then((response) => {
        const result = Helper.isValidResponse(response);

        if (result) {
          onRequestGetSteps();
          onRequestGetQuestions();
          toast.success(
            "Question dupliquée avec succès.",
            Helper.getToastOptions()
          );
        }

        onRequestSetLoading(false);
      })
      .catch((error) => {
        console.error(error);
        onRequestSetLoading(false);
      });
  };

  const _handleChangeStep = (choosenStep) => {
    const questions = [];

    steps.map((stepData) => {
      let stepQuestions = stepData.questions.map((element) => {
        return {
          ...element,
          step: { id: stepData.id, position: stepData.position },
        };
      });

      questions.push(...stepQuestions);
      return null;
    });

    const questionDependencie = getQuestionDependencies(questions, question.id);
    let elements = [];

    // dependencies1 => Les questions de dependencies1 dépendent de la question choisie
    // dependencies2 => La question choisie dépend de celles de dependencies2
    if (choosenStep.position < currentStep.position) {
      elements = questionDependencie.dependencies2.filter(
        (element) =>
          element.step.position <= currentStep.position &&
          element.step.position > choosenStep.position
      );
    } else {
      elements = questionDependencie.dependencies1.filter(
        (element) =>
          element.step.position >= currentStep.position &&
          element.step.position < choosenStep.position
      );
    }

    if (elements.length > 0) {
      toast.error(
        "Déplacement impossible à cause des dépendances de question.",
        Helper.getToastOptions()
      );
    } else {
      // Mise à jour de l'étape de la questio
      onRequestSetLoading(true);
      Api.question
        .updateQuestionStep(
          question.id,
          currentStepId,
          choosenStep.id,
          question.position,
          choosenStep.position < currentStep.position
        )
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            toast.success(
              "Étape de la question modifiée avec succès.",
              Helper.getToastOptions()
            );
            onRequestGetSteps();
            onRequestGetQuestions();
          }

          onRequestSetLoading(false);
        })
        .catch((error) => {
          console.error(error);
          onRequestSetLoading(false);
        });
    }
  };

  const _handleConfirmQuestionDelete = () => {
    if (question.id) {
      _handleDeleteConfirmationDialogDisplay();
      onRequestSetLoading(true);
      Api.question
        .deleteQuestion(question.id)
        .then((response) => {
          if (response?.status === HTTP_NO_CONTENT) {
            toast.success(
              "Question supprimée avec succès.",
              Helper.getToastOptions()
            );
            onRequestGetSteps();
            onRequestGetQuestions();
          }
          onRequestSetLoading(false);
        })
        .catch((error) => {
          console.error(error);
          onRequestSetLoading(false);
        });
    }
  };

  const _handleDelete = (item) => {
    const questions = [];
    const dependencies = [];

    steps.map((step) => {
      questions.push(...step.questions);
      return null;
    });

    questions.map((q) => {
      let questionDependencies = q.questionDependencies;

      questionDependencies.map((questionDependencie) => {
        if (question.id === questionDependencie?.target?.id) {
          dependencies.push(q);
        }
        return null;
      });
      return null;
    });

    if (dependencies.length > 0) {
      toast.error(
        "Suppression impossible à cause des dépendances de question.",
        Helper.getToastOptions()
      );
    } else {
      _handleDeleteConfirmationDialogDisplay();
    }
  };

  const _handleActionClick = (item) => {
    if (item.action === "clone") {
      _handleClone();
    } else if (item.action === "changeStep") {
      _handleChangeStep(item);
    } else {
      _handleDelete(item);
    }
  };

  const _handleQuestionClick = () => {
    onRequestQuestionClick(question.id);
  };

  return (
    <div className="flex w-100 question-row">
      <DeleteConfirmationDialog
        elementLabel="question"
        isShowing={state.isDeleteConfirmationOpened}
        isElementFeminine={true}
        onRequestHide={_handleDeleteConfirmationDialogDisplay}
        onRequestRemoveElement={_handleConfirmQuestionDelete}
      />
      <div className="flex-start">
        <div className="draggable-icon-content">
          <DragIndicator />
        </div>
        <div className="question-position">{question.position}</div>
        <div className="question-title" onClick={_handleQuestionClick}>
          {question.title}
        </div>
      </div>
      <div className="question-text">
        {GenericHelper.textWithStyles(question.questionText)}
      </div>
      <div className="question-criterion">
        <div>{`${question.criterion.shortName} ${question.criterion.type.shortName}`}</div>
        <div>{question.criterion.name}</div>
      </div>
      <div>
        <YesOrNo status={question.questionDependencies?.length > 0} />
      </div>
      <div>
        <YesOrNo status={question.mandatory} />
      </div>
      <div>
        <MenuButton
          disabledItemId={currentStepId}
          items={items}
          onRequestActionClick={_handleActionClick}
          onRequestOtherItemMenuClick={_handleChangeStep}
        />
      </div>
    </div>
  );
};

export default Question;
