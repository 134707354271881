import React, {Component} from "react"
import Chart from "react-apexcharts"

export class PieChart extends Component {
    static defaultProps = {
        data: [10, 5, 2],
        width: 500,
        colors: ["var(--blue-container)", "var(--red-container)", "var(--orange-container)"],
        labels: ["Example 1", "Example 2", "Example 3"]
    }

    constructor(props) {
        super(props)
        this.state = {
            series: props.data,
            options: {
                chart: {
                    // width: props.width,
                    type: "pie"
                },
                legend: {
                    position: "bottom",
                    offsetY: 0,
                    offsetX: 0
                },
                plotOptions: {
                    pie: {
                        customScale: 1
                    }
                },
                colors: props.colors,
                labels: props.labels
            }
        }
    }

    render() {
        return <Chart options={this.state.options} series={this.state.series} type="pie"
                      className="advanced-stats-chart flex flex-column"/>
    }
}

export class StackedBarChart extends Component {
    static defaultProps = {
        series: [{
            name: "Example 1",
            data: [10, 0, 1]
        }, {
            name: "Example 2",
            data: [0, 2, 1]
        }, {
            name: "Example 3",
            data: [0, 0, 20]
        }],
        toolbar: false,
        zoom: false,
        height: "100%",
        colors: ["var(--blue-container)", "var(--red-container)", "var(--orange-container)"],
        labels: ["01/01/2001", "02/01/2001", "03/01/2001"]
    }

    constructor(props) {
        super(props)

        this.state = {
            series: this.props.series,
            options: {
                chart: {
                    height: this.props.height,
                    type: "bar",
                    stacked: true,
                    toolbar: {
                        show: this.props.toolbar
                    },
                    zoom: {
                        enabled: this.props.zoom
                    }
                },
                colors: this.props.colors,
                labels: this.props.labels,
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 5,
                        dataLabels: {
                            enabled: false,
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: "12px",
                                    fontWeight: 500
                                }
                            }
                        }
                    }
                },
                legend: {
                    position: "top",
                    offsetY: 0,
                    offsetX: 0
                },
                fill: {
                    opacity: 0.2
                }
            }
        }
    }

    render() {
        return <Chart options={this.state.options} series={this.state.series} type="bar"
                      className="advanced-stats-chart" height={this.props.height}/>
    }
}
