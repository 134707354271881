import { MoreVert } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import "./menuButton.css";

const MenuButton = ({
  items,
  disabledItemId,
  onRequestActionClick,
  onRequestOtherItemMenuClick,
}) => {
  const [state, setState] = useState({
    anchorEl: null,
    additionalMenuAnchorEl: null,
    currentItem: null,
  });

  const open = Boolean(state.anchorEl);
  const openAdditionalMenu = Boolean(state.additionalMenuAnchorEl);

  const _setState = (newState) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  const _handleIconButtonClick = (event) => {
    _setState({ anchorEl: event.currentTarget });
  };

  const _handleClose = () => {
    _setState({ anchorEl: null });
  };

  const _handleItemClick = (event, item) => {
    if (!item.items) {
      _handleClose();
      onRequestActionClick(item);
      _setState({ currentItem: null, additionalMenuAnchorEl: null });
    } else {
      _setState({
        currentItem: item,
        additionalMenuAnchorEl: event.currentTarget,
      });
    }
  };

  const _handleAdditionalMenuClose = () => {
    _setState({ additionalMenuAnchorEl: null });
  };

  const _handleOtherItemMenuClick = (item) => {
    _setState({ additionalMenuAnchorEl: null, anchorEl: null });
    onRequestOtherItemMenuClick(item);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={_handleIconButtonClick}
        title="Actions"
        className="action-button"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={state.anchorEl}
        open={open}
        onClose={_handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items?.map((item, i) => {
          return (
            <MenuItem
              key={`MenuItem${i + 1}`}
              disabled={item.disabled}
              onClick={(event) => _handleItemClick(event, item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
      {/** Menu supplémentaire */}
      <Menu
        id={`additional-menu`}
        anchorEl={state.additionalMenuAnchorEl}
        open={openAdditionalMenu}
        onClose={_handleAdditionalMenuClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
        className="additional-menu"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="additional-menu-title">
          {state.currentItem?.itemsTitle || ""}
        </div>
        <Divider />
        {state.currentItem?.items?.map((data, index) => {
          return (
            <MenuItem
              key={`AdditionalMenuItem${index + 1}`}
              disabled={data.id === disabledItemId}
              onClick={() => _handleOtherItemMenuClick(data)}
            >
              <ListItemText>{data[state.currentItem?.itemKey]}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MenuButton;
