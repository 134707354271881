import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { InputField } from "../general/form/Input";
import ActiveSwitch from "../general/form/ActiveSwitch";
import Helper from "../../services/Helper";
import { toast } from "react-toastify";
import _ from "lodash";
import { Loading } from "../general/form/Loading";
import { CriterionFormSkeleton } from "../../services/LoadingHelper";
import { Api } from "fsy.common-library";
import {
  CRITERION_DISPLAY_CHECKBOX,
  CRITERION_DISPLAY_NOT_SPECIFIED,
  CRITERION_DISPLAY_SELECT,
  CRITERION_TYPE_BIN,
  CRITERION_TYPE_DTE,
  CRITERION_TYPE_LOC,
  CRITERION_TYPE_NUM,
  CRITERION_TYPE_OBG,
  CRITERION_TYPE_TXT,
  HTTP_CREATED,
  HTTP_CRITERIA_ALREADY_EXIST,
} from "fsy.common-library/lib/env/Constants";
import { SelectField } from "../general/form/Select";

import "./criterionForm.css";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  Add,
  // Cancel,
  // CheckCircle,
  Delete,
  FormatListBulleted,
  LooksOne,
  Memory as MemoryIcon,
  Paid as PaidIcon,
  Quiz as QuizIcon,
  Save,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextCriterionForm from "./TextCriterionForm";
import DragAndDrop from "./../general/DragAndDrop/DragAndDrop";

const SPECIFIC_CRITERION = ["criPlan_01", "criPlan_04", "criPlan_05"];

export const CriterionForm = (props) => {
  const isCreationFormType = props.type === "create";
  const infoCriterionFormRef = useRef(null);
  const valuesTextCriterionFormRef = useRef(null);
  const indicationCriterionFormRef = useRef(null);
  const departmentFormContentRef = useRef(null);
  const departmentTitleRef = useRef(null);
  const valuesLabelCriterionFormRef = useRef(null);

  const [state, setState] = useState({
    loading: true,
    saving: false,
    regionLoading: false,
    localizationChanged: false,
    criteria: createEmptyCriterion(),
    nbNewValue: 0,
    txtValue: "",
    specieValue: "",
    selectedDisplayValue: null,
    regionsOptions: [
      { label: "Régions", icon: "fa-earth-europe", options: [] },
      { label: "Régions personnalisées", icon: "fa-user-pen", options: [] },
    ],
    groupChanged: false,
    groupOptions: [{ label: "Groupe d'essence", options: [] }],
    departmentsOptions: [],
    criterionThemesOptions: [],
    criterionTypesOptions: [],
    zones: [],
    requiredFormEmptyError: {
      name: false,
      category: false,
      type: false,
    },
    selectedRegion: null,
    selectedGroup: null,
    currentEditingRegion: null,
    displayCreationForm: false,
    displayActiveOption: false,
    newRegionNumber: "",
    newRegionName: "",
    items: [],
    cardIndex: {
      source: null,
      destination: null,
    },
    regions: [],
    zonesRegionsInfoHeight: undefined,
    departmentsInfoHeight: undefined,
    locationLoading: true,
    errorInRegionDepartmentsList: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  // Memoise criterion display types (fixed values)
  const displayModeOptions = useMemo(function () {
    return [
      { value: CRITERION_DISPLAY_NOT_SPECIFIED, label: "Non spécifié" },
      { value: CRITERION_DISPLAY_CHECKBOX, label: "Liste à cocher" },
      { value: CRITERION_DISPLAY_SELECT, label: "Liste déroulante" },
    ];
  }, []);

  const loadLocalizationData = useCallback(_loadLocalizationData, [
    state.regionsOptions,
  ]);
  const loadCriteria = useCallback(_loadCriteria, [props.criteria?.id]);
  const loadSpeciesGroup = useCallback(_loadSpeciesGroup, []);
  const loadThemeAndTypeData = useCallback(_loadThemeAndTypeData, []);

  const infoHeight =
    infoCriterionFormRef.current?.getBoundingClientRect()?.height;
  const valuesHeight =
    valuesTextCriterionFormRef.current?.getBoundingClientRect()?.height;
  const indicationHeight =
    indicationCriterionFormRef.current?.getBoundingClientRect()?.height;
  const departmentFormContentHeight =
    departmentFormContentRef.current?.getBoundingClientRect()?.height;
  const departmentTitleHeight =
    departmentTitleRef.current?.getBoundingClientRect()?.height;
  const valuesLabelCriterionFormHeight =
    valuesLabelCriterionFormRef.current?.getBoundingClientRect()?.height;

  const aidFundings = [
    {
      key: "aidFundingFixedAmount",
      label: "Financement sur montant fixe",
    },
    {
      key: "aidFundingScale",
      label: "Financement au barème",
      className: "aid-funding-scale",
    },
    {
      key: "aidFundingPlant",
      label: "Financement au plant",
    },
  ];

  useEffect(() => {
    const isLocationCriterion =
      state.criteria?.type?.shortName === CRITERION_TYPE_LOC;

    if (
      isLocationCriterion &&
      infoHeight &&
      valuesHeight &&
      indicationHeight &&
      valuesLabelCriterionFormHeight
    ) {
      const othersHeight =
        infoHeight +
        valuesHeight +
        indicationHeight +
        valuesLabelCriterionFormHeight +
        15;
      const objectState = {};

      objectState.zonesRegionsInfoHeight = `calc(80vh - ${othersHeight}px - 5rem)`;

      if (departmentFormContentHeight && departmentTitleHeight) {
        const othersComponentHeight =
          othersHeight + departmentFormContentHeight + departmentTitleHeight;
        objectState.departmentsInfoHeight = `calc(80vh - ${othersComponentHeight}px - 5rem)`;
      }

      _setState(objectState);
    }
  }, [
    state.selectedRegion?.id,
    state.criteria?.type?.shortName,
    infoHeight,
    valuesHeight,
    indicationHeight,
    departmentFormContentHeight,
    departmentTitleHeight,
    valuesLabelCriterionFormHeight,
  ]);

  useEffect(() => {
    setLoading(true);
    loadThemeAndTypeData(() => {
      loadLocalizationData(() => {
        loadSpeciesGroup(() => {
          if (props.criteria === null) {
            setLoading(false);
          }
        });
      });
    });

    if (props.criteria !== null) {
      loadCriteria((currentCriteria) => {
        // Il faut trier les criterion values par ordre de position, du plus petit au plus grand
        if (currentCriteria?.type?.shortName === CRITERION_TYPE_TXT) {
          // La variable qui suit est un tableau qui contient les criterion values dont position = 0 dans la BDD
          const criterionValuesWithoutPosition =
            currentCriteria.criterionValues?.filter(
              (element) => element.position === 0
            ) || [];
          const key =
            currentCriteria.criterionValues?.length ===
            criterionValuesWithoutPosition.length
              ? "id"
              : "position";

          currentCriteria.criterionValues?.sort((a, b) => {
            return a[key] - b[key];
          });
        }

        setState((prevState) => ({
          ...prevState,
          criteria: {
            ...currentCriteria,
            type: {
              id: currentCriteria.type.id,
              value: currentCriteria.type.id,
              label: currentCriteria.type.label,
              shortName: currentCriteria.type.shortName,
            },
            category: {
              value: currentCriteria.theme.id,
              label: currentCriteria.theme.name,
            },
          },
          selectedDisplayValue: _.find(displayModeOptions, {
            value: currentCriteria?.displayMode,
          }),
          loading: false,
        }));
      });
    }
  }, [
    props.criteria,
    displayModeOptions,
    loadLocalizationData,
    loadCriteria,
    loadSpeciesGroup,
    loadThemeAndTypeData,
  ]);

  useEffect(() => {
    let itemElems = [];

    if (state.criteria?.type?.shortName === CRITERION_TYPE_TXT) {
      state.criteria?.criterionValues?.map((value) => {
        itemElems.push({
          ...(value ?? {}),
        });

        return null;
      });

      let newItems = state.items.map((item) => {
        let keyId = item.id ? "id" : "idNew";
        let id = item[keyId];
        let itemElem = itemElems.find((element) => element[keyId] === id);

        if (itemElem) {
          return {
            ...item,
            value: itemElem.value,
            active: itemElem.active,
            info: itemElem.info,
            position: itemElem.position,
          };
        }

        return item;
      });

      if (state.criteria?.criterionValues?.length > newItems.length) {
        const newValues = state.criteria.criterionValues.filter(
          (element) => element.isNew && element.idNew
        );

        newValues.map((newValue) => {
          let newValueInItems = newItems.find(
            (element) => newValue.idNew === element.idNew
          );

          if (!newValueInItems) {
            newItems.push(newValue);
          }

          return null;
        });
      } else if (state.criteria?.criterionValues?.length < newItems.length) {
        let itemToDelete = null;

        newItems.map((item) => {
          if (item.hasOwnProperty("idNew")) {
            let element = state.criteria.criterionValues.find(
              (element) => element.idNew === item.idNew
            );

            if (!element) {
              itemToDelete = item;
            }
          }

          return null;
        });

        newItems = newItems.filter(
          (element) => element.idNew !== itemToDelete?.idNew
        );
      }

      if (
        state.cardIndex.source !== null &&
        state.cardIndex.destination !== null
      ) {
        const { source, destination } = state.cardIndex;
        const elem = itemElems[source];
        itemElems.splice(destination + 1, 0, elem);
        itemElems[source] = null;
        itemElems = itemElems.filter((element) => element !== null);

        if (newItems.length === itemElems.length) {
          newItems.map((item, index) => {
            let itemId = item.id ? item.id : item.idNew;
            let itemElementId = itemElems[index].id
              ? itemElems[index].id
              : itemElems[index].idNew;

            if (itemId !== itemElementId) {
              itemElems[index] = item;
            }

            return null;
          });
        }

        notifyChange();
      } else {
        itemElems = newItems?.length > 0 ? newItems : itemElems;
      }

      // Après tous les déplacements, il faut mettre à jour la valeur de position
      // c'est avec items qu'on va gérer les positions quand on soumet le formulaire
      itemElems = itemElems.map((item, index) => {
        return {
          ...item,
          position: index + 1,
        };
      });

      setState((prevState) => {
        return {
          ...prevState,
          items: itemElems,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.criteria?.type?.shortName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(state.criteria?.criterionValues),
    state.cardIndex.source,
    state.cardIndex.destination,
  ]);

  /* ================================================ FUNCTIONS =============================================== */

  /**
   * allows to quicly update loading state
   * @param active bool
   * @param type string
   */
  function setLoading(active, type = "loading") {
    setState((prevState) => ({ ...prevState, [type]: active }));
  }

  /**
   * allows to quicly notify that the form has been updated
   */
  function notifyChange() {
    props.modalModify(true);
  }

  /**
   * Format a region or department object for selectField display
   *
   * @param r {Object}
   * @return {{label: (string|*), value, object}}
   */
  function formatOptions(r) {
    return {
      value: r.id,
      label: r.number !== null ? `${r.label} (${r.number})` : r.label,
      object: r,
    };
  }

  /**
   * Load criteria data from API
   */
  function _loadCriteria(callback = null) {
    if (props.criteria?.id) {
      Api.criteria.getCriteria(props.criteria.id).then((r) => {
        if (r?.status !== 200) {
          Helper.displayApiToastResult(r);
          return null;
        }

        const result = Helper.isValidResponse(r);
        if (typeof callback === "function") {
          callback(result);
        } else {
          return result;
        }
      });
    }
  }

  function _loadSpeciesGroup(callback = null) {
    Api.speciesGroup.getSpeciesGroups().then((r) => {
      if (r?.status !== 200) {
        Helper.displayApiToastResult(r);
        return null;
      }

      const result = Helper.isValidResponse(r);

      const groupOptions = [];
      _.each(result, (g) => {
        groupOptions.push({
          value: g.id,
          label: g.label,
          object: g,
        });
      });

      setState((prevState) => ({
        ...prevState,
        groupOptions: [{ label: "Groupe d'essence", options: groupOptions }],
      }));

      if (typeof callback === "function") {
        callback(result);
      } else {
        return result;
      }
    });
  }

  function _loadThemeAndTypeData(callback = null) {
    Promise.all([
      Api.criteria.getCriterionThemes(1, { pagination: false }),
      Api.criteria.getCriterionTypes(1, { pagination: false }),
    ])
      .then(([criterionThemes, criterionTypes]) => {
        const criterionThemesResults = Helper.isValidResponse(criterionThemes);
        const criterionTypesResults = Helper.isValidResponse(criterionTypes);

        if (criterionThemesResults && criterionTypesResults) {
          setState((prevState) => {
            return {
              ...prevState,
              criterionThemesOptions: criterionThemesResults.map(
                ({ id, name }) => {
                  return { value: id, label: name };
                }
              ),
              criterionTypesOptions: criterionTypesResults.map(
                ({ id, label, shortName }) => {
                  return {
                    value: id,
                    label,
                    shortName,
                    isDisabled: [
                      CRITERION_TYPE_LOC,
                      CRITERION_TYPE_DTE,
                    ].includes(shortName),
                  };
                }
              ),
            };
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Helper.displayGenericErrorToast();
      })
      .finally(() => {
        if (typeof callback === "function") {
          callback();
        }
      });
  }

  /**
   * Load zons, regions and departments data from API
   *
   * @param callback {?function} a callback function
   * @private
   */
  function _loadLocalizationData(callback = null) {
    let zoneList,
      departmentList = [];
    let regionList = state.regionsOptions;
    let regionsData = [];

    Promise.all([
      Api.zone.getZones(),
      Api.region.getRegions(1, {
        pagination: false,
        "order[position]": "ASC",
      }),
      Api.department.getDepartments(),
    ])
      .then(([zoneResponse, regionResponse, depResponse]) => {
        const zones = Helper.isValidResponse(zoneResponse);
        const regions = Helper.isValidResponse(regionResponse);
        const departments = Helper.isValidResponse(depResponse);

        if (zones) {
          // Format zonelist ?
          zoneList = zones;
        }

        if (regions) {
          // Format and add regions in the correct group (see: state.regionsOptions declaration)
          _.forEach(regions, (r) => {
            regionList[r.custom ? 1 : 0].options.push(formatOptions(r));
            regionsData.push({
              id: r.id,
              label: r.label,
              active: r.active,
              position: r.position,
              isUpdated: false,
              departments: r.departments || [],
            });
          });

          const regionsWithNoPosition = regionsData.filter(
            (element) => element.position === 0
          );

          if (regionsData.length === regionsWithNoPosition.length) {
            regionsData = regionsData.map((element, index) => {
              return {
                ...element,
                position: index + 1,
              };
            });
          }
        }

        if (departments) {
          departmentList = _.map(departments, (r) => formatOptions(r));
          departmentList = _.orderBy(departmentList, ["number"], ["asc"]);
        }

        _setState({ locationLoading: false });

        setState((prevState) => ({
          ...prevState,
          regionsOptions: regionList,
          departmentsOptions: departmentList,
          zones: zoneList,
          regions: regionsData,
        }));
      })
      .catch((reason) => {
        _setState({ locationLoading: false });
        console.error(reason);
        Helper.displayGenericErrorToast();
      })
      .finally(() => {
        // setLoading(false)
        if (typeof callback === "function") {
          callback();
        }
      });
  }

  /**
   * Return an object containing all criteria properties to send to the API
   *
   * @return {{multi, name: string, active, position: number, mandatory: *, displayMode: number}}
   * @private
   */
  function _getParamsFromForm() {
    let object = {
      // Generic values
      name: state.criteria.name,
      mandatory: state.criteria.mandatory,
      active: state.criteria.active,
      theme: state.criteria.category?.value,
      type: state.criteria.type.value,
      position: parseInt(state.criteria.position),
      displayMode: state.criteria.displayMode,
      financial: state.criteria.financial,
      simulatorOnly: state.criteria.simulatorOnly,
      multi: state.criteria.multi,
      specific: state.criteria.specific,
      obgAnswerFirst: state.criteria.obgAnswerFirst,
      aidFundingFixedAmount: state.criteria.aidFundingFixedAmount,
      aidFundingScale: state.criteria.aidFundingScale,
      aidFundingPlant: state.criteria.aidFundingPlant,
    };
    // Numeric values
    if (state.criteria?.type?.shortName === CRITERION_TYPE_NUM) {
      object.valueMin = parseInt(state.criteria.valueMin);
      object.valueMax = parseInt(state.criteria.valueMax);
      object.unit = state.criteria.unit;
      object.step = parseFloat(state.criteria.step);
    }
    // criteria values array
    if (
      _.includes(
        [CRITERION_TYPE_BIN, CRITERION_TYPE_OBG, CRITERION_TYPE_TXT],
        state.criteria?.type?.shortName
      )
    ) {
      // Uniquement pour les critères de type TXT
      const txtCriterionValues = [];
      if (state.criteria.type.shortName === CRITERION_TYPE_TXT) {
        state.criteria.criterionValues?.map((criterionValue) => {
          let keyId = criterionValue.hasOwnProperty("id") ? "id" : "idNew";

          let item =
            state.items.find(
              (element) => element[keyId] === criterionValue[keyId]
            ) || {};

          txtCriterionValues.push({
            ...criterionValue,
            position: item.position,
            info: item.info ? item.info : null,
          });

          return null;
        });
      }

      object.criterionValues =
        state.criteria.type.shortName === CRITERION_TYPE_TXT
          ? txtCriterionValues
          : state.criteria.criterionValues;
    }

    if (state.criteria.type.shortName === CRITERION_TYPE_LOC) {
      object.regions = state.regions.map((region) => {
        return {
          id: region.id,
          active: region.active,
        };
      });
    }

    return object;
  }

  /**
   * Check if all information of the form are valid (check format and uniqueness)
   *
   * @return {Promise<boolean>}
   */
  async function checkForm() {
    let valid = true;

    // Check that minimum and maximum are set
    if (
      state.criteria?.type?.shortName === CRITERION_TYPE_NUM &&
      (state.criteria.valueMin === "" || state.criteria.valueMax === "")
    ) {
      valid = false;
      toast.warning(
        "Les valeurs minimum et maximum doivent être obligatoirement renseignées",
        Helper.getToastOptions()
      );
    }

    // Check that minimum and maximum values are consistent
    if (
      state.criteria?.type?.shortName === CRITERION_TYPE_NUM &&
      state.criteria.valueMin >= state.criteria.valueMax
    ) {
      valid = false;
      toast.warning(
        "La valeur minimum doit obligatoirement être inférieure au maximum",
        Helper.getToastOptions()
      );
    }

    // Check that the 2 values (for BIN and OBG types) are set
    if (
      _.includes(
        [CRITERION_TYPE_BIN, CRITERION_TYPE_OBG],
        state.criteria?.type?.shortName
      ) &&
      state.criteria.criterionValues.length < 2
    ) {
      valid = false;
      toast.warning(
        "Vous devez obligatoirement renseigner les 2 valeurs !",
        Helper.getToastOptions()
      );
    }

    // Check that values has minimum 2 items
    if (
      state.criteria?.type?.shortName === CRITERION_TYPE_TXT &&
      !["criPlan_04", "criPlan_05"].includes(state.criteria?.shortName) &&
      state.criteria.criterionValues.length < 2
    ) {
      valid = false;
      toast.warning(
        "La liste des valeurs doit contenir au moins 2 lignes !",
        Helper.getToastOptions()
      );
    }

    // Check empty values
    if (_hasEmptyValues()) {
      valid = false;
      toast.warning(
        "Toutes les valeurs doivent être renseignées",
        Helper.getToastOptions()
      );
    }

    // Check empty form on Region field
    if (
      state.criteria?.type?.shortName === CRITERION_TYPE_LOC &&
      state.regions.length > 0
    ) {
      const regionsWithoutName = state.regions.filter((element) =>
        [null, undefined, ""].includes(element.label)
      );

      if (regionsWithoutName.length > 0) {
        valid = false;
        toast.warning(
          "Le nom de région doit être renseigné.",
          Helper.getToastOptions()
        );
      }
    }

    return valid;
  }

  /**
   * Check if criteria values list has empty values
   *
   * @return {boolean}
   * @private
   */
  function _hasEmptyValues() {
    return _.findIndex(state.criteria.criterionValues, { value: "" }) !== -1;
  }

  /* ---------------------------------------- Handlers ---------------------------------------- */

  /**
   * handle input changes from "general" part of the form
   *
   * @param value {int|string}
   * @param property {string}
   * @private
   */
  function _handleGeneralValueChange(value, property) {
    state.criteria[property] = value;
    setState((prevState) => ({ ...prevState, criteria: state.criteria }));
    notifyChange();
  }

  /**
   *
   * @param selectedOption {Object}
   * @private
   */
  function _handleDisplayModeChange(selectedOption) {
    state.criteria.displayMode = selectedOption.value;
    state.selectedDisplayValue = selectedOption;
    setState((prevState) => ({ ...prevState, criteria: state.criteria }));
    notifyChange();
  }

  function _handleGroupChange(selectedOption) {
    setState((prevState) => ({
      ...prevState,
      selectedGroup: selectedOption,
    }));
  }

  /**
   *
   * @param selectedOption {?Object}
   * @private
   */
  function _handleDepartementChange(selectedOption) {
    if (selectedOption !== null) {
      // Only add if value is not null
      //Insert value only if not already in array
      let regions = [...state.regions];
      const index = regions.findIndex(
        (element) => element.id === state.selectedRegion.id
      );
      const currentRegion = regions[index];

      if (
        !currentRegion?.departments?.find(
          (element) => element.id === selectedOption?.object.id
        )
      ) {
        regions[index].departments.push(selectedOption?.object);
        regions[index] = {
          ...regions[index],
          isUpdated: !regions[index].isCustomized,
        };

        setState((prevState) => ({
          ...prevState,
          regions,
        }));
        notifyChange();
      } else {
        toast.error(
          "Département déjà existant dans la région.",
          Helper.getToastOptions()
        );
      }
    }
  }

  /**
   *
   * @param department {Object}
   * @private
   */
  function _handleDeleteDepartmentClick(department) {
    let regions = [...state.regions];
    const index = regions.findIndex(
      (element) => element.id === state.selectedRegion.id
    );
    regions[index].departments = regions[index].departments.filter(
      (element) => element.id !== department.id
    );
    regions[index] = {
      ...regions[index],
      isUpdated: !regions[index].isCustomized,
    };

    setState((prevState) => ({
      ...prevState,
      regions,
    }));

    notifyChange();
  }

  /**
   *
   * @param e {event}
   * @private
   */
  function _handleCriteriaChange(e, name = null) {
    let value = e?.target?.value ?? e;

    if (name === null && e.target?.type === "number" && e.target?.step === 1) {
      //cast number value
      value = parseInt(value);
    }
    //Using the name value to update the corresponding data
    state.criteria[e?.target?.name ?? name] = value;
    // setState(prevState => ({...prevState, criteria: state.criteria}))
    setState((prevState) => {
      if (["type"].includes(name)) {
        state.criteria.criterionValues = [];
      }

      return {
        ...prevState,
        criteria: state.criteria,
      };
    });
    notifyChange();
  }

  /**
   *
   * @param e {event}
   * @private
   */
  function _handleCriteriaValueChange(e) {
    // name = "value-0" or "value-1" based on the position from the array
    const index = _.split(e.target.name, "-")[1];
    if (!state.criteria.criterionValues[index]) {
      state.criteria.criterionValues[index] = { value: "" };
    }

    state.criteria.criterionValues[index].value = e.target.value;
    setState((prevState) => ({ ...prevState, criteria: state.criteria }));
    notifyChange();
  }

  // function _handleCriteriaOBGChange(e) {
  //     state.criteria.obgAnswerFirst = e.target.value === "true"
  //     setState(prevState => ({...prevState, criteria: state.criteria}))
  //     notifyChange()
  // }

  /**
   *
   * @param e {event}
   * @private
   */
  function _handleTxtValueChange(e) {
    setState((prevState) => ({ ...prevState, txtValue: e.target.value }));
    notifyChange();
  }

  function _handleSpecieValueChange(e) {
    setState((prevState) => ({ ...prevState, specieValue: e.target.value }));
    notifyChange();
  }

  /**
   *
   * @param valueObject {Object}
   * @private
   */
  function _handleDeleteTxtClick(valueObject) {
    // remove criteria value from the array. This is only available new values, existing values can only be disabled
    _.remove(state.criteria.criterionValues, { idNew: valueObject.idNew });
    setState((prevState) => ({
      ...prevState,
      criteria: state.criteria,
      cardIndex: { source: null, destination: null },
    }));
    notifyChange();
  }

  function _handleDeleteSpecieClick(valueObject) {
    if (state.selectedGroup) {
      _.remove(state.selectedGroup.object.species, {
        idNew: valueObject.idNew,
      });
      setState((prevState) => ({
        ...prevState,
        selectedGroup: state.selectedGroup,
      }));
      notifyChange();
    }
  }

  /**
   *
   * @param valueObject {object}
   * @private
   */
  function _handleActiveTxtClick(valueObject) {
    const index = _.findIndex(state.criteria.criterionValues, {
      id: valueObject.id,
    });
    // Simply disable a criteria value from the array
    state.criteria.criterionValues[index].active =
      !state.criteria.criterionValues[index].active;
    setState((prevState) => ({
      ...prevState,
      criteria: state.criteria,
      cardIndex: { source: null, destination: null },
    }));
    notifyChange();
  }

  const _getNewRegionDefaultData = (id) => {
    return {
      id: `newRegion${id}`,
      creationOrder: id,
      label: "",
      isCustomized: true,
      active: true,
      position: 1,
      departments: [],
    };
  };

  function _handleAddNewRegion() {
    let regions = [...state.regions];
    const customizedRegions = [
      ...regions.filter((element) => element.isCustomized),
    ];
    const lastItemId =
      customizedRegions.length > 0
        ? Math.max(...customizedRegions.map((element) => element.creationOrder))
        : 0;

    regions.unshift(_getNewRegionDefaultData(lastItemId + 1));

    regions = regions.map((element, index) => {
      return {
        ...element,
        position: index + 1,
        isUpdated: !element.isCustomized,
      };
    });

    _setState({
      regions,
      selectedRegion: {
        ..._getNewRegionDefaultData(lastItemId + 1),
        object: {
          departments: [],
        },
      },
    });

    setTimeout(() => {
      const newElement = document.getElementById(
        `value-region-newRegion${lastItemId + 1}`
      );

      if (newElement) {
        newElement.focus();
      }
    }, 500);

    notifyChange();
  }

  // Affichage des départements
  function _handleRegionClick(region) {
    _setState({
      selectedRegion: {
        ...region,
        object: {
          departments: region.departments,
        },
      },
    });
  }

  function _handleRegionNameChange(event, region) {
    const { value } = event.target;
    const regions = [...state.regions];
    const index = regions.findIndex((element) => element.id === region.id);

    regions[index] = {
      ...regions[index],
      label: value,
      isUpdated: !region.isCustomized,
    };

    _setState({ regions });

    notifyChange();
  }

  // Activation ou désactivation de la région
  function _handleActiveRegionClick(region) {
    const regions = [...state.regions];
    const index = regions.findIndex((element) => element.id === region.id);

    regions[index].active = !regions[index].active;
    regions[index].isUpdated = !region.isCustomized;

    _setState({ regions });

    notifyChange();
  }

  function _handleCheckDragAndDrop(elements) {
    const regions = elements.map((element, index) => {
      let isUpdated = !element.isCustomized;

      return {
        ...element,
        position: index + 1,
        isUpdated,
      };
    });

    _setState({ regions });

    notifyChange();

    return true;
  }

  function _handleActiveSpecieClick(valueObject) {
    const object = state.selectedGroup.object;

    const index = _.findIndex(state.selectedGroup.object.species, {
      id: valueObject.id,
    });
    object.species[index].active = !object.species[index].active;

    setState((prevState) => ({
      ...prevState,
      selectedGroup: state.selectedGroup,
      groupChanged: true,
    }));

    notifyChange();
  }

  /**
   *
   * @param valueObject {object}
   * @private
   */
  function _handleActiveZoneClick(valueObject) {
    const index = _.findIndex(state.zones, { id: valueObject.id });
    // Simply disable a criteria value from the array
    state.zones[index].active = !state.zones[index].active;
    setState((prevState) => ({
      ...prevState,
      zones: state.zones,
      localizationChanged: true,
    }));
    notifyChange();
  }

  /**
   * listen for enter key (shortcut for "add" button)
   *
   * @param e {KeyboardEvent}
   * @private
   */
  function _handleTextInput(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      _handleAddTxtClick();
    }
  }

  function _handleSpecieInput(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      _handleAddSpecieClick();
    }
  }

  /**
   * Add value to the txt list
   *
   * @private
   */
  function _handleAddTxtClick() {
    // Only add if value is not empty
    if (state.txtValue !== "") {
      state.criteria.criterionValues.push({
        idNew: `new-${state.nbNewValue}`,
        value: state.txtValue,
        active: true,
        isNew: true, // used to differentiate new values from existing ones
        info: "",
      });
      setState((prevState) => ({
        ...prevState,
        criteria: prevState.criteria,
        nbNewValue: prevState.nbNewValue + 1,
        txtValue: "",
        cardIndex: { source: null, destination: null },
      }));
      notifyChange();
    }
  }

  function _handleTxtListValueChange(id, idNew, isNew, e, prop = null) {
    const txt = e.target.value;
    let index;

    if (isNew) {
      index = _.findIndex(state.criteria.criterionValues, { idNew: idNew });
    } else {
      index = _.findIndex(state.criteria.criterionValues, { id: id });
    }

    if (index !== -1) {
      state.criteria.criterionValues[index][prop ?? "value"] = txt;
      setState((prevState) => ({
        ...prevState,
        criteria: state.criteria,
        cardIndex: { source: null, destination: null },
      }));

      notifyChange();
    }
  }

  function _handleAddSpecieClick() {
    if (state.specieValue !== "" && state.selectedGroup) {
      const specie = {
        idNew: `new-${state.nbNewValue}`,
        isNew: true,
        label: state.specieValue,
        native: false,
        active: true,
      };

      state.selectedGroup.object.species = [
        specie,
        ...state.selectedGroup.object.species,
      ];

      setState((prevState) => ({
        ...prevState,
        specieValue: "",
        nbNewValue: prevState.nbNewValue + 1,
        selectedGroup: state.selectedGroup,
        groupChanged: true,
      }));
      notifyChange();
    }
  }

  function displayUniqueErrorConstraint() {
    toast.warning(
      "Ce nom de critère existe déjà. Merci de choisir un nom unique !",
      Helper.getToastOptions()
    );
  }

  function _createCriteria(ids) {
    const dataFromForm = _getParamsFromForm();

    // Enregistrement du critère en question
    Api.criteria
      .createCriteria(
        dataFromForm.name,
        "short_name", // short_name
        dataFromForm.type,
        dataFromForm.theme,
        ids,
        dataFromForm.mandatory,
        state.criteria.active,
        dataFromForm.valueMin || null,
        dataFromForm.valueMax || null,
        dataFromForm.unit || null,
        dataFromForm.step || 1,
        dataFromForm.multi || false,
        dataFromForm.specific || false,
        dataFromForm.position || 0,
        dataFromForm.displayMode || 0,
        dataFromForm.financial || false,
        dataFromForm.simulatorOnly || false,
        dataFromForm.aidFundingFixedAmount,
        dataFromForm.aidFundingScale,
        dataFromForm.aidFundingPlant
      )
      .then((response) => {
        if (response?.status === HTTP_CREATED) {
          toast.success(
            "Le critère a été enregistré",
            Helper.getToastOptions()
          );
          props.onSubmit();
        } else if (response?.status === HTTP_CRITERIA_ALREADY_EXIST) {
          displayUniqueErrorConstraint();
        }

        setLoading(false, "saving");
      })
      .catch((error) => {
        setLoading(false, "saving");
        console.error(error);
      });
  }

  function checkDepartmentsList(elements) {
    const regions = [];
    const values = [];
    let found = false;

    // Toutes les régions
    state.regions.map((element) => {
      let departements = element.departments.map((val) => val.id);

      departements.sort((a, b) => a - b);

      regions.push({
        id: element.id,
        departments: JSON.stringify(departements),
      });

      return element;
    });

    elements.map((element) => {
      let departements = element.departments.map((val) => val.id);

      departements.sort((a, b) => a - b);

      values.push({
        id: element.id,
        departments: JSON.stringify(departements),
      });

      return element;
    });

    values.map((newValue) => {
      if (!found) {
        let finding = regions.find(
          (element) =>
            element.id !== newValue.id &&
            element.departments === newValue.departments
        );

        if (finding) {
          found = true;
        }
      }

      return null;
    });

    return !found;
  }

  function checkRegionDepartmentsList() {
    let status = true;

    if (state.criteria?.type?.shortName === CRITERION_TYPE_LOC) {
      const newRegions = state.regions.filter(
        (element) => element.isCustomized
      );
      const oldRegions = state.regions.filter(
        (element) => !element.isCustomized && element.isUpdated
      );

      const isNewRegionsOK = checkDepartmentsList(newRegions);
      const isOldRegionsOK = checkDepartmentsList(oldRegions);

      status = isNewRegionsOK && isOldRegionsOK;
    }

    return status;
  }

  function _createCreaterionValueAndCriterionElement() {
    const dataFromForm = _getParamsFromForm();

    // Enregistrement des criterion values
    const query = dataFromForm.criterionValues?.map((criterionValue) => {
      let value = {
        active: criterionValue.active || true,
        value: criterionValue.value,
        position: criterionValue.position,
        info: criterionValue.info ? criterionValue.info : null,
      };

      return Api.criteria
        .createCriteriaValue(value)
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            return result.id;
          }

          return null;
        })
        .catch((error) => {
          console.error(error);
        });
    });

    // S'il y a de criterionValues
    if (query) {
      Promise.all(query)
        .then((ids) => {
          _createCriteria(ids);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      _createCriteria([]);
    }
  }

  function _updateCriteria() {
    const requests = [
      Api.criteria.updateCriteria(state.criteria.id, _getParamsFromForm()),
    ];

    const newRegions = state.regions
      .filter((element) => element.isCustomized)
      .map((element) => {
        return {
          label: element.label,
          active: element.active,
          position: element.position,
          departments: element.departments,
        };
      });

    const updatedRegions = state.regions
      .filter((element) => !element.isCustomized && element.isUpdated)
      .map((element) => {
        return {
          id: element.id,
          active: element.active,
          label: element.label,
          position: element.position,
          departments: element.departments,
        };
      });

    const withRegionsStatusUpdate =
      state.criteria?.type?.shortName === CRITERION_TYPE_LOC &&
      updatedRegions.length > 0;

    const withNewRegionsCreation =
      state.criteria?.type?.shortName === CRITERION_TYPE_LOC &&
      newRegions.length > 0;

    if (withRegionsStatusUpdate) {
      requests.push(Api.region.updateRegionsInfos(updatedRegions));
    }

    if (withNewRegionsCreation) {
      requests.push(Api.region.createNewRegions(newRegions));
    }

    Promise.all(requests)
      .then((responses) => {
        const response = responses?.[0]; // Result for criteria update
        const regionsUpdateResult = withRegionsStatusUpdate
          ? Helper.isValidResponse(responses[1])
          : true;
        const regionsCreationResult = withNewRegionsCreation
          ? Helper.isValidResponse(responses[withRegionsStatusUpdate ? 2 : 1])
          : true;

        if (response?.status !== 200) {
          if (
            response.hasOwnProperty("@type") &&
            response["@type"] === "hydra:Error" &&
            response["hydra:description"]
          ) {
            try {
              const errorData = JSON.parse(response["hydra:description"]);
              if (errorData.message) {
                setLoading(false, "saving");
                props.onUpdateError(errorData);
                return;
              }
            } catch (e) {}

            if (response?.status === HTTP_CRITERIA_ALREADY_EXIST) {
              setLoading(false, "saving");
              displayUniqueErrorConstraint();
            } else {
              setLoading(false, "saving");
              toast.error(
                response["hydra:description"],
                Helper.getToastOptions()
              );
            }
          } else {
            setLoading(false, "saving");
            Helper.displayGenericErrorToast();
          }
        } else {
          const result = Helper.isValidResponse(response);
          if (result && regionsUpdateResult && regionsCreationResult) {
            toast.success("Critère mis à jour !", Helper.getToastOptions());
            props.onSubmit();
          }
        }

        if (state.criteria?.shortName === "criPlan_01" && state.groupChanged) {
          setLoading(false, "saving");
        }
      })
      .catch((error) => {
        setLoading(false, "saving");
        console.error(error);
      });

    if (
      state.criteria?.type?.shortName === CRITERION_TYPE_LOC &&
      state.localizationChanged
    ) {
      _.each(state.zones, (z) => Api.zone.updateZone(z.id, z));
    }
  }

  function _handleFormSubmit(e) {
    e.preventDefault();
    const isAllRequiredFormSet =
      state.criteria.name !== "" &&
      state.criteria.category !== null &&
      state.criteria.type !== null;
    const isRegionDepartmentsListOK = checkRegionDepartmentsList();

    if (isAllRequiredFormSet && isRegionDepartmentsListOK) {
      setLoading(true, "saving");
      checkForm().then(async (valid) => {
        if (valid) {
          let hasError = false;

          if (
            state.criteria?.shortName === "criPlan_01" &&
            state.groupChanged
          ) {
            const groups = state.groupOptions[0].options.map((o) => {
              return {
                id: o.object.id,
                species: o.object.species,
              };
            });

            for (let i = 0; i < groups.length; i++) {
              const g = groups[i];
              const response = await Api.speciesGroup.updateSpeciesGroup(
                g.id,
                g
              );
              if (
                response.hasOwnProperty("@type") &&
                response["@type"] === "hydra:Error" &&
                response["hydra:description"]
              ) {
                try {
                  const errorData = JSON.parse(response["hydra:description"]);
                  if (errorData.message) {
                    setLoading(false, "saving");
                    props.onUpdateError(errorData);
                  }
                } catch (e) {}

                hasError = true;
              }
            }

            if (hasError) {
              setLoading(false, "saving");
              return;
            }
          }

          if (isCreationFormType) {
            _createCreaterionValueAndCriterionElement();
          } else {
            if (state.criteria?.type?.shortName === CRITERION_TYPE_LOC) {
              const params = _getParamsFromForm();

              const desactivedRegions = params.regions
                .filter((element) => !element.active)
                .map((element) => {
                  return { id: element.id };
                });

              setLoading(true, "saving");
              Api.aid
                .getAidsBasedOnRegions(desactivedRegions)
                .then((response) => {
                  const results = Helper.isValidResponse(response);

                  if (results && results.length > 0) {
                    setLoading(false, "saving");
                    props.onUpdateError({
                      message:
                        "Une ou plusieurs aides utilisent la ou les régions que vous souhaitez désactiver. Vous devez les enlever sur toutes ces aides avant de pouvoir les désactiver du critère.",
                      aids: results,
                    });
                  } else {
                    _updateCriteria();
                  }
                })
                .catch((error) => {
                  console.error(error);
                  setLoading(false, "saving");
                });
            } else {
              _updateCriteria();
            }
          }

          setState((prevState) => {
            return {
              ...prevState,
              requiredFormEmptyError: {
                name: false,
                category: false,
                type: false,
              },
            };
          });
        } else {
          setLoading(false, "saving");
        }
      });
    } else {
      if (!isAllRequiredFormSet) {
        setState((prevState) => {
          return {
            ...prevState,
            requiredFormEmptyError: {
              name: state.criteria.name === "",
              category: state.criteria.category === null,
              type: state.criteria.type === null,
            },
          };
        });
        toast.error(
          "Veuillez renseigner tous les champs obligatoires avant d'enregistrer le critère",
          Helper.getToastOptions()
        );
      }

      if (!isRegionDepartmentsListOK) {
        toast.error(
          "Une autre région possède la même liste de département que celle que vous souhaitez enregistrer",
          Helper.getToastOptions()
        );
      }
    }
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      ...draggableStyle,
    };
  };

  function onDragEnd(result) {
    const indiceMenu = {
      source: null,
      destination: null,
    };

    if (!result.destination) {
      return;
    }

    const elements = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );

    indiceMenu.source = result.source.index;
    indiceMenu.destination = result.destination.index;

    setState((prevState) => {
      return {
        ...prevState,
        items: elements,
        cardIndex: indiceMenu,
      };
    });
  }

  function getListStyle(isDraggingOver) {
    return {};
  }

  /* =============================================== RENDER ============================================== */

  return (
    <form onSubmit={_handleFormSubmit} noValidate className="criterion-form">
      {state.saving && <Loading />}
      <div className="criterion-form-content">
        {state.loading ? (
          <CriterionFormSkeleton />
        ) : (
          <>
            <div ref={infoCriterionFormRef}>
              {/* ========================================== Informations ========================================= */}
              {/* Cannot be modified, only display values */}
              {!isCreationFormType && (
                <div className="criterion-form-info">
                  <div>
                    <label>Catégorie : </label>{" "}
                    <span>{state.criteria?.category?.label}</span>
                  </div>
                  <div>
                    <label>Identifiant : </label>{" "}
                    <span>
                      [{state.criteria?.id}] {state.criteria?.shortName}
                    </span>
                  </div>
                  <div>
                    <label>Type : </label>
                    <span>{`${state.criteria?.type?.label} (${state.criteria?.type?.shortName})`}</span>
                  </div>
                  <div>
                    <label>Spécificités : </label>
                    <span className="criterion-form-info-specific flex">
                      {state.criteria?.specific && (
                        <Tooltip title="Critère spécifique" arrow>
                          <MemoryIcon />
                        </Tooltip>
                      )}
                      {state.criteria?.financial && (
                        <Tooltip title="Critère de financement" arrow>
                          <PaidIcon />
                        </Tooltip>
                      )}
                      {state.criteria?.simulatorOnly && (
                        <Tooltip title="Critère simulateur uniquement" arrow>
                          <QuizIcon />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </div>
              )}

              {!isCreationFormType && <div className="separator-wide" />}

              {/* ========================================== General form ========================================= */}
              <div className="criterion-form-general">
                <h4>Général</h4>

                {/** Switch container */}
                <div className="flex-start">
                  <div className="flex criterion-switch-container">
                    <ActiveSwitch
                      objectActive={state.criteria?.mandatory}
                      objectId={state.criteria?.id}
                      onChange={(v) =>
                        _handleGeneralValueChange(v, "mandatory")
                      }
                      idPrefix="criterion"
                      className="criterion-switch"
                      disabled={props.readOnly}
                    />
                    <span className="criterion-switch-label">
                      {state.criteria?.mandatory ? "Obligatoire" : "Facultatif"}
                    </span>
                  </div>
                  <div className="flex criterion-switch-container">
                    <ActiveSwitch
                      objectActive={state.criteria?.active}
                      objectId={state.criteria?.id}
                      onChange={(v) => _handleGeneralValueChange(v, "active")}
                      idPrefix="criterion"
                      className="criterion-switch"
                      disabled={props.readOnly}
                    />
                    <span className="criterion-switch-label">
                      {state.criteria?.active ? "Actif" : "Inactif"}
                    </span>
                  </div>
                </div>

                <div className="criterion-form-and-switch-content">
                  {/** Partie gauche */}
                  <div className="left-content">
                    {/** Catégorie et type du critère */}
                    <div className="criterion-category-type">
                      <div
                        className={`category-form ${
                          state.requiredFormEmptyError.category ||
                          state.requiredFormEmptyError.type
                            ? "form-content"
                            : ""
                        }`}
                      >
                        <SelectField
                          className="criterion-category-selector"
                          required={true}
                          placeholder="Catégorie"
                          isMulti={false}
                          options={state.criterionThemesOptions}
                          name="category"
                          isFocused={state.criteria.category !== null}
                          value={state.criteria.category}
                          onChange={(data) =>
                            _handleCriteriaChange(data, "category")
                          }
                          title="Catégorie du critère"
                          context={this}
                          readOnly={props.readOnly}
                          optionsContentHeight={100}
                        >
                          Catégorie
                        </SelectField>
                        {state.requiredFormEmptyError.category && (
                          <div className="error">Champ obligatoire</div>
                        )}
                      </div>

                      <div
                        className={`type-form ${
                          state.requiredFormEmptyError.category ||
                          state.requiredFormEmptyError.type
                            ? "form-content"
                            : ""
                        }`}
                      >
                        <SelectField
                          className="criterion-type-selector"
                          required={true}
                          placeholder="Type"
                          isMulti={false}
                          options={state.criterionTypesOptions}
                          name="type"
                          isFocused={state.criteria.type !== null}
                          value={state.criteria.type}
                          onChange={(data) =>
                            _handleCriteriaChange(data, "type")
                          }
                          title="Type du critère"
                          context={this}
                          isDisabled={!isCreationFormType}
                          readOnly={props.readOnly}
                          optionsContentHeight={100}
                        >
                          Type
                        </SelectField>
                        {state.requiredFormEmptyError.type && (
                          <div className="error">Champ obligatoire</div>
                        )}
                      </div>
                    </div>

                    {/** Nom et position du critère */}
                    <div className="flex criterion-name-and-position-content">
                      <div
                        className={`criterion-name flex-grow ${
                          state.requiredFormEmptyError.name
                            ? "form-tiny-content"
                            : ""
                        }`}
                      >
                        <InputField
                          required={true}
                          name="name"
                          isFocused={state.criteria.name !== null}
                          value={state.criteria.name}
                          onChange={_handleCriteriaChange}
                          title="Nom du critère"
                          context={this}
                          readOnly={props.readOnly}
                        >
                          Nom
                        </InputField>
                        {state.requiredFormEmptyError.name && (
                          <div className="form-error">Champ obligatoire</div>
                        )}
                      </div>

                      <div
                        className={
                          state.requiredFormEmptyError.name
                            ? "form-tiny-content"
                            : ""
                        }
                      >
                        <InputField
                          className="criterion-position"
                          type="number"
                          name="position"
                          isFocused={state.criteria.position !== null}
                          value={state.criteria.position}
                          onChange={_handleCriteriaChange}
                          title="Ordre d'affichage du critère"
                          context={this}
                          readOnly={props.readOnly}
                        >
                          Ordre
                        </InputField>
                      </div>
                    </div>
                  </div>
                  {/** Partie droite */}
                  <div className="right-content">
                    {aidFundings.map((aidFunding, index) => {
                      let isLastElement = aidFundings.length - 1 === index;

                      return (
                        <div
                          key={`AidFunding${aidFunding.key}${index + 1}`}
                          className={`flex-start criterion-switch-container ${
                            !isLastElement ? "funding-switch" : ""
                          }`}
                        >
                          <ActiveSwitch
                            objectActive={state.criteria?.[aidFunding.key]}
                            objectId={state.criteria?.key}
                            onChange={(v) =>
                              _handleGeneralValueChange(v, aidFunding.key)
                            }
                            idPrefix="criterion"
                            className="criterion-switch"
                            disabled={props.readOnly}
                          />
                          <span className="criterion-switch-label">
                            {aidFunding.label}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {state.criteria?.type?.shortName !== CRITERION_TYPE_DTE && (
              <div ref={valuesTextCriterionFormRef}>
                <div className="separator-wide" />
              </div>
            )}
            {/* ========================================== Values form ========================================== */}
            <div className="criterion-form-data">
              {state.criteria?.type?.shortName !== CRITERION_TYPE_DTE && (
                <h4 ref={valuesLabelCriterionFormRef}>Valeurs</h4>
              )}
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TYPE NUM ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              {state.criteria?.type?.shortName === CRITERION_TYPE_NUM && (
                <>
                  {/* For specific criteria only*/}
                  {SPECIFIC_CRITERION.includes(state.criteria.shortName) && (
                    /*<div className="help-text">
                                Ce critère est spécifique vous n'avez donc aucune valeur à modifier.
                            </div>*/
                    <div className="flex form-data-LOC form-data-SPECIFIC">
                      <div className="w-50">
                        <h5>Groupe d'essence</h5>

                        <SelectField
                          options={state.groupOptions}
                          value={state.selectedGroup}
                          multi={false}
                          closeMenuOnSelect={true}
                          placeholder="Selectionnez un groupe d'essence"
                          onChange={_handleGroupChange}
                        />
                      </div>
                      <div className="vertical-separator data-LOC-separator" />
                      <div className="w-50">
                        <h5>Essences</h5>
                        <div className="flex w-100">
                          {state.selectedGroup && (
                            <>
                              <InputField
                                className="input-tiny flex-grow formField--nopadding"
                                name="value"
                                placeholder="Nom de l'essence"
                                value={state.specieValue}
                                onChange={_handleSpecieValueChange}
                                onKeyDown={_handleSpecieInput}
                                title="Nom de l'essence"
                                readOnly={props.readOnly}
                              />
                              <button
                                type="button"
                                className="btn default"
                                title="Ajouter une essence"
                                onClick={_handleAddSpecieClick}
                              >
                                <Add />
                              </button>
                            </>
                          )}
                        </div>
                        <div className="data-TXT-list flex-start flex-column">
                          {_.map(
                            state.selectedGroup?.object.species,
                            (value) => {
                              return (
                                <div
                                  className="data-TXT-item flex w-100"
                                  key={`data-TXT-item-${
                                    value.id !== undefined
                                      ? value.id
                                      : value.idNew
                                  }`}
                                >
                                  <InputField
                                    className={`TXT-input input-tiny flex-grow formField--nopadding ${
                                      !value?.active ? "input-disactivated" : ""
                                    }`}
                                    name={`value-txt-${value.id}`}
                                    disabled={!value.active}
                                    value={value.label}
                                    context={this}
                                    onChange={(e) => {
                                      _handleTxtListValueChange(
                                        value?.id,
                                        value?.idNew,
                                        value.hasOwnProperty("isNew"),
                                        e
                                      );
                                    }}
                                  />
                                  {value?.isNew ? (
                                    <button
                                      type="button"
                                      className="btn alert TXT-button"
                                      title="Supprimer cette valeur"
                                      disabled={props.readOnly}
                                      onClick={() => {
                                        _handleDeleteSpecieClick(value);
                                      }}
                                    >
                                      <Delete />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className={`btn TXT-button ${
                                        value.active ? "green" : "red"
                                      }`}
                                      title={`${
                                        value.active ? "Désactiver" : "Activer"
                                      } cette valeur`}
                                      disabled={props.readOnly}
                                      onClick={() => {
                                        _handleActiveSpecieClick(value);
                                      }}
                                    >
                                      {value.active ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </button>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!SPECIFIC_CRITERION.includes(state.criteria.shortName) && (
                    <div className="flex">
                      <InputField
                        className="flex-grow criterion-num-value-min"
                        type="number"
                        name="valueMin"
                        placeholder="0"
                        isFocused={state.criteria.valueMin !== null}
                        value={state.criteria.valueMin}
                        onChange={_handleCriteriaChange}
                        title="Valeur minimum autorisée"
                        context={this}
                        readOnly={props.readOnly}
                      >
                        Minimum
                      </InputField>
                      <InputField
                        className="flex-grow"
                        type="number"
                        name="valueMax"
                        placeholder="100"
                        isFocused={state.criteria.valueMax !== null}
                        value={state.criteria.valueMax}
                        onChange={_handleCriteriaChange}
                        title="Valeur maximum autorisée"
                        context={this}
                        readOnly={props.readOnly}
                      >
                        Maximum
                      </InputField>
                      <InputField
                        className="flex-grow"
                        type="text"
                        name="unit"
                        placeholder="%"
                        isFocused={state.criteria.unit !== null}
                        value={state.criteria.unit}
                        onChange={_handleCriteriaChange}
                        title="Unité de la valeur à afficher"
                        context={this}
                        readOnly={props.readOnly}
                      >
                        Unité
                      </InputField>
                      <InputField
                        className="flex-grow criterion-num-step"
                        type="number"
                        name="step"
                        placeholder="0.0"
                        isFocused={state.criteria.step !== null}
                        value={state.criteria.step}
                        onChange={_handleCriteriaChange}
                        title="Réglage du pas du curseur numérique"
                        context={this}
                        readOnly={props.readOnly}
                        step={0.01}
                      >
                        Pas
                      </InputField>
                    </div>
                  )}
                </>
              )}

              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TYPE BIN ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              {state.criteria?.type?.shortName === CRITERION_TYPE_BIN && (
                <>
                  <div className="flex">
                    <InputField
                      className="input-tiny flex-grow"
                      name="value-0"
                      placeholder='"Oui"'
                      isFocused={
                        state.criteria.criterionValues[0]?.value !== null
                      }
                      value={state.criteria.criterionValues[0]?.value}
                      onChange={_handleCriteriaValueChange}
                      title="Valeur à afficher en premier"
                      context={this}
                      readOnly={props.readOnly}
                    >
                      Valeur 1
                    </InputField>
                    <InputField
                      className="input-tiny flex-grow"
                      name="value-1"
                      placeholder='"Non"'
                      isFocused={
                        state.criteria.criterionValues[1]?.value !== null
                      }
                      value={state.criteria.criterionValues[1]?.value}
                      onChange={_handleCriteriaValueChange}
                      title="Valeur à afficher en deuxième"
                      context={this}
                      readOnly={props.readOnly}
                    >
                      Valeur 2
                    </InputField>
                  </div>
                </>
              )}

              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TYPE OBG ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              {state.criteria?.type?.shortName === CRITERION_TYPE_OBG && (
                <>
                  <div className="flex">
                    <InputField
                      className="flex-grow criterion-obg-mandatory"
                      name="value-0"
                      placeholder='"Obligatoire"'
                      isFocused={
                        state.criteria.criterionValues[0]?.value !== null
                      }
                      value={state.criteria.criterionValues[0]?.value}
                      onChange={_handleCriteriaValueChange}
                      title="Valeur à afficher en premier"
                      context={this}
                      readOnly={props.readOnly}
                    >
                      Valeur obligatoire
                    </InputField>
                    <InputField
                      className="flex-grow  criterion-obg-not-mandatory"
                      name="value-1"
                      placeholder='"Facultatif"'
                      isFocused={
                        state.criteria.criterionValues[1]?.value !== null
                      }
                      value={state.criteria.criterionValues[1]?.value}
                      onChange={_handleCriteriaValueChange}
                      title="Valeur à afficher en deuxième"
                      context={this}
                      readOnly={props.readOnly}
                    >
                      Valeur facultative
                    </InputField>
                  </div>
                  {/*<RadioGroup row name="criteria-obg-radiogroup" value={state.criteria.obgAnswerFirst}*/}
                  {/*            onChange={_handleCriteriaOBGChange} className="flex-sb w-100">*/}
                  {/*    <FormControlLabel value={true} control={<Radio/>} label="Valeur 1 obligatoire"/>*/}
                  {/*    <FormControlLabel value={false} control={<Radio/>} label="Valeur 2 obligatoire"/>*/}
                  {/*</RadioGroup>*/}
                </>
              )}

              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TYPE TXT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              {state.criteria?.type?.shortName === CRITERION_TYPE_TXT && (
                <>
                  {/* For specific criteria only*/}
                  {SPECIFIC_CRITERION.includes(state.criteria.shortName) && (
                    <div className="help-text">
                      Ce critère est spécifique vous n'avez donc aucune valeur à
                      modifier.
                    </div>
                  )}
                  {!SPECIFIC_CRITERION.includes(state.criteria.shortName) && (
                    <div className="form-data-TXT flex flex-column">
                      {!state.criteria.specific && (
                        <div className="flex-sa w-100">
                          <div className="criterion-switch-container flex">
                            <ActiveSwitch
                              objectActive={state.criteria?.multi}
                              objectId={state.criteria?.id}
                              onChange={(v) =>
                                _handleGeneralValueChange(v, "multi")
                              }
                              idPrefix="criterion"
                              className="criterion-switch"
                              onColor={"#86c965"}
                              offColor={"#fcbf99"}
                              checkedIcon={
                                <FormatListBulleted className="reactSelect-custom-icon" />
                              }
                              uncheckedIcon={
                                <LooksOne className="reactSelect-custom-icon" />
                              }
                              disabled={props.readOnly}
                            />
                            <span className="criterion-switch-label">
                              {state.criteria?.multi
                                ? "Réponses multiples"
                                : "Réponse unique"}
                            </span>
                          </div>

                          <SelectField
                            className="form-data-TXT-displaymode"
                            options={displayModeOptions}
                            value={state.selectedDisplayValue}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            placeholder="Type d'affichage"
                            onChange={_handleDisplayModeChange}
                            context={this}
                            isDisabled={props.readOnly}
                          />
                        </div>
                      )}
                      <div className="separator" />

                      <div className="flex w-100">
                        <InputField
                          className="input-tiny flex-grow formField--nopadding"
                          name="value"
                          placeholder="Texte de la valeur"
                          value={state.txtValue}
                          onChange={_handleTxtValueChange}
                          onKeyDown={_handleTextInput}
                          title="Valeur à ajouter"
                          readOnly={props.readOnly}
                        />
                        <button
                          type="button"
                          className="btn default"
                          title="Ajouter la valeur"
                          onClick={_handleAddTxtClick}
                        >
                          <Add />
                        </button>
                      </div>
                      <div className="data-TXT-list flex-start flex-column">
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {state.items.map((item, index) => (
                                  <Draggable
                                    key={item.id ?? item.idNew}
                                    draggableId={`draggable${
                                      item.id ?? item.idNew
                                    }`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <TextCriterionForm
                                          value={item}
                                          onRequestFormChange={(
                                            id,
                                            idNew,
                                            hasPropertyIsNew,
                                            event,
                                            prop
                                          ) =>
                                            _handleTxtListValueChange(
                                              id,
                                              idNew,
                                              hasPropertyIsNew,
                                              event,
                                              prop
                                            )
                                          }
                                          onRequestActiveForm={(data) =>
                                            _handleActiveTxtClick(data)
                                          }
                                          onRequestDeleteForm={(data) =>
                                            _handleDeleteTxtClick(data)
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TYPE LOC ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              {state.criteria?.type?.shortName === CRITERION_TYPE_LOC && (
                <>
                  <div ref={indicationCriterionFormRef}>
                    <div className="help-text">
                      Ce critère est{" "}
                      {`${state.criteria?.specific ? "spécifique et" : ""}`} de
                      type "
                      {`${state.criteria?.type.label}(${state.criteria?.type.shortName})`}
                      ". Vous pouvez consulter et modifier les valeurs possibles
                      ci-dessous
                    </div>

                    <div className="separator" />
                  </div>

                  <div className="form-data-LOC flex">
                    {state.regionLoading && <Loading />}

                    {/* ---------------------------------------- Zone form ---------------------------------------- */}

                    <div className="data-LOC-zone flex align-items-start flex-column">
                      <h5>Zones</h5>
                      <div
                        className={`data-TXT-list flex-start flex-column`}
                        style={{ height: state.zonesRegionsInfoHeight }}
                      >
                        {state.locationLoading && (
                          <div className="flex">
                            <CircularProgress color="success" />
                          </div>
                        )}
                        {!state.locationLoading &&
                          _.map(state.zones, (z) => {
                            return (
                              <div
                                className="data-TXT-item flex w-100"
                                key={`data-TXT-item-${z.id}`}
                              >
                                <InputField
                                  className={`zone-input-field TXT-input input-tiny flex-grow formField--nopadding ${
                                    !z?.active ? "input-disactivated" : ""
                                  }`}
                                  name={`value-txt-${z.id}`}
                                  disabled={!z.active}
                                  value={z.label}
                                  context={this}
                                  readOnly={true}
                                />

                                <button
                                  type="button"
                                  className={`btn TXT-button ${
                                    z.active ? "green" : "red"
                                  }`}
                                  title={`${
                                    z.active ? "Désactiver" : "Activer"
                                  } cette valeur`}
                                  disabled={props.readOnly}
                                  onClick={() => {
                                    _handleActiveZoneClick(z);
                                  }}
                                >
                                  {z.active ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="vertical-separator data-LOC-separator" />

                    {/* ---------------------------------------- Region form ---------------------------------------- */}

                    <div className="data-LOC-selector">
                      <h5>
                        Régions
                        {!state.displayCreationForm && (
                          <div
                            title="Ajouter une nouvelle région"
                            className="region-add-button"
                            onClick={_handleAddNewRegion}
                          >
                            <Add />
                          </div>
                        )}
                      </h5>
                      <div
                        style={{
                          height: state.zonesRegionsInfoHeight,
                          overflowY: !state.locationLoading
                            ? "auto"
                            : "undefined",
                        }}
                      >
                        {state.locationLoading && (
                          <div className="flex">
                            <CircularProgress color="success" />
                          </div>
                        )}

                        {!state.locationLoading && (
                          <DragAndDrop
                            withDragIndicator={true}
                            elements={state.regions}
                            checkIfNewElementsValids={_handleCheckDragAndDrop}
                            renderComponent={(region) => (
                              <div className="data-TXT-item flex w-100">
                                <InputField
                                  id={
                                    region.isCustomized
                                      ? region.id
                                      : `region${region.id}`
                                  }
                                  title={`${region.label} (${
                                    region.departments?.length
                                  } département${
                                    region.departments?.length > 1 ? "s" : ""
                                  })`}
                                  className={`region-input TXT-input input-tiny flex-grow formField--nopadding ${
                                    !region?.active ? "input-disactivated" : ""
                                  } ${
                                    state.selectedRegion?.id === region.id
                                      ? "active-region"
                                      : ""
                                  }`}
                                  name={`value-region-${region.id}`}
                                  disabled={!region.active}
                                  value={region.label}
                                  context={this}
                                  readOnly={props.readOnly}
                                  onChange={(event) =>
                                    _handleRegionNameChange(event, region)
                                  }
                                  onClick={() => _handleRegionClick(region)}
                                />

                                <button
                                  type="button"
                                  className={`btn TXT-button ${
                                    region.active ? "green" : "red"
                                  }`}
                                  title={`${
                                    region.active ? "Désactiver" : "Activer"
                                  } cette valeur`}
                                  disabled={props.readOnly}
                                  onClick={() => {
                                    _handleActiveRegionClick(region);
                                  }}
                                >
                                  {region.active ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </button>
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>

                    <div className="vertical-separator data-LOC-separator" />

                    {/* ---------------------------------------- Departement form ---------------------------------------- */}

                    <div className="data-LOC-department-form">
                      <h5 ref={departmentTitleRef}>Départements</h5>
                      {state.selectedRegion !== null && (
                        <>
                          {!props.readOnly && (
                            <div
                              ref={departmentFormContentRef}
                              className="data-LOC-selector_dep flex-start"
                            >
                              <SelectField
                                className="department-selector"
                                options={state.departmentsOptions}
                                value={null}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                optionsContentHeight="18vh"
                                placeholder="Ajouter un département"
                                onChange={_handleDepartementChange}
                              />
                            </div>
                          )}
                          <div
                            className="data-LOC-list flex-start flex-column flex-grow"
                            style={{
                              height: state.departmentsInfoHeight,
                              overflowY: "auto",
                            }}
                          >
                            {state.regions
                              .find(
                                (element) =>
                                  element.id === state.selectedRegion.id
                              )
                              ?.departments?.map((dep) => {
                                return (
                                  <div
                                    className="data-LOC-item flex w-100"
                                    key={`data-LOC-item-${
                                      dep.id !== undefined ? dep.id : dep.idNew
                                    }`}
                                  >
                                    <InputField
                                      className="LOC-input w-100 input-tiny formField--nopadding"
                                      name={`dep-${dep.id}`}
                                      readOnly={true}
                                      title={dep.label}
                                      value={`${dep.number} - ${dep.label}`}
                                    />
                                    {!props.readOnly && (
                                      <button
                                        type="button"
                                        className="btn btn-tiny alert LOC-button"
                                        title="Supprimer cette valeur"
                                        onClick={() => {
                                          _handleDeleteDepartmentClick(dep);
                                        }}
                                      >
                                        <Delete />
                                      </button>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {/* ========================================== FOOTER BUTTON ========================================= */}

      {!props.readOnly && (
        <div className="criterion-form-footer">
          <button
            className="btn default btn-lg"
            type="submit"
            title="Enregistrer les données"
            ref={props.forwardRef}
          >
            <Save />
            Enregistrer
          </button>
        </div>
      )}
    </form>
  );
};

/* ================================== GLOBAL FUNCTIONS ================================== */

function createEmptyCriterion() {
  return {
    name: "",
    active: true,
    mandatory: false,
    category: null,
    type: null,
    valueMin: 0,
    valueMax: 100,
    unit: "",
    position: 0,
    displayMode: 0,
    criterionValues: [],
    aidFundingFixedAmount: false,
    aidFundingScale: false,
    aidFundingPlant: false,
  };
}
