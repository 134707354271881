import React from "react";
import { Api, Constants, Session } from "fsy.common-library";
import Helper from "../../../services/Helper";
import { toast } from "react-toastify";
import ActiveSwitch from "../../general/form/ActiveSwitch";
import { Button, CircularProgress, Divider } from "@mui/material";
import { InputField } from "../../general/form/Input";
import { TableSkeleton } from "../../../services/LoadingHelper";

import "./generalParameters.css";
import _ from "lodash";
import {
  RIGHTS_NOACCESS,
  RIGHTS_READ,
  RIGHTS_WRITE,
} from "../../../services/Constants";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

export class GeneralParameters extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.auth = Session.getAuth();
    this.isOrganismTypeFsy =
      !Session.getSessionUser()?.organization?.organism &&
      !Session.getSessionUser()?.organization?.partner &&
      !Session.getSessionUser()?.organization?.other;
    this.isPartner = Session.getSessionUser()?.organization?.partner || false;
    this.isOrganism = Session.getSessionUser()?.organization?.organism || false;
    this.state = {
      isLoading: true,
      isPlaying: false,
      maxThresholdLoading: false,
      isPercentageLoading: false,
      isNotificationMailAddressLoading: false,
      isAccountCreationLoading: false,
      updating: false,
      hasError: false,
      updatingParameters: false,
      currentParameterToUpdate: null,
      parameters: {
        [Constants.FRONT_ACCOUNT_CREATION]: { id: null, value: false },
        [Constants.MAX_ALERT_THRESHOLD]: { id: null, value: 15 },
        [Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE]: {
          id: null,
          value: 0,
        },
        [Constants.NOTIFICATION_MAIL_ADDRESS]: {
          id: null,
          value: "",
        },
        [Constants.AIDS_LINK_VALIDITY_VERIFICATION_PERIOD]: {
          id: null,
          value: 0,
        },
        [Constants.MAX_MONTH_COUNT_TO_DELETE_LOG]: {
          id: null,
          value: 0,
        },
      },
      url: "",
      urlCopyText: "Copier",
      generalParameters: [],
      fransylvaOrganization: null,
    };
    this._handleAccountCreationChange =
      this._handleAccountCreationChange.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleMaxAlertThresholdBlur =
      this._handleMaxAlertThresholdBlur.bind(this);
    this._handleLinkCopy = this._handleLinkCopy.bind(this);
    this._handleChangePercentage = this._handleChangePercentage.bind(this);
    this._handleSavePercentage = this._handleSavePercentage.bind(this);
    this._handleCreatePercentage = this._handleCreatePercentage.bind(this);
    this._handleUpdatePercentage = this._handleUpdatePercentage.bind(this);
    this._handleChangeNotificationMailAddress =
      this._handleChangeNotificationMailAddress.bind(this);
    this._handleSaveNotificationMailAddress =
      this._handleSaveNotificationMailAddress.bind(this);
    this._handleGeneralParameterChange =
      this._handleGeneralParameterChange.bind(this);
    this._handleSaveParameter = this._handleSaveParameter.bind(this);
    this._handleRequestPlay = this._handleRequestPlay.bind(this);

    this.sessionUser = Session.getSessionUser();
    this.refLink = React.createRef();
  }

  async componentDidMount() {
    const isFransylva = this.isOrganismTypeFsy;
    const isOrganism = this.isOrganism;
    const isPartner = this.isPartner;
    const organisationId = this.sessionUser.organization.id;
    const condition =
      isFransylva ||
      ((isOrganism || isPartner) &&
        this.auth[Constants.PROFILE_PARAMETER] > RIGHTS_NOACCESS);
    const requests = [Api.organization.getOrganization(organisationId)];
    const ORGANIZATION_INDEX = 0;
    const FRONT_ACCOUNT_CREATION_INDEX = 1;
    const MAX_ALERT_THRESHOLD_INDEX = 2;
    const MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE_INDEX = 3;
    const AIDS_LINK_VALIDITY_VERIFICATION_PERIOD_INDEX = 4;
    const MAX_MONTH_COUNT_TO_DELETE_LOG_INDEX = 5;
    const NOTIFICATION_MAIL_ADDRESS_INDEX = isFransylva ? 6 : 2;

    if (condition) {
      requests.push(
        Api.parameter.getParameterByKey(
          Constants.FRONT_ACCOUNT_CREATION,
          organisationId
        )
      );

      // Les paramètres uniques pour FRANSYLVA
      if (isFransylva) {
        // On cherche des infos sur FRANSYLVA
        const organizations = await Api.organization.getOrganizations(1, {
          organization_filterText: Constants.ORGANISM_TYPE_FSY,
        });
        const organizationsResults = Helper.isValidResponse(organizations);

        if (organizationsResults?.length > 0) {
          const fransylvaOrganization = organizationsResults[0];

          requests.push(
            Api.parameter.getParameterByKey(
              Constants.MAX_ALERT_THRESHOLD,
              fransylvaOrganization.id
            ),
            Api.parameter.getParameterByKey(
              Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE,
              fransylvaOrganization.id
            ),
            Api.parameter.getParameterByKey(
              Constants.AIDS_LINK_VALIDITY_VERIFICATION_PERIOD,
              fransylvaOrganization.id
            ),
            Api.parameter.getParameterByKey(
              Constants.MAX_MONTH_COUNT_TO_DELETE_LOG,
              fransylvaOrganization.id
            )
          );

          this.setState({ fransylvaOrganization });
        }
      }

      // Les autres paramètres
      requests.push(
        Api.parameter.getParameterByKey(
          Constants.NOTIFICATION_MAIL_ADDRESS,
          organisationId
        )
      );
    }

    if (requests.length > 0) {
      Promise.all(requests)
        .then((responses) => {
          const organization = Helper.isValidResponse(
            responses[ORGANIZATION_INDEX]
          );

          const parameters = [];

          if (condition) {
            parameters.push(
              Helper.isValidResponse(responses[FRONT_ACCOUNT_CREATION_INDEX])
            );
          }

          if (isFransylva) {
            parameters.push(
              Helper.isValidResponse(responses[MAX_ALERT_THRESHOLD_INDEX]),
              Helper.isValidResponse(
                responses[MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE_INDEX]
              ),
              Helper.isValidResponse(
                responses[AIDS_LINK_VALIDITY_VERIFICATION_PERIOD_INDEX]
              ),
              Helper.isValidResponse(
                responses[MAX_MONTH_COUNT_TO_DELETE_LOG_INDEX]
              )
            );
          }

          if (condition) {
            parameters.push(
              Helper.isValidResponse(responses[NOTIFICATION_MAIL_ADDRESS_INDEX])
            );
          }

          _.map(parameters, (parameter) => {
            if (parameter) {
              this.setState((prevState) => {
                let param = prevState.parameters;
                const propKey = parameter.propKey;
                let propValue = parameter.propValue;

                if (propKey === Constants.FRONT_ACCOUNT_CREATION) {
                  propValue = propValue === "true";
                }

                param[propKey] = { id: parameter.id, value: propValue };
                return { parameters: param };
              });
            }
          });

          if (organization) {
            this.setState({
              url: `${process.env.REACT_APP_PROJECT_FRONT_URL}?org=${organization.uuid}`,
            });
          }

          let hasError =
            responses?.[0]?.status !== 200 && responses?.[1]?.status !== 200;

          this.setState({
            hasError,
            isLoading: false,
          });
        })
        .catch((reason) => {
          console.error(reason);
          this.setState({ hasError: true, isLoading: false });
        });
    }

    const parameters = [];

    // Si c'est Fransylva
    if (isFransylva) {
      parameters.push(
        {
          type: "number",
          title: "Seuil maximum d'alerte",
          key: Constants.MAX_ALERT_THRESHOLD,
        },
        {
          type: "number",
          title: "Pourcentage minimum de réponse aux questions facultatives",
          key: Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE,
        }
      );
    }

    if (condition) {
      parameters.push({
        type: "mail",
        title: "Adresse mail de notification",
        key: Constants.NOTIFICATION_MAIL_ADDRESS,
      });
    }

    if (isFransylva) {
      parameters.push(
        {
          type: "number",
          title:
            "Périodicité (en jours) de vérification de validité des liens dans les aides",
          key: Constants.AIDS_LINK_VALIDITY_VERIFICATION_PERIOD,
          playData: {
            title:
              "Périodicité (en jours) de vérification de validité des liens dans les aides",
            apiFunction: () => Api.aid.launchCheckPublishedAidsLinksValidity(),
          },
        },
        {
          type: "number",
          title: "Durée limite de conservation des logs (en mois)",
          key: Constants.MAX_MONTH_COUNT_TO_DELETE_LOG,
          playData: {
            title: "",
            apiFunction: () => {},
          },
        }
      );
    }

    this.setState({ generalParameters: parameters });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.hasError) {
      Helper.displayGenericErrorToast("de vos paramètres");
      this.setState({ hasError: false });
    }
  }

  _handleAccountCreationChange(activeState) {
    this._updateParameter(Constants.FRONT_ACCOUNT_CREATION, activeState);
  }

  _handleLinkCopy() {
    const linkToCopy = this.refLink.current;
    linkToCopy.select();
    linkToCopy.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(linkToCopy.value); // Copy the text inside the text field
    document.getSelection().removeAllRanges();
    this.setState({ urlCopyText: "✔ copié" });
    setTimeout(() => this.setState({ urlCopyText: "Copier" }), 1500);
  }

  _handleChange(e) {
    const field = e.target.name;
    let value = e.target.value;

    this.setState((prevState) => {
      let parameters = prevState.parameters;
      parameters[field].value = value;
      return { parameters: parameters };
    });
  }

  _handleMaxAlertThresholdBlur() {
    this._updateParameter(
      Constants.MAX_ALERT_THRESHOLD,
      this.state.parameters[Constants.MAX_ALERT_THRESHOLD].value
    );
  }

  // Fonction permettant de créer le paramètre (s'il n'existe pas encore en base) ou de modification (s'il existe déjà en base)
  _updateParameter(parameterKey, value) {
    const parameter = this.state.parameters[parameterKey];
    const mode = parameter?.id ? "update" : "creation";
    const isFransylvaParameter = [
      Constants.MAX_ALERT_THRESHOLD,
      Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE,
      Constants.AIDS_LINK_VALIDITY_VERIFICATION_PERIOD,
      Constants.MAX_MONTH_COUNT_TO_DELETE_LOG,
    ].includes(parameterKey);
    const request =
      mode === "creation"
        ? Api.parameter.createParameter(
            isFransylvaParameter
              ? this.state.fransylvaOrganization?.id
              : this.sessionUser.organization.id,
            parameterKey,
            value.toString()
          )
        : Api.parameter.updateParameter(parameter?.id, {
            propValue: value.toString(),
          });

    this.setState({
      updatingParameters: true,
      currentParameterToUpdate: parameterKey,
    });

    request.then((response) => {
      const result = Helper.isValidResponse(response);
      if (result) {
        this.setState(
          (prevState) => {
            let param = prevState.parameters;
            param[parameterKey].id = result.id;
            param[parameterKey].value = value;

            return {
              parameters: param,
              updatingParameters: false,
              currentParameterToUpdate: null,
            };
          },
          () => {
            toast.success(
              `Paramètre ${mode === "creation" ? "enregistré" : "mis à jour"}`,
              Helper.getToastOptions()
            );
          }
        );
      } else {
        this.setState({
          updatingParameters: false,
          currentParameterToUpdate: null,
        });
        toast.error(
          mode === "creation"
            ? "Impossible d'enregistrer le paramètre"
            : "Impossible de mettre le paramètre à jour",
          Helper.getToastOptions()
        );
      }
    });
  }

  _handleChangePercentage = (event) => {
    const { value } = event.target;
    const valueAsNumber = Number(value);

    if (valueAsNumber <= 100) {
      this.setState({
        parameters: {
          ...this.state.parameters,
          [Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE]: {
            id: this.state.parameters[
              Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE
            ].id,
            value,
          },
        },
      });
    }
  };

  _handleChangeNotificationMailAddress = (event) => {
    const { value } = event.target;

    this.setState({
      parameters: {
        ...this.state.parameters,
        [Constants.NOTIFICATION_MAIL_ADDRESS]: {
          id: this.state.parameters[Constants.NOTIFICATION_MAIL_ADDRESS].id,
          value,
        },
      },
    });
  };

  // Fonction de création de paramètre
  _handleCreateParameter = (parameterKey, parameterLoadingKey) => {
    this.setState({ [parameterLoadingKey]: true });

    Api.parameter
      .createParameter(
        this.sessionUser.organization.id,
        parameterKey,
        this.state.parameters[parameterKey].value
      )
      .then((response) => {
        const result = Helper.isValidResponse(response);

        if (result) {
          this.setState({
            parameters: {
              ...this.state.parameters,
              [parameterKey]: {
                id: result.id,
                value: result.propValue,
              },
            },
          });

          toast.success(
            "Paramètre enregistré avec succès.",
            Helper.getToastOptions()
          );
        }

        this.setState({ [parameterLoadingKey]: false });
      });
  };

  // Fonction de mise à jour de paramètre
  _handleUpdateParameter = (parameterKey, parameterLoadingKey) => {
    this.setState({ [parameterLoadingKey]: true });
  };

  _handleSaveNotificationMailAddress = () => {
    const mailAddress =
      this.state.parameters[Constants.NOTIFICATION_MAIL_ADDRESS].value;
    const id = this.state.parameters[Constants.NOTIFICATION_MAIL_ADDRESS].id;

    if (mailAddress) {
      const isValidMailAddress = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mailAddress);

      if (isValidMailAddress) {
        if (id) {
          this.setState({ isNotificationMailAddressLoading: true });
          this._updateParameter(
            Constants.NOTIFICATION_MAIL_ADDRESS,
            this.state.parameters[Constants.NOTIFICATION_MAIL_ADDRESS].value,
            "mailNotification"
          );
        } else {
          this._handleCreateParameter(
            Constants.NOTIFICATION_MAIL_ADDRESS,
            "isNotificationMailAddressLoading"
          );
        }
      } else {
        toast.error(
          "Veuillez saisir une adresse mail valide",
          Helper.getToastOptions()
        );
      }
    } else {
      toast.error("Veuillez saisir l'adresse mail", Helper.getToastOptions());
    }
  };

  _handleCreatePercentage = () => {
    Api.parameter
      .createParameter(
        this.sessionUser.organization.id,
        Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE,
        this.state.parameters[
          Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE
        ].value
      )
      .then((response) => {
        const result = Helper.isValidResponse(response);

        if (result) {
          this.setState({
            parameters: {
              ...this.state.parameters,
              [Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE]: {
                id: result.id,
                value: result.propValue,
              },
            },
          });

          toast.success(
            "Paramètre enregistré avec succès.",
            Helper.getToastOptions()
          );
        }

        this.setState({ isPercentageLoading: false });
      })
      .catch((error) => {
        this.setState({ isPercentageLoading: false });
        console.error(error);
      });
  };

  _handleUpdatePercentage = () => {
    this._updateParameter(
      Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE,
      this.state.parameters[
        Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE
      ].value,
      "percentage"
    );
  };

  _handleSavePercentage = () => {
    const percentage =
      this.state.parameters[
        Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE
      ].value;

    if (![null, undefined, ""].includes(percentage)) {
      this.setState({ isPercentageLoading: true });

      if (
        !this.state.parameters[
          Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE
        ].id
      ) {
        this._handleCreatePercentage();
      } else {
        this._handleUpdatePercentage();
      }
    } else {
      toast.error(
        "Veuillez saisir la valeur du pourcentage minimum de réponse aux questions facultatives.",
        Helper.getToastOptions()
      );
    }
  };

  // Changement au niveau des paramètres
  _handleGeneralParameterChange(event) {
    const { name, value } = event.target;

    this.setState({
      parameters: {
        ...this.state.parameters,
        [name]: {
          ...this.state.parameters[name],
          value,
        },
      },
    });
  }

  // Enregistrement du paramètre saisi/choisi
  _handleSaveParameter(key) {
    const value = this.state.parameters?.[key]?.value;

    if (value) {
      let isValid = true;
      let errorMessage = "";

      // Pour les paramètres de type MAIL
      if ([Constants.NOTIFICATION_MAIL_ADDRESS].includes(key)) {
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

        if (!isValid) {
          errorMessage = "Veuillez saisir une adresse mail valide";
        }
      }

      if (isValid) {
        this._updateParameter(key, value);
      } else {
        toast.error(errorMessage, Helper.getToastOptions());
      }
    } else {
      toast.error(
        "Veuillez saisir le paramètre avant de l'enregistrer",
        Helper.getToastOptions()
      );
    }
  }

  _handleRequestPlay(parameter) {
    if (typeof parameter.playData.apiFunction === "function") {
      this.setState({
        isPlaying: true,
        currentParameterToUpdate: parameter.key,
      });
      parameter.playData
        .apiFunction()
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            if (
              parameter.key === Constants.MAX_MONTH_COUNT_TO_DELETE_LOG &&
              typeof this.props.onRequestSetWithLogsUpdate === "function"
            ) {
              this.props.onRequestSetWithLogsUpdate(true);
            }

            toast.success("Opération terminée", Helper.getToastOptions());
          }

          this.setState({ isPlaying: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isPlaying: false });
        });
    }
  }

  render() {
    return (
      <article className="parameters">
        <h3>Paramètres généraux</h3>
        <section className="parameters-list card">
          {this.state.isLoading ? (
            <div className="parameters-item fullwidth">
              <TableSkeleton
                linesCount={
                  this.isOrganismTypeFsy
                    ? 1 + this.state.generalParameters.length
                    : 2
                }
                size="large"
              />
            </div>
          ) : (
            <>
              {(this.isPartner || this.isOrganism
                ? Session.getAuth()[Constants.PROFILE_PARAMETER] > 0
                : true) && (
                <>
                  {/** Création de compte depuis le simulateur */}
                  <div className="parameters-item">
                    <span>Création de compte depuis le simulateur</span>
                    <div className="parameters-switch">
                      <ActiveSwitch
                        objectActive={
                          this.state.parameters[
                            Constants.FRONT_ACCOUNT_CREATION
                          ].value
                        }
                        objectId={this.sessionUser.organization.id}
                        apiFunction={null}
                        onChange={this._handleAccountCreationChange}
                        idPrefix="front-account-creation"
                        className="parameter-active-switch"
                        disabled={
                          (this.state.currentParameterToUpdate ===
                            Constants.FRONT_ACCOUNT_CREATION &&
                            this.state.updatingParameters) ||
                          Session.getAuth()[Constants.PROFILE_PARAMETER] ===
                            RIGHTS_READ
                        }
                      />
                      <span>
                        {this.state.parameters[Constants.FRONT_ACCOUNT_CREATION]
                          .value
                          ? "Activée"
                          : "Désactivée"}
                      </span>
                    </div>
                  </div>

                  <Divider variant="middle" />
                </>
              )}

              {/** Les paramètres uniquement pour les organismes de type FSY */}
              {(this.isOrganismTypeFsy ||
                ((this.isOrganism || this.isPartner) &&
                  this.auth[Constants.PROFILE_PARAMETER] >
                    RIGHTS_NOACCESS)) && (
                <>
                  {this.state.generalParameters.map(
                    (generalParameter, index) => {
                      let value =
                        this.state.parameters[generalParameter.key].value;

                      // Loading au niveau du bouton d'enregistrement
                      let withLoading =
                        this.state.updatingParameters &&
                        this.state.currentParameterToUpdate ===
                          generalParameter.key;

                      // Loading au niveau du bouton de PLAY
                      let withPlaying =
                        this.state.isPlaying &&
                        this.state.currentParameterToUpdate ===
                          generalParameter.key;

                      let playDataTitle = "";

                      if (generalParameter.playData) {
                        playDataTitle = generalParameter.playData.title || "";

                        if (
                          generalParameter.key ===
                          Constants.MAX_MONTH_COUNT_TO_DELETE_LOG
                        ) {
                          playDataTitle = `Supprimer les logs de plus de ${value} mois`;
                          generalParameter.playData.apiFunction = () =>
                            Api.log.deleteLogsMoreThanGivenMonth(
                              value !== "" ? value : null
                            );
                        }
                      }

                      return (
                        <div
                          key={`GeneralParameter${generalParameter.key}${
                            index + 1
                          }`}
                          className="parameters-item fullwidth"
                        >
                          <span>{generalParameter.title}</span>
                          <div
                            className={`parameters-url parameters-percentage ${
                              generalParameter.playData ? "with-play" : ""
                            }`}
                          >
                            <InputField
                              className="input-tiny input-percentage"
                              type={
                                generalParameter.type === "number"
                                  ? "number"
                                  : "text"
                              }
                              name={generalParameter.key}
                              onChange={this._handleGeneralParameterChange}
                              center={true}
                              title={generalParameter.title}
                              value={value}
                              disabled={
                                withLoading ||
                                this.auth[Constants.PROFILE_PARAMETER] <
                                  RIGHTS_WRITE
                              }
                            />

                            {/** Si le paramètre a le bouton PLAY */}
                            {generalParameter.playData && (
                              <div
                                title={!withPlaying ? playDataTitle : ""}
                                className="play-button"
                                disabled={
                                  withPlaying ||
                                  this.auth[Constants.PROFILE_PARAMETER] <
                                    RIGHTS_WRITE
                                }
                                onClick={() =>
                                  this._handleRequestPlay(generalParameter)
                                }
                              >
                                {this.state.isPlaying &&
                                this.state.currentParameterToUpdate ===
                                  generalParameter.key ? (
                                  <CircularProgress color="success" size={15} />
                                ) : (
                                  <PlayCircleIcon />
                                )}
                              </div>
                            )}
                            <Button
                              aria-label="copy"
                              title={
                                generalParameter.buttonKey ??
                                "Enregistrer la valeur"
                              }
                              onClick={() =>
                                this._handleSaveParameter(generalParameter.key)
                              }
                              color="success"
                              size="small"
                              variant="outlined"
                              className="saving-button"
                              disabled={
                                withLoading ||
                                withPlaying ||
                                this.auth[Constants.PROFILE_PARAMETER] <
                                  RIGHTS_WRITE
                              }
                            >
                              {withLoading ? (
                                <CircularProgress color="success" size={15} />
                              ) : (
                                "Enregistrer"
                              )}
                            </Button>
                          </div>
                        </div>
                      );
                    }
                  )}
                  {!this.isOrganism && <Divider variant="middle" />}
                </>
              )}

              {/** Lien du simulateur */}
              {!this.isOrganism && (
                <div className="parameters-item fullwidth">
                  <span>Lien du simulateur</span>
                  <div className="parameters-url">
                    <InputField
                      className="input-tiny input-url"
                      name="urlFront"
                      value={this.state.url}
                      readOnly
                      title="Lien du simulateur associé à votre société"
                      forwardedRef={this.refLink}
                      isFocused={false}
                    />
                    <Button
                      aria-label="copy"
                      title="Copier le lien"
                      onClick={this._handleLinkCopy}
                      color="success"
                      size="small"
                      variant="outlined"
                    >
                      {this.state.urlCopyText}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </section>
      </article>
    );
  }
}
