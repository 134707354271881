import React from "react";
import Modal from "../form/Modal";

const ConfirmationDialog = ({
  elementLabel,
  isShowing,
  isElementBeginningWithLowerCase,
  isElementFeminine,
  onRequestHide,
  onRequestRemoveElement,
  title,
  content,
}) => {
  return (
    <Modal
      title={
        title ??
        `Confirmation de supression ${
          isElementBeginningWithLowerCase ? "d'" : "de "
        }${elementLabel}`
      }
      hide={onRequestHide}
      isShowing={isShowing}
    >
      <div className="flex mt-1">
        {content ?? (
          <span className="center">{`Etes-vous sûr de vouloir supprimer ce${
            isElementFeminine ? "tte" : ""
          } ${elementLabel} ?`}</span>
        )}
      </div>
      <div className="flex flex-column center modal-close-confirm">
        <div className="modal-footer flex">
          <button className="btn alert" onClick={onRequestRemoveElement}>
            Oui
          </button>
          <button className="btn" onClick={onRequestHide}>
            Non
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
