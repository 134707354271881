import { FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import ReactSwitch from "react-switch";
import { SelectField } from "../../general/form/Select";
import moment from "moment";
import ConfirmationDialog from "../../general/DeleteConfirmationDialog/DeleteConfirmationDialog";

const SimulatorInfo = ({
  loading,
  currentVersion,
  info,
  isPublishedVersion,
  versions,
  publishedVersion,
  simulatorInfoRef,
  withWriteAuth,
  onRequestChangeVersion,
  onRequestActivityButtonClick,
  onRequestCreateNewVersion,
  onRequestChangeSimulatorStatus,
  onRequestReinitVersion,
  onRequestSandboxTest,
}) => {
  const [state, setState] = useState({
    isModalOpened: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleModalDisplay = () => {
    _setState({ isModalOpened: !state.isModalOpened });
  };

  const _handleConfirm = () => {
    _handleModalDisplay();
    onRequestReinitVersion();
  };

  return (
    <div ref={simulatorInfoRef} className="simulator-info">
      <ConfirmationDialog
        isShowing={state.isModalOpened}
        title="Confirmation d'annulation des modifications depuis la dernière version publiée"
        content="Vous allez annuler toutes les modifications depuis la dernière version publiée, confirmez-vous ?"
        onRequestHide={_handleModalDisplay}
        onRequestRemoveElement={_handleConfirm}
      />
      <div className="simulator-info-content">
        <div className="status-content">
          <span>Etat :</span>
          <ReactSwitch
            className="flex-input"
            checked={info.published}
            onColor="#a6D290"
            offColor="#FC9999"
            disabled={!withWriteAuth}
            onChange={onRequestChangeSimulatorStatus}
          />
          <span>{info.published ? "Publiée" : "Non publiée"}</span>
        </div>
        <div className="forms-content">
          <FormControl
            className="published-version-form"
            fullWidth
            size="small"
          >
            <label>Version publiée</label>
            <TextField
              id="outlined-basic"
              value={publishedVersion ? `Version ${publishedVersion}` : ""}
              name="publishedVersion"
              disabled={true}
              variant="outlined"
              size="small"
            />
          </FormControl>

          <FormControl className="current-version-form" fullWidth size="small">
            <label>Version affichée</label>
            <SelectField
              options={versions}
              value={currentVersion}
              isMulti={false}
              placeholder=""
              closeMenuOnSelect={true}
              onChange={onRequestChangeVersion}
            />
          </FormControl>
        </div>
        {isPublishedVersion ? (
          <button
            className={`btn btn-tiny default ${
              loading ? "btn-version-disabled" : ""
            }`}
            onClick={onRequestCreateNewVersion}
            disabled={loading}
          >
            Créer une nouvelle version
          </button>
        ) : (
          <button
            className={`btn btn-tiny default ${
              !withWriteAuth || loading ? "btn-version-disabled" : ""
            }`}
            onClick={_handleModalDisplay}
            disabled={!withWriteAuth || loading}
          >
            Repartir de la version publiée
          </button>
        )}
        <div className="info-content">
          <div className="info-item">
            <div>Crée par</div>
            <div>{`${info.createdBy?.name || ""} ${
              info.createdBy?.surname?.toUpperCase() || "-"
            }`}</div>
          </div>
          <div className="info-item">
            <div>Date de publication</div>
            <div>
              {info.createdAt
                ? moment(info.createdAt).format("DD/MM/YYYY")
                : "-"}
            </div>
          </div>
          <div className="info-item">
            <div>Date de modification</div>
            <div>
              {info.updatedAt
                ? moment(info.updatedAt).format("DD/MM/YYYY")
                : "-"}
            </div>
          </div>
        </div>
        <div className="buttons-content">
          <button
            className={`btn btn-tiny default ${loading ? "disabled" : ""}`}
            onClick={onRequestSandboxTest}
          >
            Prévisualiser
          </button>
          <button
            className={`btn btn-tiny default ${loading ? "disabled" : ""}`}
            onClick={onRequestActivityButtonClick}
          >
            Activités
          </button>
        </div>
      </div>
    </div>
  );
};

export default SimulatorInfo;
