import React from 'react'
import DataLayout from '../layout/DataLayout'

const AidComplexity = () => {
    return (
        <DataLayout
            title="Complexités d'aides"
            type='aid'
            partialTitleOnCreateButtonHover="complexité d'aide"
            operationName='getAidComplexities'
            countOperationName='getAidComplexityCount'
            deleteOperationName='deleteReferenceData'
            formName='aid-complexity'
            isFeminine={true}
            checkingCanBeDeletedOperationName="canBeDeleted"
        />
    )
}

export default AidComplexity