import React from "react";
import moment from "moment";
import { Divider } from "@mui/material";

const SimulatorActivities = ({ contentHeight, activities }) => {
  return (
    <div className="simulator-activities" style={{ maxHeight: contentHeight }}>
      {activities.map((activity, index) => {
        return (
          <>
            <div
              key={`SimulatorActivity${index + 1}`}
              className="activities-item flex-start align-items-start"
            >
              <div className="activities-user">{activity.user}</div>
              <div className="activities-others-infos flex-start align-items-start flex-column">
                <span className="activities-action">{activity.action}</span>
                <span className="activities-date">
                  {moment(activity.date).format("DD/MM/YYYY HH:mm")}
                </span>
              </div>
            </div>
            <Divider />
          </>
        );
      })}
    </div>
  );
};

export default SimulatorActivities;
