import React from "react";
import { Api, Constants, Session } from "fsy.common-library";
import { Link } from "react-router-dom";
import { routes as Routing } from "../../../services/RoutesHelper";
import {
  // Tooltip,
  Badge,
  IconButton,
  Paper,
  Divider,
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  DeleteOutline as DeleteOutlineIcon,
  Person as PersonIcon,
  Logout as LogoutIcon,
  Notifications,
  Check as CheckIcon,
} from "@mui/icons-material";
import Helper from "../../../services/Helper";
import { toast } from "react-toastify";
import moment from "moment";
import "./userMenu.css";
import Profile from "./../../profile/Profile";

export default class UserMenu extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    const user = Session.getSessionUser();

    this.COOKIE_NAME = "lsn";
    this.TOOLTIP_COOKIE_NAME = "lsn_info";

    this.state = {
      isAuthenticated: Session.isLoggedIn(),
      userNotification: [],
      userNotificationCount: 0,
      showNotifications: false,
      user: user,
      displayTooltip: false,
      isProfileInfoDialogOpened: false,
      isProfileInfoUpdated: false,
      currentNotificationId: null,
      readNotificationLoading: false,
      deleteNotificationLoading: false,
      allNotificationLoading: false,
    };
    this._handleNotificationsClick = this._handleNotificationsClick.bind(this);
    this._handleExpandClick = this._handleExpandClick.bind(this);
    this._getNotificationsList = this._getNotificationsList.bind(this);
    this._handleProfileInfoDialogDisplay =
      this._handleProfileInfoDialogDisplay.bind(this);
    this._handleProfileInfoUpdated = this._handleProfileInfoUpdated.bind(this);
    this._handleMarkNotificationAsRead =
      this._handleMarkNotificationAsRead.bind(this);
    this._handleDeleteNotification = this._handleDeleteNotification.bind(this);
    this._getUserNotifications = this._getUserNotifications.bind(this);
    this._handleMarkAllNotificationsAsRead =
      this._handleMarkAllNotificationsAsRead.bind(this);
  }

  /*
   * TODO: Sauvegarder en session le dernier id vu
   *  - Ajouter un bouton "marquer tout comme lu"
   *  - Ajouter un bouton "tout supprimer"
   *  - Les notifications lues sont supprimées tous les soirs à minuit
   *  - Par défaut on affiche uniquement les 10 dernières notifications
   *  - Ajouter un bouton "voir tout" ?
   */

  componentDidMount() {
    this._getUserNotifications();
  }

  _getUserNotifications() {
    Api.userNotification
      .getNotificationOfUser(this.state.user.id)
      .then((response) => {
        const resultObject = Helper.isValidResponse(response);

        if (resultObject) {
          this.setState({
            userNotification: resultObject,
            userNotificationCount: resultObject.length,
          });
          let lastSeenNotification = Session.getCookie(this.COOKIE_NAME)?.id;
          const lastNotificationGet = resultObject[0]?.id;

          if (
            lastSeenNotification === null ||
            lastSeenNotification === undefined
          ) {
            lastSeenNotification = 0;
            Session.setCookie(this.COOKIE_NAME, { id: 0 }, 365);
          }
          if (
            lastSeenNotification < lastNotificationGet &&
            Session.getCookie(this.TOOLTIP_COOKIE_NAME)?.id !==
              lastNotificationGet
          ) {
            this.setState({ displayTooltip: true });
            Session.setCookie(
              this.TOOLTIP_COOKIE_NAME,
              { id: lastNotificationGet },
              365
            );
          }
        }

        if (response?.status !== 200) {
          toast.warning(
            "Oops ! Un problème est survenu lors de la récupération de vos notifications",
            Helper.getToastOptions()
          );
        }
      });
  }

  _handleNotificationsClick() {
    this.setState((prevState) => {
      return { showNotifications: !prevState.showNotifications };
    });
  }

  _handleExpandClick() {
    console.log("expand !");
  }

  _handleMarkNotificationAsRead(notificationId) {
    this.setState({
      readNotificationLoading: true,
      currentNotificationId: notificationId,
    });

    Api.userNotification
      .markAsReadUserNotification(notificationId)
      .then((response) => {
        const result = Helper.isValidResponse(response);
        const data = {
          readNotificationLoading: false,
          currentNotificationId: null,
        };

        if (result) {
          this._getUserNotifications();
        }

        this.setState(data);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ readNotificationLoading: false });
      });
  }

  _handleMarkAllNotificationsAsRead() {
    if (this.state.userNotification?.length > 0) {
      this.setState({
        allNotificationLoading: true,
      });
      const ids = this.state.userNotification.map((element) => {
        return element.id;
      });
      Api.userNotification
        .markAsReadUserNotifications(ids)
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            this._getUserNotifications();
          }

          this.setState({ allNotificationLoading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ allNotificationLoading: false });
        });
    }
  }

  _handleDeleteNotification(notificationId) {
    this.setState({
      deleteNotificationLoading: true,
      currentNotificationId: notificationId,
    });

    Api.userNotification
      .deleteUserNotification(notificationId)
      .then((response) => {
        const data = {
          deleteNotificationLoading: false,
          currentNotificationId: null,
        };

        if (response?.status === Constants.HTTP_NO_CONTENT) {
          this._getUserNotifications();
        }

        this.setState(data);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ deleteNotificationLoading: false });
      });
  }

  _getNotificationsList() {
    let notifs = [];
    for (const notif of this.state.userNotification) {
      notifs.push(
        <div
          className={`notification-item w-100 ${!notif.seen && "unread"}`}
          key={notif.id}
        >
          <Link
            to={notif.path}
            title="Cliquez pour accéder à la page concernée"
          >
            <div className="notification-item-text">
              <span className="notification-item-content">{notif.content}</span>
              <span className="notification-item-date">
                {moment(notif.createdAt).format("DD-MM-YYYY HH:mm")}
              </span>
            </div>
          </Link>
          <div className="notification-item-icon" title="Marquer comme lu">
            {this.state.readNotificationLoading &&
            this.state.currentNotificationId === notif.id ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              <IconButton
                disabled={notif.seen}
                onClick={() => this._handleMarkNotificationAsRead(notif.id)}
              >
                <CheckIcon />
              </IconButton>
            )}
          </div>
          <div
            className="notification-item-icon"
            title="Supprimer la notification"
            onClick={() => this._handleDeleteNotification(notif.id)}
          >
            {this.state.deleteNotificationLoading &&
            this.state.currentNotificationId === notif.id ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              <DeleteOutlineIcon />
            )}
          </div>
        </div>
      );
    }
    return notifs;
  }

  _handleProfileInfoDialogDisplay() {
    this.setState({
      isProfileInfoDialogOpened: !this.state.isProfileInfoDialogOpened,
      isProfileInfoUpdated: false,
    });
  }

  _handleProfileInfoUpdated(value) {
    this.setState({
      isProfileInfoUpdated: value,
    });
  }

  render() {
    const unseenNotificationCount =
      this.state.userNotification?.filter((element) => !element.seen)?.length ||
      0;

    return (
      <aside className="userMenu">
        <Profile
          isShowing={this.state.isProfileInfoDialogOpened}
          isProfileInfoUpdated={this.state.isProfileInfoUpdated}
          onRequestHide={this._handleProfileInfoDialogDisplay}
          onRequestProfileInfoUpdated={this._handleProfileInfoUpdated}
          onRequestUpdateUsername={this.props.onRequestUpdateUsername}
        />
        <div
          className="userMenu-icon"
          title="Accéder à votre profil"
          onClick={this._handleProfileInfoDialogDisplay}
        >
          <Avatar
            alt={this.state.user.name}
            src="/avatar.png"
            variant="rounded"
          >
            <PersonIcon />
          </Avatar>
        </div>
        <div className="userMenu-account">
          <span>Hello </span>
          <span>{this.props.username}</span>
        </div>
        <div
          className="userMenu-notification"
          title="Afficher les notifications"
        >
          <Badge
            className={`notification-badge ${
              unseenNotificationCount > 0 && "hasNotif"
            }`}
            color="warning"
            badgeContent={unseenNotificationCount}
            max={10}
            onClick={this._handleNotificationsClick}
          >
            <Notifications />
          </Badge>
          {this.state.showNotifications && (
            <Paper elevation={1} className="notification-list">
              <div className="notification-list-header flex-sb">
                <span>Notifications</span>
                {this.state.userNotification?.length > 0 && (
                  <IconButton
                    aria-label="Tout marquer comme lu"
                    title="Tout marquer comme lu"
                    disabled={this.state.allNotificationLoading}
                    onClick={this._handleMarkAllNotificationsAsRead}
                  >
                    {this.state.allNotificationLoading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : (
                      <CheckIcon />
                    )}
                  </IconButton>
                )}
              </div>
              <Divider />
              <div className="notification-list-content">
                {this._getNotificationsList()}
              </div>
              <Divider />
            </Paper>
          )}
        </div>
        <Button
          href={Routing.app_logout}
          title="Déconnexion"
          className="userMenu-logout"
          color="error"
          variant="contained"
          size="large"
          disableElevation
        >
          <LogoutIcon />
        </Button>
      </aside>
    );
  }
}
