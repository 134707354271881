import { Alert } from "@mui/material";
import React from "react";
import _ from "lodash";

const AidsCausingBlock = ({ message, aids }) => {
  return (
    <div className="aids-with-error-content">
      <div className="alert-message">
        <Alert severity="error">{message}</Alert>
      </div>
      <ul>
        {_.map(aids, (aid) => {
          return (
            <li key={aid.id}>
              <a href={`/aids?id=${aid.id}`} target="_blank" rel="noreferrer">
                {aid.title}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AidsCausingBlock;
