import React from 'react'
import DataLayout from '../layout/DataLayout'

const AidScheme = () => {
    return (
        <DataLayout
            title="Dispositifs"
            type='aid'
            partialTitleOnCreateButtonHover="dispositif d'aide"
            operationName='getAidSchemes'
            countOperationName='getAidSchemeCount'
            deleteOperationName='deleteReferenceData'
            formName='aid-scheme'
            isFeminine={false}
            checkingCanBeDeletedOperationName="canBeDeleted"
        />
    )
}

export default AidScheme