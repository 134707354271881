import { Constants, Session } from "fsy.common-library";
import HtmlStructure from "../general/HtmlStructure";
import UserMenu from "./UserMenu/UserMenu";
import Activities from "./Activities/Activities";
import AdvancedStats from "./AdvancedStats/AdvancedStats";
import { GeneralParameters } from "./GeneralParameters/GeneralParameters";
import { useDate } from "../../hooks/customHooks";
import { routes as Routing } from "../../services/RoutesHelper";
import { Link } from "react-router-dom";

import "./dashboard.css";
import {
  AssignmentLate,
  Help,
  Settings,
  SupportAgent,
} from "@mui/icons-material";
import { useEffect, useState } from "react";

export default function Dashboard(props) {
  const allAuth = Session.getAuth();
  const userOrganization = Session.getSessionUser()?.organization;
  const isOrganism = userOrganization?.organism;
  const isPartner = userOrganization?.partner;
  const isOther = userOrganization?.other;
  const isFransylva = !isOrganism && !isPartner && !isOther;
  document.title = `Dashboard - ${Constants.DOCUMENT_TITLE_BACKOFFICE}`;

  const [withLogsUpdate, setWithLogsUpdate] = useState(false);
  const [withParameters, setWithParameters] = useState(true);

  const handleSetWithLogsUpdate = (status) => {
    setWithLogsUpdate(status);
  };

  useEffect(() => {
    let status = isFransylva || isPartner;

    if (isOrganism) {
      status = allAuth[Constants.PROFILE_PARAMETER] > 0;
    }

    setWithParameters(status);
    // eslint-disable-next-line
  }, []);

  return (
    <HtmlStructure menuName="Dashboard" sectionClassName="dashboard" auth="all">
      <DateTime />
      <UserMenu
        username={props.username}
        onRequestUpdateUsername={props.onRequestUpdateUsername}
      />
      <AdvancedStats />
      <Activities
        withLogsUpdate={withLogsUpdate}
        onRequestSetWithLogsUpdate={handleSetWithLogsUpdate}
      />
      <div className="dashboard-rightPanel">
        {withParameters && (
          <GeneralParameters
            onRequestSetWithLogsUpdate={handleSetWithLogsUpdate}
          />
        )}
        <Helpcenter />
      </div>
    </HtmlStructure>
  );
}

function DateTime() {
  const [currentDate] = useDate(10000); // 10s. interval
  return (
    <>
      <div className="dashboard-datetime">
        <span className="dashboard-time">{currentDate.format("HH:mm")}</span>
        <span className="dashboard-date">
          {currentDate.format("dddd DD MMMM YYYY")}
        </span>
      </div>
    </>
  );
}

function Helpcenter() {
  return (
    <>
      <div className="dashboard-help">
        <h3>Centre d'aide</h3>
        <div>
          <Link to={Routing.bo_dashboard} title="Accéder à l'aide">
            <Help />
            <span>Aide</span>
          </Link>
          <Link to={Routing.bo_dashboard} title="Contacter le support">
            <SupportAgent />
            <span>Support</span>
          </Link>
          <Link to={Routing.bo_dashboard} title="Voir le changelog">
            <AssignmentLate />
            <span>Changelog</span>
          </Link>
          <Link to={Routing.bo_dashboard} title="Accéder aux paramètres">
            <Settings />
            <span>Paramètres</span>
          </Link>
        </div>
      </div>
    </>
  );
}
