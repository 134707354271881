import {createTheme} from "@mui/material";
import {green} from "@mui/material/colors";

const fsyTheme = createTheme({
    palette: {
        primary: {
            main: green[500],
        },
    },
    typography: {
        fontFamily: [
            'Montserrat', 'Raleway', 'Open Sans', 'Roboto', 'sans-serif'
        ].join(','),
        fontSize: 11,
    },
});


export default fsyTheme