import React from "react"

import "./questionForm.css"

export default class QuestionForm extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {

        return <article className="stats">

            <section className="bo-data-title">
                <h3>Formulaire questions</h3>
            </section>

            <section className="card">
                <h1>W.I.P</h1>
            </section>
        </article>
    }
}

/* ================================== GLOBAL FUNCTIONS ================================== */
