import React from "react";
import "./simulationView.css";
import Grid from "@mui/material/Unstable_Grid2";
import { Api, Constants } from "fsy.common-library";
import Helper from "../../../services/Helper";
import _ from "lodash";
import moment from "moment/moment";
import "moment/locale/fr";
import { Loading } from "../../general/form/Loading";
import { Link } from "react-router-dom";
import QuestionHelper from "fsy.common-library/lib/helpers/QuestionHelper";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";
import ProposedAid from "./ProposedAid";

class QuestionAnswerComponent extends React.Component {
  render() {
    return (
      <div>
        <div>
          <i>{this.props.stepName.toUpperCase()}</i>
        </div>
        <ul style={{ listStyle: "none", padding: "0 0 0 1.25rem" }}>
          {_.map(this.props.questionsAnswer, (questionAnswer, i) => {
            return (
              <li key={i} title={questionAnswer.question}>
                {questionAnswer.title} - <b>{questionAnswer.answer}</b>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default class SimulationView extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      simulation: null,
      answersByStep: [],
      loading: true,
      nbAnswer: 0,
      proposedAids: [],
    };

    this.getSimulationSate = this.getSimulationSate.bind(this);
    this.getDownloadedPdfLabel = this.getDownloadedPdfLabel.bind(this);
    this.initSimulation = this.initSimulation.bind(this);
  }

  componentDidMount() {
    this.initSimulation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.simulation.id !== this.props.simulation.id) {
      this.initSimulation();
    }

    if (this.state.simulation?.id !== prevState.simulation?.id) {
      const aids = this.state.simulation?.suggestedAids?.map((data) => {
        return {
          ...data,
          name: data.aid.name, // Ceci est uniquement dans le but de ranger par ordre alphabétique dans les diverses hiérarchies possibles
        };
      });

      // Rangement des aides par ordre alphabétique selon le nom
      aids.sort((a, b) => {
        let name1 = a.name;
        let name2 = b.name;

        return name1.localeCompare(name2, "fr", { sensitivity: "base" });
      });

      let folders = aids?.filter((element) => element.aid.folder !== null);

      folders = folders?.map((element) => {
        let value = { id: null, name: "" };

        // Si c'est un dossier
        if (!element.aid.folder.folder) {
          value = {
            id: element.aid.folder.id,
            name: element.aid.folder.name,
          };
        } else {
          // Pour un sous-dossier
          value = {
            id: element.aid.folder.folder.id,
            name: element.aid.folder.folder.name,
          };
        }

        return value;
      });

      // On élimine les éventuels doublons
      folders = folders.filter(
        (folder, index, self) =>
          index === self.findIndex((o) => o.id === folder.id)
      );

      const subfolders = aids
        ?.filter(
          (element) =>
            element.aid.folder !== null && element.aid.folder.folder !== null
        )
        ?.map((element) => {
          return {
            id: element.aid.folder.id,
            name: element.aid.folder.name,
            parent: {
              id: element.aid.folder.folder.id,
              name: element.aid.folder.folder.name,
            },
          };
        });

      // Les aides qui ne sont pas contenues dans aucun dossier
      const aidsOutOfFolder =
        aids?.filter((element) => element.aid.folder === null) || [];

      const elements = [];

      folders.map((folder) => {
        let subfoldersAndAids = [];

        subfolders
          ?.filter((element) => element.parent.id === folder.id)
          ?.map((subfolder) => {
            // On met ensemble les sous-dossiers d'un dossier ainsi que les aides du dit dossier pour faciliter le rangement par ordre alphabétique
            subfoldersAndAids.push({
              type: "subfolder",
              id: subfolder.id,
              name: subfolder.name,
              aids: aids?.filter(
                (element) => element.aid.folder?.id === subfolder.id
              ),
            });

            return null;
          });

        // Elimination des éventuels doublons
        subfoldersAndAids = subfoldersAndAids.filter(
          (data, index, self) =>
            index === self.findIndex((o) => o.id === data.id)
        );

        subfoldersAndAids.push(
          ...(aids?.filter((element) => element.aid.folder?.id === folder.id) ||
            [])
        );

        // Rangement par ordre alphabétique des sous-dossiers et aides contenus dans le dossier
        subfoldersAndAids.sort((a, b) => {
          let name1 = a.name;
          let name2 = b.name;

          return name1.localeCompare(name2, "fr", { sensitivity: "base" });
        });

        // On ajoute dans le dossier les sous-dossiers ainsi que les aides
        elements.push({
          type: "folder",
          name: folder.name,
          elements: subfoldersAndAids,
          aids: aids?.filter((element) => element.aid.folder?.id === folder.id),
        });

        return null;
      });

      // On ajoute dans elements, les aides qui ne sont pas contenues dans aucun dossier
      aidsOutOfFolder.map((aid) => {
        elements.push(aid);

        return null;
      });

      // On trie "elements" par ordre alphabétique selon le nom
      elements.sort((a, b) => {
        let name1 = a.name;
        let name2 = b.name;

        return name1.localeCompare(name2, "fr", { sensitivity: "base" });
      });

      this.setState({ proposedAids: elements });
    }
  }

  initSimulation() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Api.simulation
          .getMinimizedSimulationData(this.props.simulation.id)
          .then((response) => {
            const resultObject = Helper.isValidResponse(response);
            const answersByStep = resultObject.simulationAnswers
              .map((sa) => {
                return {
                  title: sa.question.title,
                  question: sa.question.questionText,
                  position: sa.question.position,
                  answer: this.getQuestionAnswer(sa, sa.question.criterion),
                  stepName: sa.question.step.name,
                };
              })
              .reduce((acc, curr) => {
                const key = curr.stepName;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(curr);
                return acc;
              }, {});

            for (const [key, value] of Object.entries(answersByStep)) {
              answersByStep[key] = value?.sort(
                (a, b) => a["position"] - b["position"]
              );
            }

            this.setState({
              loading: false,
              simulation: resultObject,
              answersByStep: answersByStep,
              nbAnswer: resultObject.simulationAnswers.length,
            });
          });
      }
    );
  }

  getQuestionAnswer(simulationAnswer, criterion) {
    const answer = simulationAnswer.value;
    if (simulationAnswer.type === Constants.CRITERION_TYPE_DTE) {
      return this.formatDateLine(answer.answer, true);
    }

    if (simulationAnswer.type === Constants.CRITERION_TYPE_NUM) {
      if (!answer.value && answer.answers) {
        return answer["answers"].map((a) => a.label).join(" / ");
      }

      return answer.value >= 0
        ? GenericHelper.digitWithDecimalPrecision(answer.value) +
            " " +
            criterion.unit
        : "-";
    }

    if (simulationAnswer.type === Constants.CRITERION_TYPE_TXT) {
      const result = criterion.criterionValues
        .filter((cv) => {
          if (criterion.multi) {
            if (!answer.answers) {
              return "-";
            }
            return answer.answers.includes(cv.id);
          } else {
            return cv.id === answer.answer;
          }
        })
        .map((cv) => cv.value);

      return result.join(", ");
    }

    if (simulationAnswer.type === Constants.CRITERION_TYPE_OBG) {
      return answer?.answer
        ? QuestionHelper.getObgText(simulationAnswer.question, answer.answer)
        : "-";
    }

    if (simulationAnswer.type === Constants.CRITERION_TYPE_LOC) {
      return answer.answer;
    }

    return "";
  }

  formatDateLine(date, short = false) {
    moment.locale("fr");
    const _d = moment(date);

    if (short) {
      return _d.format("DD/MM/YYYY");
    }

    return _d.format("DD/MM/YYYY HH:mm") + ` ( ${_d.fromNow()} )`;
  }

  getSimulationSate() {
    if (
      this.state.simulation.status === Constants.SIMULATION_STATUS_IN_PROGRESS
    ) {
      return <span className="state-progress">En cours</span>;
    }

    if (
      this.state.simulation.status === Constants.SIMULATION_STATUS_COMPLETED
    ) {
      return <span className="state-finished">Terminée</span>;
    }

    if (
      this.state.simulation.status === Constants.SIMULATION_STATUS_REGISTERED
    ) {
      return <span className="state-saved">Enregistrée</span>;
    }
  }

  getDownloadedPdfLabel() {
    if (this.state.simulation.downloadedPdf) {
      return <b className="text-success">Oui</b>;
    } else {
      return <b style={{ color: "var(--link-color)" }}>Non</b>;
    }
  }

  getAidAmount(amount, format = null) {
    if (!amount) {
      return "-";
    }

    const value = amount.value;

    if (_.isObject(value)) {
      if (value.min === 0 && value.max > 0) {
        return `Jusqu'à ${GenericHelper.digitWithDecimalPrecision(value.max)} ${
          amount.unit ?? "€"
        }`;
      }

      if (format) {
        format = format.replace(
          "%min%",
          `${GenericHelper.digitWithDecimalPrecision(value.min)} ${
            amount.unit ?? "€"
          }`
        );
        format = format.replace(
          "%max%",
          `${GenericHelper.digitWithDecimalPrecision(value.max)} ${
            amount.unit ?? "€"
          }`
        );
        return format;
      }

      return `Minimum: ${GenericHelper.digitWithDecimalPrecision(value.min)} ${
        amount.unit ?? "€"
      } - Maximum: ${GenericHelper.digitWithDecimalPrecision(value.max || 0)} ${
        amount.unit ?? "€"
      }`;
    } else {
      return value > 0
        ? `${GenericHelper.digitWithDecimalPrecision(value)} ${
            amount.unit ?? "€"
          }`
        : "Non calculable";
    }
  }

  render() {
    return (
      <div
        style={{ position: "relative", color: "black" }}
        className="simulator-view"
      >
        {this.state.loading && (
          <div className="w-100 h-100">
            <Loading></Loading>
          </div>
        )}
        {!this.state.loading && (
          <div style={{ padding: "0 1rem", position: "relative" }}>
            <h3>
              <i>Simulation</i>
            </h3>
            <div
              className="flex"
              style={{ alignItems: "start", padding: "0 2rem" }}
            >
              <table className="table">
                <tbody>
                  <tr>
                    <td>Date de début :</td>
                    <td>
                      {this.state.simulation.startedAt
                        ? this.formatDateLine(this.state.simulation.startedAt)
                        : "/"}
                    </td>
                  </tr>
                  <tr>
                    <td>Date de clôture :</td>
                    <td>
                      {this.state.simulation.endedAt
                        ? this.formatDateLine(this.state.simulation.endedAt)
                        : "/"}
                    </td>
                  </tr>
                  <tr>
                    <td>Utilisateur :</td>
                    <td>
                      {this.state.simulation.user &&
                      this.state.simulation.user.name ? (
                        <Link to={"/users?id=" + this.state.simulation.user.id}>
                          {this.state.simulation.user.name +
                            " " +
                            this.state.simulation.user.surname}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Via le simulateur de :</td>
                    <td>
                      {this.state.simulation.organization ? (
                        <Link
                          to={
                            "/organization?id=" +
                            this.state.simulation.organization.id
                          }
                        >
                          {this.state.simulation.organization.name}
                        </Link>
                      ) : (
                        "< Inconnu >"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table" style={{ marginLeft: "auto" }}>
                <tbody>
                  <tr>
                    <td>Etat :</td>
                    <td>{this.getSimulationSate()}</td>
                  </tr>
                  <tr>
                    <td>Libellé :</td>
                    <td>
                      {this.state.simulation.label
                        ? this.state.simulation.label
                        : "<Non modifée>"}
                    </td>
                  </tr>
                  <tr>
                    <td>Pdf téléchargé :</td>
                    <td>{this.getDownloadedPdfLabel()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            <div>
              <Grid container spacing={2}>
                <Grid xs={7}>
                  <h2>
                    Questions <small>({this.state.nbAnswer} réponses)</small>
                  </h2>

                  {_.map(
                    Object.keys(this.state.answersByStep),
                    (stepName, i) => {
                      return (
                        <QuestionAnswerComponent
                          key={stepName}
                          questionsAnswer={this.state.answersByStep[stepName]}
                          stepName={i + 1 + " - " + stepName}
                        />
                      );
                    }
                  )}
                </Grid>
                <Grid xs>
                  <h2>Aides proposées</h2>
                  {this.state.proposedAids.map((data, index) => {
                    if (data.type === "folder") {
                      return (
                        <div
                          key={`AidFolder${index + 1}`}
                          className="aid-folder-container"
                        >
                          <span className="bold">{data.name}</span>
                          <div className="subfolders-and-aids-content">
                            {data.elements.map((element, index) => {
                              let lastElement = data.elements?.slice(-1)[0];
                              let isNotLastElement =
                                lastElement.id !== element.id;

                              return element.type === "subfolder" ? (
                                <div
                                  key={`Subfolder${index + 1}`}
                                  className={
                                    isNotLastElement
                                      ? "sufbolder-container"
                                      : ""
                                  }
                                >
                                  <span className="bold">{element.name}</span>
                                  <div className="aids-in-subfolder-container">
                                    {element.aids?.map((aid, i) => {
                                      let lastAid = element.aids?.slice(-1)[0];
                                      let isNotLastAid =
                                        lastAid.aid.id !== aid.aid.id;

                                      return (
                                        <ProposedAid
                                          key={`AidInSubfolder${index + 1}${
                                            i + 1
                                          }`}
                                          value={aid}
                                          className={
                                            isNotLastAid
                                              ? "proposed-aid-in-subfolder"
                                              : ""
                                          }
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <ProposedAid
                                  key={`AidInFolder${index + 1}`}
                                  value={element}
                                  className={
                                    isNotLastElement
                                      ? "proposed-aid-in-folder"
                                      : ""
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <ProposedAid
                          key={`AidOutOfFolder${index + 1}`}
                          value={data}
                          className="proposed-aid-out-of-folder"
                        />
                      );
                    }
                  })}
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}
