import React, { useEffect, useState } from "react";
import Modal from "./../general/form/Modal";
import "./profile.css";
import { InputField } from "../general/form/Input";
import { Save } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import Helper from "../../services/Helper";
import { Api, Session } from "fsy.common-library";
import { Loading } from "../general/form/Loading";
import {
  HTTP_OK,
  HTTP_PASSWORD_TOO_WEAK,
  HTTP_WRONG_OLD_PASSWORD,
} from "fsy.common-library/lib/env/Constants";
import { useNavigate } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";

const Profile = ({
  isShowing,
  isProfileInfoUpdated,
  onRequestHide,
  onRequestProfileInfoUpdated,
  onRequestUpdateUsername,
}) => {
  const mandatoryText = "Champ obligatoire";

  const user = Session.getSessionUser();

  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: false,
    passwordLoading: false,
    basicInfo: {
      surname: "",
      name: "",
      email: "",
      emailConfirmation: "",
    },
    password: {
      oldValue: "",
      value: "",
      valueForConfirmation: "",
    },
    error: {
      basicInfo: {
        surname: false,
        email: false,
        emailConfirmation: "",
      },
      password: {
        oldValue: false,
        value: false,
        valueForConfirmation: false,
      },
    },
    isEmailUpdated: false,
    isEmailUpdatedForBehavior: false,
    isPasswordModalOpened: false,
    isPasswordInfoUpdated: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (event, type) => {
    const { name, value } = event.target;
    const stateObject = {};

    stateObject[type] = {
      ...state[type],
      [name]: value,
    };

    if (name === "email") {
      const savedEmail = user.email;

      stateObject.isEmailUpdated = value !== savedEmail;
      stateObject.isEmailUpdatedForBehavior = true;
    }

    if (type === "basicInfo") {
      onRequestProfileInfoUpdated(true);
    } else {
      stateObject.isPasswordInfoUpdated = true;
    }

    _setState(stateObject);
  };

  const _basicInfoFormsStatus = () => {
    return (
      state.basicInfo.surname &&
      state.basicInfo.email &&
      (state.isEmailUpdatedForBehavior
        ? state.basicInfo.emailConfirmation
        : true)
    );
  };

  const _passwordInfoFormsStatus = () => {
    return (
      state.password.oldValue &&
      state.password.value &&
      state.password.valueForConfirmation
    );
  };

  const _handleSaveBasicInfo = () => {
    const isBasicInfoCompleted = _basicInfoFormsStatus();

    if (isBasicInfoCompleted) {
      const isSameEmailAndEmailForConfirmation = state.isEmailUpdatedForBehavior
        ? state.basicInfo.email === state.basicInfo.emailConfirmation
        : true;

      _setState({
        error: {
          ...state.error,
          basicInfo: {
            surname: false,
            email: false,
            emailConfirmation: false,
          },
        },
      });

      if (isSameEmailAndEmailForConfirmation) {
        _setState({ loading: true });
        Api.user
          .updateUser(user.id, {
            surname: state.basicInfo.surname,
            name: state.basicInfo.name,
            email: state.basicInfo.email,
            withoutSendingMail: true,
          })
          .then((response) => {
            if (response?.status === HTTP_OK) {
              Session.setCookie("session", {
                ...user,
                surname: state.basicInfo.surname,
                name: state.basicInfo.name,
                email: state.basicInfo.email,
              });

              toast.success(
                "Informations mises à jour avec succès",
                Helper.getToastOptions()
              );

              onRequestProfileInfoUpdated(false);

              if (typeof onRequestUpdateUsername === "function") {
                onRequestUpdateUsername(
                  state.basicInfo.name || state.basicInfo.surname
                );
              }

              if (state.isEmailUpdated) {
                navigate(Routing.app_logout);
              }

              onRequestHide();
              _setState({
                basicInfo: {
                  surname: "",
                  name: "",
                  email: "",
                  emailConfirmation: "",
                },
              });
            } else {
              let message =
                response?.message ||
                "Une erreur est survenue pendant la mise à jour des informations";

              toast.error(message, Helper.getToastOptions());
            }

            _setState({
              loading: false,
              isEmailUpdated: false,
              isEmailUpdatedForBehavior: false,
            });
          })
          .catch((error) => {
            _setState({ loading: false });
            console.error(error);
          });
      } else {
        toast.error(
          "L'adresse email et celle de confirmation doivent être identiques",
          Helper.getToastOptions()
        );
      }
    } else {
      _setState({
        error: {
          ...state.error,
          basicInfo: {
            surname: state.basicInfo.surname === "",
            email: state.basicInfo.email === "",
            emailConfirmation: state.basicInfo.emailConfirmation === "",
          },
        },
      });
      toast.error(
        "Veuillez renseigner tous les champs obligatoires avant d'enregistrer",
        Helper.getToastOptions()
      );
    }
  };

  const _handleSavePassword = () => {
    const isPasswordInfoCompleted = _passwordInfoFormsStatus();

    if (isPasswordInfoCompleted) {
      const isNewPasswordEqualsToItsConfirmation =
        state.password.value === state.password.valueForConfirmation;

      if (isNewPasswordEqualsToItsConfirmation) {
        _setState({
          passwordLoading: true,
          error: {
            ...state.error,
            password: {
              oldValue: false,
              value: false,
              valueForConfirmation: false,
            },
          },
        });

        Api.user
          .resetUserPassword(
            user.id,
            state.password.value,
            state.password.valueForConfirmation,
            state.password.oldValue
          )
          .then((response) => {
            const stateObject = {
              passwordLoading: false,
            };

            if (response?.status !== HTTP_OK) {
              let message =
                "Une erreur est survenue lors de la mise à jour du mot de passe";
              let duration = 2000;

              if (response?.status === HTTP_PASSWORD_TOO_WEAK) {
                message =
                  "Le mot de passe doit contenir au moins, une lettre en majuscule, une lettre en minuscule, un chiffre et un caractère spécial";
                duration = 5000;
              }

              if (response?.status === HTTP_WRONG_OLD_PASSWORD) {
                message = "Ancien mot de passe incorect";
              }

              toast.error(message, Helper.getToastOptions(duration));
            } else {
              stateObject.password = {
                oldValue: "",
                value: "",
                valueForConfirmation: "",
              };

              toast.success(
                "Mot de passe mis à jour avec succès",
                Helper.getToastOptions()
              );

              stateObject.isPasswordInfoUpdated = false;
              stateObject.isPasswordModalOpened = false;
            }

            _setState(stateObject);
            onRequestHide();
          })
          .catch((error) => {
            _setState({ passwordLoading: false });
            console.error(error);
          });
      } else {
        toast.error(
          "Le nouveau mot de passe et celui de confirmation doivent être identiques",
          Helper.getToastOptions()
        );
      }
    } else {
      _setState({
        error: {
          ...state.error,
          password: {
            oldValue: state.password.oldValue === "",
            value: state.password.value === "",
            valueForConfirmation: state.password.valueForConfirmation === "",
          },
        },
      });

      toast.error(
        "Veuillez renseigner tous les champs obligatoires avant d'enregistrer le nouveau mot de passe",
        Helper.getToastOptions(5000)
      );
    }
  };

  const _handleHideModal = () => {
    _setState({
      basicInfo: {
        surname: "",
        name: "",
        email: "",
      },
      error: {
        ...state.error,
        basicInfo: {
          surname: false,
          email: false,
        },
      },
    });

    if (typeof onRequestHide === "function") {
      onRequestHide();
    }
  };

  const _handleHidePasswordModal = () => {
    _setState({
      isPasswordModalOpened: !state.isPasswordModalOpened,
      isPasswordInfoUpdated: false,
      password: {
        oldValue: "",
        value: "",
        valueForConfirmation: "",
      },
      error: {
        ...state.error,
        password: {
          oldValue: false,
          value: false,
          valueForConfirmation: false,
        },
      },
    });
  };

  const _handlePasswordModalDisplay = () => {
    _setState({
      isPasswordModalOpened: !state.isPasswordModalOpened,
      isPasswordInfoUpdated: false,
    });
  };

  useEffect(() => {
    if (isShowing && user?.id) {
      _setState({ loading: true });

      Api.user
        .getUser(user.id)
        .then((response) => {
          const result = Helper.isValidResponse(response);
          const stateObject = { loading: false };

          if (result) {
            stateObject.basicInfo = {
              surname: result.surname || "",
              name: result.name || "",
              email: result.email || "",
              emailConfirmation: "",
            };
          }

          _setState(stateObject);
        })
        .catch((error) => {
          console.error(error);
          _setState({ loading: false });
        });
    }
    // eslint-disable-next-line
  }, [isShowing]);

  return (
    <Modal
      title="Profil Utilisateur"
      isShowing={isShowing}
      hide={_handleHideModal}
      confirm={isProfileInfoUpdated}
      saveBeforeClose={_handleSaveBasicInfo}
    >
      <div className="user-profile-root">
        {state.loading && <Loading />}
        <div className="basic-info-content">
          {/** Nom */}
          <div>
            <InputField
              required={true}
              name="surname"
              value={state.basicInfo.surname}
              isFocused={state.basicInfo.surname !== ""}
              invalid={state.error.basicInfo.surname}
              onChange={(event) => _handleFormChange(event, "basicInfo")}
            >
              Nom
            </InputField>
            {state.error.basicInfo.surname && (
              <div className="error">{mandatoryText}</div>
            )}
          </div>

          {/** Prénom(s) */}
          <InputField
            name="name"
            value={state.basicInfo.name}
            isFocused={state.basicInfo.name !== ""}
            onChange={(event) => _handleFormChange(event, "basicInfo")}
          >
            Prénom(s)
          </InputField>

          {/** Adresse Email */}
          <div>
            <InputField
              required={true}
              name="email"
              type="email"
              value={state.basicInfo.email}
              isFocused={state.basicInfo.email !== ""}
              invalid={state.error.basicInfo.email}
              onChange={(event) => _handleFormChange(event, "basicInfo")}
            >
              Adresse Email
            </InputField>
            {state.isEmailUpdated && (
              <div className="warning">
                La modification de l'adresse email entraine une déconnexion
                automatique après enregistrement.
              </div>
            )}
            {state.error.basicInfo.email && (
              <div className="error">{mandatoryText}</div>
            )}
          </div>

          {/** Confirmation de l'adresse Email */}
          <div>
            <InputField
              required={true}
              name="emailConfirmation"
              type="email"
              value={state.basicInfo.emailConfirmation}
              isFocused={false}
              invalid={state.error.basicInfo.emailConfirmation}
              onChange={(event) => _handleFormChange(event, "basicInfo")}
              disabled={!state.isEmailUpdatedForBehavior}
              readOnly={!state.isEmailUpdatedForBehavior}
              onPaste={(event) => event.preventDefault()}
            >
              Confirmation de l'adresse Email
            </InputField>
            {state.error.basicInfo.emailConfirmation && (
              <div className="error">{mandatoryText}</div>
            )}
            {state.isEmailUpdatedForBehavior && state.basicInfo.email && (
              <div
                className={
                  state.basicInfo.email === state.basicInfo.emailConfirmation
                    ? "same-address"
                    : "different-address"
                }
              >
                {`Adresses Email ${
                  state.basicInfo.email === state.basicInfo.emailConfirmation
                    ? "identiques"
                    : "différentes"
                }`}
              </div>
            )}
          </div>
          <button
            className="btn default btn-lg"
            type="submit"
            title="Enregistrer les données saisies"
            onClick={_handleSaveBasicInfo}
          >
            <Save />
            Enregistrer
          </button>
        </div>

        <Divider className="profile-info-divider" />

        {/** Contenu de bouton permettant d'afficher la fenêtre de changement de mot de passe */}
        <div className="password-info-content">
          <button
            className="btn default btn-lg"
            type="submit"
            title="Changer le mot de passe"
            onClick={_handlePasswordModalDisplay}
          >
            Changer le mot de passe
          </button>
        </div>
      </div>

      {/** Modal pour le changement de mot de passe */}
      <Modal
        title="Changement de mot de passe"
        isShowing={state.isPasswordModalOpened}
        hide={_handleHidePasswordModal}
        confirm={state.isPasswordInfoUpdated}
        saveBeforeClose={_handleSavePassword}
      >
        {state.passwordLoading && <Loading />}
        <div className="user-profile-root">
          <div className="password-info-content">
            <div>
              <InputField
                type="password"
                required={true}
                name="oldValue"
                value={state.password.oldValue}
                isFocused={state.password.oldValue !== ""}
                onChange={(event) => _handleFormChange(event, "password")}
              >
                Ancien mot de passe
              </InputField>
              {state.error.password.oldValue && (
                <div className="error">{mandatoryText}</div>
              )}
            </div>

            {/** Nouveau mot de passe */}
            <div>
              <InputField
                type="password"
                required={true}
                name="value"
                value={state.password.value}
                isFocused={state.password.value !== ""}
                onChange={(event) => _handleFormChange(event, "password")}
              >
                Nouveau mot de passe
              </InputField>
              {state.error.password.value && (
                <div className="error">{mandatoryText}</div>
              )}
            </div>

            {/** Confirmation du nouveau mot de passe */}
            <div>
              <InputField
                type="password"
                required={true}
                name="valueForConfirmation"
                value={state.password.valueForConfirmation}
                isFocused={state.password.valueForConfirmation !== ""}
                onChange={(event) => _handleFormChange(event, "password")}
              >
                Confirmation du nouveau mot de passe
              </InputField>
              {state.error.password.valueForConfirmation && (
                <div className="error">{mandatoryText}</div>
              )}
            </div>

            <button
              className="btn default btn-lg"
              type="submit"
              title="Changer le mot de passe"
              onClick={_handleSavePassword}
            >
              <Save />
              Enregistrer
            </button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default Profile;
