import React from 'react'
import DataLayout from '../layout/DataLayout'

const AidNature = () => {
    return (
        <DataLayout
            title="Natures d'aides"
            type='aid'
            partialTitleOnCreateButtonHover="nature d'aide"
            operationName='getAidNatures'
            countOperationName='getAidNatureCount'
            deleteOperationName='deleteReferenceData'
            formName='aid-nature'
            isFeminine={true}
            checkingCanBeDeletedOperationName="canBeDeleted"
        />
    )
}

export default AidNature