import React, { useEffect, useRef, useState } from "react";
import "./questionForm2.css";
import { InputField } from "../../../general/form/Input";
import ReactSwitch from "react-switch";
import { SelectField } from "../../../general/form/Select";
import { Save } from "@mui/icons-material";
import { Loading } from "../../../general/form/Loading";
import { Api } from "fsy.common-library";
import Helper from "../../../../services/Helper";
import { toast } from "react-toastify";
import Textarea from "../../../general/form/Textarea";
import { Divider, FormControlLabel, Radio } from "@mui/material";
import {
  CRITERION_TYPE_NUM,
  CRITERION_TYPE_OBG,
  CRITERION_TYPE_TXT,
} from "fsy.common-library/lib/env/Constants";
import QuestionDependency from "./QuestionDependency";

const QuestionForm = ({
  currentStep,
  withWriteAuth,
  questionId,
  steps,
  savingButtonRef,
  currentStepId,
  onRequestSuccessSaving,
  onRequestModalModified,
}) => {
  const readOnly = !withWriteAuth;

  const _getDefaultQuestionData = () => {
    return {
      title: "",
      position: null,
      mandatory: true,
      questionText: "",
      criterions: [],
      criteria: null,
      possibleAnswer1: {
        value: "Oui",
        mandatory: true,
      },
      possibleAnswer2: {
        value: "Non",
        mandatory: false,
      },
      questionDependencies: [],
      criterionValues: {},
      availableAnswers: {},
    };
  };

  const _getQuestionDependencies = (
    dependencies,
    values,
    availableAnswers,
    linkedCriteria
  ) => {
    const elements = [];
    dependencies.map((dependencie) => {
      let data = {
        type: dependencie.value.criterion.type,
        value: {},
        valueMin: null,
        valueMax: null,
        availableAnswers: null,
        target: {
          id: dependencie.value.value,
        },
      };

      let currentValues = values[dependencie.value.value];

      if (dependencie.value.criterion.type === CRITERION_TYPE_TXT) {
        data.value = { answers: currentValues };

        if (linkedCriteria === CRITERION_TYPE_TXT) {
          let currentAvailablesAnswers =
            availableAnswers[dependencie.value.value];

          if (currentAvailablesAnswers) {
            Object.entries(currentAvailablesAnswers).forEach(([key, val]) => {
              if (val?.length > 0) {
                if (data.availableAnswers === null) {
                  data.availableAnswers = [];
                }

                data.availableAnswers.push({
                  answers: [Number(key)],
                  value: val,
                });
              }
            });
          }
        }
      }

      if (dependencie.value.criterion.type === CRITERION_TYPE_NUM) {
        data.value = { min: currentValues.min, max: currentValues.max };
        data.valueMin = currentValues.min;
        data.valueMax = currentValues.max;
      }

      if (dependencie.value.criterion.type === CRITERION_TYPE_OBG) {
        data.value = { answer: currentValues[0] };
      }

      elements.push(data);

      return null;
    });

    return elements;
  };

  const _getDependenciesElements = (questionDependencies, linkedCriteria) => {
    const data = {
      questionDependencies: [],
      criterionValues: {},
      availableAnswers: {},
    };

    questionDependencies?.map((questionDependencie, index) => {
      // Les dépendances de question
      let q = questionDependencie.target;
      let criterion = q.criterion;
      let type = criterion.type.shortName;

      data.questionDependencies.push({
        id: index + 1,
        value: {
          value: q.id,
          label: `${criterion.name} - ${q.title} (${criterion.shortName} - ${type})`,
          position: q.position,
          obgText1: q.obgText1,
          obgText2: q.obgText2,
          criterion: {
            id: criterion.id,
            name: criterion.name,
            isMulti: criterion.multi,
            step: criterion.step,
            type: type,
            unit: criterion.unit,
            valueMin: criterion.valueMin,
            valueMax: criterion.valueMax,
          },
        },
      });

      // Criterion values for TXT Criterion
      if ([CRITERION_TYPE_TXT].includes(type)) {
        if (questionDependencie.value?.answers) {
          data.criterionValues[q.id] = questionDependencie.value.answers;
        }

        if (
          linkedCriteria === CRITERION_TYPE_TXT &&
          questionDependencie.availableAnswers
        ) {
          questionDependencie.availableAnswers.map((availableAnswer) => {
            if (!data.availableAnswers.hasOwnProperty(q.id)) {
              data.availableAnswers[q.id] = {};
            }

            data.availableAnswers[q.id][availableAnswer.answers[0]] =
              availableAnswer.value;
            return null;
          });
        }
      }

      // Criterion values for NUM Criterion
      if (type === CRITERION_TYPE_NUM) {
        data.criterionValues[q.id] = questionDependencie.value;
      }

      // Criterion values for OBG Criterion
      if (type === CRITERION_TYPE_OBG) {
        data.criterionValues[q.id] = [questionDependencie.value.answer];
      }
      return null;
    });

    return data;
  };

  const [state, setState] = useState({
    loading: false,
    question: null,
    currentDependency: null,
    error: {
      title: false,
      questionText: false,
      criteria: false,
      questionDependencies: [],
    },
  });

  const questionAndCriteriaRef = useRef(null);

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (data, type = null) => {
    if (state.question) {
      let name = data?.target?.name;
      let value = data?.target?.value;

      // Cas de SWITCH et de formulaire déroulant
      if (type !== null) {
        name = type;
        value = data;
      }

      let newState = {
        question: {
          ...state.question,
          [name]: value,
        },
      };

      if (
        type === "criteria" &&
        (value === null || value?.type !== CRITERION_TYPE_OBG)
      ) {
        const defaultData = _getDefaultQuestionData();

        newState.question = {
          ...newState.question,
          possibleAnswer1: defaultData.possibleAnswer1,
          possibleAnswer2: defaultData.possibleAnswer2,
        };
      }

      if (type !== "questionText") {
        onRequestModalModified();
      }

      _setState(newState);
    }
  };

  const _handleKeyPress = () => {
    onRequestModalModified();
  };

  const _handleAnswerFormChange = (event, parentProp, prop) => {
    const { value } = event.target;
    let newState = {
      question: {
        ...state.question,
        [parentProp]: {
          ...state.question[parentProp],
          [prop]: prop === "mandatory" ? value === "false" : value,
        },
      },
    };

    if (prop === "mandatory") {
      const otherProp =
        parentProp === "possibleAnswer1"
          ? "possibleAnswer2"
          : "possibleAnswer1";

      newState.question[otherProp].mandatory =
        !newState.question[parentProp].mandatory;
    }

    _setState(newState);
  };

  const _formattedCriterions = (elements) => {
    return (
      elements?.map((element) => {
        return {
          value: element.id,
          label: `${element.name} (${element.shortName} - ${element.type.shortName})`,
          type: element.type.shortName,
          isMulti: element.multi,
          valueMin: element.valueMin,
          valueMax: element.valueMax,
          unit: element.unit,
        };
      }) || []
    );
  };

  const _handleAddDependency = () => {
    const qds = [...state.question.questionDependencies];
    const lastId = qds.slice(-1)?.[0]?.id || 0;

    qds.push({
      id: lastId + 1,
      value: null,
    });

    _setState({
      question: {
        ...state.question,
        questionDependencies: qds,
      },
    });

    onRequestModalModified();
  };

  const _handleDependencyFormChange = (data, questionDependencie) => {
    const qds = state.question.questionDependencies;
    const index = qds.findIndex(
      (element) => element.id === questionDependencie.id
    );

    if (index !== -1) {
      qds[index].value = data;

      _setState({
        question: {
          ...state.question,
          questionDependencies: qds,
        },
      });

      onRequestModalModified();
    }
  };

  const _handleDeleteDependency = (id) => {
    let qds = state.question.questionDependencies;
    qds = qds.filter((element) => element.id !== id);
    const objectState = {
      question: {
        ...state.question,
        questionDependencies: qds,
      },
    };

    if (id === state.currentDependency?.id) {
      objectState.currentDependency = null;
    }

    _setState(objectState);

    onRequestModalModified();
  };

  const _handleChooseDependency = (dependency) => {
    _setState({ currentDependency: dependency });
  };

  const _handleChooseCriteriaValue = (element) => {
    let cvs = { ...state.question?.criterionValues };
    if (
      state.currentDependency?.value?.value &&
      !cvs.hasOwnProperty(state.currentDependency?.value?.value)
    ) {
      cvs[state.currentDependency.value.value] = [];
    }

    if (
      state.currentDependency?.value?.criterion?.type === CRITERION_TYPE_TXT
    ) {
      if (cvs[state.currentDependency.value.value].includes(element.id)) {
        cvs[state.currentDependency.value.value] = cvs[
          state.currentDependency.value.value
        ].filter((val) => val !== element.id);
      } else {
        cvs[state.currentDependency.value.value].push(element.id);
      }
    } else {
      cvs[state.currentDependency.value.value] = [element.id];
    }

    _setState({
      question: {
        ...state.question,
        criterionValues: cvs,
      },
    });

    onRequestModalModified();
  };

  const _handleChooseAvailableAnswers = (criteriaValue, availableAnswer) => {
    let availableAnswers = { ...state.question?.availableAnswers };

    if (
      state.currentDependency?.value?.value &&
      !availableAnswers.hasOwnProperty(state.currentDependency?.value?.value)
    ) {
      availableAnswers[state.currentDependency.value.value] = {};
    }

    if (
      !availableAnswers[state.currentDependency.value.value].hasOwnProperty(
        criteriaValue.id
      )
    ) {
      availableAnswers[state.currentDependency.value.value][criteriaValue.id] =
        [];
    }

    if (
      availableAnswers[state.currentDependency.value.value][
        criteriaValue.id
      ].includes(availableAnswer.id)
    ) {
      availableAnswers[state.currentDependency.value.value][criteriaValue.id] =
        availableAnswers[state.currentDependency.value.value][
          criteriaValue.id
        ].filter((val) => val !== availableAnswer.id);
    } else {
      availableAnswers[state.currentDependency.value.value][
        criteriaValue.id
      ].push(availableAnswer.id);
    }

    _setState({
      question: {
        ...state.question,
        availableAnswers,
      },
    });

    onRequestModalModified();
  };

  const _handleSliderChange = (data) => {
    const cvs = { ...state.question.criterionValues };

    if (data.checked) {
      cvs[state.currentDependency.value.value] = {
        min: data.min,
        max: data.max,
      };
    } else {
      delete cvs[state.currentDependency.value.value];
    }

    _setState({
      question: {
        ...state.question,
        criterionValues: cvs,
      },
    });
  };

  const _checkForm = () => {
    const questionRequirements =
      state.question?.title !== "" &&
      state.question?.questionText !== "" &&
      state.question?.criteria?.value;
    let dependenciesWithError = [];

    state.question.questionDependencies.map((questionDependencie) => {
      let criterionType = questionDependencie?.value?.criterion?.type;
      let values =
        state.question.criterionValues?.[questionDependencie?.value?.value];
      let status = false;

      if ([CRITERION_TYPE_TXT, CRITERION_TYPE_OBG].includes(criterionType)) {
        status = values?.length > 0;
      }

      if (criterionType === CRITERION_TYPE_NUM) {
        status = values?.hasOwnProperty("min") && values?.hasOwnProperty("max");
      }

      if (!status) {
        dependenciesWithError.push(questionDependencie.id);
      }

      return null;
    });

    return {
      status: questionRequirements && dependenciesWithError.length === 0,
      dependenciesWithError,
    };
  };

  const _handleFormSubmit = (event) => {
    event.preventDefault();
    const isInformationCompleted = _checkForm();

    if (isInformationCompleted.status) {
      _setState({ loading: true });
      let obgAnswerFirst = null;
      let obgText1 = null;
      let obgText2 = null;

      const questionDependencies = _getQuestionDependencies(
        state.question.questionDependencies,
        state.question.criterionValues,
        state.question.availableAnswers,
        state.question?.criteria?.type
      );

      if (state.question?.criteria.type === CRITERION_TYPE_OBG) {
        obgAnswerFirst =
          !state.question?.possibleAnswer1.mandatory &&
          !state.question?.possibleAnswer2.mandatory
            ? null
            : state.question?.possibleAnswer1.mandatory;
        obgText1 = state.question?.possibleAnswer1.value;
        obgText2 = state.question?.possibleAnswer2.value;
      }

      const executeOperation = questionId
        ? Api.question.updateQuestion(questionId, {
            title: state.question?.title,
            mandatory: state.question?.mandatory,
            questionText: state.question?.questionText,
            criterionId: state.question?.criteria?.value,
            obgAnswerFirst,
            obgText1,
            obgText2,
            questionDependencies,
          })
        : Api.question.createQuestion(
            state.question?.title,
            state.question?.questionText,
            0,
            state.question?.mandatory,
            currentStepId,
            state.question?.criteria.value,
            obgAnswerFirst,
            obgText1,
            obgText2,
            questionDependencies
          );

      executeOperation
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            toast.success(
              `Question ${
                questionId ? "mise à jour" : "enregistrée"
              } avec succès.`,
              Helper.getToastOptions()
            );
            _setState({
              question: null,
            });
            onRequestModalModified(false);
            onRequestSuccessSaving();
          }

          _setState({ loading: false });
        })
        .catch((error) => {
          console.error(error);
          _setState({ loading: false });
        });
    } else {
      toast.error(
        "Veuillez renseigner tous les champs obligatoires avant d'enregistrer.",
        Helper.getToastOptions()
      );
      _setState({
        error: {
          title: state.question?.title === "",
          questionText: state.question?.questionText === "",
          criteria: !state.question?.criteria,
          questionDependencies: isInformationCompleted.dependenciesWithError,
        },
      });
    }
  };

  useEffect(() => {
    if (questionId) {
      _setState({ loading: true });
      Promise.all([
        Api.question.getQuestion(questionId),
        Api.criteria.getUnattachedCriterions(),
      ])
        .then(([questionResponse, criterionsResponse]) => {
          const questionResult = Helper.isValidResponse(questionResponse);
          const criterionResults = Helper.isValidResponse(criterionsResponse);
          const stateObject = {
            loading: false,
          };

          if (questionResult && criterionResults) {
            const dependencyElement = _getDependenciesElements(
              questionResult.questionDependencies,
              questionResult.criterion.type.shortName
            );
            const question = {
              title: questionResult.title,
              mandatory: questionResult.mandatory,
              questionText: questionResult.questionText,
              position: questionResult.position,
              criterions: _formattedCriterions(criterionResults),
              criteria: {
                value: questionResult.criterion.id,
                label: `${questionResult.criterion.name} (${questionResult.criterion.shortName} - ${questionResult.criterion.type.shortName})`,
                type: questionResult.criterion.type.shortName,
                isMulti: questionResult.criterion.multi,
                valueMin: questionResult.criterion.valueMin,
                valueMax: questionResult.criterion.valueMax,
                unit: questionResult.criterion.unit,
              },
              possibleAnswer1: {
                value: "Oui",
                mandatory: true,
              },
              possibleAnswer2: {
                value: "Non",
                mandatory: false,
              },
              questionDependencies: dependencyElement.questionDependencies,
              criterionValues: dependencyElement.criterionValues,
              availableAnswers: dependencyElement.availableAnswers,
            };

            if (dependencyElement.questionDependencies?.length > 0) {
              stateObject.currentDependency =
                dependencyElement.questionDependencies[0];
            }

            // Uniquement pour les critères OBG
            if (
              questionResult.criterion.type.shortName === CRITERION_TYPE_OBG
            ) {
              if (questionResult.obgAnswerFirst === null) {
                question.possibleAnswer1.mandatory = false;
                question.possibleAnswer2.mandatory = false;
              } else {
                question.possibleAnswer1.mandatory =
                  questionResult.obgAnswerFirst;
                question.possibleAnswer2.mandatory =
                  !questionResult.obgAnswerFirst;
              }

              question.possibleAnswer1.value = questionResult.obgText1 || "Oui";
              question.possibleAnswer2.value = questionResult.obgText2 || "Non";
            }

            stateObject.question = question;
          }

          _setState(stateObject);
        })
        .catch((error) => {
          console.error(error);
          _setState({ loading: false });
        });
    } else {
      // On prend les critères non attachés et si c'est bon on affecte de valeur sur les autres formulaires
      _setState({ loading: true });
      Api.criteria
        .getUnattachedCriterions()
        .then((response) => {
          const results = Helper.isValidResponse(response);
          const stateObject = {
            loading: false,
            question: _getDefaultQuestionData(),
          };

          if (results) {
            stateObject.question.criterions = _formattedCriterions(results);
          }

          _setState(stateObject);
        })
        .catch((error) => {
          console.error(error);
          _setState({ loading: false });
        });
    }
    // eslint-disable-next-line
  }, [questionId]);

  return (
    <form
      className="question-form-root"
      noValidate
      onSubmit={_handleFormSubmit}
    >
      {state.loading && <Loading />}
      <div className="elements-content">
        {/** 1ere partie */}
        <div
          ref={questionAndCriteriaRef}
          className="question-and-criteria-form"
        >
          <div className="question-form">
            <div className="content-title">Question</div>
            <div className="form-block">
              <div className="form-title">Titre</div>
              <div className="flex-start">
                <InputField
                  name="title"
                  className="question-title-form"
                  onChange={_handleFormChange}
                  value={state.question?.title || ""}
                  isFocused={state.question?.title !== ""}
                  disabled={readOnly}
                />
                <div className="switch-content">
                  <ReactSwitch
                    className="flex-input"
                    checked={state.question?.mandatory || false}
                    onColor="#a6d290"
                    offColor="#fc9999"
                    onChange={(data) => _handleFormChange(data, "mandatory")}
                    disabled={readOnly}
                  />
                  <span>
                    {state.question?.mandatory ? "Obligatoire" : "Facultative"}
                  </span>
                </div>
              </div>
              {state.error.title && (
                <div className="error">Champ obligatoire</div>
              )}
            </div>
            {/** Question */}
            <div className="form-block">
              <div className="form-title">Question</div>
              <Textarea
                required={true}
                value={state.question?.questionText || ""}
                onChange={(event) => _handleFormChange(event, "questionText")}
                readOnly={readOnly}
                onRequestKeyPress={_handleKeyPress}
              />
              {state.error.questionText && (
                <div className="error">Champ obligatoire</div>
              )}
            </div>
          </div>
          <div
            className={`criteria-form${
              state.question?.criteria?.type === CRITERION_TYPE_TXT &&
              state.currentDependency?.value?.criterion?.type ===
                CRITERION_TYPE_TXT &&
              state.question?.criterionValues?.[
                state.currentDependency?.value?.value
              ]?.length > 0
                ? " criteria-form-case-2"
                : ""
            }`}
          >
            {/** criteria-form-case-2 => 2nd cas de TXT */}
            <div className="form-block">
              <div className="content-title">Critère</div>
              <div className="form-title">Critère lié à la question</div>
              <SelectField
                options={state.question?.criterions || []}
                value={state.question?.criteria || null}
                isMulti={false}
                closeMenuOnSelect={true}
                placeholder=""
                isDisabled={readOnly}
                noUpperCasePlaceholder={true}
                onChange={(data) => _handleFormChange(data, "criteria")}
              />
              {state.error.criteria && (
                <div className="error">Champ obligatoire</div>
              )}

              {state.question?.criteria?.type === CRITERION_TYPE_OBG && (
                <div className="possible-answers-content">
                  <div className="title-content">
                    <span>Réponses possibles</span>
                    <span>Obligatoire</span>
                  </div>
                  {/** Réponse 1 */}
                  <div className="form-content">
                    <InputField
                      name="possibleAnswer1"
                      className="possible-answer-form"
                      onChange={(event) =>
                        _handleAnswerFormChange(
                          event,
                          "possibleAnswer1",
                          "value"
                        )
                      }
                      value={state.question?.possibleAnswer1?.value || ""}
                      disabled={readOnly}
                    />
                    <FormControlLabel
                      className="possible-answer-radio"
                      value={state.question?.possibleAnswer1?.mandatory}
                      checked={state.question?.possibleAnswer1?.mandatory}
                      control={<Radio size="small" />}
                      onChange={(event) =>
                        _handleAnswerFormChange(
                          event,
                          "possibleAnswer1",
                          "mandatory"
                        )
                      }
                      onClick={() => {}}
                      disabled={false}
                    />
                  </div>

                  {/** Réponse 2 */}
                  <div className="form-content">
                    <InputField
                      name="possibleAnswer2"
                      className="possible-answer-form"
                      onChange={(event) =>
                        _handleAnswerFormChange(
                          event,
                          "possibleAnswer2",
                          "value"
                        )
                      }
                      value={state.question?.possibleAnswer2?.value || ""}
                      disabled={readOnly}
                    />
                    <FormControlLabel
                      className="possible-answer-radio"
                      value={state.question?.possibleAnswer2?.mandatory}
                      checked={state.question?.possibleAnswer2?.mandatory}
                      control={<Radio size="small" />}
                      onChange={(event) =>
                        _handleAnswerFormChange(
                          event,
                          "possibleAnswer2",
                          "mandatory"
                        )
                      }
                      onClick={() => {}}
                      disabled={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Divider />

        {/** 2è partie */}
        <QuestionDependency
          componentHeight={`calc(80vh - ${
            questionAndCriteriaRef.current?.offsetHeight + 6
          }px)`}
          readOnly={readOnly}
          steps={steps}
          question={{ ...(state.question || {}), id: questionId }}
          currentDependency={state.currentDependency}
          currentStep={currentStep}
          questionDependenciesWithError={state.error.questionDependencies}
          onRequestAddDependency={_handleAddDependency}
          onRequestDependencyFormChange={_handleDependencyFormChange}
          onRequestDeleteDependency={_handleDeleteDependency}
          onRequestChooseDependency={_handleChooseDependency}
          onRequestChooseCriteriaValue={_handleChooseCriteriaValue}
          onRequestChooseAvailableAnswers={_handleChooseAvailableAnswers}
          onRequestSliderChange={_handleSliderChange}
        />
      </div>

      <Divider />

      {!readOnly && (
        <div className="question-form-footer">
          <button
            className="btn default btn-lg saving-button"
            type="submit"
            title="Enregistrer les données saisies"
            ref={savingButtonRef}
          >
            <Save />
            Enregistrer
          </button>
        </div>
      )}
    </form>
  );
};

export default QuestionForm;
