import React, { useEffect, useState } from "react";
import "./referenceDataForm.css";
import { InputField } from "../../general/form/Input";
import { Save } from "@mui/icons-material";
import { toast } from "react-toastify";
import Helper from "../../../services/Helper";
import { Api } from "fsy.common-library";
import {
  HTTP_CREATED,
  HTTP_OK,
  HTTP_REFERENCE_DATA_ALREADY_EXIST,
} from "fsy.common-library/lib/env/Constants";
import { Loading } from "../../general/form/Loading";

const ReferenceDataForm = ({
  type,
  id,
  entityType,
  readOnly,
  formType,
  isFeminine,
  label,
  loading,
  refSaveButton,
  idInUrl,
  onRequestLoadingChange,
  onRequestSubmitSuccess,
  onRequestDataChange,
}) => {
  // Condition si le formulaire doit comporter un shortName ou pas
  const withShortName = ["criteria-theme"].includes(type);

  const isUpdateOperation = formType !== "create" && id;

  // Etats du composant
  const [state, setState] = useState({
    name: "",
    shortName: "",
    inputRequiredError: {
      name: false,
      shortName: false,
    },
  });

  const setLoading = (value = false) => {
    onRequestLoadingChange({ formLoading: value });
  };

  // Modification des états du composant
  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  // Quand les valeurs des formulaires changent
  const handleFormChange = (event) => {
    const value = event?.target?.value;
    const name = event?.target?.name;

    _setState({ [name]: value });
    onRequestDataChange(true);
  };

  // Condition vérifiant si les formulaires comportent de données
  const hasEmptyForm = () => {
    if (withShortName) {
      return state.name === "" || state.shortName === "";
    }

    return state.name === "";
  };

  // Les formulaires à envoyer à l'API
  const getFormData = () => {
    const object = {
      name: state.name,
    };

    if (withShortName) {
      object.shortName = state.shortName;
    }

    return object;
  };

  // Sauvegarde de données
  const saveData = () => {
    const data = getFormData();

    const creationOperationKey =
      type !== "criteria-theme"
        ? "createReferenceData"
        : "createCriterionTheme";
    const updateOperationKey =
      type !== "criteria-theme"
        ? "updateReferenceData"
        : "updateCriterionTheme";
    const lastParam = type !== "criteria-theme" ? type : null; // JWT pour le Criteria theme et type pour les autres données de référence

    const query = isUpdateOperation
      ? Api[entityType][updateOperationKey](id, data, lastParam)
      : Api[entityType][creationOperationKey](data, lastParam);
    const expectedResponseCode = isUpdateOperation ? HTTP_OK : HTTP_CREATED;

    query
      .then((response) => {
        if (response?.status !== expectedResponseCode) {
          if (response.status === HTTP_REFERENCE_DATA_ALREADY_EXIST) {
            let message =
              "Le libellé que vous tentez d'enregistrer existe déjà.";

            if (type === "criteria-theme") {
              message =
                "Le libellé ou libellé abrégé que vous tentez d'entregistrer existent déjà.";
            }

            toast.warning(message, Helper.getToastOptions());
            setLoading();
          }

          setLoading();
        } else {
          _setState({
            name: "",
            shortName: "",
          });

          const terminaison = isFeminine ? "e" : "";
          toast.success(
            `L${isFeminine ? "a" : "e"} ${label} a été ${
              isUpdateOperation
                ? `mis${terminaison} à jour`
                : `enregistré${terminaison}`
            }`,
            Helper.getToastOptions()
          );
          setLoading();
          onRequestSubmitSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading();
      });
  };

  // Fonction de somission
  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!hasEmptyForm()) {
      setLoading(true);

      saveData();

      _setState({
        inputRequiredError: {
          name: false,
          shortName: false,
        },
      });
    } else {
      const inputRequiredError = {
        name: state.name === "",
        shortName: false,
      };

      if (withShortName) {
        inputRequiredError.shortName = state.shortName === "";
      }

      _setState({ inputRequiredError });

      toast.error(
        "Veuillez renseigner tous les champs obligatoires avant d'enregistrer.",
        Helper.getToastOptions()
      );
    }
  };

  useEffect(() => {
    if (id || idInUrl) {
      setLoading(true);
      const lastParam = type !== "criteria-theme" ? type : null;

      const getData = Api[entityType][
        type !== "criteria-theme" ? "getReferenceData" : "getCriterionTheme"
      ](id || idInUrl, lastParam);

      getData
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            const object = { name: result.name };

            if (withShortName) {
              if (result.hasOwnProperty("keyword")) {
                object["shortName"] = result.keyword;
              }
            }

            if (result.hasOwnProperty("position")) {
              object["position"] = result.position;
            }

            _setState({ ...object });
          }

          setLoading();
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, idInUrl]);

  return (
    <form
      onSubmit={handleFormSubmit}
      noValidate
      className="reference-data-form-root"
    >
      {loading && <Loading />}
      <div className="reference-data-forms">
        {/** Libellé */}
        <InputField
          required={true}
          className="input-tiny"
          name="name"
          isFocused={state.name !== null}
          value={state.name}
          onChange={handleFormChange}
          title="Libellé"
          context={this}
          readOnly={readOnly}
          disabled={readOnly}
        >
          Libellé
        </InputField>

        {state.inputRequiredError.name && (
          <div className="input-required-error">Champ obligatoire</div>
        )}

        {/** Libellé abrégé */}
        {withShortName && (
          <>
            <InputField
              required={true}
              className="input-tiny"
              name="shortName"
              isFocused={state.shortName !== null}
              value={state.shortName}
              onChange={handleFormChange}
              title="Libellé abrégé"
              context={this}
              readOnly={readOnly || Boolean(id)}
              disabled={readOnly || Boolean(id)}
            >
              Libellé abrégé
            </InputField>

            {state.inputRequiredError.shortName && (
              <div className="input-required-error">Champ obligatoire</div>
            )}
          </>
        )}
      </div>
      {!readOnly && (
        <div className="reference-data-form-footer">
          <button
            ref={refSaveButton}
            className="btn default btn-lg"
            type="submit"
            title="Enregistrer les données"
          >
            <Save />
            Enregistrer
          </button>
        </div>
      )}
    </form>
  );
};

export default ReferenceDataForm;
