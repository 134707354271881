import React from 'react'
import DataLayout from '../layout/DataLayout'

const CriterionCategory = () => {
    return (
        <DataLayout
            title="Catégories de critères"
            type='criterionTheme'
            partialTitleOnCreateButtonHover="catégorie de critère"
            operationName='getCriterionThemes'
            countOperationName='getCriterionThemeCount'
            deleteOperationName='deleteCriterionTheme'
            formName='criteria-theme'
            isFeminine={true}
            checkingCanBeDeletedOperationName="canBeDeleted"
        />
    )
}

export default CriterionCategory