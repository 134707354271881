import React, { useEffect, useState } from "react";
import { SelectField } from "../../../general/form/Select";

const SpecieRow = ({
  type,
  shortName,
  data,
  speciesGroup,
  criterion,
  readOnly,
  onRequestFundingCriterionUpdate,
  onRequestSetConfirm,
}) => {
  const speciesGroupLabel = "Groupe d'essences";
  const speciesLabel = "Essence(s)";

  const [state, setState] = useState({
    speciesGroupOptions: [],
    speciesOptions: [],
    speciesGroup: null,
    specie: null,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (data, name) => {
    const stateObject = { [name]: data };

    if (name === "speciesGroup") {
      if (data) {
        const species =
          speciesGroup.find((element) => element.shortName === data.shortName)
            ?.species || [];
        const elements = [];

        if (species?.length > 0) {
          species.map((specie) => {
            elements.push({
              value: specie.id,
              label: specie.label,
            });

            return null;
          });
        }

        stateObject.speciesOptions = elements;
        stateObject.specie = null;
      } else {
        stateObject.speciesOptions = [];
        stateObject.specie = null;
      }
    }

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }

    _setState(stateObject);
  };

  useEffect(() => {
    if (speciesGroup?.length > 0) {
      const speciesGroupOptions = [];

      speciesGroup.map((group) => {
        speciesGroupOptions.push({
          value: group.id,
          label: group.label,
          shortName: group.shortName,
        });

        return null;
      });

      _setState({ speciesGroupOptions });
    }
    // eslint-disable-next-line
  }, [speciesGroup?.length]);

  useEffect(() => {
    if (typeof onRequestFundingCriterionUpdate === "function") {
      const data = {
        shortName,
        type,
        value: {
          specieGroup: state.speciesGroup?.value || null,
          specie:
            state.specie?.length > 0
              ? state.specie?.map((val) => val.value)
              : null,
        },
        criterionId: criterion.id,
      };

      onRequestFundingCriterionUpdate(data);
    }
    // eslint-disable-next-line
  }, [state.speciesGroup?.value, JSON.stringify(state.specie)]);

  useEffect(() => {
    if (data?.speciesGroup || data?.specie) {
      const stateObject = {
        speciesGroup: data.speciesGroup,
        specie: data.specie,
      };

      if (data?.speciesGroup?.value && speciesGroup?.length > 0) {
        const options =
          speciesGroup
            ?.find((element) => element.id === data?.speciesGroup?.value)
            ?.species?.map((element) => {
              return {
                value: element.id,
                label: element.label,
              };
            }) || [];

        if (options.length > 0) {
          stateObject.speciesOptions = options;
        }
      }

      _setState(stateObject);
    } else {
      _setState({ speciesGroup: null, specie: null });
    }
  }, [data, speciesGroup]);

  return (
    <div>
      <div title={speciesGroupLabel}>
        <label>{speciesGroupLabel}</label>
        <SelectField
          options={state.speciesGroupOptions}
          value={state.speciesGroup}
          isMulti={false}
          closeMenuOnSelect={true}
          placeholder=""
          isDisabled={readOnly}
          onChange={(data) => _handleFormChange(data, "speciesGroup")}
        />
      </div>
      <div title={speciesLabel}>
        <SelectField
          options={state.speciesOptions}
          value={state.specie}
          isMulti={true}
          isDisabled={readOnly}
          closeMenuOnSelect={false}
          placeholder="Essence(s)"
          optionsContentHeight={140}
          onChange={(data) => _handleFormChange(data, "specie")}
        />
      </div>
    </div>
  );
};

export default SpecieRow;
