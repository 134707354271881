import React, { Fragment, useEffect, useState } from "react";
import Modal from "./../../general/form/Modal";
import { Constants } from "fsy.common-library";
import AidFundingFixedAmount from "./AidFundingFixedAmount/AidFundingFixedAmount";
import AidFundingScale from "./AidFundingScale/AidFundingScale";
import AidFundingPlant from "./AidFundingPlant/AidFundingPlant";
import "./aidFundingWithCriterions.css";
import Helper from "../../../services/Helper";
import { toast } from "react-toastify";
import AidFundingCriterionForm from "./AidFundingCriterionForm/AidFundingCriterionForm";
import _ from "lodash";

const AidFundingWithCriterions = ({
  mode,
  type,
  isShowing,
  confirm,
  editingAidFunding,
  speciesGroup,
  zones,
  criterions,
  regions,
  departments,
  fundingLines,
  disabled,
  onRequestHide,
  onRequestSetConfirm,
  onRequestAddAidFundingLine,
  onRequestGetCriterionCVLabelById,
  onRequestGetZoneByTypeId,
  onRequestGetLocationById,
  onRequestGetSpecieLabelById,
}) => {
  const [state, setState] = useState({
    criterions: [],
    id: null,
    value: {},
    editingValue: null,
    valueToErase: null,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleEraseValueToErase = () => {
    _setState({ valueToErase: null });
  };

  const _handleUpdateData = (data) => {
    const stateValue = { ...state.value };

    stateValue[data.shortName] = ![
      "amount",
      "baseAmount",
      "rate",
      "density",
    ].includes(data.shortName)
      ? data
      : data.value;

    const stateObject = { value: stateValue };

    _setState(stateObject);
  };

  const _getCriterionData = (criterions) => {
    const newData = {};
    const exceptionsKeys = ["id", "amount", "baseAmount", "rate", "density"];

    Object.entries(criterions).forEach(([key, value]) => {
      if (!exceptionsKeys.includes(key)) {
        let criterionType = value.type;
        let criterionShortName = value.shortName;
        let criterionValue = value.value;
        let condition = false;

        switch (criterionType) {
          case Constants.CRITERION_TYPE_TXT:
            condition = criterionValue?.length > 0;
            break;
          case Constants.CRITERION_TYPE_OBG:
            condition = Boolean(criterionValue);
            break;
          case Constants.CRITERION_TYPE_LOC:
            condition = Boolean(criterionValue?.type);
            break;
          case Constants.CRITERION_TYPE_NUM:
            if (criterionShortName === "criPlan_01") {
              condition = Boolean(criterionValue?.specieGroup);
            } else {
              condition =
                criterionValue?.length === 2 &&
                Helper.isValidNumber(criterionValue[0]) &&
                Helper.isValidNumber(criterionValue[1]);
            }
            break;
          default:
            break;
        }

        if (condition) {
          newData[key] = {
            shortName: criterionShortName,
            value: criterionValue,
          };
        }
      }
    });

    return newData;
  };

  const _isAidFundingFormsValidation = () => {
    const stateValue = { ...state.value };
    let data = {
      status: true,
      message: "",
    };
    let fundingLabel = "";

    switch (type) {
      case Constants.AID_FUNDING_FIXED_AMOUNT:
        fundingLabel = "Financement sur montant fixe : ";
        break;
      case Constants.AID_FUNDING_SCALE:
        fundingLabel = "Financement au barème : ";
        break;
      case Constants.AID_FUNDING_PLAN:
        fundingLabel = "Financement au plant : ";
        break;
      default:
        break;
    }

    if (type === Constants.AID_FUNDING_FIXED_AMOUNT) {
      // Il faut renseigner au moins 1 valeur dans la partie critère
      let amount = null;
      const elements = [];
      Object.entries(stateValue).forEach(([key, value]) => {
        if (key !== "amount") {
          let condition = false;
          let dataType = value?.type;
          let dataShortName = value?.shortName;
          let dataValue = value?.value;

          switch (dataType) {
            case Constants.CRITERION_TYPE_TXT:
              condition = Array.isArray(dataValue)
                ? dataValue?.length > 0
                : Boolean(dataValue);
              break;
            case Constants.CRITERION_TYPE_OBG:
              condition = Boolean(dataValue);
              break;
            case Constants.CRITERION_TYPE_LOC:
              condition = Boolean(dataValue?.type);
              break;
            case Constants.CRITERION_TYPE_NUM:
              if (dataShortName !== "criPlan_01") {
                condition =
                  Array.isArray(dataValue) &&
                  dataValue.length === 2 &&
                  Helper.isValidNumber(dataValue[0]) &&
                  Helper.isValidNumber(dataValue[1]);
              } else {
                condition = Boolean(dataValue?.specieGroup);
              }
              break;
            default:
              break;
          }

          if (condition) {
            elements.push(dataValue);
          }
        } else {
          amount = value;
        }
      });

      if (elements.length === 0) {
        data.status = false;
        data.message = `${fundingLabel}Veuillez renseigner une valeur sur au moins un des formulaires dans la section Critères avant de valider.`;
      }

      if (data.status) {
        if (!Helper.isValidNumber(amount)) {
          data.status = false;
          data.message = `${fundingLabel}Veuillez renseigner le montant à l'hectare avant de valider.`;
        }
      }
    }

    // Type LOCALISATION
    Object.entries(stateValue).forEach(([key, value]) => {
      if (data.status) {
        // Type LOCALISATION
        if (key === "loca_01") {
          let locationType = value?.value?.type;
          if (
            [
              Constants.LOCATION_TYPE_REGIONAL,
              Constants.LOCATION_TYPE_DEPARTMENTAL,
            ].includes(locationType)
          ) {
            if (!Boolean(value?.value?.location)) {
              data.status = false;
              data.message = `${fundingLabel}Veuillez renseigner la localisation avant de valider.`;
            }
          }
        }
      }
    });

    if (type === Constants.AID_FUNDING_SCALE) {
      let baseAmount = stateValue["baseAmount"];
      let rate = stateValue["rate"];

      if (!Helper.isValidNumber(baseAmount) && !Helper.isValidNumber(rate)) {
        data.status = false;
        data.message = `${fundingLabel}Veuillez renseigner le montant de base et le taux d'aide avant de valider`;
      } else {
        if (!Helper.isValidNumber(baseAmount)) {
          data.status = false;
          data.message = `${fundingLabel}Veuillez renseigner le montant de base avant de valider`;
        }

        if (!Helper.isValidNumber(rate)) {
          data.status = false;
          data.message = `${fundingLabel}Veuillez renseigner le taux d'aide en % avant de valider`;
        }
      }
    }

    if (data.status) {
      const newData = _getCriterionData(stateValue);
      let found = false;

      fundingLines.map((line) => {
        if (!found) {
          let existingDataLine = _getCriterionData(line.data);

          if (state.id !== line.id && _.isEqual(newData, existingDataLine)) {
            found = true;
            data.status = false;
            data.message = `${fundingLabel}Les données que vous avez saisies existent déjà dans une ligne du tableau de financement.`;
          }
        }

        return null;
      });
    }

    if (type === Constants.AID_FUNDING_PLAN) {
      let planAmount = stateValue["amount"];
      let density = stateValue["density"];

      if (!Helper.isValidNumber(planAmount) && !Helper.isValidNumber(density)) {
        data.status = false;
        data.message = `${fundingLabel}Veuillez renseigner le montant au plant et la densité avant de valider`;
      } else {
        if (!Helper.isValidNumber(planAmount)) {
          data.status = false;
          data.message = `${fundingLabel}Veuillez renseigner le montant au plant avant de valider`;
        }

        if (!Helper.isValidNumber(density)) {
          data.status = false;
          data.message = `${fundingLabel}Veuillez renseigner la densité avant de valider`;
        }
      }
    }

    return data;
  };

  const _handleValidData = () => {
    const formValidation = _isAidFundingFormsValidation();
    if (formValidation.status) {
      onRequestAddAidFundingLine(state.value, state.id);
      _setState({ value: {}, id: null, editingValue: null });
    } else {
      if (formValidation.message) {
        toast.error(formValidation.message, Helper.getToastOptions());
      }
    }
  };

  useEffect(() => {
    if (editingAidFunding) {
      const { data, id } = editingAidFunding;
      const stateValue = {};

      // Début de modification de code
      if (data && typeof data === "object") {
        Object.entries(data).forEach(([key, value]) => {
          if (key !== "id") {
            let condition = false;

            if (value) {
              let option = value;

              if (!Helper.isValidNumber(option)) {
                if (option.type === Constants.CRITERION_TYPE_TXT) {
                  if (Array.isArray(option.value)) {
                    condition = option.value.length > 0;
                  } else {
                    condition = option.value;
                  }
                }

                if (option.type === Constants.CRITERION_TYPE_OBG) {
                  condition = option.value;
                }

                if (option.type === Constants.CRITERION_TYPE_LOC) {
                  condition = option.value?.type;
                }

                if (option.type === Constants.CRITERION_TYPE_NUM) {
                  if (option.shortName === "criPlan_01") {
                    condition = option.value?.specieGroup;
                  } else {
                    condition =
                      option.value &&
                      Array.isArray(option.value) &&
                      option.value?.length === 2 &&
                      Helper.isValidNumber(option.value?.[0]) &&
                      Helper.isValidNumber(option.value?.[1]);
                  }
                }
              } else {
                condition = true;
              }
            }

            if (condition) {
              stateValue[key] = value;
            }
          }
        });
      }
      // Fin de modification de code

      _setState({ id, editingValue: stateValue, value: stateValue });
    } else {
      _setState({
        id: null,
        editingValue: null,
        value: null,
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(editingAidFunding)]);

  return (
    <Modal
      title={`${
        mode === "creation" ? "Création" : "Modification"
      } de ligne de financement`}
      isShowing={isShowing}
      hide={onRequestHide}
      confirm={confirm}
      messageBeforeClose="Souhaitez-vous les valider avant de continuer"
      saveBeforeClose={_handleValidData}
    >
      <>
        <div className="aid-funding-with-criterions">
          <div className="form-content">
            <div className="criterions-funding-content">
              <h3>Critères</h3>
              <div className="criterions-form-funding">
                {criterions.map((criterion, index) => {
                  return (
                    <Fragment key={`Criterion${criterion.id}${index + 1}`}>
                      <AidFundingCriterionForm
                        criterion={criterion}
                        speciesGroup={speciesGroup}
                        zones={zones}
                        regions={regions}
                        departments={departments}
                        editingData={
                          state.editingValue?.[criterion.shortName] || null
                        }
                        aidFundingType={type}
                        valueToErase={state.valueToErase}
                        onRequestEraseValueToErase={_handleEraseValueToErase}
                        onRequestFormChange={_handleUpdateData}
                        onRequestSetConfirm={onRequestSetConfirm}
                        onRequestGetCriterionCVLabelById={
                          onRequestGetCriterionCVLabelById
                        }
                        onRequestGetZoneByTypeId={onRequestGetZoneByTypeId}
                        onRequestGetLocationById={onRequestGetLocationById}
                        onRequestGetSpecieLabelById={
                          onRequestGetSpecieLabelById
                        }
                        readOnly={disabled}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
            <div
              className={`fixed-funding-content ${
                type === Constants.AID_FUNDING_PLAN
                  ? "plant-fixed-funding-content"
                  : ""
              }`}
            >
              <h3>Financement</h3>
              <div
                className={`fixed-form-funding aid-funding-${type}-fixed-form-content`}
              >
                {type === Constants.AID_FUNDING_FIXED_AMOUNT && (
                  <AidFundingFixedAmount
                    readOnly={disabled}
                    editingData={state.editingValue}
                    onRequestUpdateData={_handleUpdateData}
                    onRequestSetConfirm={onRequestSetConfirm}
                  />
                )}
                {type === Constants.AID_FUNDING_SCALE && (
                  <AidFundingScale
                    readOnly={disabled}
                    editingData={state.editingValue}
                    onRequestUpdateData={_handleUpdateData}
                    onRequestSetConfirm={onRequestSetConfirm}
                  />
                )}
                {type === Constants.AID_FUNDING_PLAN && (
                  <AidFundingPlant
                    readOnly={disabled}
                    editingData={state.editingValue}
                    onRequestUpdateData={_handleUpdateData}
                    onRequestSetConfirm={onRequestSetConfirm}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!disabled && (
          <button
            className={`btn default btn-lg`}
            type="button"
            title="Valider les données saisies"
            onClick={_handleValidData}
          >
            OK
          </button>
        )}
      </>
    </Modal>
  );
};

export default AidFundingWithCriterions;
