import { Add } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import Question from "./question/Question";
import DragAndDrop from "../../general/DragAndDrop/DragAndDrop";
import { toast } from "react-toastify";
import Helper from "../../../services/Helper";
import { Api } from "fsy.common-library";
import { Loading } from "../../general/form/Loading";
import Modal from "../../general/form/Modal";
import QuestionForm from "./question/QuestionForm2";

const Questions = ({
  currentSimulatorId,
  currentStep,
  currentStepId,
  loading,
  steps,
  questions,
  withWriteAuth,
  onRequestSetQuestions,
  onRequestGetQuestions,
  onRequestGetSteps,
}) => {
  const titles = [
    "Titre",
    "Question",
    "Critère",
    "Dépendance",
    "Obligatoire",
    "Actions",
  ];

  const [state, setState] = useState({
    questionId: null,
    questionsListHeight: undefined,
    updating: false,
    isQuestionFormOpened: false,
    modalModified: false,
  });

  const savingButtonRef = useRef(null);
  const questionListTitleRef = useRef(null);
  const questionTitleTableRef = useRef(null);

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleQuestionClick = (questionId) => {
    _setState({ questionId, isQuestionFormOpened: true });
  };

  const _handleSetLoading = (status) => {
    _setState({ updating: status });
  };

  const _displayErrorMessageOnDragAndDrop = () => {
    toast.error(
      "Déplacement impossible à cause des dépendances de question.",
      Helper.getToastOptions()
    );
  };

  const _getQuestionDependencies = (questions, questionId) => {
    const id = Number(questionId);
    const index = questions.findIndex((element) => element.id === id);
    const elementsBefore = questions.slice(0, index) || [];
    const elementsAfter = questions.slice(index + 1) || [];
    const dependenciesElements1 = [];
    const dependenciesElements2 = [];

    // Cas 1
    elementsAfter.map((element) => {
      let currentElement = element.questionDependencies.find(
        (element) => element?.target?.id === id
      );

      if (currentElement) {
        dependenciesElements1.push(element);
      }

      return null;
    });

    // Cas 2
    questions[index]?.questionDependencies.map((questionDependencie) => {
      elementsBefore.map((element) => {
        if (questionDependencie?.target?.id === element.id) {
          dependenciesElements2.push(element);
        }

        return null;
      });

      return null;
    });

    return {
      dependencies1: dependenciesElements1,
      dependencies2: dependenciesElements2,
    };
  };

  const _handleCheckIfNewElementsValids = (elements, currentDraggableId) => {
    const id = Number(currentDraggableId);
    const index = elements.findIndex((element) => element.id === id);
    const elementsBeforeDraggable = elements.slice(0, index) || [];
    const elementsAfterDraggable = elements.slice(index + 1) || [];
    const dependenciesElements1 = [];
    const dependenciesElements2 = [];

    // Cas 1
    elementsBeforeDraggable.map((element) => {
      let currentElement = element.questionDependencies.find(
        (element) => element?.target?.id === id
      );

      if (currentElement) {
        dependenciesElements1.push(element);
      }

      return null;
    });

    // Cas 2
    elements[index]?.questionDependencies.map((questionDependencie) => {
      elementsAfterDraggable.map((element) => {
        if (questionDependencie?.target?.id === element.id) {
          dependenciesElements2.push(element);
        }

        return null;
      });

      return null;
    });

    const canDragAndDrop =
      !(dependenciesElements1.length > 0 || dependenciesElements2.length > 0) &&
      withWriteAuth;

    if (canDragAndDrop) {
      _setState({ updating: true });
      const param = {
        questions: elements.map((element) => {
          return { id: element.id };
        }),
      };

      Api.question
        .updateQuestionsOrder(param)
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            Api.question
              .getSimulatorStepQuestions(currentSimulatorId, currentStepId)
              .then((response) => {
                const results = Helper.isValidResponse(response);

                if (results) {
                  onRequestSetQuestions(results);
                  toast.success(
                    "Modification d'ordre des questions prise en compte.",
                    Helper.getToastOptions()
                  );
                }

                _setState({ updating: false });
              });
          }
        })
        .catch((error) => {
          _setState({ updating: false });
          console.error(error);
        });
    } else {
      if (
        withWriteAuth &&
        (dependenciesElements1.length > 0 || dependenciesElements2.length > 0)
      ) {
        _displayErrorMessageOnDragAndDrop();
      }
    }

    return canDragAndDrop;
  };

  const _handleQuestionFormDisplay = () => {
    _setState({
      isQuestionFormOpened: !state.isQuestionFormOpened,
      modalModified: false,
      questionId: null,
    });
  };

  const _handleModalModified = (status = true) => {
    _setState({ modalModified: status });
  };

  const _handleRequestSuccessSaving = () => {
    _handleQuestionFormDisplay();
    onRequestGetSteps(false);
    onRequestGetQuestions(false);
    _setState({ questionId: null });
  };

  const _handleSaveBeforeClose = () => {
    savingButtonRef.current?.click();
  };

  useEffect(() => {
    if (
      questionListTitleRef.current?.clientHeight &&
      questionTitleTableRef.current?.clientHeight
    ) {
      const otherHeight =
        85 +
        (questionListTitleRef.current?.clientHeight +
          questionTitleTableRef.current?.clientHeight);
      _setState({ questionsListHeight: `calc(96vh - ${otherHeight}px)` });
    }
  }, [
    questionListTitleRef.current?.clientHeight,
    questionTitleTableRef.current?.clientHeight,
    questions.length,
  ]);

  return (
    <div className="questions-list">
      {state.updating && <Loading />}
      <Modal
        title={`${state.questionId ? "Modification" : "Ajout"} d'une question`}
        isShowing={state.isQuestionFormOpened}
        confirm={state.modalModified}
        hide={_handleQuestionFormDisplay}
        saveBeforeClose={_handleSaveBeforeClose}
      >
        <QuestionForm
          withWriteAuth={withWriteAuth}
          currentStep={currentStep}
          steps={steps}
          questionId={state.questionId}
          savingButtonRef={savingButtonRef}
          currentStepId={currentStepId}
          onRequestSuccessSaving={_handleRequestSuccessSaving}
          onRequestModalModified={_handleModalModified}
        />
      </Modal>
      <div ref={questionListTitleRef} className="questions-list-title">
        <span>Questions</span>
        <button
          className={`btn btn-tiny default addButton ${
            loading ? "disabled" : ""
          } ${!withWriteAuth ? "visibility-hidden" : ""}`}
          title="Cliquer pour ajouter une question"
          onClick={_handleQuestionFormDisplay}
        >
          <Add />
          Ajouter une question
        </button>
      </div>
      <div
        className={`questions-list-content${loading ? "-with-loading" : ""}`}
      >
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
            <div
              ref={questionTitleTableRef}
              className="flex w-100 questions-headers"
            >
              {titles.map((question, index) => {
                return <div key={`QuestionColumn${index + 1}`}>{question}</div>;
              })}
            </div>
            <div
              className={`w-100 questions-rows`}
              style={{ height: state.questionsListHeight }}
            >
              {withWriteAuth ? (
                <DragAndDrop
                  elements={questions}
                  renderComponent={(item) => (
                    <Question
                      withWriteAuth={withWriteAuth}
                      currentStepId={currentStepId}
                      steps={steps}
                      question={item}
                      currentStep={currentStep}
                      getQuestionDependencies={_getQuestionDependencies}
                      onRequestSetLoading={_handleSetLoading}
                      onRequestGetSteps={() => onRequestGetSteps(false)}
                      onRequestGetQuestions={() => onRequestGetQuestions(false)}
                      onRequestQuestionClick={_handleQuestionClick}
                    />
                  )}
                  checkIfNewElementsValids={_handleCheckIfNewElementsValids}
                />
              ) : (
                <>
                  {questions.map((item, index) => {
                    return (
                      <Question
                        key={`Question${index + 1}`}
                        withWriteAuth={withWriteAuth}
                        currentStepId={currentStepId}
                        steps={steps}
                        question={item}
                        currentStep={currentStep}
                        getQuestionDependencies={_getQuestionDependencies}
                        onRequestSetLoading={_handleSetLoading}
                        onRequestGetSteps={() => onRequestGetSteps(false)}
                        onRequestGetQuestions={() =>
                          onRequestGetQuestions(false)
                        }
                        onRequestQuestionClick={_handleQuestionClick}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Questions;
