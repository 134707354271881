import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIndicator } from "@mui/icons-material";

const DragAndDrop = ({
  elements,
  draggableId,
  renderComponent,
  withDragIndicator,
  checkIfNewElementsValids,
}) => {
  const [state, setState] = useState({
    items: [],
    dragIndex: { source: null, destination: null },
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  useEffect(() => {
    _setState({ items: elements });
  }, [elements]);

  const _getListStyle = () => {
    return {};
  };

  const _getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      ...draggableStyle,
    };
  };

  const _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const _onDragEnd = (result) => {
    const index = {
      source: null,
      destination: null,
    };

    if (!result.destination) {
      return;
    }

    const elements = _reorder(
      state.items,
      result.source.index,
      result.destination.index
    );

    index.source = result.source.index;
    index.destination = result.destination.index;

    if (index.source !== index.destination) {
      if (checkIfNewElementsValids(elements, result.draggableId)) {
        _setState({ items: elements, dragIndex: index });
      }
    }
  };

  return (
    <DragDropContext onDragEnd={_onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={_getListStyle(snapshot.isDraggingOver)}
          >
            {state.items.map((item, index) => (
              <Draggable
                key={item[draggableId ?? "id"]}
                draggableId={`${item[draggableId ?? "id"]}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={_getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className={`${withDragIndicator ? "flex-start" : ""}`}
                  >
                    {withDragIndicator && <DragIndicator />}
                    {renderComponent(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDrop;
