import AppRoutes from "./services/AppRoutes"

function App() {
    // const [loading, user] = useAjax("/user")
    return <section className="container">
            <AppRoutes/>
        </section>
}

export default App
