import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Api, Constants, Session } from "fsy.common-library";
import {
  SIMULATION_STATUS_COMPLETED,
  SIMULATION_STATUS_IN_PROGRESS,
  SIMULATION_STATUS_REGISTERED,
} from "fsy.common-library/lib/env/Constants";
import {
  AdvancedStatsSkeleton,
  ChartSkeleton,
  PieChartSkeleton,
} from "../../../services/LoadingHelper";
import { routes as Routing } from "../../../services/RoutesHelper";
import { useObjectState } from "../../../hooks/customHooks";
import {
  RIGHTS_ADMINISTRATION,
  RIGHTS_NOACCESS,
} from "../../../services/Constants";
import { Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { PieChart, StackedBarChart } from "./Charts";
import _ from "lodash";

import "./advancedStats.css";

export default function AdvancedStats() {
  const withUserAdministrationRight =
    Session.getAuth()[Constants.PROFILE_USERMANAGEMENT] ===
    RIGHTS_ADMINISTRATION;
  const withSimulationAdministrationRight =
    Session.getAuth()[Constants.PROFILE_SIMULATION] === RIGHTS_ADMINISTRATION;
  const isOrganism = Session.getSessionUser()?.organization?.organism || false;
  const [state, setState] = useObjectState({
    hasAccess: false,
    loading: true,
    stats: [],
    simulationHistory: null,
    simulationDistribution: null,
  });

  useEffect(() => {
    const allAuth = Session.getAuth();
    const hasaccess = allAuth[Constants.PROFILE_REPORTING] !== RIGHTS_NOACCESS;
    setState({ hasAccess: hasaccess });

    Api.statistics
      .getStatistics({
        withUserAdministrationRight,
        withSimulationAdministrationRight,
      })
      .then((r) => {
        let stats = r?.data;

        setState({
          hasAccess: hasaccess,
          loading: false,
          stats: stats,
          simulationHistory: formatBarChartData(stats.simulationHistory),
          simulationDistribution: formatPieChartData(
            stats.simulationDistribution
          ),
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {" "}
      {state.hasAccess && (
        <>
          <Grid
            container
            className="advancedStats"
            spacing={4}
            justifyContent="space-between"
          >
            <Grid item xs={3} className="stats-coloredCard">
              <NavLink
                to={Routing.bo_users}
                className="card blue link-card"
                title="Accéder à la gestion des utilisateurs"
              >
                {state.loading ? (
                  <AdvancedStatsSkeleton />
                ) : (
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <img
                        src="/img/icon/user.png"
                        alt="user icon"
                        className="advancedStats-img"
                      />
                    </Box>
                    <Typography variant="h3">
                      {state.stats["activeUserCount"]}
                    </Typography>
                    <Typography variant="subtitle2">
                      Utilisateurs actifs
                    </Typography>
                  </Stack>
                )}
              </NavLink>
            </Grid>
            <Grid item xs={3} className="stats-coloredCard">
              <NavLink
                to={Routing.bo_aid}
                className="card yellow link-card"
                title="Accéder à la gestion des aides"
              >
                {state.loading ? (
                  <AdvancedStatsSkeleton />
                ) : (
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <img
                        src="/img/icon/published-aids.png"
                        alt="aids icon"
                        className="advancedStats-img"
                      />
                    </Box>
                    <Typography variant="h3">
                      {state.stats["activeAidCount"]}
                    </Typography>
                    <Typography variant="subtitle2">Aides publiées</Typography>
                  </Stack>
                )}
              </NavLink>
            </Grid>
            {!isOrganism && (
              <Grid item xs={3} className="stats-coloredCard">
                <NavLink
                  to={Routing.bo_simulations}
                  className="card green link-card"
                  title="Accéder à la gestion des simulations"
                >
                  {state.loading ? (
                    <AdvancedStatsSkeleton />
                  ) : (
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <img
                          src="/img/icon/sims.png"
                          alt="simulations icon"
                          className="advancedStats-img"
                        />
                      </Box>
                      <Typography variant="h3">
                        {state.stats["simulationCount"]}
                      </Typography>
                      <Typography variant="subtitle2">
                        Simulations effectuées
                      </Typography>
                    </Stack>
                  )}
                </NavLink>
              </Grid>
            )}
            <Grid item xs={3} className="stats-coloredCard">
              <NavLink
                to={Routing.bo_criterion}
                className="card red link-card"
                title="Accéder à la gestion des critères"
              >
                {state.loading ? (
                  <AdvancedStatsSkeleton />
                ) : (
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <img
                        src="/img/icon/sims-finished.png"
                        alt="criterion icon"
                        className="advancedStats-img"
                      />
                    </Box>
                    <Typography variant="h3">
                      {state.stats["activeCriterionCount"]}
                    </Typography>
                    <Typography variant="subtitle2">
                      Critères activés
                    </Typography>
                  </Stack>
                )}
              </NavLink>
            </Grid>
            {!isOrganism && (
              <>
                <Grid item xs={9} className="stats-bar">
                  <div className="card chart">
                    {state.loading ? (
                      <ChartSkeleton />
                    ) : (
                      <>
                        <h3>Historique des simulations</h3>
                        <StackedBarChart
                          series={state.simulationHistory?.series ?? []}
                          colors={state.simulationHistory?.colors ?? []}
                          labels={state.simulationHistory?.labels ?? []}
                        />
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={3} className="stats-pie">
                  <div className="card chart">
                    {state.loading ? (
                      <PieChartSkeleton />
                    ) : (
                      <>
                        <h3>État des simulations</h3>
                        <PieChart
                          data={state.simulationDistribution?.data ?? []}
                          colors={state.simulationDistribution?.colors ?? []}
                          labels={state.simulationDistribution?.labels ?? []}
                        />
                      </>
                    )}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

function formatPieChartData(data) {
  return {
    colors: [
      "var(--orange-container)",
      "var(--green-container)",
      "var(--blue-container)",
    ],
    labels: ["En cours", "Terminées", "Enregistrées"],
    data: [
      data[SIMULATION_STATUS_IN_PROGRESS],
      data[SIMULATION_STATUS_COMPLETED],
      data[SIMULATION_STATUS_REGISTERED],
    ],
  };
}

function formatBarChartData(data) {
  let result = {
    colors: [
      "var(--orange-container)",
      "var(--green-container)",
      "var(--blue-container)",
    ],
    labels: _.keys(data),
    series: [
      { name: "En cours", data: [] },
      { name: "Terminées", data: [] },
      { name: "Enregistrées", data: [] },
    ],
  };

  _.each(data, (column) => {
    _.each(column, (value, key) => {
      let index =
        parseInt(key) === SIMULATION_STATUS_IN_PROGRESS
          ? 0
          : parseInt(key) === SIMULATION_STATUS_COMPLETED
          ? 1
          : 2;
      result.series[index].data.push(value);
    });
  });

  return result;
}
