import React from "react";
import TooltipSlider from "../../general/form/TooltipSlider";

import "rc-slider/assets/index.css";
import { Constants } from "fsy.common-library";
import { Requiredstar } from "../../general/form/Input";
import MinimaDiversification from "./MinimaDiversification";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";

class CriterionNumericRow extends React.Component {
  static defaultProps = {
    requiredNumericFields: false,
    checkField: false,
    criterion: null,
    readOnly: false,
    aidCriterions: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      criterion: props.criterion,
      aidCriterions: props.aidCriterions,
      visible: false,
      showError: false,
      checked: !!props.aidCriterions[props.criterion.id],
      value: [
        props.aidCriterions[props.criterion.id]
          ? props.aidCriterions[props.criterion.id].value.min ??
            props.criterion.valueMin
          : props.criterion.valueMin,
        props.aidCriterions[props.criterion.id]
          ? props.aidCriterions[props.criterion.id].value.max ??
            props.criterion.valueMax
          : props.criterion.valueMax,
      ],
      marks: {
        [props.criterion.valueMin]: {
          style: {
            fontWeight: "bold",
            fontSize: "0.8em",
          },
          label: props.criterion.valueMin + " " + props.criterion.unit,
        },
        [props.criterion.valueMax]: {
          style: {
            fontWeight: "bold",
            whiteSpace: "nowrap",
            fontSize: "0.8em",
          },
          label: props.criterion.valueMax + " " + props.criterion.unit,
        },
      },
    };

    this.initState = this.initState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setAidCriterionValue = this.setAidCriterionValue.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.onAfterSliderChange = this.onAfterSliderChange.bind(this);
    this.onReset = this.onReset.bind(this);
    this.handleChangeMinimaDiversification =
      this.handleChangeMinimaDiversification.bind(this);
  }

  componentDidMount() {
    this.initState(this.props);
  }

  initState(props) {
    let checked = !!props.aidCriterions[props.criterion.id];
    let min = props.aidCriterions[props.criterion.id]
      ? props.aidCriterions[props.criterion.id].value.min ??
        props.criterion.valueMin
      : props.criterion.valueMin;
    let max = props.aidCriterions[props.criterion.id]
      ? props.aidCriterions[props.criterion.id].value.max ??
        props.criterion.valueMax
      : props.criterion.valueMax;

    this.setState({
      value: [min, max],
      checked: checked,
      marks: {
        [props.criterion.valueMin]: {
          style: {
            fontWeight: "bold",
            fontSize: "0.8em",
          },
          label: props.criterion.valueMin + " " + props.criterion.unit,
        },
        [props.criterion.valueMax]: {
          style: {
            fontWeight: "bold",
            whiteSpace: "nowrap",
            fontSize: "0.8em",
          },
          label: props.criterion.valueMax + " " + props.criterion.unit,
        },
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.aid && this.props.aid.id !== prevProps.aid.id) {
      this.initState(this.props);
    }

    if (
      (this.props.checkField !== prevProps.checkField ||
        this.props.requiredNumericFields !== prevProps.requiredNumericFields) &&
      this.props.checkField
    ) {
      this.setState(
        {
          showError: this.state.criterion.mandatory && !this.state.checked,
        },
        () => {
          let label = "";
          let style = {};

          if (this.state.showError && this.props.requiredNumericFields) {
            label = `${this.props.criterion.valueMin} ${this.props.criterion.unit} (Critère obligatoire)`;
            style = {
              fontWeight: "bold",
              transform: "translateX(-10%)",
              color: "red",
              background: "white",
            };
          } else {
            label = `${this.props.criterion.valueMin} ${this.props.criterion.unit}`;
            style = {
              fontWeight: "bold",
              fontSize: "0.8em",
            };
          }

          this.setState({
            marks: {
              ...this.state.marks,
              [this.props.criterion.valueMin]: {
                ...this.state.marks[this.props.criterion.valueMin],
                label: label,
                style: style,
              },
            },
          });
        }
      );
    }
  }

  handleChange = (e) => {
    this.setState(
      {
        checked: e.target.checked,
      },
      () => {
        this.setAidCriterionValue(e.target.checked);
      }
    );
  };

  onSliderChange = (value) => {
    this.setState({
      value: value,
    });
  };

  onAfterSliderChange = (checked = true) => {
    this.setState(
      {
        checked: checked,
      },
      () => {
        this.setAidCriterionValue(true);
      }
    );
  };

  handleChangeMinimaDiversification(rows) {
    const aidCriterions = this.state.aidCriterions;

    aidCriterions[this.state.criterion.id] = {
      ...aidCriterions[this.state.criterion.id],
      criterion: this.state.criterion,
      value: { answer: rows },
      type: this.state.criterion.type.shortName,
      remove: rows.length === 0,
    };

    this.setState(
      {
        aidCriterions: aidCriterions,
      },
      () => {
        this.props.onInputChange(
          this.state.criterion,
          this.state.aidCriterions
        );
      }
    );
  }

  onReset() {
    this.setState(
      {
        value: [this.props.criterion.valueMin, this.props.criterion.valueMax],
        checked: false,
      },
      () => {
        // this.onAfterSliderChange()
        this.setAidCriterionValue(false);
      }
    );
  }

  setAidCriterionValue = (isChecked) => {
    const aidCriterions = this.state.aidCriterions;

    if (!isChecked) {
      if (aidCriterions[this.state.criterion.id]) {
        aidCriterions[this.state.criterion.id].remove = true;
      }
    } else {
      aidCriterions[this.state.criterion.id] = {
        ...aidCriterions[this.state.criterion.id],
        criterion: this.state.criterion,
        value: { min: +this.state.value[0], max: +this.state.value[1] },
        type: this.state.criterion.type.shortName,
        remove: false,
      };
    }

    this.setState(
      {
        aidCriterions: aidCriterions,
      },
      () => {
        this.props.onInputChange(
          this.state.criterion,
          this.state.aidCriterions
        );
      }
    );
  };

  render() {
    if (this.state.criterion.shortName === "criPlan_01") {
      const aidCriterionValue =
        this.props.aidCriterions[this.props.criterion.id];
      return (
        <div className="aidCriteria-list-item">
          <MinimaDiversification
            disabled={this.props.readOnly}
            criterion={this.state.criterion}
            onChange={this.handleChangeMinimaDiversification}
            required={
              this.props.aid.status.value !== Constants.AID_STATUS_DRAFT &&
              this.state.criterion.mandatory
            }
            rows={
              aidCriterionValue &&
              aidCriterionValue.value &&
              aidCriterionValue.value.answer
                ? aidCriterionValue.value.answer
                : []
            }
            speciesGroup={this.props.speciesGroup}
            checkField={this.props.checkField}
          ></MinimaDiversification>
        </div>
      );
    } else {
      return (
        <div
          className="aidCriteria-list-item"
          title={this.state.criterion.name}
        >
          <div className="text-truncate item-label">
            {this.state.criterion.name}
            {
              // this.props.aid.status.value !== Constants.AID_STATUS_DRAFT && this.state.criterion.mandatory
              this.props.requiredNumericFields && (
                <Requiredstar className="required-star" />
              )
            }
          </div>

          <div className="aidCriteria-slider">
            <TooltipSlider
              range
              min={this.props.criterion.valueMin}
              max={this.props.criterion.valueMax}
              units={this.props.criterion.unit}
              value={this.state.value}
              marks={this.state.marks}
              disabled={this.props.readOnly}
              onChange={this.onSliderChange}
              checked={this.state.checked}
              onAfterChange={this.onAfterSliderChange}
              tipFormatter={(value) =>
                `${GenericHelper.digitWithDecimalPrecision(value)} ${
                  this.props.criterion.unit
                }`
              }
              tipProps={{ placement: "bottom" }}
              visible={this.state.visible}
              step={this.props.criterion.step ?? 1}
              onMinChange={(event) => {
                const value = event.target.value.replace(/[^0-9.]/g, "");
                if (value === "") {
                  this.onReset();
                  return;
                }

                this.setState({
                  value: [value, this.state.value[1]],
                  checked: true,
                });
              }}
              onMaxChange={(event) => {
                const value = event.target.value.replace(/[^0-9.]/g, "");

                if (value === "") {
                  this.onReset();
                  return;
                }

                this.setState({
                  value: [this.state.value[0], value],
                  checked: true,
                });
              }}
              onInputBlur={(result, checked) => {
                if (result[0] === "") {
                  result[0] = this.props.criterion.valueMin;
                }

                if (result[1] === "") {
                  result[1] = this.props.criterion.valueMax;
                }

                this.setState(
                  {
                    value: result,
                    checked: checked,
                  },
                  () => {
                    this.onAfterSliderChange(checked);
                  }
                );
              }}
              onReset={() => {
                this.onReset();
              }}
            />
            <input
              type="checkbox"
              checked={this.state.checked}
              hidden={true}
              onChange={this.handleChange}
            />
          </div>
          <br />
        </div>
      );
    }
  }
}

export default CriterionNumericRow;
