import { FormControl } from "@mui/base";
import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../../general/form/Select";
import { Constants } from "fsy.common-library";
import _ from "lodash";
import Helper from "../../../../services/Helper";

const AidFundingPlant = ({
  editingData,
  readOnly,
  onRequestUpdateData,
  onRequestSetConfirm,
}) => {
  const densityOptions = [
    {
      value: Constants.CURRENT_DENSITY,
      label: "Actuelle",
    },
    {
      value: Constants.PROJECT_DENSITY,
      label: "Projet",
    },
  ];

  const [state, setState] = useState({
    amount: "",
    density: null,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (event, prop = null) => {
    let value = event?.target?.value;
    let name = event?.target?.name;

    if (prop) {
      value = event;
      name = prop;
    }

    _setState({ [name]: value });

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }
  };

  useEffect(() => {
    if (typeof onRequestUpdateData === "function") {
      onRequestUpdateData({
        value: state.amount,
        shortName: "amount",
      });
    }
    // eslint-disable-next-line
  }, [state.amount]);

  useEffect(() => {
    if (typeof onRequestUpdateData === "function") {
      onRequestUpdateData({
        shortName: "density",
        value: state.density?.value || null,
      });
    }
    // eslint-disable-next-line
  }, [state.density?.value]);

  useEffect(() => {
    if (editingData) {
      const stateObject = {};
      if (Helper.isValidNumber(editingData["amount"])) {
        stateObject["amount"] = editingData["amount"];
      }

      if (editingData["density"]) {
        stateObject["density"] = densityOptions.find(
          (element) => element.value === editingData["density"]
        );
      }

      if (!_.isEqual(stateObject, {})) {
        _setState(stateObject);
      }
    }
    // eslint-disable-next-line
  }, [editingData]);

  return (
    <div className="aid-funding-plant-content">
      <div className="criterion-fixed-form-content">
        <label>Montant au plant</label>
        <FormControl className="criterion-fixed-form">
          <TextField
            id="outlined-basic"
            type="number"
            value={state.amount}
            name="amount"
            onChange={_handleFormChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            variant="outlined"
            size="small"
            disabled={readOnly}
          />
        </FormControl>
      </div>
      <div className="criterion-fixed-form-content">
        <label>Densité</label>
        <SelectField
          name="density"
          options={densityOptions}
          value={state.density}
          isMulti={false}
          closeMenuOnSelect={true}
          placeholder=""
          onChange={(data) => _handleFormChange(data, "density")}
          isDisabled={readOnly}
        />
      </div>
    </div>
  );
};

export default AidFundingPlant;
