import React, { useEffect, useRef, useState } from "react";
import { Api, Session } from "fsy.common-library";
import ListLayout from "../../general/ListLayout/ListLayout";
import Helper from "../../../services/Helper";
import { Add as AddIcon } from "@mui/icons-material";
import Modal from "../../general/form/Modal";
import ReferenceDataForm from "./ReferenceDataForm";
import { PROFILE_REFERENCE_DATA_MANAGEMENT } from "fsy.common-library/lib/env/Constants";
import { RIGHTS_READ, RIGHTS_WRITE } from "../../../services/Constants";

const DataLayout = ({
  type,
  title,
  formName,
  isFeminine,
  partialTitleOnCreateButtonHover,
  operationName,
  deleteOperationName,
  checkingCanBeDeletedOperationName,
  countOperationName,
}) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const idInUrl = params.get("id");
  const refSaveButton = useRef(null);

  const [state, setState] = useState({
    skeletonLoading: false,
    loading: false,
    formLoading: false, // Loading dans le formulaire de création
    count: 0,
    rows: [],
    elementId: null,
    hasSubmitted: false,
    modalDisplay: false,
    modalModified: false,
    formType: "create",
    filterFields: [
      {
        type: "text",
        name: "name",
        children: "Libellé",
        value: "",
      },
    ],
  });

  const requests = [
    {
      name: "rows",
      operation: (page, urlParams) => Api[type][operationName](page, urlParams),
      getFormat: (values) => getData(values, type),
    },
    {
      name: "count",
      operation: (urlParams) => Api[type][countOperationName](urlParams),
      getFormat: (element) => getCountData(element),
    },
  ];

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const allAuth = Session.getAuth();
  const withRightsWrite =
    allAuth[PROFILE_REFERENCE_DATA_MANAGEMENT] === RIGHTS_WRITE;
  const readOnly = allAuth[PROFILE_REFERENCE_DATA_MANAGEMENT] === RIGHTS_READ;

  const columns = [
    {
      width: "84vw",
      name: "Libellé",
      sortable: true,
      selector: (row) =>
        Helper.FormatClickableText(row.name, () => {
          _setState({
            formType: "update",
            modalDisplay: true,
            elementId: row.id,
            hasSubmitted: false,
          });
        }),
    },
  ];

  const handleDataChange = (newValue) => {
    _setState(newValue);
  };

  const handleCreateButtonClick = () => {
    _setState({
      modalDisplay: true,
      hasSubmitted: false,
    });
  };

  const handleModalClose = () => {
    _setState({
      modalDisplay: false,
      modalModified: false,
      formType: "create",
      elementId: null,
    });
  };

  const handleSubmitAfterConfirm = () => {
    refSaveButton.current.click();
  };

  const handleRequestSubmitSuccess = () => {
    _setState({
      modalDisplay: false,
      modalModified: false,
      hasSubmitted: true,
      elementId: null,
    });
  };

  useEffect(() => {
    if (idInUrl) {
      _setState({ modalDisplay: true, formType: "update" });
    }
  }, [idInUrl]);

  return (
    <article className="list-layout">
      <section className="bo-data-title reference-data-title">
        <h3>{title}</h3>
        {withRightsWrite && (
          <button
            className={`btn btn-tiny default addButton ${
              state.loading || state.skeletonLoading || state.formLoading
                ? "disabled"
                : ""
            }`}
            title={`Cliquer pour ajouter un${
              isFeminine ? "e" : ""
            } ${partialTitleOnCreateButtonHover}`}
            onClick={handleCreateButtonClick}
          >
            <AddIcon />
            {`Ajouter un${
              isFeminine ? "e" : ""
            } ${partialTitleOnCreateButtonHover}`}
          </button>
        )}
        <Modal
          title={`${state.formType === "create" ? "Créer" : "Modifier"} un${
            isFeminine ? "e" : ""
          } ${partialTitleOnCreateButtonHover}`}
          hide={handleModalClose}
          isShowing={state.modalDisplay}
          confirm={state.modalModified}
          saveBeforeClose={handleSubmitAfterConfirm}
        >
          <ReferenceDataForm
            type={formName}
            entityType={type}
            formType={state.formType}
            onRequestSubmitSuccess={handleRequestSubmitSuccess}
            isFeminine={isFeminine}
            id={state.elementId}
            label={partialTitleOnCreateButtonHover}
            loading={state.formLoading}
            onRequestLoadingChange={(value) => handleDataChange(value)}
            onRequestDataChange={(modalModified) =>
              handleDataChange({ modalModified })
            }
            readOnly={readOnly}
            refSaveButton={refSaveButton}
            idInUrl={idInUrl}
          />
        </Modal>
      </section>
      <ListLayout
        skeletonLoading={state.skeletonLoading}
        loading={state.loading}
        title={title}
        type={type}
        label={partialTitleOnCreateButtonHover}
        isFeminine={isFeminine}
        formName={formName}
        deleteOperationName={deleteOperationName}
        checkingCanBeDeletedOperationName={checkingCanBeDeletedOperationName}
        columns={columns}
        rows={state.rows}
        requests={requests}
        totalRows={state.count}
        hasSubmitted={state.hasSubmitted}
        filterFields={state.filterFields}
        getElementFilterParams={(elements, rowsPerPage) =>
          getDataFilterParams(elements, rowsPerPage)
        }
        onRequestDataChange={handleDataChange}
        withDeleteButton={true}
        withRightsWrite={withRightsWrite}
        routeRedirectionNameInDeleteWarningModal={
          formName !== "criteria-theme" ? "aids" : "criterion"
        }
      />
    </article>
  );
};

export default DataLayout;

/* ================================== GLOBAL FUNCTIONS ================================== */
const getData = (elements, type) => {
  return (
    elements?.map((element) => {
      let value = {
        id: element.id,
        name: element.name,
      };

      if (element.hasOwnProperty("shortName")) {
        value.shortName = element.shortName;
      }

      return value;
    }) || []
  );
};

const getCountData = (element) => {
  return element?.count || 0;
};

const getDataFilterParams = (filters, rowsPerPage = 10) => {
  const params = ["name"];
  const urlParams = {};

  filters?.map((filter) => {
    if (params.includes(filter.name) && filter.value) {
      if (filter.name === "name") {
        urlParams["filterText"] = filter.value;
      }
    }

    return null;
  });

  urlParams["per_page"] = rowsPerPage;

  return urlParams;
};
