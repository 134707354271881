import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import { Add, Close, Delete, Save } from "@mui/icons-material";
import { toast } from "react-toastify";
import Helper from "../../../services/Helper";
import { Requiredstar } from "../../general/form/Input";
import AidHelper from "../../../services/AidHelper";

class MinimaDiversification extends React.Component {
  constructor(props) {
    super(props);

    const rows = props.rows.map((row) => {
      return AidHelper.orderMinimaCriterionKeyValues(row);
    });

    this.state = {
      openDialog: false,
      header: {
        nb_ha: {
          unit: "",
          label: "Nombre d'Ha",
        },
        nb_plants: {
          unit: "",
          label: "Nombre de plants",
        },
        nb_different_species: {
          unit: "",
          label: "Nombre d'essences différentes",
        },
        nb_native_species: {
          unit: "",
          label: "Nombre d'essences autochtones",
        },
        proportion_native_species: {
          unit: "%",
          label: "Proportion d'essences autochtones",
        },
        nb_species_proportion: {
          unit: "",
          label: "Nombre d'essences en proportion",
        },
        proportion_species: {
          unit: "%",
          label: "Proportion d'essences",
        },
        group1: {
          unit: "",
          label: "Groupe 1",
        },
        specie1: {
          unit: "",
          label: "Essence 1",
        },
        proportion1: {
          unit: "%",
          label: "Proportion 1",
        },
        group2: {
          unit: "",
          label: "Groupe 2",
        },
        specie2: {
          unit: "",
          label: "Essence 2",
        },
        proportion2: {
          unit: "%",
          label: "Proportion 2",
        },
        group3: {
          unit: "",
          label: "Groupe 3",
        },
        specie3: {
          unit: "",
          label: "Essence 3",
        },
        proportion3: {
          unit: "%",
          label: "Proportion 3",
        },
        group4: {
          unit: "",
          label: "Groupe 4",
        },
        specie4: {
          unit: "",
          label: "Essence 4",
        },
        proportion4: {
          unit: "%",
          label: "Proportion 4",
        },
        group5: {
          unit: "",
          label: "Groupe 5",
        },
        specie5: {
          unit: "",
          label: "Essence 5",
        },
        proportion5: {
          unit: "%",
          label: "Proportion 5",
        },
      },
      rows: rows,
      validRows: rows,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.addNewGroup = this.addNewGroup.bind(this);
  }

  componentDidMount() {
    if (!this.props.rows.length && !this.props.disabled) {
      this.addNewGroup();
    }
  }

  handleClickOpen() {
    this.setState({
      openDialog: true,
    });
  }

  handleClose() {
    this.setState({
      openDialog: false,
    });
  }

  handleChange(event, index) {
    const name = event.target.name;
    const value = event.target.value;
    const row = this.state.rows[index];
    row[name] = value;

    this.setState({
      rows: this.state.rows,
    });
  }

  save() {
    let error = false;
    const validRows = this.state.rows
      .map((data, index) => {
        Object.keys(data).forEach((key) => {
          if (data[key]) {
            data[key] = +data[key];
          }
        });

        const res = this.checkConstraints(data);
        if (res.error && !error) {
          error = res.error;
          toast.warning(res.message, Helper.getToastOptions());
          const rows = this.state.rows;

          rows[index] = data;

          this.setState({
            rows: rows,
          });
        }

        if (!res.error) {
          const validGroupSpecie = [];

          for (let i = 1; i <= 5; i++) {
            if (
              data["group" + i] ||
              data["specie" + i] ||
              data["proportion" + i]
            ) {
              validGroupSpecie.push({
                group: data["group" + i],
                specie: data["specie" + i],
                proportion: data["proportion" + i],
              });

              data["group" + i] = "";
              data["specie" + i] = "";
              data["proportion" + i] = "";
            }
          }

          validGroupSpecie.forEach((vgs, i) => {
            data["group" + (i + 1)] = vgs["group"];
            data["specie" + (i + 1)] = vgs["specie"];
            data["proportion" + (i + 1)] = vgs["proportion"];
          });
        }

        return data;
      })
      .filter((data) => !this.checkConstraints(data).error);

    if (!error) {
      const hasUniqueValue = structuredClone(validRows)
        .map((value) => {
          return JSON.stringify(value);
        })
        .every((value, index, self) => {
          return self.indexOf(value) === index;
        });

      if (!hasUniqueValue) {
        toast.warning(
          "Deux lignes ne peuvent pas avoir la même combinaison de données.",
          Helper.getToastOptions()
        );
        return;
      }

      this.props.onChange(validRows);

      this.setState(
        {
          validRows: validRows,
        },
        () => {
          this.handleClose();
        }
      );
    }
  }

  checkConstraints(data) {
    const {
      nb_different_species,
      nb_species_proportion,
      proportion_species,
      nb_native_species,
      proportion_native_species,
    } = data;

    let result = {
      error: false,
      message: "",
    };

    if (
      !nb_different_species &&
      !nb_native_species &&
      !proportion_native_species
    ) {
      result = {
        error: true,
        message:
          "Au moins l'un des champs suivants doit être saisi: Nombre d'essences différentes, Nombre d'essences autochtones différentes, Proportion d'essences autochtones minimum",
      };
    }

    if (
      (nb_species_proportion && !proportion_species) ||
      (!nb_species_proportion && proportion_species)
    ) {
      result = {
        error: true,
        message:
          "Si Nombre d'essences en proportion est saisi, alors Proportion d'essences minimum doit être saisi (et inversement)",
      };
    }

    const totalProportions = [
      data.proportion1,
      data.proportion2,
      data.proportion3,
      data.proportion4,
      data.proportion5,
    ].reduce((accumulator, currentValue) => +accumulator + +currentValue);
    if (totalProportions > 100) {
      result = {
        error: true,
        message:
          "Le total de la Proportion des 5 blocs ne doit pas faire plus de 100%",
      };
    }

    const groupSpecies = [];
    for (let i = 1; i <= 5; i++) {
      if (data["group" + i] || data["specie" + i]) {
        groupSpecies.push({
          group: data["group" + i],
          specie: data["specie" + i],
        });
      }
    }

    const hasUniqueGroupSpecies = groupSpecies
      .map((value) => {
        return JSON.stringify(value);
      })
      .every((value, index, self) => {
        return self.indexOf(value) === index;
      });

    if (!hasUniqueGroupSpecies) {
      result = {
        error: true,
        message: "On ne peut pas avoir la même combinaison Groupe / Essence",
      };
    }

    return result;
  }

  addNewGroup() {
    const row = {
      nb_ha: "",
      nb_plants: "",
      nb_different_species: "",
      nb_native_species: "",
      proportion_native_species: "",
      nb_species_proportion: "",
      proportion_species: "",
      group1: "",
      specie1: "",
      proportion1: "",
      group2: "",
      specie2: "",
      proportion2: "",
      group3: "",
      specie3: "",
      proportion3: "",
      group4: "",
      specie4: "",
      proportion4: "",
      group5: "",
      specie5: "",
      proportion5: "",
    };

    this.setState({
      rows: [...this.state.rows, row],
    });
  }

  getObjectKeys(object) {
    return Object.keys(object);
  }

  getSpecies(groupId) {
    const filteredSpecies = this.props.speciesGroup.filter(
      (g) => g.id === groupId
    );
    if (filteredSpecies.length) {
      return filteredSpecies[0].species.filter((s) => s.active);
    }

    return [];
  }

  render() {
    const header = this.state.header;
    const speciesGroup = this.props.speciesGroup;
    const groupPattern = /(group1|group2|group3|group4|group5)/;
    const speciePattern = /(specie1|specie2|specie3|specie4|specie5)/;

    return (
      <div>
        <div>
          {this.props.criterion.name}
          {this.props.required && (
            <Requiredstar
              style={{
                position: "relative",
                display: "inline",
                top: "-3px",
                left: "3px",
              }}
            />
          )}
        </div>
        <div style={{ marginTop: 7, marginBottom: 7 }}>
          <b>{this.state.validRows.length}</b> cas configuré(s)
        </div>

        <div
          style={{ marginTop: 5 }}
          className={
            this.props.checkField &&
            this.props.criterion.mandatory &&
            this.state.validRows.length === 0
              ? "is-invalid"
              : ""
          }
        >
          <span className="error">Critère obligatoire</span>
        </div>

        <button
          style={{ marginTop: 7, marginLeft: 0 }}
          onClick={this.handleClickOpen}
          className="btn btn-tiny white"
          type="button"
        >
          {!this.props.disabled ? "Configurer" : "Voir"}
        </button>

        <Dialog
          fullWidth={true}
          maxWidth="xl"
          open={this.state.openDialog}
          onClose={this.handleClose}
        >
          <DialogTitle>
            {this.props.criterion.name}
            <IconButton
              aria-label="close"
              onClick={this.handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <TableContainer component={Paper} sx={{ height: 440 }}>
              <Table
                stickyHeader
                size="small"
                aria-label="simple table"
                className="compact"
              >
                <TableHead>
                  <TableRow>
                    {this.getObjectKeys(header).map((index) => (
                      <TableCell key={index}>{header[index].label}</TableCell>
                    ))}
                    {!this.props.disabled && (
                      <TableCell
                        style={{ minWidth: 75 }}
                        key={Helper.generateUUID()}
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {this.getObjectKeys(row).map((key) => (
                        <TableCell
                          align={
                            !(groupPattern.test(key) || speciePattern.test(key))
                              ? "right"
                              : "left"
                          }
                          key={key}
                        >
                          {!groupPattern.test(key) &&
                            !speciePattern.test(key) && (
                              <FormControl size="small" sx={{ minWidth: 120 }}>
                                <TextField
                                  name={key}
                                  disabled={this.props.disabled}
                                  value={row[key]}
                                  onChange={(event) => {
                                    this.handleChange(event, i);
                                  }}
                                  size="small"
                                  type="number"
                                  InputProps={
                                    header[key].unit
                                      ? {
                                          endAdornment: (
                                            <InputAdornment position="start">
                                              {header[key].unit}
                                            </InputAdornment>
                                          ),
                                        }
                                      : null
                                  }
                                />
                              </FormControl>
                            )}

                          {groupPattern.test(key) && (
                            <FormControl sx={{ width: "100%" }} size="small">
                              <Select
                                value={row[key]}
                                displayEmpty
                                disabled={this.props.disabled}
                                fullWidth
                                name={key}
                                onChange={(event) => {
                                  this.handleChange(event, i);
                                }}
                                inputProps={{
                                  "aria-label": "Groupe d'essences",
                                }}
                              >
                                <MenuItem key={new Date().getTime()} value="">
                                  <em>Aucune</em>
                                </MenuItem>
                                {speciesGroup.map((group) => (
                                  <MenuItem key={group.id} value={group.id}>
                                    {group.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}

                          {speciePattern.test(key) && (
                            <FormControl sx={{ width: "100%" }} size="small">
                              <Select
                                value={row[key]}
                                displayEmpty
                                disabled={this.props.disabled}
                                fullWidth
                                name={key}
                                onChange={(event) => {
                                  this.handleChange(event, i);
                                }}
                                inputProps={{
                                  "aria-label": "Groupe d'essences",
                                }}
                              >
                                <MenuItem key={new Date().getTime()} value="">
                                  <em>Aucune</em>
                                </MenuItem>
                                {this.getSpecies(
                                  row[key.replace("specie", "group")]
                                ).map((specie) => (
                                  <MenuItem key={specie.id} value={specie.id}>
                                    {specie.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>
                      ))}
                      {!this.props.disabled && (
                        <TableCell>
                          <button
                            onClick={() => {
                              const rows = this.state.rows.filter(
                                (data, key) => {
                                  return key !== i;
                                }
                              );

                              this.setState(
                                {
                                  rows: rows,
                                },
                                () => {
                                  this.props.onChange(this.state.rows);
                                }
                              );
                            }}
                            className="btn btn-tiny red"
                            type="button"
                            title="Supprimer une groupe d'essence"
                          >
                            <Delete />
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {!this.props.disabled && (
              <div>
                <br />
                <button
                  onClick={this.addNewGroup}
                  className="btn btn-tiny white"
                  type="button"
                >
                  <Add />
                  Nouveau
                </button>
              </div>
            )}
          </DialogContent>

          {!this.props.disabled && (
            <DialogActions>
              <button
                className="btn default btn-lg"
                type="submit"
                title="Enregistrer les données saisies"
                onClick={this.save}
              >
                <Save />
                Enregistrer
              </button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default MinimaDiversification;
