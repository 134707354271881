import { Constants } from "fsy.common-library"
import Helper from "./Helper"

function orderMinimaCriterionKeyValues(value) {
    const sortOrder = [
        "nb_ha",
        "nb_plants",
        "nb_different_species",
        "nb_native_species",
        "proportion_native_species",
        "nb_species_proportion",
        "proportion_species",
        "group1",
        "specie1",
        "proportion1",
        "group2",
        "specie2",
        "proportion2",
        "group3",
        "specie3",
        "proportion3",
        "group4",
        "specie4",
        "proportion4",
        "group5",
        "specie5",
        "proportion5"
    ]

    const orderedObject = {}

    sortOrder.forEach((key) => {
        if (value.hasOwnProperty(key)) {
            orderedObject[key] = value[key]
        }
    })

    return orderedObject
}

function getFundingScaleDefaultCriteriaData(criteria) {
    const sortOrder = [
        "descrip_13",
        "descrip_14",
        "general_02",
        "general_12",
        "loca_01",
        "general_01"
    ]

    return criteria.map(criterion => {
        if (criterion.shortName === "loca_01") {
            return {
                type: criterion.shortName,
                value: { type: null, answer: null },
                criterion: criterion
            }
        }
        return {
            type: criterion.shortName,
            value: { answer: "" },
            criterion: criterion
        }
    }).sort((a, b) => {
        const indexA = sortOrder.indexOf(a.type)
        const indexB = sortOrder.indexOf(b.type)

        return indexA - indexB
    })
}

function getFundingFixedAmountDefaultCriteriaData(criteria) {
    const sortOrder = [
        "travaux_09",
        "travaux_23"
    ]

    return criteria.map(criterion => {
        return {
            type: criterion.shortName,
            value: {
                answer: ""
            },
            criterion: criterion
        }
    }).sort((a, b) => {
        const indexA = sortOrder.indexOf(a.type)
        const indexB = sortOrder.indexOf(b.type)

        return indexA - indexB
    })
}

function formatAidFundingValue(elements) {
    const values = []
    const fixedFundingValues = ["amount", "baseAmount", "rate", "density"]

    elements.map((element) => {
        let line = {}
        let criterions = []

        Object.entries(element).forEach(([key, value]) => {
            if (key !== "id") {
                if (!fixedFundingValues.includes(key)) {
                    let data = value?.value
                    let type = value?.type
                    let criterionId = value?.criterionId
                    let shortName = value?.shortName
                    let condition = false

                    if (data && type && shortName && criterionId) {
                        switch (type) {
                            case Constants.CRITERION_TYPE_TXT:
                                condition = Array.isArray(data) ? data.length > 0 : Boolean(data)
                                break;
                            case Constants.CRITERION_TYPE_OBG:
                                condition = Boolean(data)
                                break;
                            case Constants.CRITERION_TYPE_LOC:
                                condition = Boolean(data.type)

                                if (condition) {
                                    data = {
                                        type: data.type,
                                        answer: data.location
                                    }
                                }
                                break;
                            case Constants.CRITERION_TYPE_NUM:
                                if (shortName !== "criPlan_01") {
                                    condition = Array.isArray(data) && data.length === 2 && Helper.isValidNumber(data[0]) && Helper.isValidNumber(data[1])

                                    if (condition) {
                                        data = {
                                            min: data[0],
                                            max: data[1]
                                        }
                                    }
                                }
                                else {
                                    condition = Boolean(data.specieGroup)
                                }
                                break;
                            default:
                                break;
                        }
                    }

                    if (condition) {
                        criterions.push({
                            value: { answer: data },
                            criterion: `/api/criteria/${criterionId}`
                        })
                    }
                }
                else {
                    if (Helper.isValidNumber(value)) {
                        line[key] = +value
                    } else {
                        line[key] = null
                    }
                }
            }
        })

        if (criterions.length > 0) {
            line.criteria = criterions
        }

        if (element?.hasOwnProperty("id") && Helper.isValidNumber(element["id"])) {
            line["id"] = +element["id"]
        }

        values.push(line)

        return null
    })

    return values
}

const exportedFunctions = {
    orderMinimaCriterionKeyValues,
    getFundingScaleDefaultCriteriaData,
    getFundingFixedAmountDefaultCriteriaData,
    formatAidFundingValue
}

export default exportedFunctions
