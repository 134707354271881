import React, { useState } from 'react'
import './step.css'
import { Delete, DragIndicator, Edit, MoreVert } from '@mui/icons-material'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

const Step = ({
    data,
    active,
    withWriteAuth,
    onRequestChooseStep,
    onRequestItemClick
}) => {
    const items = [
        {
            action: 'update',
            icon: <Edit className='step-update-icon' />,
            label: "Renommer l'étape",
            disabled: !withWriteAuth
        },
        {
            action: 'delete',
            icon: <Delete className='step-delete-icon' />,
            label: "Supprimer",
            disabled: !withWriteAuth || data?.questions?.length > 0
        }
    ]

    const [state, setState] = useState({
        anchorEl: null,
        open: false
    })

    const _setState = (newValue) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...newValue
            }
        })
    }

    const _handleIconButtonClick = (event) => {
        _setState({ anchorEl: event.currentTarget })
    }

    const _handleItemClick = (item) => {
        _setState({ anchorEl: null })
        onRequestItemClick(item)
    }

    return (
        <div className={`step-root ${active ? 'active-step' : ''}`}>
            <div className='draggable-icon-content'>
                <DragIndicator />
            </div>
            <div className='step-number'>{data.position}</div>
            <div className='step-name' onClick={() => onRequestChooseStep(data)}><span>{data.name}</span></div>
            <div className='questions-count'>{`${(data.questionsCount !== 0 && data.questionsCount < 10) ? '0' : ''}${data.questionsCount}`}</div>
            <div>
                <IconButton
                    id="basic-button"
                    aria-controls={state.open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={state.open ? 'true' : undefined}
                    onClick={_handleIconButtonClick}
                    title="Actions"
                    className='action-button'
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={state.anchorEl}
                    open={Boolean(state.anchorEl)}
                    onClose={() => _setState({ anchorEl: null }) }
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        items?.map((item, i) => {
                            return (
                                <MenuItem
                                    key={`MenuItem${i+1}`}
                                    disabled={item.disabled}
                                    onClick={() => _handleItemClick(item) }
                                >
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText>{item.label}</ListItemText>
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </div>
        </div>
    )
}

export default Step