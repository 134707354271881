import React from "react"
import {NavLink} from "react-router-dom"
import { routes as Routing } from './../../services/RoutesHelper';

const DataTabs = () => {
    const tabs = [
        {
            link: Routing.bo_data_aid_nature,
            title: "Gérer les natures d'aides",
            label: "Natures d'aides"
        },
        {
            link: Routing.bo_data_aid_complexity,
            title: "Gérer les complexités d'aides",
            label: "Complexités d'aides"
        },
        {
            link: Routing.bo_data_aid_form,
            title: "Gérer les formes d'aides",
            label: "Formes d'aides"
        },
        {
            link: Routing.bo_data_aid_environment,
            title: "Gérer les milieux",
            label: "Milieux"
        },
        {
            link: Routing.bo_data_aid_scheme,
            title: "Gérer les dispositifs",
            label: "Dispositifs"
        },
        {
            link: Routing.bo_data_criteria_theme,
            title: "Gérer les catégories de critères",
            label: "Catégories de critères"
        },
    ]

    return (
        <article className="bo-tabs">
            {
                tabs.map((tab, index) => {
                    return (
                        <div key={`TabItem${index+1}`} className="bo-tabs-item">
                            <NavLink to={tab.link} title={tab.title} end>
                                <span>{tab.label}</span>
                            </NavLink>
                        </div>
                    )
                })
            }
        </article>
    )
}

export default DataTabs