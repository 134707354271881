import React from "react";
import { Api, Constants, Session } from "fsy.common-library";
import { InputField, RequiredText } from "../general/form/Input";
import Helper from "../../services/Helper";
import { Loading } from "../general/form/Loading";
import ReactSwitch from "react-switch";
import { Save } from "@mui/icons-material";
import { RIGHTS_READ } from "../../services/Constants";

export class ProfileForm extends React.Component {
  static defaultProps = {
    type: "create",
    profile: null,
    onSubmit: () => {
      console.log("submited");
    },
  };

  constructor(props) {
    super(props);

    this.readOnly =
      Session.getAuth()[Constants.PROFILE_PROFILEMANAGEMENT] === RIGHTS_READ;
    this.state = {
      loading: false,
      selectedTypeOption: null,
      profile: this.createEmptyProfileObject(),
    };

    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeDefaultProfile =
      this.handleChangeDefaultProfile.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.handleChangePartnerDefaultProfile =
      this.handleChangePartnerDefaultProfile.bind(this);
    this.handleChangeOrganismDefaultProfile =
      this.handleChangeOrganismDefaultProfile.bind(this);
  }

  createEmptyProfileObject() {
    return {
      id: 0,
      trigram: "",
      label: "",
      defaultProfile: false,
      partnerDefaultProfile: false,
      organismDefaultProfile: false,
    };
  }

  componentDidMount() {
    if (this.props.type === "edit" && this.props.profile) {
      const profile = [this.props.profile];
      const profile_obj = profile.map(({ users, ...rest }) => rest);
      this.setState({
        profile: profile_obj[0],
      });
    }
  }

  handleChangeText(field, e) {
    this.setState({
      profile: {
        ...this.state.profile,
        [field]: e.target.value,
      },
    });
    this.props.modalModify(true);
  }

  handleChangeDefaultProfile(state) {
    this.setState({
      profile: {
        ...this.state.profile,
        defaultProfile: state,
      },
    });
    this.props.modalModify(true);
  }

  handleChangePartnerDefaultProfile(state) {
    this.setState({
      profile: {
        ...this.state.profile,
        partnerDefaultProfile: state,
      },
    });
    this.props.modalModify(true);
  }

  handleChangeOrganismDefaultProfile(state) {
    this.setState({
      profile: {
        ...this.state.profile,
        organismDefaultProfile: state,
      },
    });
    this.props.modalModify(true);
  }

  checkForm() {
    return (
      this.state.profile.trigram.match(/^[A-Z]{3}$/) &&
      this.state.profile.label !== ""
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (!this.checkForm()) {
      this.props.onSubmit(
        false,
        "Les deux champs sont obligatoires, le libellé court doit comporter 3 lettres majuscules exactement"
      );
      return;
    }
    this.setState({ loading: true });
    if (this.props.type === "create") {
      Api.profile
        .createProfile(
          this.state.profile.trigram,
          this.state.profile.label,
          this.state.profile.defaultProfile,
          this.state.profile.partnerDefaultProfile,
          this.state.profile.organismDefaultProfile
        )
        .then((r) => {
          if (r?.status !== 201) {
            if (
              r.hasOwnProperty("hydra:description") &&
              r["hydra:description"].indexOf("Integrity constraint violation") >
                0
            ) {
              this.props.onSubmit(false, `Ce libellé court a déjà été utilisé`);
            } else {
              this.props.onSubmit(
                false,
                `Oops ! Une erreur est survenue lors de la création du profil : ${r.message}`
              );
            }
            this.setState({ loading: false });
            return;
          }

          const result = Helper.isValidResponse(r);
          if (result) {
            this.props.onSubmit(true, "Profil créé ✔");
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else if (this.props.type === "edit") {
      Api.profile
        .updateProfile(this.state.profile.id, this.state.profile)
        .then((r) => {
          // console.log('result', r)
          if (r?.status !== 200) {
            if (
              r.hasOwnProperty("hydra:description") &&
              r["hydra:description"].indexOf("Integrity constraint violation") >
                0
            ) {
              this.props.onSubmit(false, `Ce libellé court a déjà été utilisé`);
            } else {
              this.props.onSubmit(
                false,
                `Oops ! Une erreur est survenue lors de la création du profil : ${r.message}`
              );
            }
            this.setState({ loading: false });
            return;
          }

          const result = Helper.isValidResponse(r);
          if (result) {
            this.props.onSubmit(true, "Profil modifié ✔");
          }
        });
    }
  }

  render() {
    return (
      <>
        {this.state.loading && <Loading />}
        <form
          id="profile-form"
          onSubmit={this.handleFormSubmit}
          className="user-form"
        >
          <div>
            <InputField
              name="profileContactName"
              isFocused={this.state.profile?.trigram !== ""}
              value={this.state.profile.trigram}
              onChange={(e) => this.handleChangeText("trigram", e)}
              title="Libellé court"
              context={this}
              required={true}
              disabled={this.readOnly}
            >
              Libellé court (3 lettres majuscules)
            </InputField>
            <InputField
              name="profileName"
              isFocused={this.state.profile?.label !== ""}
              value={this.state.profile.label}
              onChange={(e) => this.handleChangeText("label", e)}
              title="Libellé long"
              context={this}
              required={true}
              disabled={this.readOnly}
            >
              Libellé long
            </InputField>

            <div className="formField">
              <table>
                {/** Profil attribué par défaut au niveau du FRONT */}
                <tr>
                  <td>Profil attribué par défaut pour les forestiers ?</td>
                  <td className="flex-start profile-switch-column">
                    <ReactSwitch
                      checked={this.state.profile.defaultProfile ?? false}
                      onColor="#a6d290"
                      offColor="#fc9999"
                      onChange={this.handleChangeDefaultProfile}
                      disabled={this.readOnly}
                    />
                    <div className="profile-switch-label">
                      {this.state.profile.defaultProfile ? "OUI" : "NON"}{" "}
                    </div>
                  </td>
                </tr>

                {/** Profil attribué par défaut pour les partenaires */}
                <tr>
                  <td>Profil attribué par défaut pour les partenaires ?</td>
                  <td className="flex-start profile-switch-column">
                    <ReactSwitch
                      checked={
                        this.state.profile.partnerDefaultProfile ?? false
                      }
                      onColor="#a6d290"
                      offColor="#fc9999"
                      onChange={this.handleChangePartnerDefaultProfile}
                      disabled={this.readOnly}
                    />
                    <div className="profile-switch-label">
                      {this.state.profile.partnerDefaultProfile ? "OUI" : "NON"}{" "}
                    </div>
                  </td>
                </tr>

                {/** Profil attribué par défaut pour les organismes */}
                <tr>
                  <td>Profil attribué par défaut pour les organismes ?</td>
                  <td className="flex-start profile-switch-column">
                    <ReactSwitch
                      checked={
                        this.state.profile.organismDefaultProfile ?? false
                      }
                      onColor="#a6d290"
                      offColor="#fc9999"
                      onChange={this.handleChangeOrganismDefaultProfile}
                      disabled={this.readOnly}
                    />
                    <div className="profile-switch-label">
                      {this.state.profile.organismDefaultProfile
                        ? "OUI"
                        : "NON"}{" "}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {!this.readOnly && (
            <div className="user-form-footer flex-sb">
              <button
                className="btn default btn-lg"
                type="submit"
                title="Enregistrer les données saisies"
                ref={this.props.forwardRef}
              >
                <Save />
                Enregistrer
              </button>
              <RequiredText />
            </div>
          )}
        </form>
      </>
    );
  }
}

/* ================================== GLOBAL FUNCTIONS ================================== */
