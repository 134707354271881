import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/base";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import Helper from "../../../../services/Helper";

const AidFundingScale = ({
  editingData,
  readOnly,
  onRequestUpdateData,
  onRequestSetConfirm,
}) => {
  const [state, setState] = useState({
    baseAmount: "",
    rate: "",
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (event) => {
    const { value, name } = event.target;

    _setState({ [name]: value });

    if (typeof onRequestUpdateData === "function") {
      onRequestUpdateData({
        shortName: name,
        value,
      });
    }

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }
  };

  useEffect(() => {
    if (editingData) {
      const stateObject = {};

      if (Helper.isValidNumber(editingData["baseAmount"])) {
        stateObject.baseAmount = editingData.baseAmount;
      }

      if (Helper.isValidNumber(editingData["rate"])) {
        stateObject.rate = editingData.rate;
      }

      if (!_.isEqual(stateObject, {})) {
        _setState(stateObject);
      }
    }
  }, [editingData]);

  return (
    <>
      {/** Montant de base */}
      <div className="criterion-fixed-form-content">
        <label>Montant de base</label>
        <FormControl className="criterion-fixed-form">
          <TextField
            id="outlined-basic"
            type="number"
            value={state.baseAmount}
            name="baseAmount"
            onChange={_handleFormChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">€/Ha</InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            disabled={readOnly}
          />
        </FormControl>
      </div>

      {/** Taux de l'aide */}
      <div className="criterion-fixed-form-content">
        <label>Taux de l'aide</label>
        <FormControl className="criterion-fixed-form">
          <TextField
            id="outlined-basic"
            type="number"
            value={state.rate}
            name="rate"
            onChange={_handleFormChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            variant="outlined"
            size="small"
            disabled={readOnly}
          />
        </FormControl>
      </div>
    </>
  );
};

export default AidFundingScale;
