import React from "react";
import { NavLink } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import { Constants, Session } from "fsy.common-library";

export default class SimulatorTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.handleTabClick = this.handleTabClick.bind(this)
  }

  render() {
    const allAuth = Session.getAuth();
    const withSimulationAuth = allAuth[Constants.PROFILE_SIMULATION] > 0;
    const withSimulatorAuth = allAuth[Constants.PROFILE_SIMULATOR] > 0;

    return (
      <article className="bo-tabs">
        {withSimulationAuth && (
          <div className="bo-tabs-item">
            <NavLink
              to={Routing.bo_simulations}
              title="Consulter les simulations effectuées"
            >
              <span>Simulations</span>
            </NavLink>
          </div>
        )}
        {withSimulatorAuth && (
          <div className="bo-tabs-item">
            <NavLink to={Routing.bo_simulator} title="Gérer le simulateur">
              <span>Simulateur</span>
            </NavLink>
          </div>
        )}
      </article>
    );
  }
}
