import React, { useEffect, useState } from 'react'
import { InputField } from '../../general/form/Input'
import { Save } from "@mui/icons-material";
import './aidFolder.css'
import { toast } from 'react-toastify';
import Helper from '../../../services/Helper'
import { Api } from 'fsy.common-library';
import { Loading } from '../../general/form/Loading';
import { HTTP_CREATED, HTTP_OK, HTTP_FOLDER_ALREADY_EXIST } from 'fsy.common-library/lib/env/Constants';

const AidFolder = ({
    type,
    subfolderFolderId,
    readOnly,
    modalModify,
    forwardRef,
    folderId,
    onRequestSubmitSuccess
}) => {
    const formName = `Nom du ${type === 'folder' ? 'dossier' : 'sous-dossier'}`

    const [state, setState] = useState({
        loading: false,
        name: ""
    })

    const _setState = (newValue) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...newValue
            }
        })
    }

    const handleFormChange = (event) => {
        const { value, name } = event.target

        _setState({ [name]: value })
        modalModify(true)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()

        if(state.name){
            const typeId = type === 'folder' ? 1 : 2
            const operation = folderId ? Api.folder.updateFolder(folderId, state.name) : Api.folder.createFolder(state.name, typeId, subfolderFolderId)

            _setState({ loading: true })

            operation.then(response => {
                const status = response?.status
                const expectedStatus = folderId ? HTTP_OK : HTTP_CREATED

                if(status === expectedStatus){
                    const result = Helper.isValidResponse(response)

                    if(result){
                        _setState({ name: "" })
                        toast.success(`Le ${type === 'folder' ? 'dossier' : 'sous-dossier'} a été ${folderId ? "mis à jour" : "enregistré"}`, Helper.getToastOptions())
                        onRequestSubmitSuccess()
                    }
                }
                else{
                    if(status === HTTP_FOLDER_ALREADY_EXIST){
                        toast.warning(`Le ${type === 'folder' ? 'dossier' : 'sous-dossier'} que vous essayez d'enregistrer existe déjà.`, Helper.getToastOptions())
                    }
                }

                _setState({ loading: false })
            })
            .catch(error => {
                console.error(error)
                _setState({ loading: false })
            })
        }
        else{
            toast.error(`Veuillez renseigner le nom du ${type === 'folder' ? 'dossier' : 'sous-dossier'} avant d'enregistrer.`, Helper.getToastOptions())
        }
    }

    useEffect(() => {
        if(folderId){
            _setState({ loading: true })
            Api.folder.getFolder(folderId)
            .then(response => {
                const result = Helper.isValidResponse(response)

                if(response){
                    _setState({ name: result.name })
                }

                _setState({ loading: false })
            })
            .catch(error => {
                console.error(error)
                _setState({ loading: false })
            })
        }
    }, [ folderId ])

    return (
        <form className='aid-folder-form-root' noValidate onSubmit={handleFormSubmit}>
            { state.loading && <Loading /> }
            <InputField
                required={true} 
                className="input-tiny" 
                name="name"
                isFocused={state.name}
                value={state.name} 
                onChange={handleFormChange}
                title={formName}
                readOnly={readOnly}
                disabled={readOnly}
            >
                {formName}
            </InputField>
            {
                !readOnly && (
                    <div className="aid-folder-form-footer">
                        <button className="btn default btn-lg" type="submit" title="Enregistrer la donnée saisie" ref={forwardRef}>
                            <Save />
                            Enregistrer
                        </button>
                    </div>
                )
            }
        </form>
    )
}

export default AidFolder