import React from 'react'
import { Constants } from 'fsy.common-library';
import DataTabs from './DataTabs';
import HtmlStructure from './../general/HtmlStructure';
import AidNature from './aidNature/AidNature';
import AidComplexity from './aidComplexity/AidComplexity';
import AidForm from './aidForm/AidForm';
import AidEnvironment from './aidEnvironment/AidEnvironment';
import AidScheme from './aidScheme/AidScheme';
import CriterionCategory from './criterionCategory/CriterionCategory';

export const AidNaturePage = () => {
    return createDataSubPage("Natures d'aides - ", "aid-nature", AidNature)
}

export const AidComplexityPage = () => {
    return createDataSubPage("Complexités d'aides - ", "aid-complexity", AidComplexity)
}

export const AidFormPage = () => {
    return createDataSubPage("Formes d'aides - ", "aid-form-data", AidForm)
}

export const AidEnvironmentPage = () => {
    return createDataSubPage("Milieux  - ", "aid-environment", AidEnvironment)
}

export const AidSchemePage = () => {
    return createDataSubPage("Dispositifs - ", "aid-scheme", AidScheme)
}

export const CriterionCategoryPage = () => {
    return createDataSubPage("Catégories de critères - ", "criterion-category", CriterionCategory)
}

const createDataSubPage = (titleString, sectionClassName, Component, withTabs = true, props) => {
    document.title = `${titleString} ${Constants.DOCUMENT_TITLE_BACKOFFICE}`

    return (
        <HtmlStructure menuName="data" sectionClassName={`${sectionClassName} bo-with-tabs`} auth={[Constants.PROFILE_REFERENCE_DATA_MANAGEMENT]}>
            {withTabs && <DataTabs />}
            <Component {...props}/>
        </HtmlStructure>
    )

}