import React from "react";
import "./proposedAid.css";
import {
  AID_FUNDING_ESTIM_BILL,
  AID_FUNDING_SCALE,
} from "fsy.common-library/lib/env/Constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";

const ProposedAid = ({ value, className }) => {
  const { aid } = value;

  const getAidAmount = (amount, format = null) => {
    if (!amount) {
      return "-";
    }

    const value = amount.value;

    if (_.isObject(value)) {
      if (value.min === 0 && value.max > 0) {
        return `Jusqu'à ${GenericHelper.digitWithDecimalPrecision(value.max)} ${
          amount.unit ?? "€"
        }`;
      }

      if (format) {
        format = format.replace(
          "%min%",
          `${GenericHelper.digitWithDecimalPrecision(value.min)} ${
            amount.unit ?? "€"
          }`
        );
        format = format.replace(
          "%max%",
          `${GenericHelper.digitWithDecimalPrecision(value.max)} ${
            amount.unit ?? "€"
          }`
        );
        return format;
      }

      return `Minimum: ${GenericHelper.digitWithDecimalPrecision(value.min)} ${
        amount.unit ?? "€"
      } - Maximum: ${GenericHelper.digitWithDecimalPrecision(value.max || 0)} ${
        amount.unit ?? "€"
      }`;
    } else {
      return value > 0
        ? `${GenericHelper.digitWithDecimalPrecision(value)} ${
            amount.unit ?? "€"
          }`
        : "Non calculable";
    }
  };

  return (
    <div className={`proposed-aid ${className}`}>
      <Link to={`/aids?id=${aid.id}`}>
        <b>
          <i>{`${aid.name} - version ${aid.version}`}</i>
        </b>
      </Link>
      <div>
        <small>{value.aid?.funding?.label || "-"}</small>
        {value.aid?.funding &&
        [AID_FUNDING_SCALE, AID_FUNDING_ESTIM_BILL].includes(
          value.aid.funding.shortName
        ) &&
        value.amount.unit === "%" ? (
          <small className="total-line">
            Total:{" "}
            {getAidAmount(
              value.amount,
              "%min% à %max% " +
                (value.aid.funding.shortName === AID_FUNDING_SCALE
                  ? `${
                      value.amount.value.hasOwnProperty("baseAmount") &&
                      value.amount.value.baseAmount.amount > 0
                        ? `de ${GenericHelper.digitWithDecimalPrecision(
                            value.amount.value.baseAmount.amount
                          )} ${value.amount.value.baseAmount.unit}`
                        : "du montant de base"
                    }`
                  : "du devis/facture")
            )}
          </small>
        ) : (
          <small className="total-line">
            Total: {getAidAmount(value.amount)}
          </small>
        )}
      </div>
    </div>
  );
};

export default ProposedAid;
