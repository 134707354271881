import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  ThemeProvider,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import { Api, Constants, Session } from "fsy.common-library";
import Helper from "../../../services/Helper";
import _ from "lodash";
import moment from "moment";
import SimulationView from "./SimulationView";
import { Loading } from "../../general/form/Loading";
import { FilterAlt } from "@mui/icons-material";
import fsyTheme from "../../general/form/FsyTheme";

import "./simulationList.css";
import { RIGHTS_ADMINISTRATION } from "../../../services/Constants";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: ".85rem",
}));

const Item2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  fontSize: ".85rem",
}));

const PaginationContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "1.5vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: -2,
  position: "relative",
}));

export default class SimulationList extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    const filters = {
      [Constants.SIMULATION_STATUS_IN_PROGRESS]: {
        enable: false,
        value: Constants.SIMULATION_STATUS_IN_PROGRESS,
      },
      [Constants.SIMULATION_STATUS_REGISTERED]: {
        enable: false,
        value: Constants.SIMULATION_STATUS_REGISTERED,
      },
      [Constants.SIMULATION_STATUS_COMPLETED]: {
        enable: false,
        value: Constants.SIMULATION_STATUS_COMPLETED,
      },
    };

    this.isAdminFransylva =
      Session.getAuth(Constants.PROFILE_SIMULATION) === RIGHTS_ADMINISTRATION;
    this.state = {
      loading: true,
      simulations: [],
      simulationsCount: 0,
      currentPage: 1,
      nbPage: 1,
      currentSimulation: null,
      open: false,
      anchorEl: null,
      filters: filters,
    };

    this.ITEMS_PER_PAGE = 10;

    this.getList = this.getList.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.handleCheckFilter = this.handleCheckFilter.bind(this);
    this.handleCloseFilter = this.handleCloseFilter.bind(this);
    this.isFilterChecked = this.isFilterChecked.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.state.currentPage !== nextState.currentPage) {
      this.getList(nextState.currentPage); // Refresh the list if the page change
    }
  }

  getList(page = this.state.currentPage) {
    const simulationFilteredStatus = [];

    const filters = {
      perPage: this.ITEMS_PER_PAGE,
      [`order[id]`]: "desc",
      sandbox: false,
    };

    Object.values(this.state.filters)
      .filter((f) => f.enable)
      .forEach((f) => {
        simulationFilteredStatus.push(f.value);
      });

    if (!this.isAdminFransylva) {
      const user = Session.getSessionUser();
      filters.organizationUuid = user.organization.uuid;
    }

    if (simulationFilteredStatus.length > 0) {
      filters.simulationStatus = JSON.stringify(simulationFilteredStatus);
    }

    Api.simulation
      .getSimulationsWithBasicInfo(page, filters)
      .then((response) => {
        const simulationsResult = Helper.isValidResponse(response);

        if (simulationsResult) {
          const resultObject = simulationsResult.elements;
          const simulationsCount = simulationsResult.count;
          this.setState({
            loading: false,
            simulations: resultObject,
            simulationsCount: simulationsCount,
            nbPage: Math.ceil(simulationsCount / this.ITEMS_PER_PAGE),
            currentSimulation: resultObject.length ? resultObject[0] : null,
          });
        }
      });
  }

  handleCheckFilter(event) {
    const filters = this.state.filters;
    filters[event.target.value].enable = !filters[event.target.value].enable;

    this.setState({
      filters: filters,
    });
  }

  applyFilter() {
    this.setState(
      {
        loading: true,
        open: false,
        anchorEl: null,
      },
      () => {
        this.getList();
      }
    );
  }

  handleCloseFilter() {
    this.setState({
      open: false,
      anchorEl: null,
    });
  }

  isFilterChecked(filterValue) {
    return (
      this.state.filters[`status[${filterValue}]`] &&
      this.state.filters[`status[${filterValue}]`] !== ""
    );
  }

  handlePageChange(event, value) {
    this.setState({
      currentPage: value,
      loading: true,
      currentSimulation: null,
    });
  }

  render() {
    return (
      <article className="simulations">
        <section className="bo-data-title">
          <h3>Liste des simulations</h3>
        </section>

        <ThemeProvider theme={fsyTheme}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid xs={2.75}>
                <Item>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="w-100"
                  >
                    <b style={{ fontSize: "1rem" }}>
                      Toutes ({this.state.simulationsCount})
                    </b>
                    <div style={{ marginLeft: "auto" }}>
                      <IconButton
                        onClick={(event) =>
                          this.setState({
                            anchorEl: event.currentTarget,
                            open: true,
                          })
                        }
                        aria-label="filter"
                      >
                        <FilterAlt />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        open={this.state.open}
                        onClose={this.handleCloseFilter}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filters[
                                    Constants.SIMULATION_STATUS_REGISTERED
                                  ].enable
                                }
                                value={
                                  this.state.filters[
                                    Constants.SIMULATION_STATUS_REGISTERED
                                  ].value
                                }
                                onChange={this.handleCheckFilter}
                              />
                            }
                            label="Enregistrée"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filters[
                                    Constants.SIMULATION_STATUS_COMPLETED
                                  ].enable
                                }
                                value={
                                  this.state.filters[
                                    Constants.SIMULATION_STATUS_COMPLETED
                                  ].value
                                }
                                onChange={this.handleCheckFilter}
                              />
                            }
                            label="Terminée"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filters[
                                    Constants.SIMULATION_STATUS_IN_PROGRESS
                                  ].enable
                                }
                                value={
                                  this.state.filters[
                                    Constants.SIMULATION_STATUS_IN_PROGRESS
                                  ].value
                                }
                                onChange={this.handleCheckFilter}
                              />
                            }
                            label="En cours"
                          />
                        </MenuItem>
                        <div
                          style={{ padding: "8px 14px", textAlign: "right" }}
                        >
                          <Button
                            onClick={this.applyFilter}
                            style={{ marginLeft: "auto" }}
                            size="small"
                            variant="contained"
                            color="success"
                          >
                            Appliquer
                          </Button>
                        </div>
                      </Menu>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid xs={9.25}>
                <Item>
                  <Grid
                    xs
                    container
                    spacing={1}
                    alignItems="center"
                    style={{ fontSize: "1rem" }}
                  >
                    <Grid xs>
                      Simulation sélectionnée :{" "}
                      <b>
                        Simulation #
                        {this.state.currentSimulation
                          ? this.state.currentSimulation.id
                          : "-"}
                      </b>
                    </Grid>
                    <Grid xs="auto" xsOffset="auto">
                      Légende :
                    </Grid>
                    <Grid
                      xs="auto"
                      className="legend-saved"
                      title="Simulations enregistrées dans un compte utilisateur"
                    >
                      <Button
                        size="small"
                        variant="contained"
                        disableElevation
                        disabled
                        children="Enregistrée"
                      />
                    </Grid>
                    <Grid
                      xs="auto"
                      className="legend-finished"
                      title="Simulations faites avec résultats présentés à l'utilisateur mais non enregistrées dans un compte"
                    >
                      <Button
                        size="small"
                        variant="contained"
                        disableElevation
                        disabled
                        children="Terminée"
                      />
                    </Grid>
                    <Grid
                      xs="auto"
                      className="legend-progress"
                      title="Simulations commencées sans être allé jusqu'à l'étape de recherche des aides"
                    >
                      <Button
                        size="small"
                        variant="contained"
                        disableElevation
                        disabled
                        children="En cours"
                      />
                    </Grid>
                  </Grid>
                </Item>
              </Grid>

              <Grid xs={2.75}>
                <Item2
                  style={{
                    height: "75vh",
                    width: "100%",
                    overflow: "auto",
                    position: "relative",
                  }}
                >
                  {this.state.loading && <Loading />}
                  {_.map(this.state.simulations, (simulation, i) => {
                    return (
                      <List
                        key={i}
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                          padding: "0",
                        }}
                      >
                        <ListItemButton
                          alignItems="flex-start"
                          selected={
                            this.state.currentSimulation &&
                            simulation.id === this.state.currentSimulation.id
                          }
                          onClick={() => {
                            this.setState({
                              currentSimulation: simulation,
                            });
                          }}
                        >
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: ".75rem",
                                    fontSize: "1rem",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <div
                                    className={
                                      "simulation-state status-" +
                                      simulation.status
                                    }
                                  />
                                  <b>Simulation #{simulation.id}</b>
                                  <Chip
                                    style={{ marginLeft: "auto" }}
                                    label={
                                      simulation.suggestedAids?.length || 0
                                    }
                                    size="small"
                                  />
                                </Typography>
                              </>
                            }
                            secondary={
                              <>
                                <span
                                  style={{ display: "flex", fontSize: ".8rem" }}
                                >
                                  <span>
                                    Créé le{" "}
                                    {simulation.startedAt
                                      ? moment(simulation.startedAt).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "/"}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "auto",
                                      display: "inline-block",
                                      textAlign: "right",
                                    }}
                                  >
                                    Terminée le{" "}
                                    {simulation.endedAt
                                      ? moment(simulation.endedAt).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "/"}
                                  </span>
                                </span>
                                <span
                                  className="simulator-info"
                                  style={{ display: "flex", fontSize: ".8rem" }}
                                >
                                  <span>
                                    Par :{" "}
                                    {simulation.user
                                      ? simulation.user.name +
                                        " " +
                                        simulation.user.surname
                                      : "< Anonyme >"}
                                  </span>
                                  <span>
                                    Simulateur :{" "}
                                    {simulation.organization
                                      ? simulation.organization.name
                                      : "< Inconnu >"}
                                  </span>
                                </span>
                              </>
                            }
                          />
                        </ListItemButton>
                      </List>
                    );
                  })}
                </Item2>
                <PaginationContainer>
                  <Pagination
                    count={this.state.nbPage}
                    page={this.state.currentPage}
                    siblingCount={0}
                    onChange={this.handlePageChange}
                  />
                </PaginationContainer>
              </Grid>

              <Grid xs={9.25} alignItems="center">
                <Item2
                  style={{
                    height: "80vh",
                    width: "100%",
                    overflow: "auto",
                    textAlign: "left",
                  }}
                >
                  {this.state.currentSimulation && (
                    <SimulationView
                      simulation={this.state.currentSimulation}
                    ></SimulationView>
                  )}
                </Item2>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </article>
    );
  }
}

/* ================================== GLOBAL FUNCTIONS ================================== */
