import React, { useEffect, useState } from 'react'
import './listLayout.css'
import { Loading } from '../form/Loading'
import { TableWithFiltersSkeleton } from '../../../services/LoadingHelper'
import { SelectField } from '../form/Select'
import { InputField } from '../form/Input'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import Helper from '../../../services/Helper'
import { Api, Constants } from 'fsy.common-library'
import HtmlStructure from './../HtmlStructure';
import DataTable from 'react-data-table-component'
import { Delete as DeleteIcon } from "@mui/icons-material"
import Modal from '../form/Modal'
import { HTTP_NO_CONTENT, HTTP_OK } from 'fsy.common-library/lib/env/Constants'
import { toast } from 'react-toastify'
import { Alert } from '@mui/material'
import { Link } from 'react-router-dom'

const ListPageLayout = (props) => {
    const {
        title,
        withHTMLStructure
    } = props

    document.title = `${title} - ${Constants.DOCUMENT_TITLE_BACKOFFICE}`

    return  withHTMLStructure ? (
        <HtmlStructure sectionClassName="listLayout" auth={[Constants.PROFILE_REFERENCE_DATA_MANAGEMENT]}>
            <div className="content-header">
                <section className="bo-data-title">
                    <h3>{title}</h3>
                </section>
            </div>
            <div className="content-body">
                <ListLayout
                    {...props}
                />
            </div>
    </HtmlStructure>
    ) : (
        <ListLayout
            {...props}
        />
    )
}

const ListLayout = ({
    type,
    formName,
    skeletonLoading,
    loading,
    getElementFilterParams,
    hasSubmitted,
    label,
    isFeminine,
    rows,
    totalRows,
    filterFields,
    columns,
    requests,
    withRightsWrite,
    withDeleteButton,
    deleteOperationName,
    routeRedirectionNameInDeleteWarningModal,
    checkingCanBeDeletedOperationName,
    onRequestDataChange
}) => {
    const [state, setState] = useState({
        cols: columns,
        id: null,
        deleteModalConfirmationDisplay: false,
        deleteWarningData: {
            display: false,
            message: "",
            elements: []
        },
        hasFiltered: false,
        currentPage: 0,
        page: 1,
        rowsPerPage: Helper.getTablePerPageNumber(),
    })

    const _setState = (newValue) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...newValue
            }
        })
    }

    const setLoading = (type='loading', value=false) => {
        onRequestDataChange({ [type]: value })
    }

    const handleFormChange = (event, prop) => {
        let value = event?.target?.value || null
        let name = event?.target?.name || prop
        const filterFieldsData = [...filterFields]

        // Cas des formulaires de type SELECT
        if(prop && !value){
            value = event
        }

        filterFieldsData.map((filterFieldData) => {
            if(name === filterFieldData.name){
                filterFieldData.value = value
            }

            return null
        })

        onRequestDataChange({ filterFields: filterFieldsData })
    }

    const getDataForFilter = () => {
        const values = []

        filterFields.map((element) => {
            let value = (element.value && typeof element.value === 'object') ? element.value.value : element.value

            if(value){
                values.push({ name: element.name, value })
            }

            return null
        })

        return values
    }

    const handleFilterElements = (dataForFilter, hasFiltered=true) => {
        const filterData = dataForFilter

        const filterParams = getElementFilterParams(filterData, state.rowsPerPage)

        getElements(false, false, 1, filterParams)

        _setState({ hasFiltered })
    }

    const handleCancelFilter = () => {
        const filterFieldsData = [...filterFields]

        filterFieldsData?.map((field) => {
            field.value = field.type === 'select' ? null : ""
            
            return null
        })

        handleFilterElements(filterFieldsData, false)

        onRequestDataChange({ filterFields: filterFieldsData })

        _setState({ currentPage: 0 })
    }

    const handlePaginationDataChange = (newValue, type) => {
        const page = type === 'page' ? newValue : state.page
        const rowsPerPage = type === 'rowsPerPage' ? newValue : state.rowsPerPage 
        const urlParams = getElementFilterParams(filterFields, rowsPerPage)

        getElements(false, true, page, urlParams)

        // Mise à jour de page actuelle ou du nombre de ligne par page
        if(type === 'page'){
            _setState({ page })
        }

        if(type === 'rowsPerPage'){
            _setState({ rowsPerPage })
        }
    }

    const handleSort = () => {}

    // Requête vers l'API
    const handleRemoveElement = () => {
        const lastParam = !['criterion-theme'].includes(formName) ? formName : null

        _setState({ deleteModalConfirmationDisplay: false })
        setLoading('loading', true)
        Api[type][deleteOperationName](state.id, lastParam)
        .then(response => {
            if(response?.status === HTTP_NO_CONTENT){
                toast.success(`${label?.charAt(0)?.toUpperCase()}${label?.slice(1)} supprimé${isFeminine ? 'e' : ''}`, Helper.getToastOptions())
                getElements(false, false, 1)
            }

            setLoading()
        })
        .catch(error => {
            console.error(error)
            setLoading()
        })
    }

    // Check si on peut supprimer ou pas
    const handleDeleteElement = (row) => {
        if(row){
            const { id } = row

            setLoading('loading', true)
            const lastParam = !['criterion-theme'].includes(formName) ? formName : null

            // On vérifie d'abord si la donnée peut être supprimée
            Api[type][checkingCanBeDeletedOperationName](id, lastParam)
            .then(response => {
                if(response?.status === HTTP_OK){
                    // Si la donnée peut être supprimée, on la supprime
                    if(response?.data?.status){
                        _setState({
                            id: id,
                            deleteModalConfirmationDisplay: true,
                        })

                        setLoading()
                    }
                    else{
                        const isElementPlural = response?.data?.elements?.length > 1

                        let suppText = ""

                        if(type !== 'criterionTheme'){
                            suppText = `${isElementPlural ? "les " : "l'"}aide${isElementPlural ? 's' : ''} suivante${isElementPlural ? 's' : ''} :` 
                        }
                        else{
                            suppText = `${isElementPlural ? "les" : "le"} critère${isElementPlural ? 's' : ''} suivant${isElementPlural ? 's' : ''} :` 
                        }

                        let message = `
                            Impossible de supprimer l${isFeminine ? 'a' : 'e'} ${label}. 
                            ${isFeminine ? "Elle" : "Il"} est utilisé${isFeminine ? 'e' : ''} 
                            dans ${suppText}
                        `
                        _setState({ deleteWarningData: { ...state.deleteWarningData, display: true, message, elements: response?.data?.elements || [] } })
                        setLoading()
                    }
                }
                else{
                    setLoading()
                }
            })
            .catch(error => {
                console.error(error)
                setLoading()
            })
        }

        _setState({ deleteModalConfirmationDisplay: false })
    }

    const getElements = (
        isFirstComponentLoading=true, 
        isRowsRequestOnly=false,
        page=1,
        urlParams={}
    ) => {
        setLoading(isFirstComponentLoading ? 'skeletonLoading' : 'loading', true)

        if(isRowsRequestOnly){
            requests = requests.filter((element) => element.name === 'rows')
        }

        Promise.all([...(requests?.map((request) => { 
            if(request.name === 'rows'){
                return request.operation(page, {"order[name]": 'asc', "per_page": state.rowsPerPage, ...urlParams})
            }

            if(request.name === 'count'){
                return request.operation(urlParams) 
            }

            return null
        } ))])
        .then((responses) => {
            const value = {}
            const filterData = [...filterFields]

            responses?.map((response, index) => {
                let results = Helper.isValidResponse(response)

                if(results){
                    let key = requests[index].name
                    let getFormat = requests[index].getFormat

                    if(getFormat){
                        results = getFormat(results)
                    }

                    // Généralement pour les lignes de base d'un tableau
                    if(!requests[index].isOptionsForSelectForm){
                        value[key] = results
                    }
                    else{
                        let i = filterData.findIndex((element) => element.name === key)
                        
                        filterData[i].options = results
                    }
                }

                return null
            })

            value['filterFields'] = filterData

            setLoading(isFirstComponentLoading ? 'skeletonLoading' : 'loading')

            onRequestDataChange(value)
        })
        .catch(error => {
            console.error(error)
            setLoading(isFirstComponentLoading ? 'skeletonLoading' : 'loading')
        })
    }

    useEffect(() => {
        if(withDeleteButton && withRightsWrite){
            const cols = [...state.cols]

            cols.push(
                {
                    width: '4vw',
                    name: 'Action',
                    center: true, 
                    sortable: false,
                    selector: row => {
                        return (
                            <>
                                <button onClick={() => handleDeleteElement(row)} className='delete-button-reference-data-content' title="Supprimer">
                                    <DeleteIcon color='danger' />
                                </button>
                            </>
                        )
                    }
                }
            )

            _setState({ cols })
        }

        getElements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ withRightsWrite ])

    useEffect(() => {
        if(hasSubmitted){
            getElements(false, false, 1)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ hasSubmitted ])

    return (
        <>
            { loading && <Loading /> }
                <Modal
                    isShowing={state.deleteWarningData.display}
                    zindex={101}
                    hide={() => { _setState({ deleteWarningData: { ...state.deleteWarningData, display: false } }) }}
                    title="Attention">
                    <div style={{padding: "0.5rem", maxHeight: "80vh", minWidth: '40vw', overflowY: 'auto'}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                            <Alert severity="error">{state.deleteWarningData.message}</Alert>
                        </div>
                        <ul>
                            {
                                state.deleteWarningData.elements?.map((element, index) => {
                                    return (
                                        <li key={`DeleteWarningElement${index+1}`}>
                                            <Link to={`/${routeRedirectionNameInDeleteWarningModal}?id=${element.id}`}>{element.name}</Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="flex flex-column modal-close-confirm">
                        <div className="modal-footer flex">
                            <button className="btn" onClick={() => { _setState({ deleteWarningData: { ...state.deleteWarningData, display: false } }) }}>
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal>
            <Modal
                title={`Confirmation de supression de ${label}`}
                hide={() => { _setState({ deleteModalConfirmationDisplay: false }) }}
                isShowing={state.deleteModalConfirmationDisplay}
            >
                <div className='flex mt-1'>
                    <span className='center'>{`Etes-vous sûr de vouloir supprimer ce${isFeminine ? 'tte' : ''} ${label} ?`}</span>
                </div>
                <div className="flex flex-column center modal-close-confirm">
                    <div className="modal-footer flex">
                        <button className="btn alert" onClick={handleRemoveElement}>Oui</button>
                        <button className="btn" onClick={() => { _setState({ deleteModalConfirmationDisplay: false }) }}>Non</button>
                    </div>
                </div>
            </Modal>
            <section className="bo-data-table card">
            {
                skeletonLoading ?
                <TableWithFiltersSkeleton linesCount={10} filtersCount={filterFields?.length} /> : (
                    <>
                        <div className='list-layout-filter-content'>
                            {
                                filterFields?.map((field, index) => {
                                    return field.type === 'select' ? (
                                        <SelectField
                                            key={`SelectFieldListLayout${index+1}`}
                                            placeholder={field.placeholder}
                                            className='select-form'
                                            options={field.options}
                                            value={field.value}
                                            onChange={(data) => handleFormChange(data, field.name)}
                                        />

                                    ) : (
                                        <InputField
                                            key={`InputFieldListLayout${index+1}`}
                                            type={field.type}
                                            name={field.name}
                                            children={field.children}
                                            className='input-form'
                                            value={field.value}
                                            onChange={handleFormChange}
                                        />
                                    )
                                })
                            }
                            <div className="button-filter-action-content">
                                <button
                                    type='button'
                                    className="btn default filterButton"
                                    title="Filtrer les résultats"
                                    disabled={loading}
                                    onClick={ () => handleFilterElements(getDataForFilter())}
                                ><FilterAltIcon /></button>
                                {
                                    state.hasFiltered && (
                                        <button 
                                            type="button" 
                                            className="btn warning resetButton"
                                            title="Effacer les filtres"
                                            onClick={handleCancelFilter}
                                        ><FilterAltOffIcon /></button>
                                    )
                                }
                            </div>
                        </div>
                        <DataTable
                            columns={state.cols}
                            data={rows}
                            fixedHeader
                            fixedHeaderScrollHeight="70vh"
                            dense
                            persistTableHead
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={state.rowsPerPage}
                            onChangeRowsPerPage={(value) => handlePaginationDataChange(value, 'rowsPerPage')}
                            onChangePage={(value) => handlePaginationDataChange(value, 'page')}
                            responsive
                            onSort={handleSort}
                            defaultSortAsc={false}
                            className="dataListLayoutTable"
                            subHeaderAlign="center"
                            subHeaderWrap
                            paginationDefaultPage={state.page}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Lignes par page',
                                rangeSeparatorText: 'sur',
                            }}
                        />
                    </>
                )
            }
            </section>
    </>
    )
}

export default ListPageLayout