import React from "react";
import { Constants, Roles, Session } from "fsy.common-library";
import { Link, NavLink } from "react-router-dom";
import "./menu.css";
import { routes as Routing } from "../../../services/RoutesHelper";
import {
  Checklist,
  CorporateFare,
  Dashboard,
  Edit,
  InsertChart,
  Logout,
  PeopleAlt,
  Person,
  BackupTable,
} from "@mui/icons-material";
import Profile from "../../profile/Profile";

export default class Menu extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.sessionUser = Session.getSessionUser();

    this.state = {
      isAuthenticated: Session.isLoggedIn(),
      user: this.sessionUser,
      display: {
        aid: false,
        criterion: false,
        simulator: false,
        organization: false,
        users: false,
        stats: false,
        data: false,
      },
      isProfileInfoDialogOpened: false,
      isProfileInfoUpdated: false,
    };

    this._handleProfileInfoDialogDisplay =
      this._handleProfileInfoDialogDisplay.bind(this);
    this._handleProfileInfoUpdated = this._handleProfileInfoUpdated.bind(this);
  }

  componentDidMount() {
    const allAuth = Session.getAuth();
    this.setState({
      display: {
        aid:
          allAuth[Constants.PROFILE_AIDENTRY] > 0 ||
          allAuth[Constants.PROFILE_AIDVALIDATION] > 0,
        criterion: allAuth[Constants.PROFILE_CRITERION] > 0,
        simulator:
          allAuth[Constants.PROFILE_SIMULATOR] > 0 ||
          allAuth[Constants.PROFILE_SIMULATION] > 0,
        organization: allAuth[Constants.PROFILE_ORGANIZATION] > 0,
        users:
          allAuth[Constants.PROFILE_USERMANAGEMENT] > 0 ||
          allAuth[Constants.PROFILE_PROFILEMANAGEMENT] > 0,
        stats: allAuth[Constants.PROFILE_REPORTING] > 0,
        data: allAuth[Constants.PROFILE_REFERENCE_DATA_MANAGEMENT] > 0,
      },
    });
  }

  _handleProfileInfoDialogDisplay() {
    this.setState({
      isProfileInfoDialogOpened: !this.state.isProfileInfoDialogOpened,
      isProfileInfoUpdated: false,
    });
  }

  _handleProfileInfoUpdated(value) {
    this.setState({
      isProfileInfoUpdated: value,
    });
  }

  render() {
    return (
      <aside className="menu">
        <Profile
          isShowing={this.state.isProfileInfoDialogOpened}
          isProfileInfoUpdated={this.state.isProfileInfoUpdated}
          onRequestHide={this._handleProfileInfoDialogDisplay}
          onRequestProfileInfoUpdated={this._handleProfileInfoUpdated}
          onRequestUpdateUsername={this.props.onRequestUpdateUsername}
        />
        <div className="menu-title">
          <NavLink to={Routing.app_home} title="Retour à l'accueil">
            <img
              src="/img/Logo-Fransylva-small.png"
              alt="Fransylva"
              title="Backoffice simulateur Fransylva"
            />
          </NavLink>
        </div>

        <nav className="menu-nav" role="menu">
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            DashboardMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.aid &&
            AidMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.simulator &&
            SimulatorMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.criterion &&
            CriterionMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.data &&
            DataMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.organization &&
            OrganizationMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.users &&
            UserMenu(this.props.nameMenu)}
          {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) &&
            this.state.display.stats &&
            StatsMenu(this.props.nameMenu)}
        </nav>
        <div className="menu-footer">
          <div
            className="menu-user"
            title={`Actuellement connecté en tant que ${this.state.user?.name}`}
          >
            <span
              className="menu-user-name"
              onClick={this._handleProfileInfoDialogDisplay}
            >
              <Person /> <span>{this.props.username}</span>
            </span>
            <span
              className="menu-user-role"
              title={this.state.user?.profile?.label}
            >
              {this.state.user?.profile?.trigram}
            </span>
            <span className="menu-user-society">
              {this.state.user?.organization?.name}
            </span>
            <Link
              to={Routing.app_logout}
              title="Déconnexion"
              className="menu-user-logout"
            >
              <Logout />
            </Link>
          </div>
          <div className="menu-version">{process.env.REACT_APP_VERSION}</div>
        </div>
      </aside>
    );
  }
}

function DashboardMenu(nameMenu) {
  return generateMenuItem(
    Routing.bo_dashboard,
    <Dashboard />,
    "Dashboard",
    nameMenu === "dashboard"
  );
}

function UserMenu(nameMenu) {
  const allAuth = Session.getAuth();
  const withUserManagementAuth = allAuth[Constants.PROFILE_USERMANAGEMENT] > 0;
  let route = "";

  if (withUserManagementAuth) {
    route = Routing.bo_users;
  } else route = Routing.bo_profiles;

  return generateMenuItem(
    route,
    <PeopleAlt />,
    "Utilisateurs",
    nameMenu === "users"
  );
}

function OrganizationMenu(nameMenu) {
  return generateMenuItem(
    Routing.bo_organizations,
    <CorporateFare />,
    "Organismes",
    nameMenu === "organizations"
  );
}

function SimulatorMenu(nameMenu) {
  const allAuth = Session.getAuth();
  const withSimulationManagementAuth =
    allAuth[Constants.PROFILE_SIMULATION] > 0;
  let route = "";

  if (withSimulationManagementAuth) {
    route = Routing.bo_simulations;
  } else route = Routing.bo_simulator;

  return generateMenuItem(
    route,
    <Checklist />,
    "Simulateur",
    nameMenu === "simulator"
  );
}

function AidMenu(nameMenu) {
  const AidIcon = () => (
    <svg
      viewBox="0 0 24 24"
      focusable={false}
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium principal-menu-icon"
    >
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            d="M-5069 6526h24v24h-24z"
            data-name="Rectangle 78"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#a)"
        data-name="Mask Group 3"
        transform="translate(5069 -6526)"
      >
        <path
          d="M-5050.389 6532.52a.669.669 0 0 1-.3-.57 6.416 6.416 0 0 0-6.316-5.95 6.415 6.415 0 0 0-6.312 5.949.672.672 0 0 1-.3.57 6.65 6.65 0 0 0 2.02 11.709 1.681 1.681 0 0 0 1.1.061 3.092 3.092 0 0 0 .552-2.213 2.063 2.063 0 0 0-.982-.506 3.915 3.915 0 0 1-1.775-6.332 3.466 3.466 0 0 1 .918-.757 2.255 2.255 0 0 0 1.105-1.964 3.737 3.737 0 1 1 7.473 0 2.121 2.121 0 0 0 1.103 1.953 2.59 2.59 0 0 1 .916.752 3.846 3.846 0 0 1 .982 2.6 3.779 3.779 0 0 1-3.736 3.861 4.364 4.364 0 0 0-4.29 4.417v2.523a2.428 2.428 0 0 0 .184 1.142 2.683 2.683 0 0 0 2.205 0 2.258 2.258 0 0 0 .189-1.142v-2.585a1.686 1.686 0 0 1 1.658-1.713 6.413 6.413 0 0 0 6.316-6.525 6.541 6.541 0 0 0-2.7-5.313l-.015.033Z"
          data-name="Icon simple-gumtree"
        />
      </g>
    </svg>
  );

  return generateMenuItem(
    Routing.bo_aid,
    <AidIcon />,
    "Aides",
    nameMenu === "aids"
  );
}

function CriterionMenu(nameMenu) {
  return generateMenuItem(
    Routing.bo_criterion,
    <Edit />,
    "Critères",
    nameMenu === "criterion"
  );
}

function StatsMenu(nameMenu) {
  return generateMenuItem(
    Routing.bo_stats,
    <InsertChart />,
    "Statistiques",
    nameMenu === "stats"
  );
}

function DataMenu(nameMenu) {
  return generateMenuItem(
    Routing.bo_data_aid_nature,
    <BackupTable />,
    "Références",
    nameMenu === "data",
    "Références"
  );
}

function generateMenuItem(
  link,
  iconClassName,
  label,
  isActive = false,
  title = null
) {
  const activeClass = isActive ? "active" : "";
  return (
    <NavLink
      to={link}
      title={title ?? label}
      className={`menu-item ${activeClass}`}
      role="menuitem"
    >
      {iconClassName}
      <span className="menu-text">{label}</span>
    </NavLink>
  );
}
