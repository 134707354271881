import React from 'react'
import DataLayout from '../layout/DataLayout'

const AidForm = () => {
    return (
        <DataLayout
            title="Formes d'aides"
            type='aid'
            partialTitleOnCreateButtonHover="forme d'aide"
            operationName='getAidForms'
            countOperationName='getAidFormCount'
            deleteOperationName='deleteReferenceData'
            formName='aid-form'
            isFeminine={true}
            checkingCanBeDeletedOperationName="canBeDeleted"
        />
    )
}

export default AidForm