import React from "react";
import Helper from "../../../services/Helper";
import { SelectField } from "../../general/form/Select";
import { Constants, Session } from "fsy.common-library";
import _ from "lodash";
import { Requiredstar } from "../../general/form/Input";
import { RIGHTS_NOACCESS } from "../../../services/Constants";

class CriterionLOCRow extends React.Component {
  static defaultProps = {
    onChange: () => {},
    requiredFields: false,
    criterion: null,
    readOnly: false,
    aidCriterions: null,
    zones: [],
    regions: [],
    departments: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      criterion: props.criterion,
      aidCriterions: props.aidCriterions,
      zoneOptions: [
        { label: "Type de localisation", icon: "fa-list-check", options: [] },
      ],
      regionOptions: [
        { label: "Régions", icon: "fa-earth-europe", options: [] },
        { label: "Régions personnalisées", icon: "fa-user-pen", options: [] },
      ],
      departmentOptions: [
        { label: "Départements", icon: "fa-earth-europe", options: [] },
      ],
      locationValueOptions: [],
      locationTypeValue: "",
      value: "",
      visible: false,
      showError: false,
    };

    this.handleLocationTypeChange = this.handleLocationTypeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let zoneOptions = this.state.zoneOptions,
      regionOptions = this.state.regionOptions,
      departmentOptions = this.state.departmentOptions,
      locationTypeValue = this.state.locationTypeValue;
    _.forEach(this.props.zones, (z) => {
      zoneOptions[0].options.push(Helper.formatZoneOptions(z));
    });

    if (this.props.aidCriterions[this.props.criterion.id]) {
      locationTypeValue = zoneOptions[0].options.filter(
        (element) =>
          element.value ===
          this.props.aidCriterions[this.props.criterion.id].value.type
      );
    }

    // Format and add regions in the correct group (see: state.regionsOptions declaration)
    _.forEach(this.props.regions, (r) => {
      regionOptions[r.custom ? 1 : 0].options.push(Helper.formatLOCOptions(r));
    });
    _.forEach(this.props.departments, (d) => {
      departmentOptions[0].options.push(Helper.formatLOCOptions(d));
    });

    // Save values
    this.setState(
      {
        zoneOptions: zoneOptions,
        regionOptions: regionOptions,
        departmentOptions: departmentOptions,
        locationTypeValue: locationTypeValue[0],
      },
      () => this.initState()
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.checkField !== prevProps.checkField &&
      this.props.checkField
    ) {
      const criterion = this.state.criterion;
      this.setState(
        {
          showError:
            criterion.mandatory &&
            (!this.state.locationTypeValue ||
              (this.state.locationTypeValue &&
                this.state.locationTypeValue.value !==
                  Constants.LOCATION_TYPE_NATIONAL &&
                !this.state.value)),
        },
        () => {}
      );
    }

    if (prevProps.regions?.length !== this.props.regions?.length) {
      const regionOptions = this.state.regionOptions;

      _.forEach(this.props.regions, (r) => {
        regionOptions[r.custom ? 1 : 0].options.push(
          Helper.formatLOCOptions(r)
        );
      });

      this.setState({ regionOptions });
    }

    if (
      this.props.newRegion &&
      prevProps.newRegion?.id !== this.props.newRegion?.id
    ) {
      this.handleChange({
        value: this.props.newRegion.id,
        label: this.props.newRegion.label,
        object: this.props.newRegion,
      });
    }
  }

  initState = () => {
    if (this.state.locationTypeValue) {
      let value;
      if (this.props.aidCriterions[this.props.criterion.id]) {
        value = this.props.aidCriterions[this.props.criterion.id].value.answer;
      }
      this.setLocationValueOptions(this.state.locationTypeValue, value);
    }
  };

  handleLocationTypeChange = (locationTypeValue) => {
    this.setState(
      {
        locationTypeValue: locationTypeValue,
      },
      () => {
        this.setAidCriterionValue(
          locationTypeValue?.value,
          locationTypeValue &&
            locationTypeValue?.value === Constants.LOCATION_TYPE_NATIONAL
            ? { value: null }
            : null
        );
        this.setLocationValueOptions(
          locationTypeValue ? locationTypeValue : ""
        );
      }
    );
  };

  handleChange = (locationValue) => {
    if (locationValue?.value === "new") {
      if (typeof this.props.onRequestCreateRegion) {
        this.props.onRequestCreateRegion(null);
      }
    } else {
      this.setAidCriterionValue(
        this.state.locationTypeValue.value,
        locationValue
      );
    }
  };

  setAidCriterionValue = (locationTypeValue, locationValue) => {
    const criterion = this.state.criterion;
    const aidCriterions = this.state.aidCriterions;

    if (typeof aidCriterions[criterion.id] === "undefined") {
      aidCriterions[criterion.id] = {
        criterion: criterion,
      };
    }

    if (locationValue) {
      aidCriterions[criterion.id] = {
        ...aidCriterions[criterion.id],
        criterion: criterion,
        value: {
          type: locationTypeValue,
          answer: locationValue ? locationValue.value : "",
        },
        type: criterion.type.shortName,
        remove: false,
      };
    } else {
      aidCriterions[criterion.id].remove = true;
    }

    this.setState(
      {
        value: locationValue,
        aidCriterions: aidCriterions,
      },
      () => {
        this.props.onChange(this.state.criterion, this.state.aidCriterions);
      }
    );
  };

  setLocationValueOptions = (locationType, value) => {
    let locationValueOptions = [];
    if (locationType) {
      switch (locationType?.value) {
        case Constants.LOCATION_TYPE_REGIONAL:
          locationValueOptions = this.state.regionOptions;
          break;
        case Constants.LOCATION_TYPE_DEPARTMENTAL:
          locationValueOptions = this.state.departmentOptions;
          break;
        default:
          locationValueOptions = [];
          break;
      }
    }

    this.setState({
      value: "",
      locationValueOptions: locationValueOptions,
    });

    if (value) {
      let selectedOption = [];
      _.map(locationValueOptions, (l) => {
        if (!selectedOption.length) {
          selectedOption = l.options.filter(
            (locationValueOption) => locationValueOption.value === value
          );
        }
      });

      this.setState({
        value: selectedOption.length ? selectedOption[0] : "",
      });
    }
  };

  render() {
    const criterion = this.props.criterion;
    const locationValueOptions = this.state.locationValueOptions;

    if (
      this.state.locationTypeValue?.value ===
        Constants.LOCATION_TYPE_REGIONAL &&
      Session.getAuth(Constants.PROFILE_REGION_ADDING_FROM_AID) >
        RIGHTS_NOACCESS
    ) {
      if (!locationValueOptions.find((element) => element?.value === "new")) {
        locationValueOptions.push({
          label: "Créer une nouvelle région",
          value: "new",
        });
      }
    }

    return (
      <div className="aidCriteria-list-item">
        <div className="text-truncate item-label" title={criterion.name}>
          {criterion.name}
          {this.props.aid.status.value !== Constants.AID_STATUS_DRAFT &&
            criterion.mandatory && <Requiredstar className="required-star" />}
        </div>
        <div className={this.state.showError ? "is-invalid" : ""}>
          <SelectField
            options={this.state.zoneOptions}
            value={this.state.locationTypeValue}
            isMulti={false}
            closeMenuOnSelect={true}
            required={false}
            placeholder={this.state.zoneOptions[0].label}
            isDisabled={this.props.readOnly}
            onChange={this.handleLocationTypeChange}
            context={this}
          />
          <SelectField
            options={locationValueOptions}
            value={this.state.value}
            isMulti={false}
            closeMenuOnSelect={true}
            required={false}
            placeholder={
              this.state.locationValueOptions.length
                ? this.state.locationValueOptions[0].label
                : ""
            }
            isDisabled={
              this.props.readOnly ||
              (this.state.locationTypeValue &&
                this.state.locationTypeValue?.value ===
                  Constants.LOCATION_TYPE_NATIONAL)
            }
            onChange={this.handleChange}
            context={this}
            creatable={
              this.state.locationTypeValue?.value ===
                Constants.LOCATION_TYPE_REGIONAL &&
              Session.getAuth(Constants.PROFILE_REGION_ADDING_FROM_AID) >
                RIGHTS_NOACCESS
            }
            formatCreateLabel="Créer la région"
            createOptionPosition="top"
            onCreateOption={(value) => this.props.onRequestCreateRegion(value)}
          />
          {this.state.showError && (
            <div className="error">Champ obligatoire</div>
          )}
        </div>
      </div>
    );
  }
}

export default CriterionLOCRow;
