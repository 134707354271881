import React from "react";
import { NavLink } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import { Constants, Session } from "fsy.common-library";

export default class UserTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.handleTabClick = this.handleTabClick.bind(this)
  }

  render() {
    const allAuth = Session.getAuth();

    return (
      <article className="bo-tabs">
        {allAuth[Constants.PROFILE_USERMANAGEMENT] > 0 && (
          <div className="bo-tabs-item">
            <NavLink to={Routing.bo_users} title="Gérer les utilisateurs">
              <span>Utilisateurs</span>
            </NavLink>
          </div>
        )}
        {allAuth[Constants.PROFILE_PROFILEMANAGEMENT] > 0 && (
          <div className="bo-tabs-item">
            <NavLink to={Routing.bo_profiles} title="Gérer les profiles">
              <span>Profils</span>
            </NavLink>
          </div>
        )}
      </article>
    );
  }
}
