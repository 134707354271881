import * as React from "react"
import "rc-tooltip/assets/bootstrap.css"
import Slider from "rc-slider"
import raf from "rc-util/lib/raf"
import Tooltip from "rc-tooltip"
import {IconButton} from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import GenericHelper from 'fsy.common-library/lib/helpers/GenericHelper'

const HandleTooltip = props => {
    const {
        value,
        children,
        visible,
        tipFormatter = val => `${GenericHelper.digitWithDecimalPrecision(val)} %`,
        ...restProps
    } = props

    const tooltipRef = React.useRef()
    const rafRef = React.useRef(null)

    function cancelKeepAlign() {
        raf.cancel(rafRef.current)
    }

    function keepAlign() {
        rafRef.current = raf(() => {
            tooltipRef.current?.forceAlign()
        })
    }

    React.useEffect(() => {
        if (visible) {
            keepAlign()
        } else {
            cancelKeepAlign()
        }

        return cancelKeepAlign
    }, [value, visible])

    return (
        <Tooltip
            placement="top"
            overlay={tipFormatter(value)}
            overlayInnerStyle={{minHeight: "auto"}}
            ref={tooltipRef}
            visible={visible}
            {...restProps}
        >
            {children}
        </Tooltip>
    )
}

const TooltipSlider = ({tipFormatter, tipProps, ...props}) => {
    const tipHandleRender = (node, handleProps) => {
        return (
            <HandleTooltip
                value={handleProps.value}
                visible={handleProps.dragging}
                tipFormatter={tipFormatter}
                {...tipProps}
            >
                {node}
            </HandleTooltip>
        )
    }

    const handleBlur = (event) => {
        if (props.onInputBlur) {
            const {value, min, max} = props;
            let checked = true
            let valueMin = value[0] === "" ? "" : (isNaN(+value[0]) ? min : +value[0])
            let valueMax = value[1] === "" ? "" : (isNaN(+value[1]) ? max : +value[1])

            let result = [
                valueMin,
                valueMax
            ]

            if (event.target.value === "") {
                checked = false
                result = [
                    min,
                    max
                ]
            } else {
                if (+value[0] > +value[1]) {
                    if (+value[1] < min) {
                        value[1] = min
                    }

                    if (+value[0] > max) {
                        value[0] = max
                    }

                    result = [+value[1], +value[0]]
                }

                if (+value[0] < min) {
                    result = [min, +value[1]]
                }

                if (+value[1] > max) {
                    result = [+value[0], max]
                }
            }

            props.onInputBlur(result, checked)
        }
    }

    return <>
        <Slider {...props} handleRender={tipHandleRender}/>
        <div className="flex slider-input-value-container" style={{marginTop: '0.25rem'}}>
            <input
                onChange={(event) => {
                    if (props.onMinChange) {
                        const { value } = event.target
                        event.target.value = value?.replace(",", ".")
                        props.onMinChange(event)
                    }
                }}
                onBlur={(event) => {
                    handleBlur(event)
                }}
                onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }}
                style={{maxWidth: '45px', textAlign: 'right'}}
                value={props.checked ? props.value[0]?.toString().replace(".", ',') : ""}
                disabled={props.disabled}
            />
            ↔
            <input
                onChange={(event) => {
                    if (props.onMaxChange) {
                        const { value } = event.target
                        event.target.value = value?.replace(",", ".")
                        props.onMaxChange(event)
                    }
                }}
                onBlur={(event) => {
                    handleBlur(event)
                }}
                onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }}
                style={{maxWidth: '45px'}}
                value={props.checked ? props.value[1]?.toString().replace(".", ',') : ""}
                disabled={props.disabled}
            />

            <IconButton
                onClick={() => {
                    if (props.onReset) {
                        props.onReset()
                    }
                }}
                disabled={props.disabled}
                aria-label="reset" size="small">
                <RestartAltIcon/>
            </IconButton>
        </div>
    </>
}

export default TooltipSlider
