import React from 'react'
import DataLayout from '../layout/DataLayout'

const AidEnvironment = () => {
    return (
        <DataLayout
            title="Milieux"
            type='aid'
            partialTitleOnCreateButtonHover="milieu"
            operationName='getAidEnvironments'
            countOperationName='getAidEnvironmentCount'
            deleteOperationName='deleteReferenceData'
            formName='aid-environment'
            isFeminine={false}
            checkingCanBeDeletedOperationName="canBeDeleted"
        />
    )
}

export default AidEnvironment