import React, { useState } from "react";
import "./textarea.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Requiredstar } from "./Input";

const Textarea = ({
  value,
  required,
  onChange,
  placeholder,
  readOnly,
  onRequestKeyPress,
}) => {
  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  };

  const formats = ["bold", "italic", "underline"];

  const [isFocused, setIsFocused] = useState(false);

  const _getQuestionAsParagraph = () => {
    const regex = /^<p>.*<\/p>$/;
    let valueAsParagraph = value;

    if (valueAsParagraph && !regex.test(valueAsParagraph)) {
      valueAsParagraph = `<p>${value}</p>`;
    }

    return valueAsParagraph;
  };

  return (
    <div className="textarea-root">
      {placeholder && (
        <div
          className={`${
            isFocused || (value && value !== "<p><br></p>") ? "focus-" : ""
          }label-content ${isFocused ? "focus-text" : ""}`}
        >
          <label>
            <span>{placeholder}</span>
            {required && <Requiredstar />}
          </label>
        </div>
      )}
      <ReactQuill
        className={isFocused ? "focused-quill-form" : ""}
        modules={modules}
        formats={formats}
        theme="snow"
        readOnly={readOnly}
        value={_getQuestionAsParagraph()}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyPress={(event) => {
          if (typeof onRequestKeyPress === "function") {
            onRequestKeyPress(event);
          } else {
            alert("BONJOUR");
          }
        }}
      />
    </div>
  );
};

export default Textarea;
