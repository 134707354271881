import React from "react";
import TooltipSlider from "./TooltipSlider";
import _ from "lodash"
import GenericHelper from 'fsy.common-library/lib/helpers/GenericHelper'

class SliderRange extends React.Component {
    constructor(props) {
        super(props)
        const value = this.getMinMaxPropsValue()

        this.state = {
            value: value,
            marks: {
                [props.min]: {
                    style: {
                        fontWeight: "bold",
                        fontSize: "0.8em"
                    },
                    label: props.min.toLocaleString() + " " + props.unit
                },
                [props.max]: {
                    style: {
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        fontSize: "0.8em"
                    },
                    label: props.max.toLocaleString() + " " + props.unit
                }
            },
            checked: props.checked
        }

        this.getMinMaxPropsValue = this.getMinMaxPropsValue.bind(this)
        this.onSliderChange = this.onSliderChange.bind(this)
        this.onAfterSliderChange = this.onAfterSliderChange.bind(this)
        this.onReset = this.onReset.bind(this)
    }


    getMinMaxPropsValue() {
        return [
            this.props.value && !_.isNull(this.props.value[0]) ? this.props.value[0] : this.props.min,
            this.props.value && !_.isNull(this.props.value[1]) ? this.props.value[1] : this.props.max,
        ]
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.getMinMaxPropsValue(),
                checked: this.props.checked
            })
        }
    }

    onSliderChange = (value) => {
        this.setState({
            value: value,
            checked: true
        })
    }

    onAfterSliderChange = (checked = true) => {
        this.setState({ checked: checked }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.value)
            }
        })
    }

    onReset() {
        this.setState({
            value: [
                this.props.min,
                this.props.max,
            ],
        })

        if (this.props.onReset) {
            this.props.onReset()
        }
    }

    render() {
        return <div className={'aidCriteria-list-item'}>
            <div className="text-truncate w-100">
                {this.props.label}
            </div>
            <div className={'aidCriteria-slider'}>
                <TooltipSlider
                    range
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    value={this.state.value}
                    marks={this.state.marks}
                    disabled={this.props.disabled}
                    onChange={this.onSliderChange}
                    onAfterChange={this.onAfterSliderChange}
                    tipFormatter={(value) => `${GenericHelper.digitWithDecimalPrecision(value)} ${this.props.unit}`}
                    tipProps={{placement: "bottom"}}
                    visible={this.state.visible}
                    checked={this.state.checked}

                    onMinChange={(event) => {
                        const value = event.target.value.replace(/[^0-9.]/g, '')
                        if (value === "") {
                            this.onReset()
                            return
                        }

                        this.setState({
                            value: [
                                value,
                                this.state.value[1],
                            ],
                            checked: true
                        })

                        this.props.onRequestMinOrMaxChange(value, 'minimum')
                    }}

                    onMaxChange={(event) => {
                        const value = event.target.value.replace(/[^0-9.]/g, '')
                        if (value === "") {
                            this.onReset()
                            return
                        }
                        this.setState({
                            value: [
                                this.state.value[0],
                                value,
                            ],
                            checked: true
                        })
                        
                        this.props.onRequestMinOrMaxChange(value, 'maximum')
                    }}

                    onInputBlur={(result, checked) => {
                        this.setState({
                            value: result,
                            checked: checked
                        }, () => {
                            // this.onAfterSliderChange()
                        })
                    }}

                    onReset={() => {
                        this.onReset()
                    }}
                />
            </div>
            <br/>
        </div>
    }
}

export default SliderRange