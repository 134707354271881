import { Add, Close, Delete, East, MoreVert } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { SelectField } from "../../../general/form/Select";
import {
  CircularProgress,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  CRITERION_TYPE_NUM,
  CRITERION_TYPE_OBG,
  CRITERION_TYPE_TXT,
} from "fsy.common-library/lib/env/Constants";
import { Api } from "fsy.common-library";
import Helper from "../../../../services/Helper";
import TooltipSlider from "../../../general/form/TooltipSlider";
import "rc-slider/assets/index.css";

const QuestionDependency = ({
  steps,
  currentDependency,
  question,
  readOnly,
  componentHeight,
  questionDependenciesWithError,
  currentStep,
  onRequestAddDependency,
  onRequestDependencyFormChange,
  onRequestDeleteDependency,
  onRequestChooseDependency,
  onRequestChooseCriteriaValue,
  onRequestChooseAvailableAnswers,
  onRequestSliderChange,
}) => {
  const dependencyTitleRef = useRef(null);
  const availableAnswersContentRef = useRef(null);
  const availableAnswersHeaderRef = useRef(null);

  const [state, setState] = useState({
    loading: false,
    availableAnswersLoading: false,
    isOpenedDetails: false,
    questions: [],
    criterionValues: [],
    availableAnswerValues: [],
    currentChosenCriteriaValue: null,
    criterionNumericData: {},
    availableAnswersBodyHeight: undefined,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleAvailableAnswersDialogDisplay = (element) => {
    _setState({
      isOpenedDetails: !state.isOpenedDetails,
      currentChosenCriteriaValue: element,
    });
  };

  const _handleSliderChange = (newValue) => {
    _setState({
      criterionNumericData: {
        ...state.criterionNumericData,
        value: newValue,
        checked: true,
      },
    });
    onRequestSliderChange({
      min: newValue[0],
      max: newValue[1],
      checked: true,
    });
  };

  const _handleMinOrMaxChange = (event, index) => {
    const value = event.target.value.replace(/[^0-9.]/g, "");
    const currentValue = [...state.criterionNumericData.value];
    currentValue[index] =
      Number(value) ||
      currentDependency?.value?.criterion?.[
        `value${index === 0 ? "Min" : "Max"}`
      ];

    _setState({
      criterionNumericData: {
        ...state.criterionNumericData,
        value: currentValue,
        checked: true,
      },
    });

    onRequestSliderChange({
      min: currentValue[0],
      max: currentValue[1],
      checked: true,
    });
  };

  const _handleReset = () => {
    const newValue = [
      currentDependency.value.criterion.valueMin,
      currentDependency.value.criterion.valueMax,
    ];

    _setState({
      criterionNumericData: {
        ...state.criterionNumericData,
        value: newValue,
        checked: false,
      },
    });
    onRequestSliderChange({
      min: newValue[0],
      max: newValue[1],
      checked: false,
    });
  };

  const _handleFormBlur = (result, checked) => {
    if (result[0] < currentDependency.value.criterion.valueMin) {
      result[0] = currentDependency.value.criterion.valueMin;
    }

    if (result[1] > currentDependency.value.criterion.valueMax) {
      result[1] = currentDependency.value.criterion.valueMax;
    }

    _setState({
      criterionNumericData: {
        ...state.criterionNumericData,
        value: result,
        checked,
      },
    });
    onRequestSliderChange({
      min: result[0],
      max: result[1],
      checked: checked,
    });
  };

  const _getActiveCriterionCriteriaValues = async (
    criterionId,
    type = "availableAnswers"
  ) => {
    const loadingProp =
      type === "availableAnswers" ? "availableAnswersLoading" : "loading";
    _setState({ [loadingProp]: true });
    let results = await Api.criterionValue.getActiveCriterionCriteriaValues(
      criterionId
    );
    results = Helper.isValidResponse(results);
    _setState({ [loadingProp]: false });

    return results;
  };

  useEffect(() => {
    if (
      steps?.length > 0 && currentStep.position > 0 && question?.id
        ? question?.position > 0
        : true
    ) {
      const questions = [];
      const position = currentStep.position;

      steps.map((step) => {
        let stepQuestions = [];
        step.questions.map((q) => {
          let creationModeCondition = step.position <= position;

          let updateModeCondition =
            step.position < position ||
            (step.position === position && q.position < question?.position);

          let condition = question?.id
            ? updateModeCondition
            : creationModeCondition;

          if (condition) {
            stepQuestions.push({
              value: q.id,
              position: q.position,
              label: `${q.title} - ${q.criterion.name} (${q.criterion.shortName} - ${q.criterion.type.shortName})`,
              obgText1: q.obgText1,
              obgText2: q.obgText2,
              criterion: {
                id: q.criterion.id,
                name: q.criterion.name,
                type: q.criterion.type.shortName,
                isMulti: q.criterion.multi,
                valueMin: q.criterion.valueMin,
                valueMax: q.criterion.valueMax,
                unit: q.criterion.unit,
                step: q.criterion.step,
              },
            });
          }
          return null;
        });

        stepQuestions.sort(
          (question1, question2) => question1.position - question2.position
        );

        questions.push(...stepQuestions);
        return null;
      });

      _setState({ questions });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(steps), currentStep.position, question?.position]);

  useEffect(() => {
    if (currentDependency?.value?.criterion?.id) {
      if (currentDependency.value.criterion.type === CRITERION_TYPE_TXT) {
        _setState({ loading: true });
        Api.criterionValue
          .getActiveCriterionCriteriaValues(
            currentDependency.value.criterion.id
          )
          .then((response) => {
            const results = Helper.isValidResponse(response);
            const stateObject = {
              loading: false,
            };

            if (results) {
              results.sort((a, b) => a.position - b.position);
              stateObject.criterionValues = results;
            }

            _setState(stateObject);
          })
          .catch((error) => {
            _setState({ loading: false });
            console.error(error);
          });
      } else if (
        currentDependency.value.criterion.type === CRITERION_TYPE_OBG
      ) {
        const results = [
          {
            id: "yes",
            value: currentDependency.value.obgText1 || "Oui",
          },
          {
            id: "no",
            value: currentDependency.value.obgText2 || "Non",
          },
        ];

        _setState({ criterionValues: results });
      }
    }
    // eslint-disable-next-line
  }, [currentDependency?.value?.criterion?.id]);

  useEffect(() => {
    if (state.isOpenedDetails) {
      _getActiveCriterionCriteriaValues(question?.criteria?.value)
        .then((results) => {
          _setState({ availableAnswerValues: results });
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, [state.isOpenedDetails]);

  useEffect(() => {
    if (currentDependency?.value?.criterion) {
      const criterion = currentDependency.value.criterion;
      if (currentDependency?.value?.criterion?.type === CRITERION_TYPE_NUM) {
        const currentValue =
          question?.criterionValues[currentDependency.value.value];
        _setState({
          criterionNumericData: {
            value: currentValue
              ? [currentValue.min, currentValue.max]
              : [criterion.valueMin, criterion.valueMax],
            valueMin: criterion.valueMin,
            valueMax: criterion.valueMax,
            unit: criterion.unit,
            step: criterion.step,
            checked: currentValue ? true : false,
            marks: {
              [criterion.valueMin]: {
                style: {
                  fontWeight: "bold",
                  fontSize: "0.8em",
                },
                label: `${criterion.valueMin} ${criterion.unit}`,
              },
              [criterion.valueMax]: {
                style: {
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  fontSize: "0.8em",
                },
                label: `${criterion.valueMax} ${criterion.unit}`,
              },
            },
          },
        });
      } else {
        _setState({
          criterionNumericData: {},
        });
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(currentDependency)]);

  useEffect(() => {
    const availableAnswersContentHeight =
      availableAnswersContentRef.current?.offsetHeight;
    const availableAnswersHeaderHeight =
      availableAnswersHeaderRef.current?.offsetHeight;

    if (availableAnswersContentHeight && availableAnswersHeaderHeight) {
      const availableAnswersBodyHeight =
        availableAnswersContentHeight - availableAnswersHeaderHeight - 15;

      _setState({
        availableAnswersBodyHeight,
      });
    }
  }, [
    availableAnswersContentRef.current?.offsetHeight,
    availableAnswersHeaderRef.current?.offsetHeight,
    question?.questionDependencies?.length,
  ]);

  return (
    <>
      <div
        className="question-dependency-form"
        style={{ minHeight: componentHeight }}
      >
        <div className="dependency-label-form">
          <div ref={dependencyTitleRef} className="content-title">
            <span>Dépendances</span>
            {!readOnly && (
              <button
                type="button"
                className={`btn btn-tiny default addButton`} // loading => disabled
                title="Cliquer pour ajouter une dépendance"
                onClick={onRequestAddDependency}
              >
                <Add />
                Ajouter une dépendance
              </button>
            )}
          </div>
          {question?.questionDependencies?.map((questionDependencie, index) => {
            return (
              <div
                key={`Dependencie${questionDependencie.id}${index + 1}`}
                className="content-form"
              >
                <SelectField
                  options={state.questions}
                  value={questionDependencie.value}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  placeholder=""
                  isDisabled={readOnly}
                  optionsContentHeight={150}
                  onChange={(data) =>
                    onRequestDependencyFormChange(data, questionDependencie)
                  }
                />
                <div className="dependency-action-content">
                  {!readOnly && (
                    <div
                      title="Supprimer"
                      onClick={() =>
                        onRequestDeleteDependency(questionDependencie.id)
                      }
                    >
                      <Delete
                        className="delete-dependency-icon"
                        fontSize="large"
                      />
                    </div>
                  )}
                  <div
                    title="Voir"
                    className={`${
                      currentDependency?.id === questionDependencie.id
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      onRequestChooseDependency(questionDependencie)
                    }
                  >
                    <East className={`consult-dependency-icon`} />
                  </div>
                </div>
                {currentDependency?.id === questionDependencie.id && (
                  <div className="active-mark" />
                )}
              </div>
            );
          })}
        </div>
        {currentDependency && (
          <div
            style={{
              paddingTop: 10 + dependencyTitleRef.current?.offsetHeight,
            }}
            className={`dependency-value-form${
              state.loading ? "-loading" : ""
            }`}
          >
            {/** Emplacement */}
            {state.isOpenedDetails && (
              <div
                ref={availableAnswersContentRef}
                className="txt-additional-forms"
              >
                <div className="txt-additional-content">
                  <div
                    ref={availableAnswersHeaderRef}
                    className="txt-additional-header"
                  >
                    <button
                      type="button"
                      className="btn alert modal-close-button"
                      title="Fermer"
                      onClick={_handleAvailableAnswersDialogDisplay}
                    >
                      <Close />
                    </button>
                  </div>
                  <div
                    className={`txt-additional-body`}
                    style={{
                      height: state.availableAnswersBodyHeight,
                    }}
                  >
                    <ToggleButtonGroup
                      orientation="vertical"
                      className="answers-list"
                      aria-label="answers"
                      fullWidth
                    >
                      {state.availableAnswersLoading ? (
                        <div className="flex">
                          <CircularProgress color="success" />
                        </div>
                      ) : (
                        <>
                          {state.availableAnswerValues?.map(
                            (element, index) => {
                              return (
                                <ToggleButton
                                  key={`AvailableAnswer${element.id}${
                                    index + 1
                                  }`}
                                  className={`${
                                    question?.availableAnswers?.[
                                      currentDependency?.value?.value
                                    ]?.[
                                      state.currentChosenCriteriaValue?.id
                                    ]?.includes(element.id)
                                      ? "active-criteria-value"
                                      : ""
                                  }`}
                                  size="small"
                                  disabled={readOnly}
                                  onClick={() =>
                                    onRequestChooseAvailableAnswers(
                                      state.currentChosenCriteriaValue,
                                      element
                                    )
                                  }
                                >
                                  {element.value}
                                </ToggleButton>
                              );
                            }
                          )}
                        </>
                      )}
                    </ToggleButtonGroup>
                  </div>
                </div>
              </div>
            )}
            {!state.loading ? (
              <div
                className={`dependency-value-content${
                  question?.criteria?.type === CRITERION_TYPE_TXT &&
                  currentDependency?.value?.criterion?.type ===
                    CRITERION_TYPE_TXT &&
                  question?.criterionValues?.[currentDependency?.value?.value]
                    ?.length > 0
                    ? "-TXT"
                    : ""
                }`}
              >
                <div className="form-title">
                  {`${
                    currentDependency?.value?.criterion?.type ===
                    CRITERION_TYPE_NUM
                      ? "Valeurs"
                      : "Dépendances"
                  } pour ${currentDependency?.value?.criterion?.name}`}
                </div>
                {/** Pour type TXT et OBG */}
                {[CRITERION_TYPE_TXT, CRITERION_TYPE_OBG].includes(
                  currentDependency?.value?.criterion?.type
                ) && (
                  <ToggleButtonGroup
                    orientation="vertical"
                    exclusive={true}
                    className={`answers-list${
                      question?.criteria?.type === CRITERION_TYPE_OBG &&
                      currentDependency?.value?.criterion?.type ===
                        CRITERION_TYPE_OBG
                        ? " obg-form-content"
                        : ""
                    }`}
                    aria-label="answers"
                  >
                    {state.criterionValues.map((element, index) => {
                      return (
                        <div
                          className="flex-start"
                          key={`CriteriaValue${element.id}${index + 1}`}
                        >
                          <ToggleButton
                            value={element.id}
                            disabled={readOnly}
                            className={`txt-value ${
                              question?.criterionValues?.[
                                currentDependency.value.value
                              ]?.includes(element.id)
                                ? "active-criteria-value"
                                : ""
                            }`}
                            size="small"
                            onClick={() => {
                              onRequestChooseCriteriaValue(element);
                            }}
                          >
                            {element.value}
                          </ToggleButton>
                          {question?.criteria?.type === CRITERION_TYPE_TXT &&
                            currentDependency?.value?.criterion?.type ===
                              CRITERION_TYPE_TXT &&
                            question?.criterionValues[
                              currentDependency?.value?.value
                            ]?.includes(element.id) && (
                              <div className="flex icon-button-content">
                                <IconButton
                                  id="basic-button"
                                  aria-haspopup="true"
                                  onClick={() =>
                                    _handleAvailableAnswersDialogDisplay(
                                      element
                                    )
                                  }
                                  title="Actions"
                                  className="action-button"
                                >
                                  <MoreVert />
                                </IconButton>
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </ToggleButtonGroup>
                )}
                {/** Pour type NUM */}
                {currentDependency?.value?.criterion?.type ===
                  CRITERION_TYPE_NUM && (
                  <div>
                    <TooltipSlider
                      range
                      min={state.criterionNumericData.valueMin}
                      max={state.criterionNumericData.valueMax}
                      units={state.criterionNumericData.unit}
                      value={state.criterionNumericData.value}
                      marks={state.criterionNumericData.marks}
                      checked={state.criterionNumericData.checked}
                      disabled={readOnly}
                      onChange={_handleSliderChange}
                      visible={false}
                      step={state.criterionNumericData.step}
                      onMinChange={(event) => _handleMinOrMaxChange(event, 0)}
                      onMaxChange={(event) => _handleMinOrMaxChange(event, 1)}
                      onInputBlur={(result, checked) =>
                        _handleFormBlur(result, checked)
                      }
                      onReset={_handleReset}
                      onAfterChange={() => {}}
                      tipFormatter={(value) =>
                        `${value} ${state.criterionNumericData.unit}`
                      }
                      tipProps={{ placement: "bottom" }}
                    />
                  </div>
                )}
                {questionDependenciesWithError.includes(
                  currentDependency?.id
                ) && <div className="error">Champ obligatoire</div>}
              </div>
            ) : (
              <CircularProgress color="success" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionDependency;
