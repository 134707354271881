import { Constants } from "fsy.common-library";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../../general/form/Select";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
} from "@mui/material";
import SpecieRow from "./SpecieRow";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";
import TooltipSlider from "../../../general/form/TooltipSlider";
import "./aidFundingCriterionForm.css";
import Helper from "../../../../services/Helper";
import fsyTheme from "./../../../general/form/FsyTheme";

const AidFundingCriterionForm = ({
  criterion,
  readOnly,
  speciesGroup,
  zones,
  regions,
  editingData,
  departments,
  valueToErase,
  onRequestEraseValueToErase,
  onRequestFormChange,
  onRequestSetConfirm,
  onRequestGetCriterionCVLabelById,
  onRequestGetZoneByTypeId,
  onRequestGetLocationById,
  onRequestGetSpecieLabelById,
}) => {
  const type = criterion?.type?.shortName;
  const shortName = criterion?.shortName;

  const [state, setState] = useState(_getDefaultState());

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleAfterFormChange = (data, isWithFormStatusUpdated = true) => {
    if (isWithFormStatusUpdated && typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }

    if (typeof onRequestFormChange === "function") {
      onRequestFormChange({
        value: data,
        shortName,
        type,
        criterionId: criterion.id,
      });
    }
  };

  const _handleFormChange = (data, name = null) => {
    let value = null;

    if ([Constants.CRITERION_TYPE_TXT].includes(type)) {
      value = data;

      if (value) {
        if (Array.isArray(value)) {
          value = value.map((v) => {
            return {
              ...v,
              criterion,
            };
          });
        } else {
          if (value.hasOwnProperty("value") && value.hasOwnProperty("label")) {
            value = {
              ...value,
              criterion,
            };
          }
        }
      }
    }

    if ([Constants.CRITERION_TYPE_OBG].includes(type)) {
      value = data?.target?.value || null;

      value = {
        value,
        criterion,
      };
    }

    if ([Constants.CRITERION_TYPE_LOC].includes(type)) {
      value = {
        type: state.value?.type || null,
        location: state.value?.location || null,
        criterion,
      };

      if (name === "zone") {
        value.type = data;
        value.location = null;
      }

      if (name === "location") {
        value.location = data;
      }
    }

    _setState({ value });

    if (typeof onRequestFormChange === "function") {
      let data = null;

      if (type === Constants.CRITERION_TYPE_TXT) {
        if (Array.isArray(value)) {
          data = value?.map((val) => val.value) || [];
        }
      }

      if (type === Constants.CRITERION_TYPE_OBG) {
        data = value?.value || null;

        if (data && !isNaN(data)) {
          data = +data;
        }
      }

      if (type === Constants.CRITERION_TYPE_LOC) {
        data = {
          type: value?.type?.value || null,
          location: value?.location?.value || null,
        };
      }

      _handleAfterFormChange(data);
    }
  };

  const _handleOptionClick = () => {
    if (!readOnly) {
      _setState({ value: null });

      if (typeof onRequestSetConfirm === "function") {
        onRequestSetConfirm(true);
      }

      _handleAfterFormChange(null);
    }
  };

  const _handleSliderChange = (value) => {
    _setState({
      value,
      checked: true,
    });

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }

    _handleAfterFormChange(value);
  };

  const _handleReset = () => {
    _setState({
      value: [criterion.valueMin, criterion.valueMax],
      checked: false,
    });

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }

    _handleAfterFormChange(null);
  };

  const _handleChangeMinOrMax = (event, index) => {
    const newValue = event.target.value.replace(/[^0-9.]/g, "");
    const currentValue = [...state.value];

    currentValue[index] = +newValue;

    _setState({
      value: currentValue,
      checked: true,
    });

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }

    _handleAfterFormChange(currentValue);
  };

  const _handleFormBlur = (result, checked) => {
    if (result[0] < criterion.valueMin) {
      result[0] = criterion.valueMin;
    }

    if (result[1] > criterion.valueMax) {
      result[1] = criterion.valueMax;
    }

    _setState({
      value: result,
      checked,
    });

    _handleAfterFormChange(result, false);
  };

  useEffect(() => {
    if (criterion?.id) {
      const criterionValues = criterion.criterionValues?.filter(
        (element) => element.active
      );

      if (
        [Constants.CRITERION_TYPE_TXT, Constants.CRITERION_TYPE_OBG].includes(
          type
        )
      ) {
        let options = [];

        if (!["criPlan_04", "criPlan_05"].includes(shortName)) {
          options = criterionValues.map((element) => {
            return {
              value: element.id,
              label: element.value,
            };
          });
        } else {
          options = speciesGroup?.map((group) => {
            return {
              label: group.label,
              options: group.species?.map((specie) => {
                return {
                  value: specie.id,
                  label: specie.label,
                };
              }),
            };
          });
        }

        _setState({ options });
      }

      if (type === Constants.CRITERION_TYPE_LOC) {
        const zoneOptions = zones?.map((zone) => {
          return {
            value: zone.value,
            label: zone.label,
          };
        });
        const regionOptions = regions?.map((region) => {
          return {
            value: region.id,
            label: region.label,
          };
        });
        const departmentOptions = departments?.map((department) => {
          return {
            value: department.id,
            label: department.label,
          };
        });

        _setState({ zoneOptions, regionOptions, departmentOptions });
      }
    }
    // eslint-disable-next-line
  }, [criterion?.id, type]);

  useEffect(() => {
    if (type === Constants.CRITERION_TYPE_LOC) {
      const regions = state.regionOptions;
      const departments = state.departmentOptions;
      let options = [];
      const stateObject = {};

      if (state.value?.type?.value) {
        if (
          [
            Constants.LOCATION_TYPE_REGIONAL,
            Constants.LOCATION_TYPE_DEPARTMENTAL,
          ].includes(state.value.type.value)
        ) {
          options =
            state.value.type.value === Constants.LOCATION_TYPE_REGIONAL
              ? regions
              : departments;

          stateObject.options = options;
        }
      } else {
        stateObject.value = null;
      }

      _setState(stateObject);
    }
    // eslint-disable-next-line
  }, [type, state.value?.type?.value]);

  // En cas de modification
  useEffect(() => {
    if (editingData) {
      let data = null;
      let specieData = null;
      let valueMin = null;
      let valueMax = null;
      let checked = false;
      let marks = {};

      const { value } = editingData;

      if (type === Constants.CRITERION_TYPE_TXT) {
        if (Array.isArray(value)) {
          data = [];

          value.map((val) => {
            data.push({
              value: val,
              label: !["criPlan_04", "criPlan_05"].includes(shortName)
                ? onRequestGetCriterionCVLabelById(val)
                : onRequestGetSpecieLabelById(val, true),
            });
            return null;
          });
        } else {
          data = {
            value,
            label: onRequestGetCriterionCVLabelById(value),
          };
        }
      }

      if (type === Constants.CRITERION_TYPE_OBG) {
        data = {
          value,
        };
      }

      if (type === Constants.CRITERION_TYPE_LOC) {
        data = {
          type: {
            value: value.type,
            label: onRequestGetZoneByTypeId(value.type),
          },
          location: value.location
            ? {
                value: value.location,
                label: onRequestGetLocationById(value.location, value.type),
              }
            : null,
        };
      }

      if (type === Constants.CRITERION_TYPE_NUM) {
        if (shortName !== "criPlan_01") {
          let val = value;
          data = val;
          valueMin = criterion.valueMin; //val[0];
          valueMax = criterion.valueMax; //val[1];
          checked = true;
          marks = {
            [valueMin]: {
              style: {
                fontWeight: "bold",
                fontSize: "0.8em",
              },
              label: `${valueMin} ${criterion.unit}`,
            },
            [valueMax]: {
              style: {
                fontWeight: "bold",
                whiteSpace: "nowrap",
                fontSize: "0.8em",
              },
              label: `${valueMax} ${criterion.unit}`,
            },
          };
        } else {
          specieData = {
            speciesGroup: {
              value: value.specieGroup,
              label: onRequestGetSpecieLabelById(value.specieGroup),
            },
            specie:
              value.specie?.length > 0
                ? value.specie.map((val) => {
                    return {
                      value: val,
                      label: onRequestGetSpecieLabelById(val, true),
                    };
                  })
                : null,
          };
        }
      }

      if (data || specieData) {
        _setState({ value: data, specieData, checked, marks });
      }
    } else {
      let val = null;
      let valueMin = null;
      let valueMax = null;
      let marks = {};

      const isNumericRow =
        type === Constants.CRITERION_TYPE_NUM && shortName !== "criPlan_01";

      if (isNumericRow) {
        valueMin = criterion.valueMin;
        valueMax = criterion.valueMax;
      }

      if (
        isNumericRow &&
        Helper.isValidNumber(valueMin) &&
        Helper.isValidNumber(valueMax)
      ) {
        val = [valueMin, valueMax];
        marks = {
          [valueMin]: {
            style: {
              fontWeight: "bold",
              fontSize: "0.8em",
            },
            label: `${valueMin} ${criterion.unit}`,
          },
          [valueMax]: {
            style: {
              fontWeight: "bold",
              whiteSpace: "nowrap",
              fontSize: "0.8em",
            },
            label: `${valueMax} ${criterion.unit}`,
          },
        };
      }

      _setState({ value: val, specieData: null, checked: false, marks });
    }
    // eslint-disable-next-line
  }, [editingData, type, shortName, criterion]);

  useEffect(() => {
    if (valueToErase && valueToErase === shortName) {
      _setState({ value: null });

      if (
        typeof onRequestEraseValueToErase === "function" &&
        typeof onRequestFormChange === "function"
      ) {
        onRequestFormChange({
          value: null,
          shortName: valueToErase,
          type,
          criterionId: criterion.id,
        });
        onRequestEraseValueToErase();
      }
    }
    // eslint-disable-next-line
  }, [valueToErase, shortName]);

  return shortName === "criPlan_01" ? (
    <SpecieRow
      type={type}
      shortName={shortName}
      data={state.specieData}
      speciesGroup={speciesGroup}
      criterion={criterion}
      onRequestFundingCriterionUpdate={onRequestFormChange}
      onRequestSetConfirm={onRequestSetConfirm}
      readOnly={readOnly}
    />
  ) : (
    <div className="aid-funding-criterion-form-root">
      <div
        className={`content ${
          type === Constants.CRITERION_TYPE_NUM && shortName !== "criPlan_01"
            ? "num-content"
            : ""
        }`}
      >
        {shortName !== "criPlan_01" && <label>{criterion?.name || ""}</label>}
        {type === Constants.CRITERION_TYPE_TXT && (
          <SelectField
            name={shortName}
            options={state.options}
            value={state.value}
            isMulti={true}
            closeMenuOnSelect={false}
            placeholder=""
            isDisabled={readOnly}
            optionsContentHeight={150}
            onChange={_handleFormChange}
          />
        )}
        {type === Constants.CRITERION_TYPE_OBG && (
          <ThemeProvider theme={fsyTheme}>
            <FormControl style={{ width: "100%" }}>
              <RadioGroup
                value={state.value?.value || ""}
                style={{ width: "100%" }}
              >
                {state.options.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={<Radio size="small" />}
                      title={option.label}
                      onChange={_handleFormChange}
                      onClick={_handleOptionClick}
                      style={{
                        width: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      disabled={readOnly}
                      label={option.label}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        )}
        {type === Constants.CRITERION_TYPE_LOC && (
          <>
            <SelectField
              options={state.zoneOptions}
              value={state.value?.type}
              isMulti={false}
              closeMenuOnSelect={true}
              placeholder=""
              isDisabled={readOnly}
              onChange={(event) => _handleFormChange(event, "zone")}
            />
            <SelectField
              options={state.options}
              value={state.value?.location}
              isMulti={false}
              closeMenuOnSelect={true}
              required={false}
              placeholder={
                [
                  Constants.LOCATION_TYPE_REGIONAL,
                  Constants.LOCATION_TYPE_DEPARTMENTAL,
                ].includes(state.value?.type?.value)
                  ? state.value?.type?.value ===
                    Constants.LOCATION_TYPE_REGIONAL
                    ? "Région"
                    : "Département"
                  : ""
              }
              isDisabled={
                readOnly ||
                !state.value ||
                state.value?.type?.value === Constants.LOCATION_TYPE_NATIONAL
              }
              optionsContentHeight={140}
              onChange={(event) => _handleFormChange(event, "location")}
            />
          </>
        )}
        {type === Constants.CRITERION_TYPE_NUM &&
          shortName !== "criPlan_01" && (
            <>
              <TooltipSlider
                range
                min={criterion.valueMin}
                max={criterion.valueMax}
                units={criterion.unit}
                value={state.value}
                marks={state.marks}
                disabled={readOnly}
                onChange={_handleSliderChange}
                checked={state.checked}
                onAfterChange={() => {}}
                tipFormatter={(value) =>
                  `${GenericHelper.digitWithDecimalPrecision(value)} ${
                    criterion.unit
                  }`
                }
                tipProps={{ placement: "bottom" }}
                visible={false}
                step={criterion.step ?? 1}
                onMinChange={(event) => _handleChangeMinOrMax(event, 0)}
                onMaxChange={(event) => _handleChangeMinOrMax(event, 1)}
                onReset={_handleReset}
                onInputBlur={(result, checked) =>
                  _handleFormBlur(result, checked)
                }
              />
            </>
          )}
      </div>
    </div>
  );
};

export default AidFundingCriterionForm;

const _getDefaultState = () => {
  return {
    value: null,
    zoneOptions: [],
    regionOptions: [],
    departmentOptions: [],
    options: [],
    marks: {},
    checked: false,
    specieData: null,
  };
};
