import React from "react";
import { Divider, Skeleton, Stack } from "@mui/material";
import Helper from "./Helper";

export function TableWithFiltersSkeleton({
  linesCount = 10,
  filtersCount = 3,
  linesSize = "medium",
}) {
  return (
    <Stack spacing={2}>
      <Filters number={filtersCount} />
      <Divider />
      <TableLines number={linesCount} size={linesSize} />
    </Stack>
  );
}

export function TableSkeleton({ linesCount = 10, size }) {
  return <TableLines number={linesCount} size={size} />;
}

export function TextSkeleton({ height = 15, width = "100%" }) {
  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={width}
      height={height}
    />
  );
}

export function StatBoxSkeleton() {
  return (
    <Stack spacing={1} className="stats-item">
      <Skeleton animation="wave" variant="circular" width={20} height={20} />
      <Skeleton animation="wave" variant="rectangular" width={50} height={15} />
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
      <Skeleton animation="wave" variant="rectangular" width={50} height={15} />
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={210}
        height={118}
      />
    </Stack>
  );
}

export function AdvancedStatsSkeleton() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Skeleton
        animation="wave"
        variant="circular"
        width="64px"
        height="64px"
      />
      <Skeleton animation="wave" variant="rounded" width="20%" height="50px" />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="40%"
        height="25px"
      />
    </Stack>
  );
}

export function ChartSkeleton({ number = 10, height = 325 }) {
  let width = Math.floor(80 / number); //80% of the total width
  let skeleton = [];
  for (let i = 0; i < number; i++) {
    skeleton.push(
      <Skeleton
        animation="wave"
        variant="rounded"
        height={`${Helper.getRandomInt(100)}%`}
        width={`${width}%`}
        key={`skeleton-${i}`}
      />
    );
  }
  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      height={height}
    >
      {skeleton}
    </Stack>
  );
}

export function PieChartSkeleton({ size = 300 }) {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Skeleton
        animation="wave"
        variant="circular"
        height={size}
        width={size}
      />
      <Skeleton animation="wave" variant="rounded" width="60%" height={20} />
    </Stack>
  );
}

export function CriterionFormSkeleton() {
  return (
    <Stack spacing={1}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Skeleton animation="wave" variant="rounded" width="20%" height={20} />
        <Skeleton animation="wave" variant="rounded" width="20%" height={20} />
        <Skeleton animation="wave" variant="rounded" width="20%" height={20} />
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Skeleton animation="wave" variant="rounded" width="20%" height={20} />
        <div className="flex">
          <div
            style={{
              width: "60%",
              marginRight: "20px",
              boxSizing: "border-box",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              width="100%"
              height={100}
            />
          </div>
          <div style={{ width: "40%" }}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width="100%"
              height={100}
            />
          </div>
        </div>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Skeleton animation="wave" variant="rounded" width="20%" height={20} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="40vw"
          height={100}
        />
      </Stack>
    </Stack>
  );
}

export function AidFormSkeleton() {
  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
    >
      <Skeleton animation="wave" variant="rounded" width="75%" height="65vh" />
      <Skeleton animation="wave" variant="rounded" width="20%" height="45vh" />
    </Stack>
  );
}

function TableLines({ number = 10, size = "medium" }) {
  let height;
  switch (size) {
    case "small":
      height = "2vh";
      break;
    case "medium":
      height = "2.5vh";
      break;
    case "large":
      height = "3vh";
      break;
    default:
      height = "2vh";
  }
  let skeleton = [];
  for (let i = 0; i < number; i++) {
    skeleton.push(
      <Skeleton
        animation="wave"
        variant="rounded"
        width="100%"
        height={height}
        key={`skeleton-${i}`}
      />
    );
  }
  return <Stack spacing={1}>{skeleton}</Stack>;
}

function Filters({ number = 3 }) {
  let skeleton = [];
  for (let i = 0; i < number; i++) {
    skeleton.push(
      <Skeleton
        animation="wave"
        variant="rounded"
        width="20%"
        height={35}
        key={`skeleton-${i}`}
      />
    );
  }
  return (
    <Stack
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt="25px"
      ml="10px"
      mr="10px"
    >
      {skeleton}
      <Skeleton animation="wave" variant="rounded" width="50px" height={35} />
    </Stack>
  );
}
