import React, { useEffect, useState } from "react";
import { Api, Constants, Session } from "fsy.common-library";
import HtmlStructure from "../general/HtmlStructure";
import { Loading } from "../general/form/Loading";
import DataTable from "react-data-table-component";
import Helper from "../../services/Helper";
import moment from "moment";
import { toast } from "react-toastify";
import ReactSwitch from "react-switch";
import Modal from "../general/form/Modal";
import { InputField } from "../general/form/Input";
import { SelectField } from "../general/form/Select";
import { AidForm } from "./AidForm";
import {
  AID_STATUS_DRAFT,
  AID_STATUS_LIST,
  AID_STATUS_REFUSED,
  AID_STATUS_VALIDATED,
  AID_STATUS_VALIDATING,
  ASCENDING_SORT_DIRECTION,
  HTTP_NO_CONTENT,
} from "fsy.common-library/lib/env/Constants";
import {
  Chip,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { TableWithFiltersSkeleton } from "../../services/LoadingHelper";
import {
  RIGHTS_NOACCESS,
  RIGHTS_READ,
  RIGHTS_WRITE,
} from "../../services/Constants";
import _ from "lodash";

import "./aids.css";
import {
  Add,
  ContentCopy,
  Delete,
  FilterAlt,
  FilterAltOff,
  MoreVert,
  CreateNewFolder as NewFolder,
  Edit as EditIcon,
  PlayCircle,
} from "@mui/icons-material";
import AidFolder from "./AidFolder/AidFolder";

export default function AidsPage() {
  document.title = "Aides - " + Constants.DOCUMENT_TITLE_BACKOFFICE;

  return (
    <HtmlStructure
      menuName="aids"
      sectionClassName="aids"
      auth={[Constants.PROFILE_AIDENTRY, Constants.PROFILE_AIDVALIDATION]}
    >
      <AidList />
    </HtmlStructure>
  );
}

const ActionButton = ({
  currentObject,
  listeObj,
  items,
  type,
  onCreateOrUpdateAction,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);
  const open = Boolean(anchorEl);

  let titleModalAdditionnalText = "";
  let contentModalAdditionnalText = "";
  let msgAdditionnalText = "";

  switch (type) {
    case 1: // FOLDER
      titleModalAdditionnalText = "de dossier";
      contentModalAdditionnalText = "ce dossier";
      msgAdditionnalText = "Le dossier";
      break;
    case 2: // SUBFOLDER
      titleModalAdditionnalText = "de sous-dossier";
      contentModalAdditionnalText = "ce sous-dossier";
      msgAdditionnalText = "Le sous-dossier";
      break;
    default: // AIDE
      titleModalAdditionnalText = "d'aide";
      contentModalAdditionnalText = "cette aide";
      msgAdditionnalText = "L'aide";
      break;
  }

  const handleAidButtonsAction = (item) => {
    if ([1, 2].includes(type) && ["create", "update"].includes(item.type)) {
      onCreateOrUpdateAction(item.type);
      return;
    }

    const action = [1, 2].includes(type)
      ? Api.folder[`${item.type}Folder`]
      : Api.aid[`${item.type}Aid`];

    setLoading(true);
    action(currentObject.id).then((response) => {
      let resultObject = null;

      if (item.type === "duplicate") {
        resultObject = Helper.isValidResponse(response);
      }

      if (item.type === "delete") {
        resultObject = response?.status === HTTP_NO_CONTENT;
      }

      if (resultObject) {
        listeObj?.refresh();

        if (item.type === "duplicate") {
          listeObj.displayModal("edit", resultObject);
        }

        if (item.type === "delete") {
          toast.success(
            `${msgAdditionnalText} a été supprimé${
              ![1, 2].includes(type) ? "e" : ""
            }.`,
            Helper.getToastOptions()
          );
        }
      } else {
        toast.error("Une erreur est survenue", Helper.getToastOptions());
      }

      setLoading(false);
    });
  };

  const handleModalStatus = () => {
    setIsOpenModalConfirmation(!isOpenModalConfirmation);
  };

  const handleItemClick = (item) => {
    if (item.withConfirmation) {
      setCurrentItem(item);
      handleModalStatus();
    } else {
      handleAidButtonsAction(item);
    }

    setAnchorEl(null);
  };

  const handleConfirm = () => {
    handleAidButtonsAction(currentItem);
    handleModalStatus();
    setCurrentItem(null);
  };

  return (
    <div>
      <Modal
        title={`Confirmation de supression ${titleModalAdditionnalText}`}
        hide={handleModalStatus}
        isShowing={isOpenModalConfirmation}
      >
        <div className="flex mt-1">
          <span className="center">{`Etes-vous sûr de vouloir supprimer ${contentModalAdditionnalText} ?`}</span>
        </div>
        <div className="flex flex-column center modal-close-confirm">
          <div className="modal-footer flex">
            <button className="btn alert" onClick={handleConfirm}>
              Oui
            </button>
            <button className="btn" onClick={handleModalStatus}>
              Non
            </button>
          </div>
        </div>
      </Modal>
      {!loading ? (
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          title="Actions"
        >
          <MoreVert />
        </IconButton>
      ) : (
        <CircularProgress color="success" size="20px" />
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items?.map((item, i) => {
          return (
            <MenuItem
              key={`MenuItem${i + 1}`}
              disabled={item.disabled}
              onClick={() => handleItemClick(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

class AidList extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      onAidEdition: false,
      searching: false,
      duplicatingId: null,
      aids: [],
      totalRows: 0,
      aidCount: {
        all: 0,
        validated: 0,
        toPublished: 0,
        validating: 0,
        draft: 0,
      },
      perPage: Helper.getTablePerPageNumber(),
      changeGeneralState: false,
      formType: "create",
      editingAid: null,
      aidModalDisplay: false,
      aidModalConfirmMessage: "",
      aidModalAfterConfirmAction: "closeModal",
      modalModified: false,
      commentModified: false,
      authEntry: RIGHTS_NOACCESS,
      authValidation: RIGHTS_NOACCESS,
      quickFilter: {
        status: 0,
        active: null,
      },
      filters: {},
      page: 1,
      aidModalTitle: "",
      aidCreationSuccess: false,
      currentSort: { slug: "name", direction: "ASC" },
      aidRowsPerPage: Helper.getTablePerPageNumber(),
      previousPage: 1,
      aidFolders: [],
      folderModalDisplay: false,
      folderModalModified: false,
      modalFolderType: "folder",
      folderId: null,
      aidsInFolders: {},
      subfolderFolderId: null, // Identifiant d'un dossier content le sous-dossier à ajouter
    };

    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSubmitAfterConfirm = this.handleSubmitAfterConfirm.bind(this);
    this.updateModalModified = this.updateModalModified.bind(this);
    this.handleVersionCreate = this.handleVersionCreate.bind(this);
    this.handleVersionSwitched = this.handleVersionSwitched.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleQuickFilter = this.handleQuickFilter.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFolderModalClose = this.handleFolderModalClose.bind(this);
    this.handleFolderSaveRequestSuccess =
      this.handleFolderSaveRequestSuccess.bind(this);
    this.ExpandableRowsComponent = this.ExpandableRowsComponent.bind(this);
    this.ExpandableSubfolderComponent =
      this.ExpandableSubfolderComponent.bind(this);
    this.handleReinitFolderModalValue =
      this.handleReinitFolderModalValue.bind(this);
    this.sortedFoldersAids = this.sortedFoldersAids.bind(this);
    this.updateCommentModified = this.updateCommentModified.bind(this);
    this.handleAidTest = this.handleAidTest.bind(this);

    this.refCreateVersionButton = React.createRef();
    this.refSwitchVersionSelect = React.createRef();
    this.refSaveButton = React.createRef();
    this.refFolderFormSaveButton = React.createRef();
    this.refCloseButton = React.createRef();
  }

  columns = [
    {
      name: "Nom",
      selector: (row) => row.title,
      sortable: true,
      width: "39.5vw",
    },
    {
      name: "Organisme porteur",
      selector: (row) => row.organization,
      sortable: true,
      width: "14vw",
    },
    {
      name: "Simulable",
      selector: (row) => row.simulable,
      center: true,
      width: "6vw",
    },
    {
      name: "Dépôt max",
      selector: (row) => row.deposit_date,
      width: "7vw",
      center: true,
    },
    {
      name: "Statut",
      selector: (row) => row.status,
      width: "6vw",
    },
    {
      name: "Publiée",
      selector: (row) => row.active,
      width: "5vw",
    },
    {
      name: "Version",
      selector: (row) => row.version,
      width: "5vw",
      center: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      center: true,
      sortable: false,
      width: "4vw",
    },
  ];

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    this.setState({ authEntry: Session.getAuth(Constants.PROFILE_AIDENTRY) });
    this.setState({
      authValidation: Session.getAuth(Constants.PROFILE_AIDVALIDATION),
    });
    this.setState({
      aidModalTitle:
        this.state.formType === "create"
          ? "Créer une aide"
          : `Modifier - une aide`,
    });

    if (params.get("id")) {
      this.setState(
        {
          onAidEdition: true,
        },
        async () => {
          const versionsResponse = await Api.aid.getAid(+params.get("id"));
          const aidObject = Helper.isValidResponse(versionsResponse);
          this.displayModal("edit", aidObject);
          this.setState({
            onAidEdition: false,
          });
        }
      );
    }

    getDataLines.bind(this)(1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.changeGeneralState) {
      getDataLines.bind(this)(this.state.page, this.state.filters);
      this.setState({ changeGeneralState: false });
    }
  }

  refresh() {
    this.setState({ changeGeneralState: true }, this.forceUpdate());
  }

  handleSort(column, sortDirection) {
    if (column.sortable) {
      let slug;
      switch (column.name) {
        case "Nom":
          slug = "name";
          break;
        case "Dépôt maximum":
          slug = "depositDate";
          break;
        case "Publiée":
          slug = "active";
          break;
        case "Statut":
          slug = "status";
          break;
        case "Organisme porteur":
          slug = "organization";
          break;
        case "Libellé":
          slug = "label";
          break;
        default:
          slug = "id";
          break;
      }

      this.setState(
        {
          currentSort: {
            slug,
            direction: sortDirection,
          },
          searching: true,
        },
        () => {
          getDataLines.bind(this)(
            this.state.currentPage,
            this.state.filters,
            null
          );
        }
      );
    }
  }

  handlePerRowsChange(newPerPage, page) {
    this.setState({ perPage: newPerPage, searching: true, page: page }, () => {
      Helper.setTablePerPageNumber(newPerPage);
      getDataLines.bind(this)(page, this.state.filters);
    });
  }

  handlePageChange(page) {
    this.setState({ searching: true, page: page }, () => {
      getDataLines.bind(this)(page, this.state.filters);
    });
  }

  handleFilter(filterFunctionFromChild, updateButtonStateFunction) {
    this.setState({ searching: true });
    updateButtonStateFunction(true);

    const filters = filterFunctionFromChild();

    const { active, status } = this.state.quickFilter;

    if (this.state.quickFilter.status) {
      filters["statusAid"] = status;
    }

    if (this.state.quickFilter.active) {
      filters["active"] = active;
    }

    this.setState({
      filters: filters,
    });

    Api.aid
      .getAidCount(Helper.getFiltersUrlParams(filters))
      .then((response) => {
        const resultObject = Helper.isValidResponse(response);
        if (resultObject) {
          this.setState({
            totalRows: resultObject.count,
            aidCount: {
              all: resultObject.all,
              validated: resultObject.validated,
              toPublished: resultObject.toPublished,
              validating: resultObject.validating,
              draft: resultObject.draft,
            },
          });
        }

        if (response?.status !== 200) {
          Helper.displayGenericErrorToast();
        }
      });

    getDataLines.bind(this)(1, filters, updateButtonStateFunction);
  }

  handleQuickFilter(status, active = null) {
    this.setState({
      searching: true,
      quickFilter: {
        status: status,
        active: active,
      },
    });

    const filters = this.state.filters;

    if (status) {
      filters["statusAid"] = status;
    } else {
      delete filters["statusAid"];
    }

    if (active) {
      filters["active"] = active;
    } else {
      delete filters["active"];
    }

    getDataLines.bind(this)(1, filters);
  }

  displayModal(type = "create", aid = null) {
    this.setState(
      {
        formType: type,
        editingAid: aid,
        aidModalDisplay: true,
        aidModalTitle:
          type === "create" ? "Créer une aide" : `Modifier - ${aid?.name}`,
      },
      () => this.setState({ duplicatingId: null })
    );
  }

  displayFolderModal() {
    this.setState({ folderModalDisplay: !this.state.folderModalDisplay });
  }

  handleModalClose() {
    this.setState({ modalModified: false });
    if (this.state.aidModalAfterConfirmAction === "closeModal") {
      this.setState({ aidModalDisplay: false });
    } else if (this.state.aidModalAfterConfirmAction === "createVersion") {
      setTimeout(() => {
        this.refCreateVersionButton.current.click();
      }, 150);
    } else if (this.state.aidModalAfterConfirmAction === "switchVersion") {
      setTimeout(() => {
        this.refSwitchVersionSelect.current.click();
      }, 150);
    }
    this.setState({ aidModalAfterConfirmAction: "closeModal" });
  }

  handleReinitFolderModalValue() {
    this.setState({
      folderModalModified: false,
      folderId: null,
      subfolderFolderId: null,
      modalFolderType: "folder",
    });

    this.displayFolderModal();
  }

  handleFolderModalClose() {
    this.handleReinitFolderModalValue();
  }

  handleFolderSaveRequestSuccess() {
    this.handleReinitFolderModalValue();

    getDataLines.bind(this)(this.state.page, this.state.filters);
  }

  handleVersionCreate(formType, callback) {
    if (this.state.modalModified) {
      this.setState({ aidModalAfterConfirmAction: "createVersion" });
      this.refCloseButton.current.click();
      return;
    }

    this.setState({ formType: formType });
    callback();
  }

  handleVersionSwitched(callback) {
    if (this.state.modalModified) {
      this.setState({ aidModalAfterConfirmAction: "switchVersion" });
      this.refCloseButton.current.click();
      return;
    }

    callback();
  }

  handleFormSubmit(success, message) {
    if (success) {
      getDataLines.bind(this)(this.state.page, this.state.filters);
      toast.success(message, Helper.getToastOptions());
      this.handleModalClose();
      this.setState({
        aidCreationSuccess: true,
        commentModified: false,
      });
      return;
    }
    toast.error(message, Helper.getToastOptions());
    this.setState({ aidModalAfterConfirmAction: "closeModal" });
  }

  handleSubmitAfterConfirm() {
    this.refSaveButton.current.click();
  }

  handleSubmitFolderFormAfterConfirm() {
    this.refFolderFormSaveButton.current.click();
  }

  updateModalModified(state) {
    if (
      this.state.authEntry === RIGHTS_WRITE ||
      this.state.authValidation === RIGHTS_WRITE
    ) {
      this.setState({ modalModified: state });
    }
  }

  updateCommentModified(state) {
    this.setState({ commentModified: state });
  }

  updateFolderModalModified(state) {
    if (
      this.state.authEntry === RIGHTS_WRITE ||
      this.state.authValidation === RIGHTS_WRITE
    ) {
      this.setState({ folderModalModified: state });
    }
  }

  handleNameChange(name) {
    this.setState({
      aidModalTitle:
        (this.state.formType === "create" ? `Créer` : `Modifier`) +
        " - " +
        name,
    });
  }

  sortedFoldersAids(folders, aids, slug, direction, isPrincipalRow) {
    let rows = [...folders, ...aids];

    // Si c'est un tri par organisation
    if (isPrincipalRow && slug === "organization") {
      rows = [...aids];
    }

    rows.sort((a, b) => {
      let value1 = a.row?.[slug];
      let value2 = b.row?.[slug];

      if (slug === "organization") {
        value1 = value1?.name;
        value2 = value2?.name;
      }

      if (direction.toUpperCase() === ASCENDING_SORT_DIRECTION) {
        return value1.localeCompare(value2, "fr", { sensitivity: "base" });
      } else {
        return value2.localeCompare(value1, "fr", { sensitivity: "base" });
      }
    });

    if (isPrincipalRow && slug === "organization") {
      rows = [
        ...rows,
        ...this.sortedFoldersAids(
          folders,
          [],
          "name",
          ASCENDING_SORT_DIRECTION
        ),
      ];
    }

    return rows;
  }

  ExpandableSubfolderComponent({ data }) {
    const subfolder = data.row;
    const aids = subfolder?.id ? this.state.aidsInFolders[subfolder.id] : [];

    return (
      <DataTable
        className="aids-in-subfolder-table"
        highlightOnHover
        columns={this.columns}
        data={aids}
        expandableRows
        conditionalRowStyles={[
          {
            when: () => true,
            classNames: ["aids-in-subfolder"],
          },
        ]}
        noDataComponent={<></>}
      />
    );
  }

  ExpandableRowsComponent({ data }) {
    const folder = data.row;
    const aidsInFolder = folder?.aids ? Object.values(folder.aids) : [];
    const subfoldersInFolder = folder?.subfolders
      ? Object.values(folder.subfolders)
      : [];
    let aidsCount = aidsInFolder.length;

    subfoldersInFolder.map((subfolderInFolder) => {
      let currentAids = subfolderInFolder.aids
        ? Object.values(subfolderInFolder.aids)
        : [];

      aidsCount += currentAids.length;

      return null;
    });

    const subfolderRows = FormatAidFolderRow.bind(this)(
      2, // Sous-dossier
      subfoldersInFolder,
      (element) => {
        this.setState({
          folderModalDisplay: true,
          folderId: element.id,
          modalFolderType: "subfolder",
        });
      }
    );

    const aidRows = folder?.id ? this.state.aidsInFolders[folder.id] : [];
    const direction =
      this.state.currentSort.slug === "organization"
        ? ASCENDING_SORT_DIRECTION
        : this.state.currentSort.direction;

    return subfoldersInFolder.length > 0 || aidsCount > 0 ? (
      <DataTable
        highlightOnHover
        columns={this.columns}
        data={this.sortedFoldersAids(subfolderRows, aidRows, "name", direction)}
        expandableRows
        expandableRowsComponent={this.ExpandableSubfolderComponent}
        conditionalRowStyles={[
          {
            when: (row) => !row.isAid,
            classNames: ["subfolder-in-folder"],
          },
          {
            when: (row) => row.isAid,
            classNames: ["aids-in-folder"],
          },
        ]}
        noDataComponent={<></>}
      />
    ) : (
      <></>
    );
  }

  handleAidTest() {
    const user = Session.getSessionUser();

    if (user) {
      const user = Session.getSessionUser();

      this.setState({ searching: true });
      Api.simulator
        .getPublishedSimulator()
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            Api.simulator
              .updateSimulatorUuid(result.id)
              .then((resp) => {
                const res = Helper.isValidResponse(resp);

                if (res?.uuid) {
                  const url = `${process.env.REACT_APP_PROJECT_FRONT_URL}/simulator-sandbox-mode?mode=${Constants.SIMULATOR_TEST_MODE}&uuid=${res.uuid}&userId=${user.id}&organizationUuid=${user.organization.uuid}`;

                  window.open(url, "_blank");
                }

                this.setState({ searching: false });
              })
              .catch((err) => {
                console.error(err);
                this.setState({ searching: false });
              });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ searching: false });
        });
    }
  }

  render() {
    return (
      <article className="aids">
        <section className="bo-data-title">
          <h3>Aides</h3>

          {(this.state.authEntry >= RIGHTS_WRITE ||
            this.state.authValidation >= RIGHTS_WRITE) && (
            <span className="creation-button-content">
              {/** Ajout d'aide */}
              <button
                className={`btn btn-tiny default addButton ${
                  this.state.loading || this.state.searching ? "disabled" : ""
                }`}
                title="Cliquer pour ajouter une aide"
                onClick={() => this.displayModal()}
              >
                <Add />
                <span className="aids-button-action">Ajouter une aide</span>
              </button>

              {/** Ajout de dossier */}
              <button
                className={`btn btn-tiny default addButton ${
                  this.state.loading || this.state.searching ? "disabled" : ""
                }`}
                title="Cliquer pour ajouter un dossier"
                onClick={() => this.displayFolderModal()}
              >
                <NewFolder />
                <span className="aids-button-action">Ajouter un dossier</span>
              </button>

              {/** Test des aides */}
              <button
                className={`btn btn-tiny default addButton ${
                  this.state.loading || this.state.searching ? "disabled" : ""
                }`}
                title="Cliquer pour tester les versions d'aides non publiées dans le simulateur"
                onClick={() => this.handleAidTest()}
              >
                <PlayCircle />
                <span className="aids-button-action test-action">
                  Tester les aides
                </span>
              </button>
            </span>
          )}
          <Modal
            title={this.state.aidModalTitle}
            hide={this.handleModalClose}
            isShowing={this.state.aidModalDisplay}
            confirm={this.state.modalModified || this.state.commentModified}
            confirmMessage={this.state.aidModalConfirmMessage}
            saveBeforeClose={this.handleSubmitAfterConfirm}
            closeButtonRef={this.refCloseButton}
          >
            <div className="aid-form">
              <AidForm
                type={this.state.formType}
                aid={this.state.editingAid}
                onSubmit={this.handleFormSubmit}
                onVersionCreate={this.handleVersionCreate}
                onVersionSwitched={this.handleVersionSwitched}
                onNameChange={this.handleNameChange}
                createVersionForwardRef={this.refCreateVersionButton}
                switchVersionForwardRef={this.refSwitchVersionSelect}
                forwardRef={this.refSaveButton}
                modalModify={this.updateModalModified}
                commentModify={this.updateCommentModified}
                readOnly={this.state.authEntry < RIGHTS_WRITE}
                canValidate={
                  this.state.authValidation === RIGHTS_WRITE &&
                  !(
                    Session.getSessionUser()?.organization?.organism &&
                    this.state.editingAid?.status === AID_STATUS_VALIDATING
                  )
                }
                formType={this.state.formType}
              />
            </div>
          </Modal>

          <Modal
            title={`${this.state.folderId ? "Modifier" : "Créer"} un ${
              this.state.modalFolderType === "folder"
                ? "dossier"
                : "sous-dossier"
            }`}
            hide={this.handleFolderModalClose}
            isShowing={this.state.folderModalDisplay}
            confirm={this.state.folderModalModified}
            saveBeforeClose={this.handleSubmitFolderFormAfterConfirm}
          >
            <div className="aid-folder-form">
              <AidFolder
                type={this.state.modalFolderType}
                subfolderFolderId={this.state.subfolderFolderId}
                readOnly={this.state.authEntry < RIGHTS_WRITE}
                modalModify={(state) => this.updateFolderModalModified(state)}
                forwardRef={this.refFolderFormSaveButton}
                folderId={this.state.folderId}
                onRequestSubmitSuccess={this.handleFolderSaveRequestSuccess}
              />
            </div>
          </Modal>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className="quickfilter-wrap"
          >
            <Chip
              label={`Toutes les aides (${this.state.aidCount.all})`}
              variant="filled"
              onClick={() => this.handleQuickFilter(0)}
              color={
                this.state.quickFilter.status === 0 ? "success" : "default"
              }
              disabled={this.state.loading || this.state.searching}
            />
            <Chip
              label={`Aides publiées (${this.state.aidCount.validated})`}
              variant="filled"
              onClick={() =>
                this.handleQuickFilter(Constants.AID_STATUS_VALIDATED, "true")
              }
              color={
                this.state.quickFilter.status === AID_STATUS_VALIDATED &&
                this.state.quickFilter.active === "true"
                  ? "success"
                  : "default"
              }
              disabled={this.state.loading || this.state.searching}
            />
            <Chip
              label={`Aides à publier (${this.state.aidCount.toPublished})`}
              variant="filled"
              onClick={() =>
                this.handleQuickFilter(Constants.AID_STATUS_VALIDATED, "false")
              }
              color={
                this.state.quickFilter.status === AID_STATUS_VALIDATED &&
                this.state.quickFilter.active === "false"
                  ? "success"
                  : "default"
              }
              disabled={this.state.loading || this.state.searching}
            />
            <Chip
              label={`Aides à valider (${this.state.aidCount.validating})`}
              variant="filled"
              onClick={() =>
                this.handleQuickFilter(Constants.AID_STATUS_VALIDATING)
              }
              color={
                this.state.quickFilter.status === AID_STATUS_VALIDATING
                  ? "success"
                  : "default"
              }
              disabled={this.state.loading || this.state.searching}
            />
            <Chip
              label={`Aides en cours de rédaction (${this.state.aidCount.draft})`}
              variant="filled"
              onClick={() => this.handleQuickFilter(Constants.AID_STATUS_DRAFT)}
              color={
                this.state.quickFilter.status === AID_STATUS_DRAFT
                  ? "success"
                  : "default"
              }
              disabled={this.state.loading || this.state.searching}
            />
          </Stack>
        </section>

        <section className="bo-data-table card">
          {this.state.loading ? (
            <TableWithFiltersSkeleton linesCount={13} filtersCount={5} />
          ) : (
            <>
              <AidFilters
                onFilter={this.handleFilter}
                aidCreationSuccess={this.state.aidCreationSuccess}
                onFinishUpdate={() => {
                  this.setState({
                    aidCreationSuccess: false,
                  });
                }}
              />
              <div className="bo-data-table-content">
                {(this.state.searching || this.state.onAidEdition) && (
                  <Loading />
                )}
                <DataTable
                  columns={this.columns}
                  data={this.sortedFoldersAids(
                    this.state.aidFolders,
                    this.state.aids,
                    this.state.currentSort.slug,
                    this.state.currentSort.direction,
                    true
                  )}
                  fixedHeader
                  fixedHeaderScrollHeight="75vh"
                  dense
                  persistTableHead
                  highlightOnHover
                  pagination
                  paginationTotalRows={
                    this.state.aids.length + this.state.aidFolders.length
                  }
                  paginationPerPage={this.state.perPage}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  responsive
                  onSort={this.handleSort.bind(this)}
                  defaultSortAsc={false}
                  className="aidsTable"
                  subHeaderAlign="center"
                  subHeaderWrap
                  expandableRows
                  expandableRowDisabled={rowPreDisabled}
                  expandableRowsComponent={this.ExpandableRowsComponent}
                  conditionalRowStyles={[
                    {
                      when: (row) => row.isAid,
                      classNames: ["aid-out-folder"],
                    },
                    {
                      when: (row) => !row.isAid,
                      classNames: ["folder"],
                    },
                  ]}
                />
              </div>
            </>
          )}
        </section>
      </article>
    );
  }
}

class AidFilters extends React.Component {
  static defaultProps = {
    onFilter: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      aidFilterText: "",
      selectedOrganization: null,
      selectedOrganizationAidCreator: null,
      selectedStatusOptions: null,
      selectedActiveOptions: null,
      hasFilters: false,
      loading: false,
      organizationList: [],
      organizationListFiltered: [],
      organizationAidCreatorsList: [],
    };
    this.statusOptions = [
      {
        label: "statut",
        icon: "fa-list-check",
        options: [
          { value: AID_STATUS_DRAFT, label: "Brouillon" },
          { value: AID_STATUS_VALIDATING, label: "À valider" },
          { value: AID_STATUS_REFUSED, label: "Refusée" },
          { value: AID_STATUS_VALIDATED, label: "Validée" },
        ],
      },
    ];
    this.activeOptions = [
      {
        label: "état",
        icon: "fa-table-list",
        options: [
          { value: true, label: "Publiée" },
          { value: false, label: "Non publiée" },
        ],
      },
    ];

    this.typeOrganization = [
      Constants.ORGANISM_TYPE_FSY,
      Constants.ORGANISM_TYPE_ORGANISM,
      Constants.ORGANISM_TYPE_PARTNER,
      Constants.ORGANISM_TYPE_OTHER,
    ];

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
    this.handleTextInput = this.handleTextInput.bind(this);
    this.updateButtonState = this.updateButtonState.bind(this);
    this.handleNatureChange = this.handleNatureChange.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.getOrganizationsCreator = this.getOrganizationsCreator.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.aidCreationSuccess !== this.props.aidCreationSuccess &&
      this.props.aidCreationSuccess
    ) {
      this.props.onFinishUpdate();
      this.getOrganizationsCreator();
    }
  }

  getFilters() {
    let filters = {};
    if (this.state.aidFilterText !== "") {
      filters.aidFilterText = this.state.aidFilterText;
    }

    if (
      this.state.selectedStatusOptions !== null &&
      Object.keys(this.state.selectedStatusOptions).length !== 0
    ) {
      filters.statusAid = this.state.selectedStatusOptions.value;
      filters.lastStatusAid = this.state.selectedStatusOptions.value;
    }

    if (
      this.state.selectedActiveOptions !== null &&
      Object.keys(this.state.selectedActiveOptions).length !== 0
    ) {
      filters.active = this.state.selectedActiveOptions.value;
      filters.lastActive = this.state.selectedActiveOptions.value;
    }

    if (
      this.state.selectedOrganization !== null &&
      Object.keys(this.state.selectedOrganization).length !== 0
    ) {
      filters.organizations = [this.state.selectedOrganization.value];
    }

    if (
      this.state.selectedOrganizationAidCreator !== null &&
      Object.keys(this.state.selectedOrganizationAidCreator).length !== 0
    ) {
      filters.organizationAidCreator = [
        this.state.selectedOrganizationAidCreator.value,
      ];
    }

    this.setState(() => {
      return { hasFilters: Object.keys(filters).length !== 0 };
    });

    return filters;
  }

  handleStatusChange(selectedOptions) {
    this.setState({
      selectedStatusOptions: selectedOptions,
    });
  }

  handleActiveChange(selectedOptions) {
    this.setState({
      selectedActiveOptions: selectedOptions,
    });
  }

  handleOrganizationChange(selectedOptions) {
    this.setState({
      selectedOrganization: selectedOptions,
    });
  }

  handleNatureChange(selectedOptions) {
    this.setState({
      selectedOrganizationAidCreator: selectedOptions,
    });
  }

  handleTextInput(e) {
    if (e.key === "Enter") {
      this.handleFilterSubmit();
    }
  }

  handleFilterSubmit() {
    this.props.onFilter(this.getFilters, this.updateButtonState);
  }

  handleClearFilter() {
    this.setState(
      {
        aidFilterText: "",
        selectedOrganization: [],
        selectedOrganizationAidCreator: [],
        selectedStatusOptions: [],
        selectedActiveOptions: [],
        hasFilters: false,
      },
      () => {
        this.handleFilterSubmit();
      }
    );
  }

  updateButtonState(isLoading = false) {
    this.setState({ loading: isLoading });
  }

  componentDidMount() {
    Api.organization.getOrganizations().then((response) => {
      const resultObject = Helper.isValidResponse(response);

      if (resultObject) {
        this.setState({ organizationList: resultObject }, () => {
          this.updateOrganizationList();
        });
      }

      if (response?.status !== 200) {
        Helper.displayGenericErrorToast();
      }
    });

    this.getOrganizationsCreator();
  }

  getOrganizationsCreator() {
    Api.aid.getOrganizationAidCreators().then((response) => {
      const resultObject = Helper.isValidResponse(response);

      if (resultObject) {
        const organizationAidCreatorsList = resultObject.map((element) => {
          return {
            label: element.name,
            value: element.id,
          };
        });

        this.setState({
          organizationAidCreatorsList: [
            {
              label: "Créée par",
              icon: "fa-table-list",
              options: organizationAidCreatorsList,
            },
          ],
        });
      }

      if (response?.status !== 200) {
        Helper.displayGenericErrorToast();
      }
    });
  }

  updateOrganizationList = () => {
    let filtered = [];
    this.typeOrganization.forEach((type) => {
      filtered = [
        ...filtered,
        {
          label: type,
          options: this.state.organizationList
            .filter((element) => {
              switch (type) {
                case Constants.ORGANISM_TYPE_ORGANISM:
                  if (element.organism) {
                    return true;
                  }
                  break;
                case Constants.ORGANISM_TYPE_PARTNER:
                  if (element.partner) {
                    return true;
                  }
                  break;
                case Constants.ORGANISM_TYPE_OTHER:
                  if (element.other) {
                    return true;
                  }
                  break;
                case Constants.ORGANISM_TYPE_FSY:
                  if (!element.organism && !element.partner && !element.other) {
                    return true;
                  }
                  break;
                default:
                  break;
              }
              return false;
            })
            .map((element) => {
              return { label: element.name, value: element.id };
            }),
        },
      ];
    });
    this.setState({
      organizationListFiltered: filtered,
    });
  };

  render() {
    return (
      <div className="bo-data-filters">
        <InputField
          className="aids-text"
          name="aidFilterText"
          onKeyDown={this.handleTextInput}
          value={this.state.aidFilterText}
          title="Texte à rechercher"
          context={this}
        >
          Texte à rechercher
        </InputField>
        <SelectField
          options={this.state.organizationListFiltered}
          value={this.state.selectedOrganization}
          isMulti={false}
          closeMenuOnSelect={true}
          placeholder="Organisme porteur"
          onChange={this.handleOrganizationChange}
          context={this}
        />
        <SelectField
          options={this.state.organizationAidCreatorsList}
          value={this.state.selectedOrganizationAidCreator}
          isMulti={false}
          closeMenuOnSelect={true}
          placeholder="Créée par"
          onChange={this.handleNatureChange}
          context={this}
        />
        <SelectField
          options={this.activeOptions}
          value={this.state.selectedActiveOptions}
          isMulti={false}
          closeMenuOnSelect={true}
          placeholder="Etat"
          onChange={this.handleActiveChange}
          context={this}
        />
        <SelectField
          options={this.statusOptions}
          value={this.state.selectedStatusOptions}
          isMulti={false}
          closeMenuOnSelect={true}
          placeholder="Statut"
          onChange={this.handleStatusChange}
          context={this}
        />
        <div>
          <button
            type="button"
            className="btn default filterButton"
            title="Filtrer les résultats"
            disabled={this.state.loading}
            onClick={this.handleFilterSubmit}
          >
            <FilterAlt />
          </button>
          {this.state.hasFilters && (
            <button
              type="button"
              className="btn warning resetButton"
              title="Effacer les filtres"
              onClick={this.handleClearFilter}
            >
              <FilterAltOff />
            </button>
          )}
        </div>
      </div>
    );
  }
}

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * @param page {int}
 * @param urlParams {object|null}
 * @return {Promise<any>}
 */
async function getFolders(page = 1, urlParams = {}) {
  urlParams = {
    ...urlParams,
    pagination: false,
  };

  const folders = await Api.folder.getFolders(page, urlParams);
  const results = Helper.isValidResponse(folders);

  if (results) {
    return Object.values(results);
  }

  return null;
}

/**
 * Call questions Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getDataLines(page = 1, filters = null, callback = null) {
  const filtersUrlParams =
    filters === null ? {} : Helper.getFiltersUrlParams(filters);

  const folderParams = {
    folderType: 1,
    aidFilters: {
      [`order[${this.state.currentSort.slug}]`]:
        this.state.currentSort.direction,
    },
  };

  if (this.state.currentSort.slug === "name") {
    folderParams[`order[name]`] = this.state.currentSort.direction;
  }

  if (filters && Object.keys(filters).length > 0) {
    folderParams.aidFilters = {
      ...folderParams.aidFilters,
      ...Helper.getFiltersUrlParams(filters),
    };
  }

  folderParams.aidFilters = JSON.stringify(folderParams.aidFilters);

  let folders = await getFolders(1, { ...folderParams });

  // Ici, il faut formatter les aides qui seront contenues dans des dossiers et sous-dossiers
  // En même temps, en cas de filtre, il faut supprimer les dossiers et sous-dossiers qui n'ont pas d'aide
  if (folders?.length > 0) {
    const aidsInFolderWithFormat = {};

    folders.map((folder) => {
      let aids = folder.aids ? Object.values(folder.aids) : [];
      let subfolders = folder.subfolders
        ? Object.values(folder.subfolders)
        : [];
      let aidsWithFormat = [];

      // Les aides de dossiers
      aids.map(async (aid) => {
        aidsWithFormat.push(await FormatRow.bind(this)(aid));

        return null;
      });

      aidsInFolderWithFormat[`${folder.id}`] = aidsWithFormat;

      // Les aides de sous-dossiers
      subfolders.map((subfolder) => {
        aidsWithFormat = [];

        let subfolderAids = subfolder.aids ? Object.values(subfolder.aids) : [];

        subfolderAids.map(async (aid) => {
          aidsWithFormat.push(await FormatRow.bind(this)(aid));

          return null;
        });

        aidsInFolderWithFormat[`${subfolder.id}`] = aidsWithFormat;

        return null;
      });

      return null;
    });

    // En cas de filtre, on supprime les dossiers et sous-dossiers qui n'ont pas d'aide
    if (filters && Object.keys(filters).length > 0) {
      folders = folders.filter((folder) => {
        let subfolders = folder.subfolders
          ? Object.values(folder.subfolders)
          : [];
        let aidsFolders = Object.values(folder.aids);

        folder.subfolders = subfolders.filter(
          (subfolder) => Object.values(subfolder.aids).length > 0
        );

        return aidsFolders.length > 0 || folder.subfolders.length > 0;
      });
    }

    this.setState({
      aidFolders: FormatAidFolderRow.bind(this)(
        1, // Dossier
        folders,
        (element, actionType) => {
          this.setState({
            folderModalDisplay: true,
            modalFolderType: actionType === "update" ? "folder" : "subfolder",
            [actionType === "update" ? "folderId" : "subfolderFolderId"]:
              element.id,
          });
        }
      ),
      aidsInFolders: aidsInFolderWithFormat,
    });
  } else {
    this.setState({
      aidFolders: [],
      aidsInFolders: [],
    });
  }

  Api.aid.getAidCount(filtersUrlParams).then((response) => {
    const resultObject = Helper.isValidResponse(response);
    if (resultObject) {
      this.setState({
        totalRows: resultObject.count,
        aidCount: {
          all: resultObject.all,
          validated: resultObject.validated,
          toPublished: resultObject.toPublished,
          validating: resultObject.validating,
          draft: resultObject.draft,
        },
      });
    }

    if (response?.status !== 200) {
      Helper.displayGenericErrorToast();
    }
  });

  Helper.getDataTableLines
    .bind(this)(
      Api.aid.getAids,
      {
        [`order[${this.state.currentSort.slug}]`]:
          this.state.currentSort.direction,
        pagination: false,
        isOutOfFolder: true,
      },
      "aids",
      FormatRow,
      page,
      filters,
      callback
    )
    .then(() => {
      this.setState({ searching: false });
    });
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param response
 */
async function FormatRow(response) {
  const aid = response.aid;
  const activeVersion = response.activeVersion;
  const active = (
    <ActiveSwitch
      currentObject={aid}
      objectId={aid.id}
      apiFunction={Api.aid.updateAid}
      idPrefix="aid"
      listeObj={this}
      className="aids-active-switch"
      disabled={this.state.authValidation < RIGHTS_WRITE}
      onRequestSetLoading={(status) => this.setState({ searching: status })}
    />
  );

  const user = Session.getSessionUser();
  const userOrganization = user.organization;

  let canEdit = Session.getAuth(Constants.PROFILE_AIDENTRY) >= RIGHTS_READ;

  const name = canEdit
    ? (userOrganization.partner &&
        aid.organizationCreator.id !== userOrganization.id) ||
      (userOrganization.organism && aid.organization.id !== userOrganization.id)
      ? Helper.FormatClickableMutedText(aid.name, () =>
          this.displayModal("edit", aid)
        )
      : Helper.FormatClickableText(aid.name, () =>
          this.displayModal("edit", aid)
        )
    : aid.name;

  let disableDuplication = this.state.authValidation < RIGHTS_WRITE;
  let disableDelete = !(aid.version === 1 && !aid.active);

  if (userOrganization.organism) {
    disableDuplication = userOrganization.id !== aid.organization.id;
    disableDelete =
      userOrganization.id !== aid.organization.id || disableDelete;
  }

  if (userOrganization.partner) {
    disableDuplication = userOrganization.id !== aid.organizationCreator.id;
    disableDelete =
      userOrganization.id !== aid.organizationCreator.id || disableDelete;
  }

  const actionsItems = [
    {
      type: "duplicate",
      label: "Cloner",
      disabled: disableDuplication,
      icon: <ContentCopy />,
    },
    {
      type: "delete",
      label: "Supprimer",
      icon: <Delete />,
      disabled: disableDelete,
      withConfirmation: true,
    },
  ];

  return {
    title: name,
    organization: aid.organization?.name || "-",
    simulable: aid.simulable ? "Oui" : "Non",
    deposit_date: aid.depositDate
      ? moment(aid.depositDate).format("DD/MM/YYYY")
      : "",
    status: (
      <div className="badge-display">
        <span className={`badge status-row status-${aid.status}`}>
          {_.find(AID_STATUS_LIST, { id: aid.status }).label}
        </span>
      </div>
    ),
    active: active,
    version: activeVersion ? `V.${activeVersion}` : "",
    // label: <span title={aid.label}>{aid.label}</span>,
    // nature: aid.nature.name,
    // open_date: aid.openDate ? moment(aid.openDate).format("DD/MM/YYYY") : "",
    // updated_at: moment(aid.updatedAt).format("DD/MM/YYYY HH:mm"),
    row: aid,
    creator: aid.creator,
    organizationCreator: aid.organizationCreator,
    actions: (
      <ActionButton currentObject={aid} listeObj={this} items={actionsItems} />
    ),
    expandableDisabled: true,
    isAid: true,
  };
}

function aidFolderRowActionItems(folderType, isDeleteButtonDisabled) {
  const actions = [
    {
      type: "update",
      label: "Renommer",
      disabled: false,
      icon: <EditIcon />,
    },
    {
      type: "delete",
      label: "Supprimer",
      icon: <Delete />,
      disabled: isDeleteButtonDisabled,
      withConfirmation: true,
    },
  ];

  // Uniquement pour les dossiers
  if (folderType === 1) {
    actions.unshift({
      type: "create",
      label: "Ajouter un sous-dossier",
      disabled: false,
      icon: <Add />,
    });
  }

  return actions;
}
/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param response
 */
function FormatAidFolderRow(
  type,
  elements,
  onCreateSubfolderOrUpdateActionClicked
) {
  return (
    elements?.map((element) => {
      const elementAids = element.aids ? Object.values(element.aids) : [];
      const elementSubfolders = element.subfolders
        ? Object.values(element.subfolders)
        : [];
      let elementSubfoldersCount = 0;

      elementSubfolders.map((elementSubfolder) => {
        let currentAids = elementSubfolder.aids
          ? Object.values(elementSubfolder.aids)
          : [];

        elementSubfoldersCount += currentAids.length;
        return null;
      });

      const aidsCount = elementAids.length + elementSubfoldersCount;
      let actionItems = aidFolderRowActionItems(
        type,
        aidsCount > 0 || elementSubfolders.length > 0
      );

      return {
        row: { ...element, type },
        title: (
          <div className="aids-folder-title">
            <span title={element.name}>{element.name}</span>
            <div className="aids-count">{aidsCount}</div>
          </div>
        ),
        actions: (
          <ActionButton
            currentObject={element}
            listeObj={this}
            items={actionItems}
            type={type}
            onCreateOrUpdateAction={(actionType) =>
              onCreateSubfolderOrUpdateActionClicked({ ...element }, actionType)
            }
          />
        ),
      };
    }) || []
  );
}

/**
 * Return a switch component that update the question active state (question object passed in props)
 *
 * @param props {object}
 * @return {JSX.Element}
 */
function ActiveSwitch(props) {
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(props.currentObject.active);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setActive(props.currentObject.active);
  }, [setActive, props.currentObject.active]);

  useEffect(() => {
    props.onRequestSetLoading(loading);
    // eslint-disable-next-line
  }, [loading]);

  const triggerHideModal = () => {
    setShowModal(false);
  };

  const handleChange = (activeState) => {
    if (activeState) {
      if (props.currentObject.status !== AID_STATUS_VALIDATED) {
        toast.error(
          "Vous ne pouvez pas publier une aide sans l'avoir validée !",
          Helper.getToastOptions()
        );
      } else {
        toggleActive(true);
      }
    } else {
      setShowModal(true);
    }
  };

  const handleConfirmDeactivate = () => {
    toggleActive(false);
  };

  const toggleActive = (activeState) => {
    setLoading(true);
    Api.aid
      .updateAid(props.currentObject.id, { active: activeState })
      .then((response) => {
        const resultObject = Helper.isValidResponse(response);
        if (resultObject) {
          if (resultObject.active) {
            Api.aid
              .notifyInAidStatus(resultObject.id)
              .then((resp) => {
                const publishedAidResultNotif = Helper.isValidResponse(resp);

                if (publishedAidResultNotif) {
                  setActive(resultObject.active);
                  setLoading(false);
                  toast.success("Enregistré!", Helper.getToastOptions());
                  props.listeObj.refresh();
                }
              })
              .catch((err) => {
                console.error(err);
                setLoading(false);
              });
          } else {
            setActive(resultObject.active);
            setLoading(false);
            toast.success("Enregistré!", Helper.getToastOptions());
            props.listeObj.refresh();
          }
        } else {
          setLoading(false);
          toast.error("Une erreur est survenue", Helper.getToastOptions());
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .finally(setShowModal(false));
  };

  return (
    <>
      <ReactSwitch
        checked={active ?? false}
        className={props.className}
        id={`aid-${props.currentObject.id}`}
        onColor="#a6d290"
        offColor="#fc9999"
        onChange={handleChange}
        disabled={props.disabled}
      />

      <Modal
        title="Désactivation"
        hide={triggerHideModal}
        isShowing={showModal}
      >
        <div className="modal-body center mt-1">
          Etes-vous sûr de vouloir désactiver l'aide&nbsp;
          <span className="bold">{props.currentObject.name} </span>&nbsp;?
        </div>
        <div className="modal-footer flex-end">
          <button className="btn" onClick={triggerHideModal}>
            Non
          </button>
          <button className="btn alert" onClick={handleConfirmDeactivate}>
            Oui
          </button>
        </div>
      </Modal>
    </>
  );
}

function rowPreDisabled(row) {
  return row.expandableDisabled;
}
