import React from "react";
import { SelectField } from "../../general/form/Select";
import { Requiredstar } from "../../general/form/Input";
import _ from "lodash";

export default class CriterionTXTRow extends React.Component {
  static defaultProps = {
    onChange: () => {},
    requiredFields: false,
    criterion: null,
    readOnly: false,
    aidCriterions: null,
    speciesGroup: [],
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.initState = this.initState.bind(this);
    this.state = {
      criterion: props.criterion,
      aidCriterions: props.aidCriterions,
    };
  }

  componentDidMount() {
    this.initState(this.props);
  }

  initState(props) {
    let selectedOptions = [],
      criterionOptions = [];

    // For specific criteria 'criPlan_04', 'criPlan_05'
    if (["criPlan_04", "criPlan_05"].includes(props.criterion.shortName)) {
      // create an optionGroup for each species group
      _.each(props.speciesGroup, (group) => {
        criterionOptions.push({
          label: group.label,
          options: _.map(
            group.species.filter((s) => s.active),
            (s) => {
              return { value: s.id, label: s.label };
            }
          ),
        });
      });
    } else {
      // for generic criterion : create a single group with all the options
      criterionOptions.push({
        label: props.criterion.name,
        options: props.criterion.criterionValues
          .filter((val) => val.active)
          .map((val) => {
            return { value: val.id, label: val.value };
          }),
      });
    }
    // Set selected values by filtering options array
    if (props.aidCriterions[props.criterion.id]) {
      _.each(criterionOptions, (group) => {
        selectedOptions = _.concat(
          selectedOptions,
          group.options.filter((opt) =>
            props.aidCriterions[props.criterion.id].value.answers.includes(
              opt.value
            )
          )
        );
      });
    }

    this.setState({
      criterion: props.criterion,
      aidCriterions: props.aidCriterions,
      criterionOptions: criterionOptions,
      value: selectedOptions,
      visible: false,
      showError: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.aid && this.props.aid.id !== prevProps.aid.id) {
      this.initState(this.props);
    }

    if (
      this.props.checkField !== prevProps.checkField &&
      this.props.checkField
    ) {
      const criterion = this.state.criterion;
      this.setState({
        showError: criterion.mandatory && !this.state.value.length,
      });
    }
  }

  handleChange = (criterionValues) => {
    const criterion = this.state.criterion;
    const aidCriterions = this.state.aidCriterions;

    if (typeof aidCriterions[criterion.id] === "undefined") {
      aidCriterions[criterion.id] = {};
    }

    if (criterionValues?.length) {
      let criterionValueIds = criterionValues.map(
        (criterionValue) => criterionValue.value
      );

      aidCriterions[criterion.id] = {
        ...aidCriterions[criterion.id],
        criterion: criterion,
        value: { answers: criterionValueIds },
        type: criterion.type.shortName,
        remove: false,
      };
    } else {
      aidCriterions[criterion.id].remove = true;
    }

    this.setState(
      {
        value: criterionValues,
        aidCriterions: aidCriterions,
      },
      () => {
        this.props.onChange(this.state.criterion, this.state.aidCriterions);
      }
    );
  };

  render() {
    const criterion = this.props.criterion;

    return (
      <div className="aidCriteria-list-item">
        <div className="text-truncate item-label" title={criterion.name}>
          {criterion.name}
          {
            /*this.props.aid.status.value !== Constants.AID_STATUS_DRAFT && criterion.mandatory*/
            this.props.requiredTextFields && (
              <Requiredstar className="required-star" />
            )
          }
        </div>
        <div className={this.state.showError ? "is-invalid" : ""}>
          <SelectField
            options={this.state.criterionOptions}
            value={this.state.value}
            isMulti={true}
            closeMenuOnSelect={false}
            required={false}
            placeholder=""
            isDisabled={this.props.readOnly}
            onChange={this.handleChange}
            context={this}
          />
          {this.state.showError && this.props.requiredTextFields && (
            <div className="error">Champ obligatoire</div>
          )}
        </div>
      </div>
    );
  }
}
