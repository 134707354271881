import React, { useEffect, useState } from "react";
import { InputField } from "../general/form/Input";
import {
  Delete,
  Visibility,
  VisibilityOff,
  DragIndicator,
  Info,
  Add,
} from "@mui/icons-material";
import Modal from "../general/form/Modal";
import { Loading } from "../general/form/Loading";

const TextCriterionForm = ({
  value,
  onRequestFormChange,
  onRequestActiveForm,
  onRequestDeleteForm,
}) => {
  const readOnly = value?.readOnly || false;
  const withInfo = value?.info;

  const [state, setState] = useState({
    isShowingInfoDialog: false,
    isInfoUpdated: false,
    info: value?.info || "",
    mode: "creation",
    loading: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleModalDisplay = () => {
    const stateObject = { isShowingInfoDialog: !state.isShowingInfoDialog };

    if (state.isShowingInfoDialog) {
      stateObject.info = "";
      stateObject.isInfoUpdated = false;
      stateObject.mode = "creation";
      stateObject.loading = false;
    }

    _setState(stateObject);
  };

  const _handleFormChange = (event) => {
    _setState({
      isInfoUpdated: true,
      info: event.target.value,
    });
  };

  const _handleSaveInfo = () => {
    onRequestFormChange(
      value?.id,
      value?.idNew,
      value.hasOwnProperty("isNew"),
      { target: { value: state.info } }, // Ceci est uniquement dans le but de ne pas poluer le code qui existe actuellement, car normalement c'est du event
      "info"
    );

    _handleModalDisplay();
  };

  useEffect(() => {
    if (value?.info && state.isShowingInfoDialog) {
      _setState({
        info: value.info,
        mode: "update",
      });
    }
  }, [value?.info, state.isShowingInfoDialog]);

  return (
    <>
      <Modal
        title={`${
          state.mode === "creation" ? "Ajout" : "Modification"
        } d'info bulle`}
        isShowing={state.isShowingInfoDialog}
        confirm={state.isInfoUpdated}
        saveBeforeClose={_handleSaveInfo}
        hide={_handleModalDisplay}
        messageBeforeClose="Voulez-vous valider avant de continuer"
      >
        {state.loading && <Loading />}
        <div className="info-bulle-form-content">
          <InputField
            readOnly={readOnly}
            context={this}
            isFocused={![null, undefined, ""].includes(state.info)}
            value={state.info}
            onChange={_handleFormChange}
          >
            Info bulle
          </InputField>
          <button
            className={`btn default btn-lg`}
            type="button"
            title="OK"
            onClick={_handleSaveInfo}
          >
            OK
          </button>
        </div>
      </Modal>
      <div
        className="data-TXT-item flex w-100"
        key={`data-TXT-item-${value.id !== undefined ? value.id : value.idNew}`}
      >
        <DragIndicator />
        <InputField
          className={`TXT-input input-tiny flex-grow formField--nopadding ${
            !value?.active ? "input-disactivated" : ""
          }`}
          name={`value-txt-${value?.id ?? value?.idNew}`}
          disabled={!value.active}
          value={value.value}
          context={this}
          onChange={(e) => {
            onRequestFormChange(
              value?.id,
              value?.idNew,
              value.hasOwnProperty("isNew"),
              e
            );
          }}
          readOnly={readOnly}
        />
        {value?.isNew ? (
          <button
            type="button"
            className="btn alert TXT-button"
            title="Supprimer cette valeur"
            disabled={readOnly}
            onClick={() => {
              onRequestDeleteForm(value);
            }}
          >
            <Delete />
          </button>
        ) : (
          <button
            type="button"
            className={`btn TXT-button ${value.active ? "green" : "red"}`}
            title={`${value.active ? "Désactiver" : "Activer"} cette valeur`}
            disabled={readOnly}
            onClick={() => {
              onRequestActiveForm(value);
            }}
          >
            {value.active ? <Visibility /> : <VisibilityOff />}
          </button>
        )}

        {/** Bouton pour l'info bulle */}
        <button
          type="button"
          className={`btn TXT-button`}
          title={withInfo ? value.info : "Ajouter une info bulle"}
          disabled={readOnly}
          onClick={_handleModalDisplay}
        >
          {withInfo ? <Info /> : <Add />}
        </button>
      </div>
    </>
  );
};

export default TextCriterionForm;
