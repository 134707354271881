import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import Helper from "../../../services/Helper";
import { HTTP_OK } from "fsy.common-library/lib/env/Constants";

/**
 * Return a switch component that update the question active state (question object passed in props)
 *
 * @param props {object}
 * @return {JSX.Element}
 */
export default function ActiveSwitch(props) {
  const [active, setActive] = useState(props.objectActive);
  const [loading, setLoading] = useState(false);
  const property = props.property ?? "active";

  const onColor = props.onColor ?? "#a6d290";
  const offColor = props.offColor ?? "#fc9999";
  // const uncheckedIcon =  ?? null
  // const checkedIcon =  ?? null

  useEffect(() => {
    setActive(props.objectActive);
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (typeof props.onRequestSetLoading === "function") {
      props.onRequestSetLoading(loading);
    }
    // eslint-disable-next-line
  }, [loading]);

  const handleChange = (activeState) => {
    setActive(activeState);
    if (props.onChange !== undefined) {
      props.onChange(activeState);
    }
  };

  if (props.apiFunction === undefined) {
    return (
      <ReactSwitch
        checked={active ?? false}
        className={props.className}
        id={`${props.idPrefix}-${props.objectId}`}
        onColor={onColor}
        offColor={offColor}
        uncheckedIcon={props.uncheckedIcon}
        checkedIcon={props.checkedIcon}
        onChange={handleChange}
        disabled={props.disabled}
      />
    );
  }

  const handleActiveSwitchChange = (activeState) => {
    if (typeof props.apiFunction == "function") {
      setLoading(true);
      props
        .apiFunction(props.objectId, { [property]: activeState })
        .then((response) => {
          if (response?.status === HTTP_OK) {
            setActive(activeState);
            toast.success("Etat mis à jour", Helper.getToastOptions(1000));
          } else {
            if (typeof props.callback === "function") {
              props.callback(response);
            } else {
              if (response["hydra:description"]) {
                toast.error(
                  response["hydra:description"],
                  Helper.getToastOptions()
                );
              } else {
                toast.error(
                  "Un problème est survenu lors de la mise à jour de l'état",
                  Helper.getToastOptions(1000)
                );
              }
            }
          }

          setLoading(false);
        });
    } else {
      props.onChange(activeState);
    }
  };
  return (
    <ReactSwitch
      onChange={handleActiveSwitchChange}
      checked={active ?? false}
      className={props.className}
      id={`${props.idPrefix}-${props.objectId}`}
      onColor={onColor}
      offColor={offColor}
      uncheckedIcon={props.uncheckedIcon}
      checkedIcon={props.checkedIcon}
      disabled={props.disabled}
    />
  );
}
