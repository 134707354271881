import { FormControl } from "@mui/base";
import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Helper from "../../../../services/Helper";

const AidFundingFixedAmount = ({
  editingData,
  readOnly,
  onRequestUpdateData,
  onRequestSetConfirm,
}) => {
  const [state, setState] = useState({
    amount: "",
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (event) => {
    const { value, name } = event.target;

    _setState({ [name]: value });

    if (typeof onRequestSetConfirm === "function") {
      onRequestSetConfirm(true);
    }

    if (typeof onRequestUpdateData === "function") {
      onRequestUpdateData({ shortName: "amount", value });
    }
  };

  useEffect(() => {
    if (Helper.isValidNumber(editingData?.["amount"])) {
      _setState({ amount: editingData["amount"] });
    }
  }, [editingData]);

  return (
    <div className="criterion-fixed-form-content">
      <label>Montant à l'hectare</label>
      <FormControl className="criterion-fixed-form">
        <TextField
          id="outlined-basic"
          type="number"
          value={state.amount}
          name="amount"
          onChange={_handleFormChange}
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          variant="outlined"
          size="small"
          disabled={readOnly}
        />
      </FormControl>
    </div>
  );
};

export default AidFundingFixedAmount;
