import { Add, Save } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import Step from "./step/Step";
import { Loading } from "../../../components/general/form/Loading";
import DragAndDrop from "../../general/DragAndDrop/DragAndDrop";
import { toast } from "react-toastify";
import Helper from "../../../services/Helper";
import { Api } from "fsy.common-library";
import Modal from "../../general/form/Modal";
import { InputField } from "../../general/form/Input";
import {
  HTTP_CREATED,
  HTTP_NO_CONTENT,
  HTTP_OK,
  HTTP_STEP_ALREADY_EXIST,
} from "fsy.common-library/lib/env/Constants";
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog/DeleteConfirmationDialog";

const Steps = ({
  loading,
  withWriteAuth,
  steps,
  updatingSteps,
  currentSimulatorId,
  currentStep,
  onRequestSetUpdatingState,
  onRequestChooseStep,
  onRequestGetSteps,
}) => {
  const [state, setState] = useState({
    isModalOpened: false,
    isDeleteConfirmModalOpened: false,
    modalModified: false,
    saving: false,
    stepToDeleteId: null,
    step: {
      id: null,
      name: "",
    },
  });

  const saveButtonRef = useRef(null);
  const stepListTitleRef = useRef(null);

  const otherHeight = 72 + stepListTitleRef.current?.clientHeight;
  const stepsListContentHeight = `calc(96vh - ${otherHeight}px)`;

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (event) => {
    const { name, value } = event.target;

    _setState({
      modalModified: true,
      step: {
        ...state.step,
        [name]: value,
      },
    });
  };

  const _handleModalDisplay = () => {
    _setState({ isModalOpened: !state.isModalOpened });
  };

  const _handleCloseModal = () => {
    _setState({
      isModalOpened: false,
      modalModified: false,
      step: {
        id: null,
        name: "",
      },
    });
  };

  const _handleSaveBeforeClose = () => {
    saveButtonRef.current?.click();
  };

  const _displayErrorMessageOnDragAndDrop = () => {
    toast.error(
      "Déplacement impossible à cause des dépendances de question.",
      Helper.getToastOptions()
    );
  };

  const _checkForm = () => {
    return state.step.name !== "";
  };

  const _handleFormSubmit = (event) => {
    event.preventDefault();

    if (_checkForm()) {
      _setState({ saving: true });
      const executeOperation = state.step.id
        ? Api.step.updateStep(state.step.id, { name: state.step.name })
        : Api.step.createStep(currentSimulatorId, state.step.name, "user");

      executeOperation
        .then((response) => {
          const objectState = {
            saving: false,
          };

          if (response?.status === HTTP_STEP_ALREADY_EXIST) {
            toast.warning("Cette étape existe déjà.", Helper.getToastOptions());
          } else {
            const expectedResultStatus = state.step.id ? HTTP_OK : HTTP_CREATED;

            if (response.status === expectedResultStatus) {
              objectState.isModalOpened = false;
              objectState.modalModified = false;
              objectState.step = {
                id: null,
                name: "",
              };

              onRequestGetSteps(false);

              toast.success(
                "Etape enregistrée avec succès.",
                Helper.getToastOptions()
              );
            }
          }

          _setState(objectState);
        })
        .catch((error) => {
          console.error(error);
          _setState({ saving: false });
        });
    } else {
      toast.error(
        "Veuillez renseigner toutes les données obligatoires.",
        Helper.getToastOptions()
      );
    }
  };

  const _handleCheckInNewElementsValids = (elements, currentDraggableId) => {
    const id = Number(currentDraggableId);
    const index = elements.findIndex((element) => element.id === id);
    const elementsBeforeDraggable = elements.slice(0, index) || [];
    const elementsAfterDraggable = elements.slice(index + 1) || [];
    const questionsBefore = [];
    const questionsAfter = [];
    const currentDraggableQuestions = elements[index].questions;
    const dependenciesElements1 = [];
    const dependenciesElements2 = [];

    elementsBeforeDraggable.map((element) => {
      questionsBefore.push(...(element.questions || []));

      return null;
    });

    elementsAfterDraggable.map((element) => {
      questionsAfter.push(...(element.questions || []));

      return null;
    });

    // Cas 1
    questionsBefore.map((question) => {
      currentDraggableQuestions.map((currentDraggableQuestion) => {
        let currentElement = question.questionDependencies.find(
          (element) => element?.target?.id === currentDraggableQuestion.id
        );

        if (currentElement) {
          dependenciesElements1.push(question);
        }

        return null;
      });

      return null;
    });

    // Cas 2
    currentDraggableQuestions.map((currentDraggableQuestion) => {
      let { questionDependencies } = currentDraggableQuestion;

      questionDependencies?.map((questionDependecy) => {
        questionsAfter.map((questionAfter) => {
          if (questionDependecy?.target?.id === questionAfter.id) {
            //
            dependenciesElements2.push(questionAfter);
          }

          return null;
        });

        return null;
      });

      return null;
    });

    const canDragAndDrop =
      !(dependenciesElements1.length > 0 || dependenciesElements2.length > 0) &&
      withWriteAuth;

    if (canDragAndDrop) {
      onRequestSetUpdatingState();
      const data = {
        simulatorId: currentSimulatorId,
        steps: elements.map((element) => {
          return { id: element.id };
        }),
      };

      Api.step
        .updateStepsOrder(data)
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            toast.success(
              "Modification d'ordre des étapes prise en compte.",
              Helper.getToastOptions()
            );
            onRequestGetSteps(false);
          }
        })
        .catch((error) => {
          console.error(error);
          onRequestSetUpdatingState();
        });
    } else {
      if (
        withWriteAuth &&
        (dependenciesElements1.length > 0 || dependenciesElements2.length > 0)
      ) {
        _displayErrorMessageOnDragAndDrop();
      }
    }

    return canDragAndDrop;
  };

  const _handleItemClick = (item, step) => {
    const { id } = step;

    if (item.action === "update") {
      _setState({
        isModalOpened: true,
        step: {
          ...state.step,
          id,
        },
      });
    } else {
      _setState({
        isDeleteConfirmModalOpened: true,
        stepToDeleteId: id,
      });
    }
  };

  const _handleDeleteConfirmationDialogDisplay = () => {
    _setState({
      isDeleteConfirmModalOpened: !state.isDeleteConfirmModalOpened,
    });
  };

  const _handleRemoveStep = () => {
    if (state.stepToDeleteId) {
      _setState({ isDeleteConfirmModalOpened: false });
      onRequestSetUpdatingState(true);
      Api.step
        .deleteStep(state.stepToDeleteId)
        .then((response) => {
          if (response?.status === HTTP_NO_CONTENT) {
            toast.success(
              "Étape supprimée avec succès.",
              Helper.getToastOptions()
            );
            onRequestGetSteps(false);
          }

          onRequestSetUpdatingState(false);
        })
        .catch((error) => {
          onRequestSetUpdatingState(false);
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (state.step.id) {
      _setState({ saving: true });
      Api.step
        .getStep(state.step.id)
        .then((response) => {
          const result = Helper.isValidResponse(response);
          const objectState = {
            saving: false,
            step: {
              ...state.step,
              name: "",
            },
          };

          if (result) {
            objectState.step.name = result.name;
          }

          _setState(objectState);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, [state.step.id]);

  return (
    <div className="steps-list">
      {updatingSteps && <Loading />}
      <Modal
        title={`${state.step.id ? "Modifier" : "Créer"} une étape`}
        isShowing={state.isModalOpened}
        confirm={state.modalModified}
        saveBeforeClose={_handleSaveBeforeClose}
        hide={_handleCloseModal}
      >
        <form
          className="step-form-root"
          noValidate
          onSubmit={_handleFormSubmit}
        >
          {state.saving && <Loading />}
          <InputField
            required={true}
            className="input-tiny"
            name="name"
            isFocused={state.step.name !== ""}
            value={state.step.name}
            onChange={_handleFormChange}
            title="Nom de l'étape"
            readOnly={!withWriteAuth}
            disabled={!withWriteAuth}
          >
            Nom de l'étape
          </InputField>
          {withWriteAuth && (
            <div className="step-form-footer">
              <button
                ref={saveButtonRef}
                className="btn default btn-lg"
                type="submit"
                title="Enregistrer la donnée saisie"
              >
                <Save />
                Enregistrer
              </button>
            </div>
          )}
        </form>
      </Modal>
      <DeleteConfirmationDialog
        elementLabel="étape"
        isElementFeminine={true}
        isElementBeginningWithLowerCase={true}
        isShowing={state.isDeleteConfirmModalOpened}
        onRequestHide={_handleDeleteConfirmationDialogDisplay}
        onRequestRemoveElement={_handleRemoveStep}
      />
      <div ref={stepListTitleRef} className="steps-list-title">
        <span>Étapes</span>
        <button
          className={`btn btn-tiny default addButton ${
            loading ? "disabled" : ""
          } ${!withWriteAuth ? "visibility-hidden" : ""}`}
          title="Cliquer pour ajouter une étape"
          onClick={_handleModalDisplay}
        >
          <Add />
          Ajouter une étape
        </button>
      </div>
      <div
        className={`steps-list-content${loading ? "-with-loading" : ""}`}
        style={{ height: stepsListContentHeight }}
      >
        {loading && <Loading />}
        {withWriteAuth ? (
          <DragAndDrop
            elements={steps}
            renderComponent={(step) => (
              <Step
                withWriteAuth={withWriteAuth}
                data={step}
                active={currentStep?.id === step.id}
                onRequestChooseStep={onRequestChooseStep}
                onRequestItemClick={(item) => _handleItemClick(item, step)}
              />
            )}
            checkIfNewElementsValids={_handleCheckInNewElementsValids}
          />
        ) : (
          <>
            {steps.map((step, index) => {
              return (
                <Step
                  key={`Etape${index + 1}`}
                  withWriteAuth={withWriteAuth}
                  data={step}
                  active={currentStep?.id === step.id}
                  onRequestChooseStep={onRequestChooseStep}
                  onRequestItemClick={(item) => _handleItemClick(item, step)}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Steps;
