import React, { useEffect, useRef, useState } from "react";
import "./simulatorList.css";
import { Api, Session, Constants } from "fsy.common-library";
import Helper from "../../../services/Helper";
import SimulatorInfo from "./SimulatorInfo";
import Steps from "./Steps";
import Questions from "./Questions";
import SimulatorActivities from "./SimulatorActivities";
import { RIGHTS_WRITE } from "../../../services/Constants";
import { Loading } from "../../general/form/Loading";
import { toast } from "react-toastify";
import ConfirmationDialog from "../../general/DeleteConfirmationDialog/DeleteConfirmationDialog";

const SimulatorList = () => {
  const simulatorInfoRef = useRef(null);

  const [state, setState] = useState({
    firstLoading: true,
    stepsLoading: false,
    questionsLoading: false,
    simulatorLoading: false,
    updatingSteps: false,
    currentSimulator: null,
    firstVersion: null,
    currentVersion: null,
    lastVersion: null,
    currentStep: null,
    publishedVersion: null,
    currentQuestions: [],
    simulatorVersions: [],
    isSimulatorActivitiesContentOpened: false,
    isOpenedSimulatorStatusConfirmationUpdate: false,
    activitiesContentHeight: undefined,
    histories: [],
  });

  const isPublished = state.currentSimulator?.published || false;
  const withWriteAuth =
    Session.getAuth()?.simulator >= RIGHTS_WRITE &&
    !isPublished &&
    state.currentVersion?.value === state.lastVersion;

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleChooseStep = (choosenStep) => {
    if (state.currentStep?.id !== choosenStep?.id) {
      _setState({ currentStep: choosenStep });
    }
  };

  const _handleActivityContentDisplay = () => {
    _setState({
      isSimulatorActivitiesContentOpened:
        !state.isSimulatorActivitiesContentOpened,
    });
  };

  const _handleSetQuestions = (questions) => {
    _setState({ currentQuestions: questions });
  };

  const _getQuestions = (withLoading = true) => {
    if (withLoading) {
      _setState({ questionsLoading: true });
    }

    if (state.currentSimulator?.id && state.currentStep?.id) {
      Api.question
        .getSimulatorStepQuestions(
          state.currentSimulator.id,
          state.currentStep.id
        )
        .then((response) => {
          const questions = Helper.isValidResponse(response);
          const stateObject = {
            questionsLoading: false,
          };

          if (questions) {
            stateObject.currentQuestions = questions;
          }

          _setState(stateObject);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const _getSteps = (
    withSettingDefaultValue = true,
    version = state.currentVersion?.value
  ) => {
    Promise.all([
      Api.simulator.getSimulatorWithAdditionalInfo(version),
      Api.simulatorHistory.getSimulatorHistories(),
    ])
      .then(([response, responseHistories]) => {
        const stateObject = {
          firstLoading: false,
          updatingSteps: false,
          simulatorLoading: false,
          histories: [],
        };

        const data = Helper.isValidResponse(response);
        const histories = Helper.isValidResponse(responseHistories);

        if (data) {
          stateObject.publishedVersion = data.publishedVersion;

          stateObject.simulatorVersions = [];

          stateObject.firstVersion = data.firstVersion;

          stateObject.lastVersion = data.lastVersion;

          for (let i = 0; i < data.lastVersion; i++) {
            stateObject.simulatorVersions.push({
              value: i + 1,
              label: `Version ${i + 1}`,
            });
          }

          stateObject.currentSimulator = data.simulator;

          stateObject.currentVersion = {
            value: version ?? data.simulator.versionNumber,
            label: `Version ${version ?? data.simulator.versionNumber}`,
          };

          if (stateObject.currentSimulator?.steps?.length > 0) {
            // Il faut ranger les étapes par ordre de position
            stateObject.currentSimulator.steps.sort((step1, step2) => {
              return step1.position - step2.position;
            });

            // Il faut extraire les infos nécéssaires uniquements
            stateObject.currentSimulator.steps =
              stateObject.currentSimulator.steps.map((step) => {
                return {
                  id: step.id,
                  name: step.name,
                  position: step.position,
                  questions: step.questions,
                  questionsCount: step.questions?.length || 0,
                  icon: step.icon,
                };
              });

            if (withSettingDefaultValue) {
              stateObject.currentStep = stateObject.currentSimulator.steps[0];
            }
          }
        }

        if (histories) {
          stateObject.histories = histories;
        }

        _setState(stateObject);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const _handleSetUpdatingState = (status = null) => {
    _setState({
      updatingSteps: status !== null ? status : !state.updatingSteps,
    });
  };

  const _handleCreateNewVersion = () => {
    if (state.currentSimulator) {
      const { id, parent } = state.currentSimulator;
      const user = Session.getSessionUser();

      _setState({ simulatorLoading: true });
      Api.simulator
        .createSimulator(parent ? parent.id : id, user.id, 0)
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            _getSteps(true, result.versionNumber);
            toast.success(
              "Nouvelle version créée avec succès.",
              Helper.getToastOptions()
            );
          }

          _setState({ simulatorLoading: false });
        })
        .catch((error) => {
          console.error(error);
          _setState({ simulatorLoading: false });
        })
        .finally(() => _setState({ simulatorLoading: false }));
    }
  };

  const _handleChangeVersion = (data) => {
    if (data) {
      const version = data.value;
      _setState({ simulatorLoading: true });
      _getSteps(true, version);
      _setState({
        currentVersion: {
          value: version,
          label: `Version ${version}`,
        },
      });
    }
  };

  const _handleChangeSimulatorStatus = () => {
    _setState({
      isOpenedSimulatorStatusConfirmationUpdate: false,
      simulatorLoading: true,
    });
    Api.simulator
      .updateSimulatorStatus(state.currentSimulator.id, {
        id: state.currentSimulator.id,
        status: true,
      })
      .then((response) => {
        const result = Helper.isValidResponse(response);

        if (result) {
          _getSteps(false);
          toast.success(
            "Statut du simulateur mis à jour avec succès.",
            Helper.getToastOptions()
          );
        }

        _setState({ simulatorLoading: false });
      })
      .catch((error) => {
        _setState({ simulatorLoading: false });
        console.error(error);
      });
  };

  const _handleReinitVersion = () => {
    _setState({ simulatorLoading: true });
    Api.simulator
      .reinitVersion(state.currentSimulator.id, {
        id: state.currentSimulator.id,
      })
      .then((response) => {
        const result = Helper.isValidResponse(response);

        if (result) {
          _getSteps(true, state.currentVersion.value);

          toast.success(
            "Version réinitialisée à partir de celle qui est publiée.",
            Helper.getToastOptions()
          );
        }

        _setState({ simulatorLoading: false });
      })
      .catch((error) => {
        console.error(error);
        _setState({ simulatorLoading: false });
      });
  };

  const _redirectToFrontSimulator = (simulatorUuid, organizationUuid) => {
    const user = Session.getSessionUser();
    const url = `${process.env.REACT_APP_PROJECT_FRONT_URL}/simulator-sandbox-mode?mode=${Constants.SIMULATOR_SANDBOX_MODE}&uuid=${simulatorUuid}&userId=${user?.id}&organizationUuid=${organizationUuid}`;

    window.open(url, "_blank");
  };

  const _handleSandboxTest = () => {
    const { id: simulatorId } = state.currentSimulator;

    if (simulatorId) {
      _setState({ simulatorLoading: true });
      const user = Session.getSessionUser();
      Api.organization
        .getOrganization(user?.organization?.id)
        .then((response) => {
          const organization = Helper.isValidResponse(response);

          if (organization?.uuid) {
            const organizationUuid = organization.uuid;

            Api.simulator
              .updateSimulatorUuid(simulatorId)
              .then((response) => {
                const result = Helper.isValidResponse(response);

                if (result?.uuid) {
                  _redirectToFrontSimulator(result.uuid, organizationUuid);
                }

                _setState({ simulatorLoading: false });
              })
              .catch((error) => {
                console.error(error);
                _setState({ simulatorLoading: false });
              });
          } else {
            _setState({ simulatorLoading: false });
          }
        })
        .catch((error) => {
          _setState({ simulatorLoading: false });
          console.error(error);
        });
    }
  };

  useEffect(() => {
    _getSteps();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    _setState({ questionsLoading: true });
    if (state.currentSimulator?.id && state.currentStep?.id) {
      Api.question
        .getSimulatorStepQuestions(
          state.currentSimulator.id,
          state.currentStep.id
        )
        .then((response) => {
          const questions = Helper.isValidResponse(response);
          const stateObject = {
            questionsLoading: false,
          };

          if (questions) {
            stateObject.currentQuestions = questions;
          }

          _setState(stateObject);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [state.currentStep?.id, state.currentSimulator?.id]);

  useEffect(() => {
    const simulatorInfoHeight = simulatorInfoRef.current?.clientHeight;
    if (simulatorInfoHeight) {
      const otherHeight = simulatorInfoHeight;

      _setState({ activitiesContentHeight: `calc(90vh - ${otherHeight}px)` });
    }
  }, [simulatorInfoRef.current?.clientHeight]);

  return (
    <div className="simulator-list-root">
      {state.simulatorLoading && <Loading />}
      <ConfirmationDialog
        title="Confirmation de publication de version"
        content={
          <div>
            <div className="center">
              Vous êtes sur le point de publier une nouvelle version du
              simulateur.
            </div>
            <div className="center">Confirmez-vous cette publication ?</div>
          </div>
        }
        isShowing={state.isOpenedSimulatorStatusConfirmationUpdate}
        onRequestHide={() =>
          _setState({
            isOpenedSimulatorStatusConfirmationUpdate:
              !state.isOpenedSimulatorStatusConfirmationUpdate,
          })
        }
        onRequestRemoveElement={_handleChangeSimulatorStatus}
      />
      <div className="steps-content">
        <Steps
          readOnly={false}
          withWriteAuth={withWriteAuth}
          loading={state.firstLoading || state.stepsLoading}
          steps={state.currentSimulator?.steps || []}
          currentSimulatorId={state.currentSimulator?.id}
          currentStep={state.currentStep || {}}
          updatingSteps={state.updatingSteps}
          onRequestSetUpdatingState={_handleSetUpdatingState}
          onRequestChooseStep={_handleChooseStep}
          onRequestGetSteps={_getSteps}
        />
        <Questions
          withWriteAuth={withWriteAuth}
          currentSimulatorId={state.currentSimulator?.id}
          currentStepId={state.currentStep?.id}
          loading={state.firstLoading || state.questionsLoading}
          steps={state.currentSimulator?.steps || []}
          questions={state.currentQuestions || []}
          onRequestSetQuestions={_handleSetQuestions}
          onRequestGetSteps={_getSteps}
          onRequestGetQuestions={_getQuestions}
          currentStep={state.currentStep}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "18%" }}>
        <SimulatorInfo
          loading={state.simulatorLoading || state.firstLoading}
          withWriteAuth={withWriteAuth}
          isPublishedVersion={state.currentSimulator?.published}
          simulatorInfoRef={simulatorInfoRef}
          publishedVersion={state.publishedVersion}
          currentVersion={state.currentVersion}
          info={state.currentSimulator || {}}
          versions={state.simulatorVersions}
          onRequestActivityButtonClick={_handleActivityContentDisplay}
          onRequestCreateNewVersion={_handleCreateNewVersion}
          onRequestChangeVersion={_handleChangeVersion}
          onRequestSandboxTest={_handleSandboxTest}
          onRequestChangeSimulatorStatus={() =>
            _setState({ isOpenedSimulatorStatusConfirmationUpdate: true })
          }
          onRequestReinitVersion={_handleReinitVersion}
        />
        {state.isSimulatorActivitiesContentOpened && (
          <SimulatorActivities
            contentHeight={state.activitiesContentHeight}
            activities={[
              ...state.histories.map((history) => {
                return {
                  user: `${history.user?.name || ""} ${history.user?.surname}`,
                  action: history.action,
                  date: history.date,
                };
              }),
              // Création du simulateur
              {
                user: `${state.firstVersion?.createdBy?.name || ""} ${
                  state.firstVersion?.createdBy?.surname || ""
                }`,
                action: "Création du simulateur",
                date: state.firstVersion?.createdAt || new Date(),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default SimulatorList;
