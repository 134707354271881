import React, { Fragment } from "react";
import "./aidFundingTable.css";
import { Delete } from "@mui/icons-material";
import { Constants } from "fsy.common-library";
import Helper from "../../../../services/Helper";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";

const AidFundingTable = ({
  criterions,
  lines,
  aidFixedFundingElements,
  disabled,
  onRequestRowClick,
  onRequestDeleteRow,
  onRequestGetCriterionCVLabelById,
  onRequestGetZoneByTypeId,
  onRequestGetLocationById,
  onRequestGetSpecieLabelById,
  onRequestGetDensityLabelById,
}) => {
  const SPECICIC_CRITERIONS = ["loca_01", "criPlan_01"];

  return (
    <div className="aid-funding-table-root">
      <table>
        {/** Titre du tableau des financements */}
        <tr className="title-row">
          {criterions.map((criterion, index) => {
            let shortName = criterion.shortName;
            let isCenteredTitle =
              criterion?.type?.shortName === Constants.CRITERION_TYPE_OBG ||
              (criterion?.type?.shortName === Constants.CRITERION_TYPE_NUM &&
                shortName !== "criPlan_01");

            return !SPECICIC_CRITERIONS.includes(shortName) ? (
              <th
                key={`FundingCriterionTitleContent${index + 1}`}
                className={`${isCenteredTitle ? "centered-title" : ""}`}
              >
                {criterion.name}
              </th>
            ) : (
              <Fragment key={`FundingCriterionTitleContent${index + 1}`}>
                <th>
                  {shortName === "loca_01"
                    ? "Type de localisation"
                    : "Groupe d'essences"}
                </th>
                <th>
                  {shortName === "loca_01" ? "Localisation" : "Essence(s)"}
                </th>
              </Fragment>
            );
          })}
          {aidFixedFundingElements.map((fixedData, i) => {
            return (
              <th key={`FixedFundingTitle${i + 1}`} className="centered-title">
                {fixedData.label}
              </th>
            );
          })}
          <td></td>
        </tr>

        {/** Contenus du tableau des financements */}
        {lines?.length > 0 &&
          lines?.map((line, i) => {
            return (
              <tr
                className="value-row"
                key={`FundingCriterionRow${i + 1}`}
                onClick={() => onRequestRowClick(line)}
                title="Cliquer pour modifier les données de la ligne"
              >
                {criterions.map((criterion, index) => {
                  let shortName = criterion.shortName;
                  let label = "-";
                  let label2 = "-";
                  let data = line?.data?.[shortName];
                  let isCenteredTitle =
                    data?.type === Constants.CRITERION_TYPE_NUM &&
                    shortName !== "criPlan_01";

                  if (data) {
                    let type = data.type;

                    if (type === Constants.CRITERION_TYPE_TXT) {
                      if (Array.isArray(data.value) && data.value?.length > 0) {
                        let elements = [];

                        data.value.map((val) => {
                          let currentLabel = ![
                            "criPlan_04",
                            "criPlan_05",
                          ].includes(shortName)
                            ? onRequestGetCriterionCVLabelById(val)
                            : onRequestGetSpecieLabelById(val, true);

                          elements.push(currentLabel);
                          return null;
                        });

                        if (elements.length > 0) {
                          label = elements.join(", ");
                        }
                      }

                      if (typeof data.value === "number") {
                        label = onRequestGetCriterionCVLabelById(data.value);
                      }
                    }

                    if (type === Constants.CRITERION_TYPE_OBG) {
                      label = onRequestGetCriterionCVLabelById(data.value);
                    }

                    if (type === Constants.CRITERION_TYPE_LOC) {
                      label = onRequestGetZoneByTypeId(data.value?.type);

                      if (
                        [
                          Constants.LOCATION_TYPE_REGIONAL,
                          Constants.LOCATION_TYPE_DEPARTMENTAL,
                        ].includes(data.value?.type) &&
                        data.value?.location
                      ) {
                        label2 = onRequestGetLocationById(
                          data.value.location,
                          data.value.type
                        );
                      }
                    }

                    if (type === Constants.CRITERION_TYPE_NUM) {
                      if (shortName === "criPlan_01") {
                        label = onRequestGetSpecieLabelById(
                          data.value?.specieGroup
                        );

                        if (
                          Array.isArray(data.value?.specie) &&
                          data.value?.specie?.length > 0
                        ) {
                          let speciesLabel = [];

                          data.value.specie.map((sp) => {
                            speciesLabel.push(
                              onRequestGetSpecieLabelById(sp, true)
                            );
                            return null;
                          });

                          label2 = speciesLabel.join(", ");
                        }
                      } else {
                        let val = data.value;

                        if (
                          Array.isArray(val) &&
                          val?.length === 2 &&
                          Helper.isValidNumber(val[0]) &&
                          Helper.isValidNumber(val[1])
                        ) {
                          let min = val[0];
                          let max = val[1];
                          let unit = criterion.unit ?? "€";

                          if (min !== max) {
                            if (min === 0 && max > 0) {
                              label = `Jusqu'à ${GenericHelper.digitWithDecimalPrecision(
                                +max
                              )} ${unit}`;
                            } else {
                              label = `${GenericHelper.digitWithDecimalPrecision(
                                +min
                              )} ${unit} à ${GenericHelper.digitWithDecimalPrecision(
                                +max
                              )} ${unit}`;
                            }
                          } else {
                            label = `${GenericHelper.digitWithDecimalPrecision(
                              +max
                            )} ${unit}`;
                          }
                        }
                      }
                    }
                  }

                  return !SPECICIC_CRITERIONS.includes(shortName) ? (
                    <td
                      key={`FundingCriterionElementColumn${i + 1}${index + 1}`}
                      className={`${isCenteredTitle ? "centered-title" : ""} ${
                        data?.type === Constants.CRITERION_TYPE_OBG
                          ? "center"
                          : ""
                      }`}
                    >
                      {label}
                    </td>
                  ) : (
                    <Fragment
                      key={`FundingCriterionElementColumn${i + 1}${index + 1}`}
                    >
                      <td>{label}</td>
                      <td>{label2}</td>
                    </Fragment>
                  );
                })}
                {aidFixedFundingElements.map((fixedData, index) => {
                  let value = "-";

                  if (Helper.isValidNumber(line?.data?.[fixedData.shortName])) {
                    value = `${GenericHelper.digitWithDecimalPrecision(
                      +line.data[fixedData.shortName]
                    )} ${fixedData.unit}`;
                  }

                  if (fixedData.shortName === "density") {
                    value = onRequestGetDensityLabelById(
                      line?.data?.[fixedData.shortName]
                    );
                  }

                  return (
                    <td
                      key={`FundingFixedDataElement${i + 1}${index + 1}`}
                      className={`${
                        fixedData.shortName !== "density"
                          ? "centered-title"
                          : "center"
                      }`}
                    >
                      {value}
                    </td>
                  );
                })}
                <td>
                  {!disabled && (
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        onRequestDeleteRow(line);
                      }}
                      className="btn btn-tiny red"
                      type="button"
                      title="Supprimer la ligne"
                    >
                      <Delete />
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default AidFundingTable;
